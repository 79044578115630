import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../hooks/Notification';
import theme from '../../common/default-theme';
import { NotificationsList } from '../Shared/Notifications/NotificationsList';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    opacity: 0.8,
    backgroundColor: theme.colors.black,
  },
});

const Backdrop: React.FC = () => {
  const { closeNotification } = useNotification();
  const notifications = useNotificationsList();

  return (
    <View style={styles.container}>
      <NotificationsList
        notifications={notifications}
        onDismiss={closeNotification}
      />
    </View>
  );
};

export default Backdrop;
