import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    rowGap: 8,
    alignSelf: 'center',
  },
  content: {
    padding: 30,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
});

export default styles;
