import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const OrderHistoryStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    filters: {
      margin: 20,
      columnGap: 8,
      flexDirection: 'row',
      alignItems: 'center',
    },
    search: {
      flex: 1,
    },
    dropdown: {
      width: 180,
    },
    datePicker: {
      width: 200,
      height: 44,
      flexDirection: 'row',
      paddingHorizontal: 12,
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: theme.radius.s,
      borderColor: colors.borderColor,
      backgroundColor: colors.elevation0,
    },
    dateLabel: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontVariant: ['tabular-nums'],
    },
    orderTypeDropdown: {
      marginHorizontal: 10,
    },
  });
};

export default OrderHistoryStyles;
