import { StyleSheet } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';

interface Props {
  info?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
}

const NotificationStyles = ({
  info = false,
  error = false,
  success = false,
  warning = false,
}: Props) => {
  const { colors } = useTheme();

  const getBgColor = () => {
    if (success) {
      return colors.green;
    } else if (info) {
      return colors.blue;
    } else if (warning) {
      return colors.orange;
    } else if (error) {
      return colors.red;
    } else {
      return colors.black;
    }
  };

  return StyleSheet.create({
    list: {
      top: 0,
      zIndex: 99,
      width: '100%',
      position: 'absolute',
    },
    container: {
      padding: 12,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: getBgColor(),
    },
    text: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      color: colors.white,
      textAlign: 'center',
      fontFamily: theme.fonts.medium,
      marginLeft: 22,
    },
    btn: {
      width: 20,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default NotificationStyles;
