import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const COLUMN_GAP = 20;

const OpenOrderStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    filters: {
      padding: 20,
      columnGap: 8,
      flexDirection: 'row',
    },
    search: {
      flex: 1,
    },
    dropdown: {
      width: 180,
    },
    noteContainer: {
      width: 380,
      padding: 20,
      alignSelf: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
      rowGap: 32,
    },
    note: {
      rowGap: 20,
      alignItems: 'center',
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    noteTitle: {
      fontSize: 16,
      lineHeight: 24,
      color: colors.text,
      marginTop: 20,
      fontFamily: theme.fonts.medium,
    },
    noteText: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    table: {
      marginBottom: 100,
      paddingHorizontal: 20,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      rowGap: 4,
      marginVertical: 8,
    },
    cellFiller: {
      width: 134,
    },
    cellPlacedAt: {
      width: 124,
      marginRight: COLUMN_GAP,
    },
    cell100: {
      width: 100,
      marginRight: COLUMN_GAP,
    },
    cellName: {
      flex: 1,
      marginRight: COLUMN_GAP,
    },
    cellNote: {
      flex: 1,
      marginRight: COLUMN_GAP,
    },
    cellLastAction: {
      width: 100,
      textAlign: 'right',
      marginRight: COLUMN_GAP,
    },
    cellAmount: {
      width: 74,
      textAlign: 'right',
      marginRight: 194,
    },
  });
};

export default OpenOrderStyles;
