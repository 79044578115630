import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

interface Props {
  themed: boolean;
  isOtherCourse?: boolean;
  disabled?: boolean;
  autoFire?: boolean;
}

const CartCourseStyles = ({
  themed,
  isOtherCourse,
  disabled,
  autoFire,
}: Props) => {
  const { colors } = useTheme();

  const getBgColor = () => {
    if (themed) {
      return isOtherCourse
        ? colors.tealLight
        : disabled
        ? colors.elevation2
        : autoFire
        ? colors.greenLight
        : colors.orangeLight;
    } else {
      return isOtherCourse
        ? theme.colors.tealLight
        : disabled
        ? theme.colors.grey2
        : autoFire
        ? theme.colors.greenLight
        : theme.colors.orangeLight;
    }
  };

  const getFgColor = () => {
    return isOtherCourse
      ? theme.colors.teal
      : disabled && themed
      ? colors.text
      : disabled && !themed
      ? theme.colors.dark
      : autoFire
      ? theme.colors.green
      : theme.colors.orange;
  };

  return StyleSheet.create({
    parent: {
      height: 28,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    indicator: {
      minWidth: 28,
      height: 28,
      marginRight: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: getBgColor(),
    },
    name: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
      backgroundColor: getBgColor(),
    },
    nameText: {
      flex: 1,
      fontSize: 13,
      lineHeight: 20,
      paddingVertical: 4,
      color: getFgColor(),
      fontFamily: theme.fonts.semibold,
      textTransform: 'uppercase',
    },
    textAlign: {
      textAlign: 'center',
    },
    iconFire: {
      color: disabled ? theme.colors.grey4 : getFgColor(),
    },
    fgColor: {
      color: getFgColor(),
    },
  });
};

export default CartCourseStyles;
