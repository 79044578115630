import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const EnrolCustomerModalStyles = () => {
  return StyleSheet.create({
    container: {
      width: 400,
      alignSelf: 'center',
      borderRadius: theme.radius.l,
      backgroundColor: theme.colors.white,
      overflow: 'hidden',
    },
    header: {
      rowGap: 20,
      padding: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
    btnClose: {
      width: 38,
      height: 38,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 8,
      left: 8,
    },
    stars: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
    },
    text: {
      rowGap: 8,
    },
    title: {
      fontSize: 18,
      lineHeight: 26,
      color: theme.colors.white,
      fontFamily: theme.fonts.medium,
      textAlign: 'center',
    },
    subtitle: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.white,
      fontFamily: theme.fonts.medium,
      textAlign: 'center',
      opacity: 0.6,
    },
    body: {
      padding: 30,
      rowGap: 30,
    },
    form: {
      rowGap: 20,
    },
  });
};

export default EnrolCustomerModalStyles;
