import React, { useState, useCallback, useRef } from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import { ButtonActionsProps } from './Button.types';
import theme from '../../../common/default-theme';
import ButtonStyles from './Button.styles';
import SelectStyles from '../Select/Select.styles';
import Icon from '../../Icon/Icon';

const ButtonActions: React.FC<ButtonActionsProps> = ({
  testID,
  actions,
  disabled,
  height,
  icon,
  label,
  loading = false,
  placement,
  themed = false,
  type = 'cancel',
  uppercase = false,
  containerStyle,
}: ButtonActionsProps) => {
  const touchable = useRef(null);

  const [showOptions, setShowOptions] = useState(false);

  const selectStyles = SelectStyles({ themed: false });
  const styles = ButtonStyles({ type, themed, disabled, uppercase });

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const closeDropDown = useCallback((): void => {
    setShowOptions(false);
  }, []);

  const onBlur = useCallback(
    (e): void => {
      const targetEvent = (
        e.nativeEvent as unknown as {
          relatedTarget: unknown;
        }
      )?.relatedTarget;
      !targetEvent && closeDropDown();
    },
    [closeDropDown],
  );

  const onToggleOptions = useCallback((): void => {
    setShowOptions(!showOptions);
  }, [showOptions]);

  const handlePress = useCallback(
    (action: Function) => {
      action();
      closeDropDown();
    },
    [closeDropDown],
  );

  const onToggle = useCallback(
    (action: Function) => {
      onToggleOptions();
      setTimeout(() => {
        handlePress(action);
      }, 500);
    },
    [handlePress, onToggleOptions],
  );

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        ref={touchable}
        testID={testID}
        onBlur={onBlur}
        disabled={disabled}
        onPress={onToggleOptions}
        style={[
          styles.container,
          // eslint-disable-next-line react-native/no-inline-styles
          { height: height || 44, minWidth: icon ? height : 100 },
        ]}
      >
        <View style={styles.btnAction}>
          {loading ? (
            <ActivityIndicator
              color={
                type === 'primaryLight' ? theme.colors.dark : theme.colors.light
              }
            />
          ) : !icon ? (
            <>
              <Text style={[styles.text, styles.fgColor]}>{label}</Text>
              <Icon
                size={20}
                style={styles.fgColor}
                name={icon ? icon : showOptions ? 'angle-up' : 'angle-down'}
              />
            </>
          ) : (
            <Icon size={20} name={icon} style={styles.fgColor} />
          )}
        </View>
      </TouchableOpacity>
      <Popover
        from={touchable}
        isVisible={showOptions}
        placement={placement ?? PopoverPlacement.AUTO}
        onRequestClose={() => onRequestClosePopover()}
        backgroundStyle={selectStyles.bgStyle}
        popoverStyle={selectStyles.optionsContainer}
      >
        <View
          style={[
            selectStyles.optionsPopup,
            actions.length < 10 && { maxHeight: actions.length * 40 + 20 },
          ]}
        >
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <ScrollView contentContainerStyle={{ rowGap: 4 }}>
            {actions.map((action, i: number) => (
              <TouchableOpacity
                key={i}
                disabled={action.disabled}
                onPress={() => onToggle(action.action)}
                style={[
                  styles.option,
                  // eslint-disable-next-line react-native/no-inline-styles
                  { opacity: action.disabled ? 0.3 : 1 },
                ]}
              >
                <Text
                  numberOfLines={1}
                  style={[
                    selectStyles.optionText,
                    action.textStyle && action.textStyle !== 'default'
                      ? { color: theme.colors.states[action.textStyle] }
                      : null,
                  ]}
                >
                  {action.label}
                </Text>
                <View style={styles.icon}>
                  {action.icon ? (
                    <Icon
                      size={20}
                      name={action.icon}
                      color={action.iconColor}
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="angle-right"
                      color={theme.colors.grey4}
                    />
                  )}
                </View>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      </Popover>
    </View>
  );
};

export default ButtonActions;
