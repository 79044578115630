import { StyleSheet } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';

const LayoutStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    screen: {
      flex: 1,
      backgroundColor: colors.background,
    },
    loader: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default LayoutStyles;
