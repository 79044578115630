import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import CartTotalStyles from './CartTotals.styles';

export interface ChangeDueProps {
  testID?: string;
  change: number;
  themed?: boolean;
  containerStyle?: ViewStyle;
}

const ChangeDue: React.FC<ChangeDueProps> = ({
  change,
  themed = false,
  containerStyle,
}: ChangeDueProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const styles = CartTotalStyles({ themed });

  return (
    <View
      testID="change-summary-container"
      style={[styles.changeDue, containerStyle]}
    >
      <Text style={styles.changeDueText}>{translate('payment.changeDue')}</Text>
      <Text style={styles.changeDueText}>{formatCurrency(change)}</Text>
    </View>
  );
};

export default ChangeDue;
