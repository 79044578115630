import { StyleSheet } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';

const ManageCashStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    content: {
      height: '100%',
    },
    filters: {
      margin: 20,
      flexDirection: 'row',
    },
    search: {
      flex: 1,
      marginRight: 10,
    },
    dropdown: {
      width: 180,
      marginRight: 10,
    },
    table: {
      marginBottom: 80,
      marginHorizontal: 20,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      marginVertical: 8,
    },
    tableRow: {
      height: 50,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    cellType: {
      width: 60,
      marginLeft: 12,
      textAlign: 'center',
    },
    cellDate: {
      width: 110,
      marginLeft: 12,
      color: colors.text,
    },
    cellUser: {
      width: 140,
      marginLeft: 12,
      color: colors.text,
    },
    cellReason: {
      width: 140,
      marginLeft: 12,
      color: colors.text,
    },
    cellNote: {
      flex: 1,
      marginLeft: 20,
    },
    headerAmount: {
      width: 110,
      marginLeft: 20,
      marginRight: 106,
      textAlign: 'right',
    },
    cellAmount: {
      width: 110,
      marginRight: 20,
      textAlign: 'right',
      color: colors.text,
      fontVariant: ['tabular-nums'],
    },
    cellInfo: {
      marginRight: 6,
    },
    cellPrint: {
      marginRight: 12,
    },
  });
};

export default ManageCashStyles;
