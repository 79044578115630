import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const ShiftsSidePanelStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 340,
      position: 'absolute',
      right: 0,
      bottom: 0,
      padding: 6,
      rowGap: 4,
      backgroundColor: colors.background,
      ...theme.shadow.light,
    },
    content: {
      padding: 20,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    group: {
      marginBottom: 14,
      paddingBottom: 14,
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    row: {
      flexDirection: 'row',
      columnGap: 1,
      marginBottom: 8,
    },
    groupTitle: {
      fontSize: 13,
      lineHeight: 20,
      marginBottom: 12,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    rowTitle: {
      fontSize: 13,
      lineHeight: 18,
      color: colors.textLight,
      fontFamily: theme.fonts.regular,
      fontVariant: ['tabular-nums'],
    },
    rowValue: {
      flex: 1,
      fontSize: 13,
      lineHeight: 18,
      textAlign: 'right',
      color: colors.textBody,
      fontFamily: theme.fonts.regular,
      fontVariant: ['tabular-nums'],
    },
    rowTotalText: {
      flex: 1,
      fontSize: 13,
      lineHeight: 18,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    rowTotalValue: {
      textAlign: 'right',
    },
    negative: {
      color: colors.red,
    },
    positive: {
      color: colors.green,
    },
  });
};

export default ShiftsSidePanelStyles;
