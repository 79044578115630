import { StyleSheet, ViewStyle } from 'react-native';
import theme from '../../../../common/default-theme';

const accessories: ViewStyle = {
  flexDirection: 'row',
  columnGap: 4,
  position: 'absolute',
  top: 4,
};

const styles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-color-literals
  container: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.04)',
    backgroundColor: theme.colors.white,
  },
  text: {
    fontSize: 13,
    lineHeight: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
  },
  lhsAccessories: {
    ...accessories,
    left: 4,
  },
  rhsAccessories: {
    ...accessories,
    right: 4,
  },
  warning: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: theme.colors.red,
  },
  count: {
    paddingVertical: 4,
    paddingHorizontal: 6,
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
  },
  countText: {
    fontSize: 12,
    lineHeight: 12,
    fontVariant: ['tabular-nums'],
    fontFamily: theme.fonts.medium,
  },
  variant: {
    width: 0,
    height: 0,
    borderTopWidth: 8,
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderTopColor: theme.colors.accent,
    borderLeftColor: theme.colors.transparent,
    borderRightColor: theme.colors.transparent,
  },
  selection: {
    position: 'absolute',
    left: 2,
    right: 2,
    bottom: 2,
    height: 4,
    borderRadius: 2,
    backgroundColor: theme.colors.green,
  },
});

export default styles;
