import { StyleSheet } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';

const VariableQuantityModalStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 380,
      alignSelf: 'center',
      rowGap: 8,
    },
    content: {
      padding: 20,
      borderRadius: theme.radius.s,
      backgroundColor: colors.white,
    },
    item: {
      height: 26,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.grey1,
      marginBottom: 4,
    },
    itemText: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.black1,
      fontFamily: theme.fonts.medium,
    },
    display: {
      rowGap: 12,
      padding: 20,
      paddingVertical: 16,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.tertiary1,
      marginBottom: 10,
    },
    title: {
      fontSize: 28,
      lineHeight: 40,
      color: theme.colors.orange,
      fontFamily: theme.fonts.semibold,
      fontVariant: ['tabular-nums'],
    },
    subtitle: {
      fontSize: 12,
      lineHeight: 16,
      color: theme.colors.grey6,
      fontFamily: theme.fonts.medium,
    },
    btnClear: {
      width: 38,
      height: 38,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 20,
      right: 20,
      transform: [{ scaleX: -1 }],
    },
  });
};

export default VariableQuantityModalStyles;
