import React, { FC } from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import styles from './CartNew.styles';
import Icon from '../../../Icon/Icon';

const CartNew: FC = () => {
  const { translate } = useTranslation();

  return (
    <View testID="cart-new" style={styles.container}>
      <View style={styles.line} />
      <View style={styles.labelContainer}>
        <Icon size={18} name="angle-down" color={theme.colors.white} />
        <Text style={styles.label}>{translate('order.newLabel')}</Text>
        <Icon size={18} name="angle-down" color={theme.colors.white} />
      </View>
    </View>
  );
};

export default CartNew;
