import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import {
  GET_MENU_SCHEDULES_QUERY,
  GET_MENU_SCHEDULE_QUERY,
  CREATE_MENU_SCHEDULE,
  UPDATE_MENU_SCHEDULE,
  DELETE_MENU_SCHEDULE,
} from './graphql';
import { parseApolloError, noopHandler } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  CatalogueScheduleItem,
  CatalogueScheduleItemInput,
  DayOfWeek,
  TimeBlock,
  TimeRange,
} from '@oolio-group/domain';
import { useNotification } from '../../Notification';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation } from '@react-navigation/native';

export interface UseMenusProps {
  menuSchedules: Record<string, CatalogueScheduleItem>;
  error: string | undefined;
  loading: boolean;
  getMenuSchedules: () => void;
  createMenuSchedule: (input: CatalogueScheduleItemInput) => void;
  updateMenuSchedule: (
    updateMenuInputs: CatalogueScheduleItemInput,
  ) => Promise<void>;
  deleteMenuSchedule: (id: string) => Promise<boolean | undefined>;
  getMenuSchedule: (id: string) => void;
}

export const mappingScheduleToTimeBlocks = (timeBlocks: TimeRange[]) => {
  const timeBlocksMapped: TimeBlock[] = [];
  timeBlocks.forEach(item => {
    if (item.days?.length === 1) {
      if (item.days[0] === DayOfWeek.ALL) {
        [
          DayOfWeek.MONDAY,
          DayOfWeek.TUESDAY,
          DayOfWeek.WEDNESDAY,
          DayOfWeek.THURSDAY,
          DayOfWeek.FRIDAY,
          DayOfWeek.SATURDAY,
          DayOfWeek.SUNDAY,
        ].map(day => {
          timeBlocksMapped.push({
            day: day,
            isActive: true,
            timeSlots: [
              {
                startTime: item.timeSlot.startTime,
                endTime: item.timeSlot.endTime,
              },
            ],
          });
        });
      } else {
        timeBlocksMapped.push({
          day: item.days[0],
          isActive: true,
          timeSlots: [
            {
              startTime: item.timeSlot.startTime,
              endTime: item.timeSlot.endTime,
            },
          ],
        });
      }
    } else {
      item.days.map(day => {
        timeBlocksMapped.push({
          day: day,
          isActive: true,
          timeSlots: [
            {
              startTime: item.timeSlot.startTime,
              endTime: item.timeSlot.endTime,
            },
          ],
        });
      });
    }
  });
  return timeBlocksMapped;
};

export function useMenuSchedules(): UseMenusProps {
  const { showNotification, closeAllNotifications } = useNotification();
  const { translate } = useTranslation();
  const [menuSchedules, setMenuSchedules] = useState<
    Record<string, CatalogueScheduleItem>
  >({});
  const navigation = useNavigation();
  const deletedId = useRef<string>();

  const [getMenusRequest, getMenusRes] = useLazyQuery<{
    menuSchedules: CatalogueScheduleItem[];
  }>(GET_MENU_SCHEDULES_QUERY, {
    onError: noopHandler,
    fetchPolicy: 'cache-and-network',
    onCompleted: response => {
      setMenuSchedules(keyBy(response.menuSchedules, 'id'));
    },
  });

  const getMenuSchedules = useCallback(() => {
    getMenusRequest();
  }, [getMenusRequest]);

  const [getMenuReq, getMenuRes] = useLazyQuery<{
    menuSchedule: CatalogueScheduleItem;
  }>(GET_MENU_SCHEDULE_QUERY, {
    onError: noopHandler,
  });

  const getMenuSchedule = useCallback(
    (menuId: string) => {
      getMenuReq({
        variables: {
          id: menuId,
        },
      });
    },
    [getMenuReq],
  );

  const [createMenuScheduleRequest, createMenuScheduleResponse] = useMutation<{
    createMenuSchedule: CatalogueScheduleItem;
  }>(CREATE_MENU_SCHEDULE, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('menus.menuSchedules.createdSuccessful'),
        success: true,
      });
    },
  });

  const createMenuSchedule = useCallback(
    (createMenuScheduleInput: CatalogueScheduleItemInput) => {
      createMenuScheduleRequest({
        variables: {
          input: { ...createMenuScheduleInput, createdAt: Date.now() },
        },
      });
    },
    [createMenuScheduleRequest],
  );

  const [updateMenuScheduleRequest, updateMenuScheduleResponse] = useMutation<{
    updateMenuSchedule: CatalogueScheduleItem;
  }>(UPDATE_MENU_SCHEDULE, {
    onError: noopHandler,
    onCompleted: () => {
      closeAllNotifications();
      showNotification({
        message: translate('menus.menuSchedules.updatedSuccessful'),
        success: true,
      });
    },
  });

  const updateMenuSchedule = useCallback(
    async (updateMenuScheduleInput: CatalogueScheduleItemInput) => {
      await updateMenuScheduleRequest({
        variables: {
          input: { ...updateMenuScheduleInput, updatedAt: Date.now() },
        },
      });
    },
    [updateMenuScheduleRequest],
  );

  const [deleteMenuScheduleRequest, deleteMenuScheduleResponse] = useMutation<{
    deleteMenuSchedule: boolean;
  }>(DELETE_MENU_SCHEDULE, {
    onError: noopHandler,
    onCompleted: () => {
      showNotification({
        message: translate('menus.menuSchedules.deletedSuccessful'),
        success: true,
      });
    },
  });

  const deleteMenuSchedule = useCallback(
    async (id: string) => {
      deletedId.current = id;
      const response = await deleteMenuScheduleRequest({
        variables: {
          id,
        },
      });
      return response.data?.deleteMenuSchedule;
    },
    [deleteMenuScheduleRequest],
  );

  useEffect(() => {
    if (getMenusRes.data) {
      const scheduleMenusData = getMenusRes.data.menuSchedules;
      setMenuSchedules(prev => {
        const menus = { ...prev };
        scheduleMenusData.forEach(scheduleMenu => {
          menus[scheduleMenu.id] = scheduleMenu;
        });
        return menus;
      });
    }
  }, [getMenusRes.data]);

  useEffect(() => {
    if (createMenuScheduleResponse.data) {
      const newMenuSchedule = createMenuScheduleResponse.data
        .createMenuSchedule as CatalogueScheduleItem;
      setMenuSchedules(prev => ({
        ...prev,
        [newMenuSchedule.id]: newMenuSchedule,
      }));
      navigation.goBack();
    }
  }, [createMenuScheduleResponse.data, navigation]);

  useEffect(() => {
    if (updateMenuScheduleResponse.data) {
      const updatedMenuSchedule =
        updateMenuScheduleResponse?.data?.updateMenuSchedule;
      setMenuSchedules(prev => ({
        ...prev,
        [updatedMenuSchedule?.id]: updatedMenuSchedule,
      }));
    }
  }, [updateMenuScheduleResponse.data]);

  useEffect(() => {
    if (getMenuRes.data) {
      const scheduleMenuData = getMenuRes.data.menuSchedule;
      scheduleMenuData &&
        setMenuSchedules(prev => ({
          ...prev,
          [scheduleMenuData.id]: scheduleMenuData,
        }));
    }
  }, [getMenuRes.data]);

  useEffect(() => {
    if (
      deleteMenuScheduleResponse.data?.deleteMenuSchedule &&
      deletedId.current
    ) {
      setMenuSchedules(prev => {
        const prevSchedule = { ...prev };
        delete prevSchedule?.[deletedId.current as string];
        return prevSchedule;
      });
      deletedId.current = '';
      navigation.goBack();
    }
  }, [deleteMenuScheduleResponse.data?.deleteMenuSchedule, navigation]);

  const error: ApolloError | undefined =
    getMenusRes.error ||
    getMenuRes.error ||
    createMenuScheduleResponse.error ||
    updateMenuScheduleResponse.error ||
    deleteMenuScheduleResponse.error;

  const loading: boolean =
    getMenusRes.loading ||
    getMenuRes.loading ||
    createMenuScheduleResponse.loading ||
    updateMenuScheduleResponse.loading ||
    deleteMenuScheduleResponse.loading;

  return useMemo(
    () => ({
      menuSchedules,
      getMenuSchedules,
      createMenuSchedule,
      updateMenuSchedule,
      deleteMenuSchedule,
      getMenuSchedule,
      error: error ? parseApolloError(error) : undefined,
      loading,
    }),
    [
      menuSchedules,
      getMenuSchedules,
      createMenuSchedule,
      updateMenuSchedule,
      deleteMenuSchedule,
      getMenuSchedule,
      error,
      loading,
    ],
  );
}
