import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 680,
    alignSelf: 'center',
  },
  row: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  content: {
    padding: 30,
    marginTop: 8,
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
    paddingLeft: 0,
    paddingRight: 0,
  },
  column: {
    width: 340,
    rowGap: 8,
    alignSelf: 'center',
  },
  inputWrapper: {
    padding: 30,
    paddingTop: 0,
    borderRadius: theme.radius.s,
  },
  divider: {
    height: 380,
    alignSelf: 'center',
    marginVertical: 10,
    width: 3,
    backgroundColor: theme.colors.grey3,
  },
  textCenter: {
    textAlign: 'center',
    fontSize: 18,
    fontWeight: '500',
    paddingBottom: 18,
  },
  paddingHorizontal: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  summaryText: {
    fontSize: 14,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.neutral,
    textAlign: 'center',
    marginTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
});

export default styles;
