import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const OrderPreviewStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 340,
      position: 'absolute',
      right: 0,
      bottom: 0,
      padding: 6,
      rowGap: 4,
      backgroundColor: themed ? colors.background : theme.colors.grey1,
      ...theme.shadow.light,
    },
    btnContainer: {
      columnGap: 4,
      flexDirection: 'row',
    },
    customer: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : theme.colors.white,
    },
    customerIcon: {
      width: 48,
      height: 38,
      marginRight: 4,
      alignItems: 'center',
      justifyContent: 'center',
    },
    customerName: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
    placeholder: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : theme.colors.white,
    },
    errorText: {
      lineHeight: 20,
      textAlign: 'center',
      marginVertical: 20,
      paddingHorizontal: 40,
      color: theme.colors.states.negative,
    },
    content: {
      padding: 10,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : theme.colors.white,
    },
  });
};

export default OrderPreviewStyles;
