import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { OrderAction, OrderStatus, OrderType } from '@oolio-group/domain';
import { useQuery, useReactiveVar } from '@apollo/client/react/hooks';
import { useTranslation } from '@oolio-group/localization';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { GET_ALL_ORDER_TYPES } from '../../../../graphql/orders';
import { AppScreen } from '../../../../types/AppScreen';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import { useNotification } from '../../../../hooks/Notification';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { parseApolloError } from '../../../../utils/errorHandlers';
import {
  filterOpenOrdersData,
  mapOrdersToOpenOrdersView,
} from '../../../../utils/OpenOrdersHelper';
import { ordersReceivedViaPollingAt } from '../../../../state/cache';
import OpenOrdersTable, { OpenOrdersDataProps } from './Table/OpenOrdersTable';
import OpenOrderStyles from './OpenOrders.styles';
import Search from '../../../../components/Shared/Search/Search';
import Select from '../../../../components/Shared/Select/Select';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import OrdersSegmentTabs from '../OrdersSegmentTabs';
import { useCart } from '../../../../hooks/orders/useCart';
import Message from '../../../../components/Office/Message/Message';
import { useNetworkStatus } from '../../../../hooks/app/useNetworkStatus';
import { useOolioLoyalty } from '../../../../hooks/useOolioLoyalty';

type OpenOrdersParamList = RouteProp<
  {
    OpenOrders: { previousScreen: string };
  },
  'OpenOrders'
>;

const OpenOrders: React.FC = () => {
  const {
    orders,
    loading,
    error: ordersDataError,
    getOrdersFromCache,
  } = useOrders();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { isConnected } = useNetworkStatus();
  const { showNotification } = useNotification();
  const { openOrderCart, updateCart } = useCart();
  const { printBill, reprintKitchenDocket } = usePrinting();
  const { program: newLoyaltySettings } = useOolioLoyalty();

  const styles = OpenOrderStyles();

  const route = useRoute<OpenOrdersParamList>();
  const { previousScreen } = route.params ?? {};

  const [keywordFilter, setKeyWordFilter] = useState<string>('');
  const [orderTypeFilter, setOrderTypeFilter] = useState<string>('all');

  const ordersUpdatedThroughPolling = useReactiveVar<number>(
    ordersReceivedViaPollingAt,
  );

  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    let timeoutId: number | null;
    if (ordersUpdatedThroughPolling) {
      timeoutId = setTimeout(() => {
        getOrdersFromCache(OrderStatus.IN_PROGRESS);
        getOrdersFromCache(OrderStatus.ON_HOLD);
      }, 2000) as unknown as number;
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [getOrdersFromCache, ordersUpdatedThroughPolling]);

  useEffect(() => {
    if (isFocused) {
      getOrdersFromCache(OrderStatus.IN_PROGRESS);
      getOrdersFromCache(OrderStatus.ON_HOLD);
    }
  }, [getOrdersFromCache, isFocused]);

  const { data: orderTypesData, error: orderTypesError } = useQuery<{
    orderTypes: OrderType[];
  }>(GET_ALL_ORDER_TYPES);

  useEffect(() => {
    if (orderTypesError) {
      showNotification({
        error: true,
        message: parseApolloError(orderTypesError),
      });
    }
  }, [orderTypesError, showNotification]);

  useEffect((): void => {
    if (ordersDataError) {
      showNotification({
        error: true,
        message: ordersDataError,
      });
    }
  }, [ordersDataError, showNotification]);

  const ordersArray = useMemo(() => {
    return Object.values(orders);
  }, [orders]);

  const filteredData = useMemo<OpenOrdersDataProps[]>(() => {
    const filteredInprogress = filterOpenOrdersData(
      mapOrdersToOpenOrdersView(
        (ordersArray || []).filter(x =>
          [OrderStatus.IN_PROGRESS, OrderStatus.ON_HOLD].includes(x.status),
        ),
        orderTypesData ? orderTypesData.orderTypes : [],
      ),
      orderTypeFilter,
      keywordFilter,
    );
    return filteredInprogress;
  }, [ordersArray, orderTypeFilter, keywordFilter, orderTypesData]);

  const orderTypeFilterOptions = useMemo(
    () => [
      {
        label: translate('pricings.allOrderTypes'),
        value: 'all',
      },
      ...(orderTypesData?.orderTypes || []).map(x => ({
        label: x.name,
        value: x.name,
      })),
    ],
    [orderTypesData?.orderTypes, translate],
  );

  const onSelectOrder = useCallback(
    orderId => {
      navigation.navigate('TakeOrder', {
        id: orderId,
        isCompleted: false,
        isExisting: true,
      });
      openOrderCart(orderId);
    },
    [navigation, openOrderCart],
  );

  const onPressPrintReceipt = useCallback(
    async (orderId: string, nthPayment?: number) => {
      const order = orders[orderId];
      if (order) {
        updateCart(OrderAction.ORDER_PRINT);
        const result = await printBill(order, nthPayment, {
          loyaltyProgram: newLoyaltySettings,
        });
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [orders, updateCart, printBill, showNotification, newLoyaltySettings],
  );

  const onPressReprintDocket = useCallback(
    async (orderId: string) => {
      const order = orders[orderId];
      if (order) {
        const result = await reprintKitchenDocket(order, order.orderItems);
        if (result && Object.keys(result)?.length > 0 && result.error) {
          showNotification(result);
        }
      }
    },
    [reprintKitchenDocket, showNotification, orders],
  );

  const onPressVoid = useCallback(() => {
    // FIXME: Voided order is not reflected immediately in cache.
    setTimeout(() => getOrdersFromCache(OrderStatus.IN_PROGRESS), 200);
  }, [getOrdersFromCache]);

  const onTextEntered = useCallback(text => {
    setKeyWordFilter(text);
    setCurrentPage(1);
  }, []);

  const onPickerValueChange = useCallback(value => {
    setOrderTypeFilter(value);
    setCurrentPage(1);
  }, []);

  return (
    <ScreenLayout
      title="Open Orders"
      loading={loading}
      onBack={() => navigation.goBack()}
      tabs={
        <OrdersSegmentTabs
          activeScreen={AppScreen.OPEN_ORDER}
          previousScreen={previousScreen}
        />
      }
    >
      {!isConnected ? (
        <Message type="negative" message={translate('offline.openOrder')} />
      ) : null}
      <View style={styles.filters}>
        {orderTypesData ? (
          <Select
            testID="select-orderType"
            themed
            options={orderTypeFilterOptions}
            selectedValue={orderTypeFilter}
            onValueChange={onPickerValueChange}
            containerStyle={styles.dropdown}
          />
        ) : null}
        <Search
          testID="search-orders"
          themed
          placeholder={translate('openOrders.searchPlaceholder')}
          onChangeText={onTextEntered}
          containerStyle={styles.search}
        />
      </View>
      <OpenOrdersTable
        orders={orders}
        data={filteredData}
        onSelectOrder={onSelectOrder}
        onPressPrintReceipt={onPressPrintReceipt}
        onPressRePrintDocket={onPressReprintDocket}
        onVoidOrder={onPressVoid}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </ScreenLayout>
  );
};

export default OpenOrders;
