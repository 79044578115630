import React from 'react';
import { View, Text, ViewStyle, TouchableOpacity } from 'react-native';
import Icon from '../../Icon/Icon';
import SettingsOptionsStyles from './SettingsOptions.styles';
import { useTheme } from '../../../hooks/useTheme';
import LoadingIndicator from '../../Shared/Loaders/LoadingIndicator';

export interface SettingsIconProps {
  title: string;
  icon?: string;
  iconSecondary?: string;
  value?: string;
  testID: string;
  onPress?: () => void;
  iconColor?: string;
  loading?: boolean;
  error?: string;
}

export interface SettingsToggleProps {
  title: string;
  testID: string;
  isToggled: boolean;
  onToggle: () => void;
}

export interface SettingsSelectProps {
  title: string;
  testID: string;
  isSelected: boolean;
  onPress: () => void;
}

export interface SettingsActionProps {
  label: string;
  testID: string;
  loading?: boolean;
  type: 'positive' | 'neutral' | 'focus' | 'negative';
  onPress: () => void;
  containerStyle?: ViewStyle;
}

export const SettingsIcon: React.FC<SettingsIconProps> = ({
  title,
  icon,
  value,
  testID,
  onPress,
  iconColor,
  loading,
  error,
  iconSecondary,
}) => {
  const { colors } = useTheme();
  const styles = SettingsOptionsStyles();

  return (
    <TouchableOpacity
      testID={testID}
      style={styles.container}
      onPress={onPress}
    >
      <View style={styles.content}>
        <Text
          style={[styles.title, { color: error ? colors.red : colors.text }]}
        >
          {title}
        </Text>
        <View style={styles.disclosure}>
          {!!value ? (
            <Text
              style={[
                styles.value,
                {
                  color: error
                    ? colors.red
                    : value === 'OK'
                    ? colors.green
                    : colors.textLight,
                },
              ]}
            >
              {value}
            </Text>
          ) : null}
          {error ? (
            <Icon size={20} name="exclamation-triangle" color={colors.red} />
          ) : null}
          {iconSecondary ? (
            <Icon size={20} name={iconSecondary} color={colors.blue} />
          ) : null}
          {!!icon ? (
            <View style={styles.icon}>
              {!loading ? (
                <Icon
                  size={24}
                  name={icon || 'angle-right'}
                  color={iconColor ? iconColor : colors.disclosure}
                />
              ) : (
                <LoadingIndicator />
              )}
            </View>
          ) : null}
        </View>
      </View>
      {error ? (
        <View style={styles.errorView}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

export const SettingsToggle: React.FC<SettingsToggleProps> = ({
  title,
  testID,
  isToggled,
  onToggle,
}) => {
  const { colors } = useTheme();
  const styles = SettingsOptionsStyles();

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onToggle}
      style={styles.container}
    >
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.icon}>
          <Icon
            size={24}
            name={isToggled ? 'toggle-on' : 'toggle-off'}
            color={isToggled ? colors.green : colors.disclosure}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const SettingsSelect: React.FC<SettingsSelectProps> = ({
  title,
  testID,
  isSelected,
  onPress,
}) => {
  const { colors } = useTheme();
  const styles = SettingsOptionsStyles();

  return (
    <TouchableOpacity
      testID={testID}
      style={styles.container}
      onPress={onPress}
    >
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        {isSelected ? (
          <View style={styles.icon}>
            <Icon size={20} name="check" color={colors.green} />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

export const SettingsAction: React.FC<SettingsActionProps> = ({
  testID,
  label,
  type,
  loading,
  onPress,
  containerStyle,
}) => {
  const { colors } = useTheme();
  const styles = SettingsOptionsStyles();

  const getLabelColor = () => {
    switch (type) {
      case 'positive':
        return colors.green;
      case 'neutral':
        return colors.blue;
      case 'focus':
        return colors.orange;
      case 'negative':
        return colors.red;
      default:
        return colors.text;
    }
  };

  return (
    <TouchableOpacity
      testID={testID}
      style={[containerStyle, styles.action]}
      disabled={loading}
      onPress={onPress}
    >
      {!loading ? (
        <Text style={[styles.actionLabel, { color: getLabelColor() }]}>
          {label}
        </Text>
      ) : (
        <LoadingIndicator />
      )}
    </TouchableOpacity>
  );
};
