import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import { View, Text } from 'react-native';
import {
  IntegrationApps,
  IntegrationPartner,
  Store,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { keyBy } from 'lodash';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { SettingsContext } from '../OolioWaitlistsIntegrationsSettingsTabs';
import { StoreRow } from './StoreRow';
import styles from './Settings.styles';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import { useSession } from '../../../../../../hooks/app/useSession';

export const SettingsTab: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [session] = useSession();

  const [integrationSettings, setIntegrationSettings] = useState<
    Record<string, IntegrationPartner>
  >({});

  const {
    stores,
    getStores,
    loading: storesLoading,
    error: storesErr,
  } = useStores();

  const {
    loading: integrationPartnerLoading,
    getIntegrationPartnerSettings,
    integrationPartners,
    error: integrationPartnerError,
  } = useIntegrationPartners();

  const route = useRoute();
  const context = useContext(SettingsContext);
  const params = context.params || (route.params as { app: IntegrationApps });
  const app = params.app;

  const loading = integrationPartnerLoading || storesLoading;
  const error = integrationPartnerError || storesErr;

  useFocusEffect(
    useCallback(() => {
      getStores();
      getIntegrationPartnerSettings({ appName: app });
    }, [getStores, getIntegrationPartnerSettings, app]),
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    const integrationSettingsByStore = keyBy(
      Object.values(integrationPartners),
      'store',
    );
    setIntegrationSettings(integrationSettingsByStore);
  }, [integrationPartners]);

  const storeList = useMemo(() => Object.values(stores), [stores]);

  return (
    <ScreenLayout
      loading={loading}
      title={'Oolio Waitlists Settings | Oolio'}
      hideSaveButton
    >
      <Section layoutWidth="small">
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('backOfficeFeatures.venuesAndSettings')}
            </Text>
          </View>
          <View>
            {storeList?.map((store: Store, i: number) => {
              const isActive = !!integrationSettings[store.id];

              return (
                <StoreRow
                  key={i}
                  isEnabled={isActive}
                  name={store.name}
                  integrationApp={app}
                  isConnected={true}
                  orgId={session?.currentOrganization?.id || ''}
                />
              );
            })}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
