import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const PrintingQueueStyles = () => {
  const { colors } = useTheme();

  const rowText: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    color: colors.text,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
    marginRight: 10,
  };

  return StyleSheet.create({
    filters: {
      margin: 20,
      columnGap: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    search: {
      flex: 1,
    },
    table: {
      marginBottom: 100,
      paddingHorizontal: 20,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      marginVertical: 8,
    },
    tableRow: {
      height: 50,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    cellStatus: {
      width: 70,
      marginLeft: 12,
      marginRight: 20,
      textAlign: 'center',
    },
    cellOrder: {
      width: 110,
      ...rowText,
    },
    cellType: {
      width: 60,
      ...rowText,
    },
    cellTime: {
      width: 180,
      ...rowText,
    },
    cellPrinter: {
      width: 120,
      ...rowText,
    },
    cellDescription: {
      width: 120,
      ...rowText,
    },
    cellMessage: {
      flex: 1,
      ...rowText,
      marginRight: 20,
    },
    cellRemove: {
      marginLeft: 10,
      marginRight: 12,
    },
    // Reprint Modal
    modal: {
      width: 340,
      alignSelf: 'center',
    },
    modalTitle: {
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.white,
      ...theme.shadow.strong,
    },
    modalBtnClose: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitleText: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      marginRight: 44,
    },
    modalBody: {
      padding: 20,
      marginTop: 10,
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.white,
      ...theme.shadow.strong,
    },
    modalDropdown: {
      marginTop: 20,
      marginBottom: 30,
    },
  });
};

export default PrintingQueueStyles;
