import { gql } from '@apollo/client';

export const orderTableInfo = `
  id
  name
  guestCount
  status
  section {
    id
    name
  }
`;

export const ordersFragment = `
  id
  createdAt
  updatedAt
  placedAt
  lastCheck
  lastOrderedAt
  requiredAt
  prevEventId
  customer {
    firstName
    lastName
    email
    id
    phone
    loyaltyMember
    loyaltyPointsBalance
    preferredAddress {
      line1
      city
      postalCode
      isoCountryCode
    }
    customerAccountDetails {
      maxOrderLimit
      maxBalanceLimit
      currentBalance
      accountPayment
    }
  }
  createdBy {
    id
    name
  }
  updatedBy {
    id
    name
  }
  status
  orderNumber
  createdByDevice {
    id
    name
  }
  updatedByDevice {
    id
    name
  }
  orderType {
    id
    name
    code
  }
  table {
    ${orderTableInfo}
  }
  tables {
    ${orderTableInfo}
  }
  orderItems {
    id
    savedAmount
    tareWeight
    printerProfiles
    transferInfo {
      toOrderId
      toTableId
      transferredBy {
        name
        id
      }
      transferredAt
    }
    product {
      id
      name
      variableQuantity
      category {
        id
      }
      measuredBy {
        unitType
        defaultSize
        units
      }
      optionValues {
        parentOptionId
        key
        value
      }
      course {
        name
        priority
        id
      }
      printerProfiles {
        id
      }
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
    }
    comboItems {
      id
      product {
        id
        name
        printerProfiles {
          id
        }
        optionValues {
          parentOptionId
          key
          value
        }
        course {
          name
          priority
          id
        }
      }
      course {
        name
        priority
        id
      }
      unitPrice
      quantity
      status
      proRatedAmount
      notes
      modifierGroupId
      itemFired
      modifiers {
        modifierGroupId
        modifierGroupPriority
        id
        name
        quantity
        unitPrice
      }
      variant {
        id
        name
      }
    }
    course {
      name
      priority
      id
    }
    variant {
      id
      name
      category {
        id
      }
      options {
        id
        key
        values
      }
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
    }
    quantity
    notes
    unitPrice
    costPrice
    modifiers {
      modifierGroupId
      modifierGroupPriority
      id
      name
      quantity
      unitPrice
      taxes {
        id
        name
        code
        rate
      }
      alternateNames {
        locale
        display
        kitchen
        receipt
        description
      }
    }
    taxes {
      id
      name
      rate
      code
    }
    discounts {
      amount
      type
    }
    discountAmount
    surchargeAmount
    taxInclusive
    reason
    status
    itemFired
    seatNumber
    saved
    adjustments {
      id
      name
      amount
      adjustmentUnit
      adjustmentType
      allowOnPaymentType
      quantity
      itemQuantity
      maximumAmount
      pointsRequired
      isDiscountRuleEnabled
      autoApply
    }
    createdAt
    paymentStatus
  }
  payments {
    id
    paymentType {
      id
      name
    }
    status
    failedReason
    amount
    roundOffDifference
    tendered
    paymentRequestId
    paymentTransactionRef
    paymentCompletedAt
    paymentReceipt
    paymentSurcharge
    cardType
    updatedByDeviceId
    splitPaymentBy
    merchantCode
    isPrePayment
  }
  allergens
  orderNote
  amountDue
  subTotal
  totalTax
  totalPrice
  totalPaymentAmount
  taxes {
    tax {
      id
      name
    }
    amount
  }
  discounts {
    type
    amount
  }
  discountAmount
  roundingAmount
  adjustments {
    id
    name
    amount
    adjustmentUnit
    allowOnPaymentType
    doNotIncludeInSalesAmount
    displayNameOnReceipt
    adjustmentType
    quantity
    maximumAmount
    pointsRequired
    createdAt
    saved
  }
  surchargeAmount
  refundOf
  refundType
  salesChannel {
    id
    name
  }
  integrationInfo {
    id
    app
    channelOrderId
    channelOrderDisplayId
  }
  isEdited @client
  shippingAddress {
    line1
    line2
    postalCode
    state
    isoCountryCode
    suburb
    city
    country
    extraAddressInfo
  }
  courier {
    firstName
    lastName
    phoneNumber
    deliveryBy
  }
  deliveryFee
  serviceCharge
  tip
  reason
  rewardDiscountAmount
  lastSyncedEventId @client
  loyaltySnapshot {
    pointsEarned
    availableBalance
    availableRewards {
      ... on RewardByDiscountEntireSale {
        id
        rewardType
        rewardName
        pointsRequired
      }
      ... on RewardByDiscountProduct {
        id
        rewardType
        rewardName
        pointsRequired
      }
      ... on RewardByFreeItem {
        id
        rewardType
        rewardName
        pointsRequired
      }
    }
  }
  dueAt
  prepTime
  orderName
  source
  tokenNumber
  isTraining
  store {
    id
  }
  reservation {
    externalRef
  }
  eventsCount
`;

export const GET_ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    ${ordersFragment}
  }
`;

export const GET_ORDERS = gql`
  query Orders($filter: OrderFilterInput) {
    orders(filter: $filter) @client {
      ... on Order {
        ${ordersFragment}
      }
    }
  }
`;

export const GET_PAGINATED_ORDER_QUERY = gql`
query paginatedOrders ($first: Int, $after: String, $filter: OrderFilterInput){
  paginatedOrders(
    first: $first,
    after: $after,
    filter: $filter
  ){
    edges{
      node{
        ... on Order {
          ${ordersFragment}
        }
      }
      cursor
    }
    pageInfo{
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
`;

export const GET_PAGINATED_ORDER_HISTORY_QUERY = gql`
  query paginatedOrders(
    $first: Int
    $after: String
    $filter: OrderFilterInput
  ) {
    paginatedOrders(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          ... on Order {
            id
            orderNumber
            createdAt
            updatedAt
            placedAt
            orderName
            customer {
              id
              firstName
              lastName
              phone
              email
            }
            tokenNumber
            status
            orderType {
              id
              name
              code
            }
            table {
              ${orderTableInfo}
            }
            tables {
              ${orderTableInfo}
            }
            payments {
              paymentType {
                id
                name
              }
              status
              amount
              tendered
            }
            totalPaymentAmount
            roundingAmount
            refundOf
            isTraining
          }
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const GET_ORDER = gql`
  query Order($orderId: ID!) {
    order(id: $orderId) {
      ${ordersFragment}
    }
  }
`;

export const GET_REFUND_ORDER_QUERY = gql`
  query refundOrder($refundOf: ID!) {
    refundOrder(refundOf: $refundOf) {
      ${ordersFragment}
  }
}
`;

export const ORDER_SAVE = gql`
  mutation saveOrder($data: Order!) {
    saveOrder(input: $data) @client
  }
`;

export const ORDERS_SAVE = gql`
  mutation saveOrders($data: [Order]!) {
    saveOrders(input: $data) @client
  }
`;
