import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  EnrollCustomerInput,
  LoyaltyEnrolmentSource,
} from '@oolio-group/domain';
import {
  useTranslation,
  usePhoneNumber,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import EnrolCustomerModalStyles from './EnrolCustomerModal.styles';
import Icon from '../../../Icon/Icon';
import InputText from '../../../Shared/Inputs/InputText';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import InputPhone from '../../../Shared/Inputs/InputPhone';
import Gradient from '../../../Gradient/Gradient';

interface Props {
  loading?: boolean;
  assignedCustomerInfo?: { phone?: string; firstName?: string };
  onCloseModal: () => void;
  onEnrollLoyalty: (customerInfo: EnrollCustomerInput) => void;
}

const EnrolCustomerModal: React.FC<Props> = ({
  loading,
  assignedCustomerInfo,
  onCloseModal,
  onEnrollLoyalty,
}) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const styles = EnrolCustomerModalStyles();

  const defaultCountry = session?.currentOrganization?.country || 'AU';
  const [selectedCountry, setSelectedCountry] =
    useState<string>(defaultCountry);

  const [customerInfo, setCustomerInfo] = useState<{
    phone: string;
    firstName: string;
  }>({
    phone: assignedCustomerInfo?.phone || '',
    firstName: assignedCustomerInfo?.firstName || '',
  });

  const onEnrollCustomer = useCallback(async () => {
    if (!customerInfo.firstName) {
      return showNotification({
        message: translate('backOfficeCustomerModal.errorFirstNameMissing'),
        error: true,
      });
    }
    if (
      !isValidPhoneNumber(
        getFullFormattedPhoneNumber(selectedCountry, customerInfo.phone),
      )
    ) {
      return showNotification({
        message: translate('customer.invalidPhoneMessage'),
        error: true,
      });
    }
    onEnrollLoyalty({
      phone: getFullFormattedPhoneNumber(selectedCountry, customerInfo.phone),
      firstName: customerInfo.firstName,
      loyaltyEnrolmentSource: LoyaltyEnrolmentSource.POS,
    });
  }, [
    customerInfo,
    getFullFormattedPhoneNumber,
    onEnrollLoyalty,
    selectedCountry,
    showNotification,
    translate,
  ]);

  const onChange = useCallback((key: string, value: string): void => {
    setCustomerInfo(preCustomer => ({
      ...preCustomer,
      [key]: value,
    }));
  }, []);

  return (
    <View style={styles.container}>
      <Gradient>
        <View style={styles.header}>
          <TouchableOpacity onPress={onCloseModal} style={styles.btnClose}>
            <Icon size={20} name="times" />
          </TouchableOpacity>
          <View style={styles.stars}>
            {Array.from({ length: 3 }, (_, i) => (
              <Icon key={`star-${i}`} size={20} name="star" />
            ))}
          </View>
          <View style={styles.text}>
            <Text style={styles.title}>
              {translate('customerLoyalty.enrollCustomer')}
            </Text>
            <Text style={styles.subtitle}>
              {translate('customerLoyalty.enrollLoyaltyDescription')}
            </Text>
          </View>
        </View>
      </Gradient>
      <View style={styles.body}>
        <View style={styles.form}>
          <InputText
            testID="first-name"
            title={translate('form.firstName')}
            value={customerInfo?.firstName}
            onChangeText={onChange.bind(null, 'firstName')}
            placeholder="Enter first name..."
          />
          <InputPhone
            testID="input-phone"
            title={translate('form.phoneNumber')}
            value={customerInfo.phone}
            defaultCountry={selectedCountry}
            onChangeText={onChange.bind(null, 'phone')}
            onPressCountry={setSelectedCountry}
          />
        </View>
        <View>
          <TreatButton
            testID="btn-enrol"
            height={50}
            type="positive"
            label={translate('button.enrollCustomer')}
            onPress={onEnrollCustomer}
            isLoading={loading}
          />
        </View>
      </View>
    </View>
  );
};

export default EnrolCustomerModal;
