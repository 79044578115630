import {
  Order,
  OrderItem,
  PrinterTemplate,
  RemoveOrderItemEvent,
  Course,
  OrderStatus,
  OrderItemStatus,
  FeatureIDs,
  Feature,
  PrinterSeries,
  LOCALE,
  DEFAULT_TABLE_ABBREVIATION,
  SML_KITCHEN_DOCKET,
  LRG_KITCHEN_DOCKET,
  IntegrationApps,
  AllergensInfo,
  AllergensKey,
  ProductAllergens,
  KITCHEN_DOCKET_58MM,
  PrinterProfile,
  Product,
  UnitType,
} from '@oolio-group/domain';
import { EscPos } from '@tillpos/xml-escpos-helper';
import { table, getBorderCharacters, TableUserConfig } from 'table';
import { Session } from '../../state/Session';
import { dashDivider, divider } from './printDivider';
import { sortBy, get } from 'lodash';
import { format } from 'date-fns';
import { getShortVersion } from '../../hooks/orders/useOrderNumber';
import {
  getOnlineOrderDetail,
  getOnlineOrderType,
  isOnlineOrder,
} from './generateOnlineOrderDetail';
import { getOrderChannel } from './salesChannel';
import {
  kitchenDocket58mmTemplate,
  kitchenDocketTemplate,
  lrgKitchenDocketTemplate,
  smlkitchenDocketTemplate,
} from './xmlTemplates';
import { getCustomerInfo } from './customer';
import { getFormattedAddress, COMBINED_SEGMENT } from '../../utils/places.util';
import {
  getKitchenNameByLocale,
  isDeselectDefaultOption,
} from '@oolio-group/client-utils';
import { ALL_ALLERGENS_KEY } from '@oolio-group/order-helper';

const nameToTemplate: { [key: string]: string } = {
  [SML_KITCHEN_DOCKET]: smlkitchenDocketTemplate,
  [LRG_KITCHEN_DOCKET]: lrgKitchenDocketTemplate,
  [KITCHEN_DOCKET_58MM]: kitchenDocket58mmTemplate,
};
export interface CoursePrintItems {
  courseName: string;
  priority: number;
  printItems: OrderItem[];
}

export type PrintableProductRow = [string | undefined, string | undefined];

export interface RemoveOrderItemDocketEvent extends RemoveOrderItemEvent {
  quantity?: number;
}
interface OrderItemsPrintDetails {
  groupedItems: GroupedPrintItems[];
  header?: string;
}
interface PrintableLineItems {
  product: string;
  note: string;
  modifiers: string;
}
interface GroupedPrintItems {
  items: PrintableLineItems[];
  seat?: string;
  allergens?: string;
}
type PartialPrintableKitchenOrder = (args: {
  order: Order;
  session: Session;
  template: PrinterTemplate;
  printItems: OrderItem[];
  hasVoidOrderItem?: boolean;
  translatedNowCourse: string;
  profileName?: string;
  locale?: LOCALE;
  printerSeries?: PrinterSeries;
  enableKitchenBuzzer?: boolean;
  currentItemNumber?: number;
  totalItems?: number;
}) => Buffer | undefined;

/**
 * Order details section has two columns and `n` row(s)
 */
//for text width 1
export const twoColumnLargeTextConfig: TableUserConfig = {
  columns: {
    0: { alignment: 'left', width: 9 },
    1: { alignment: 'right', width: 10, wrapWord: true },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};
//for text width 0
export const twoColumnConfig: TableUserConfig = {
  columns: {
    0: { alignment: 'left', width: 18 },
    1: { alignment: 'right', width: 20 },
  },
  border: getBorderCharacters('void'),
  columnDefault: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawHorizontalLine: () => {
    return false;
  },
};

export const tableOrderItemConfig: TableUserConfig = {
  columns: {
    0: { width: 4, alignment: 'right' },
    1: {
      width: 17,
      alignment: 'left',
      wrapWord: true,
    },
  },
  border: getBorderCharacters('void'),
  drawHorizontalLine: () => {
    return false;
  },
};

export const tableOrderItemModifierConfig: (
  template?: PrinterTemplate,
) => TableUserConfig = template =>
  template?.name === LRG_KITCHEN_DOCKET
    ? {
        columns: {
          0: { width: 2, alignment: 'left' },
          1: {
            width: 12,
            alignment: 'left',
            wrapWord: true,
          },
        },
        border: getBorderCharacters('void'),
        columnDefault: {
          paddingLeft: 0,
          paddingRight: 1,
        },
        drawHorizontalLine: () => {
          return false;
        },
      }
    : {
        columns: {
          0: { width: 4, alignment: 'left' },
          1: {
            width: 25,
            alignment: 'left',
            wrapWord: true,
          },
        },
        border: getBorderCharacters('void'),
        columnDefault: {
          paddingLeft: 0,
          paddingRight: 1,
        },
        drawHorizontalLine: () => {
          return false;
        },
      };

export const tableOrderItemNoteConfig: (
  template?: PrinterTemplate,
) => TableUserConfig = template =>
  template?.name === LRG_KITCHEN_DOCKET
    ? {
        columns: {
          0: { width: 14, wrapWord: true },
        },
        border: getBorderCharacters('void'),
        drawHorizontalLine: () => {
          return false;
        },
      }
    : {
        columns: {
          0: { width: 28, wrapWord: true },
        },
        border: getBorderCharacters('void'),
        drawHorizontalLine: () => {
          return false;
        },
      };

export const groupItemsByPrinterProfile = (orderItems: OrderItem[]) => {
  return orderItems.reduce((acc, item) => {
    const processProfiles = (
      profiles: PrinterProfile[],
      itemToAdd: OrderItem,
    ) => {
      profiles.forEach(profile => {
        acc[profile.id] = [...(acc[profile.id] || []), itemToAdd];
      });
    };

    if (item.comboItems?.length) {
      item.comboItems.forEach(comboItem => {
        processProfiles(comboItem.product?.printerProfiles || [], {
          ...item,
          ...comboItem,
          product: { ...comboItem.product } as Product,
          modifiers: comboItem?.modifiers || [],
          quantity: (item.quantity || 1) * (comboItem.quantity || 1),
          unitPrice: comboItem.unitPrice || 0,
        });
      });
    } else {
      processProfiles(item.product?.printerProfiles || [], item);
    }

    return acc;
  }, {} as Record<string, OrderItem[]>);
};

/**
 * ========================================================================================
 * Utilities for Partial orders (partial order items)
 * ========================================================================================
 *
 */

/**
 * Get product name (for all types of events)
 *
 * return newly added ot modified variants or notes
 * @param item
 * @param events
 */
// FIXME: once modifier events have been added here
export const getProductDetails = (item: OrderItem, locale?: LOCALE) => {
  locale = locale ? locale : LOCALE.ENGLISH_US;
  //const variant = item.variant;
  const product = item.product;

  const productName =
    (locale &&
      product?.alternateNames &&
      getKitchenNameByLocale(product, locale)) ||
    product?.name;

  if (
    locale &&
    product?.alternateNames &&
    product?.alternateNames.some(x => x.locale === locale)
  ) {
    return productName;
  }

  // TODO: add modifiers / variants etc
  return productName;
};

export const appendModifiers = (
  acc: PrintableProductRow[],
  item: OrderItem,
  locale?: LOCALE,
) => {
  if (item.modifiers && item.modifiers?.length > 0) {
    item.modifiers.forEach(modifier => {
      const name =
        (locale &&
          modifier?.alternateNames &&
          getKitchenNameByLocale(modifier, locale)) ||
        modifier?.name;
      if (modifier.quantity === 1) {
        const isDeselectDefault = isDeselectDefaultOption(name);
        const sign = isDeselectDefault ? '' : '+';
        acc.push([sign, `${name || ''}`]);
      } else {
        acc.push([`${modifier.quantity}`, `${name || ''}`]);
      }
    });
  }
};

/**
 * Returns partial order (items) printable array
 *
 * @param partialItems
 * @param events
 */
export const getOrderItemsPrintString = (
  printItems: OrderItem[],
  allergens?: AllergensInfo,
  locale?: LOCALE,
  seatManagement?: boolean,
  template?: PrinterTemplate,
): GroupedPrintItems[] => {
  const groupedItems: { [key: string]: OrderItem[] } = seatManagement
    ? printItems.reduce<Record<string, OrderItem[]>>((acc, item) => {
        const seatNumber = item.seatNumber
          ? 'SEAT ' + item.seatNumber?.toString()
          : DEFAULT_TABLE_ABBREVIATION;
        acc[seatNumber] = [...(acc[seatNumber] || []), item];
        return acc;
      }, {})
    : { [DEFAULT_TABLE_ABBREVIATION]: printItems };
  const result: GroupedPrintItems[] = [];

  Object.keys(groupedItems)
    .sort((a, b) => {
      if (a === b) {
        return 0;
      }
      if (
        a === DEFAULT_TABLE_ABBREVIATION ||
        (b !== DEFAULT_TABLE_ABBREVIATION && a < b)
      ) {
        return -1;
      }
      return 1;
    })
    .forEach(cur => {
      const items = groupedItems[cur];
      const groupResult: PrintableLineItems[] = [];
      items.forEach(x => {
        const product = getProductDetails(x, locale);
        const modifierRows: PrintableProductRow[] = [];
        const isVoidedItem = x.status === OrderItemStatus.VOID;
        const quantity = isVoidedItem
          ? `-${parseFloat(
              (x.quantity * (x.product?.measuredBy?.defaultSize || 1)).toFixed(
                3,
              ),
            )}`
          : `${parseFloat(
              (x.quantity * (x.product?.measuredBy?.defaultSize || 1)).toFixed(
                3,
              ),
            )}`;

        const rows: PrintableProductRow[] = [];

        const weightedQuantity = [UnitType.Volume, UnitType.Weight].includes(
          x.product?.measuredBy?.unitType,
        );

        const productRow: PrintableProductRow = [
          weightedQuantity ? '' : quantity,
          product,
        ];
        rows.push(productRow);

        if (weightedQuantity) {
          const units = x.product?.measuredBy?.units;
          const unitPrice = parseFloat(
            (x.unitPrice / (x.product?.measuredBy?.defaultSize || 1)).toFixed(
              2,
            ),
          );
          const weightedQuantityRow: PrintableProductRow = [
            '',
            `${quantity}${units} NET ${
              x.tareWeight ? `(${x.tareWeight}${units} TARE)` : ''
            } @ ${unitPrice}/${units}`,
          ];
          rows.push(weightedQuantityRow);
        }

        appendModifiers(modifierRows, x, locale);
        const printableLineItem = {
          product: table(rows, tableOrderItemConfig),
          note: isVoidedItem
            ? table(
                [[`** ${x?.reason} **`]],
                tableOrderItemNoteConfig(template),
              )
            : x?.notes
            ? table([[`** ${x?.notes} **`]], tableOrderItemNoteConfig(template))
            : '',
          //currently double array is needed for reason and notes
          modifiers:
            modifierRows.length > 0
              ? '\n' +
                table(modifierRows, tableOrderItemModifierConfig(template))
              : '',
        };
        groupResult.push(printableLineItem);
      });
      const allergensKey =
        cur === DEFAULT_TABLE_ABBREVIATION
          ? ALL_ALLERGENS_KEY
          : cur.split(' ')?.[1];
      const seatAllergens = allergens?.[allergensKey]
        ?.map(a => `**ALLERGY ${ProductAllergens[a].toUpperCase()}**`)
        ?.join('\n')
        ?.concat('\n');
      result.push({
        ...(seatManagement && {
          seat: cur,
          seatAllergens,
        }),
        items: groupResult,
      });
    });
  return result;
};

/**
 * Returns partial order (items) printable array with Course Layout
 *
 * @param partialItems
 */
export const getOrderItemsPrintStringByCourse = (
  itemsWithCourse: CoursePrintItems[],
  allergens?: AllergensInfo,
  locale?: LOCALE,
  seatManagement?: boolean,
  template?: PrinterTemplate,
): OrderItemsPrintDetails[] => {
  const printableOrder = itemsWithCourse.reduce(
    (acc: OrderItemsPrintDetails[], course) => {
      const { courseName, printItems } = course;
      acc.push({
        groupedItems: getOrderItemsPrintString(
          printItems,
          allergens,
          locale,
          seatManagement,
          template,
        ),
        header: courseName.toLocaleUpperCase() + '\n',
      });
      return acc;
    },
    [],
  );

  return printableOrder;
};

export const printCourse = (order: Order, session: Session) => {
  //asdf
  const isCourseFeatureEnabled =
    !!session.currentOrganization?.features?.filter(
      (feature: Feature) => feature.featureId === FeatureIDs.COURSES,
    )?.[0]?.enabled;

  const isCoursesEnabled =
    session.deviceProfile?.enableCourses && isCourseFeatureEnabled;

  return isCourseFeatureEnabled && isCoursesEnabled;
};

export const getPrintableKitchenOrder: PartialPrintableKitchenOrder = ({
  order,
  printItems,
  session,
  hasVoidOrderItem,
  template,
  translatedNowCourse,
  profileName,
  locale,
  printerSeries,
  currentItemNumber,
  totalItems,
}) => {
  let printableItems = [] as OrderItemsPrintDetails[];
  const shouldPrintProfile =
    session.deviceProfile?.showPrinterProfileInDocket || false;
  const groupBySeat =
    (session.deviceProfile?.enableSeatManagement &&
      order.orderType?.name === 'Dine In') ||
    false;
  if (printCourse(order, session)) {
    const itemsWithCourse = printItems.reduce((courses, item) => {
      const course =
        item.course || ({ name: translatedNowCourse, priority: -1 } as Course);
      const courseIndex = courses.findIndex(x => x.courseName === course.name);
      if (courseIndex < 0) {
        courses.push({
          courseName: course.name,
          priority: course.priority,
          printItems: [item],
        });
      } else {
        const existCourse = courses[courseIndex];
        courses[courseIndex] = {
          ...existCourse,
          printItems: [...existCourse.printItems, item],
        };
      }

      return courses;
    }, [] as CoursePrintItems[]);

    const sortedCourses = sortBy(itemsWithCourse, course => course.priority);
    printableItems = getOrderItemsPrintStringByCourse(
      sortedCourses,
      order.allergens,
      locale,
      groupBySeat,
      template,
    );
  } else {
    printableItems = [
      {
        groupedItems: getOrderItemsPrintString(
          printItems,
          order.allergens,
          locale,
          groupBySeat,
          template,
        ),
      },
    ];
  }
  let allergens: AllergensKey[] = [];
  if (!groupBySeat) {
    allergens = [...new Set(Object.values(order.allergens ?? {}).flat())];
  }

  const buffer = getPrintableBuffer({
    ...(shouldPrintProfile ? { profileName: profileName || '' } : {}),
    orderItems: printableItems,
    originalOrder: order,
    template,
    title: hasVoidOrderItem
      ? 'CANCELLATION'
      : order.isEdited
      ? 'EDIT ORDER'
      : 'NEW ORDER',
    allergens,
    printerSeries: printerSeries || PrinterSeries.TM_M30II,
    session,
    currentItemNumber,
    totalItems,
  });
  return buffer;
};

export const getOrderType = (
  order: Order,
  showCustomerName: boolean,
  config?: TableUserConfig,
): string => {
  if (isOnlineOrder(order))
    return getOnlineOrderType(
      order,
      config ? config : twoColumnLargeTextConfig,
      showCustomerName,
    );

  const orderTypeName = order.orderType?.name.toLocaleUpperCase() || 'DINE IN';

  const orderNumber = getShortVersion(order.orderNumber);
  const orderIdentifier =
    orderTypeName == 'DINE IN' && order?.table?.name
      ? `Table ${order?.table?.name}`
      : orderNumber;
  return table(
    [[orderTypeName, orderIdentifier]],
    config ? config : twoColumnLargeTextConfig,
  );
};

export const getTableDetail = (order: Order): string => {
  if (isOnlineOrder(order)) return getOnlineOrderDetail(order, twoColumnConfig);

  const tableData = [
    'table.section.name',
    'table.guestCount',
    'createdBy.name',
    'createdByDevice.name',
  ];

  let rowInfo: string[] = [];
  const result = tableData.reduce((acc, infoPath) => {
    let orderDetail = get(order, infoPath);
    if (!orderDetail) return acc;

    if (infoPath === 'table.guestCount') {
      orderDetail = 'Guests: ' + orderDetail;
    }

    rowInfo.push(orderDetail);
    if (rowInfo.length == 2) {
      acc.push([...rowInfo] as [string, string]);
      rowInfo = [];
    }
    return acc;
  }, [] as [string, string][]);

  if (!result.length) return '';

  return table(result, twoColumnConfig);
};

const getDocketTimeStamp = (originalDocket: Order): number | undefined => {
  return originalDocket.salesChannel?.name?.toLowerCase() == 'online' ||
    originalDocket.integrationInfo?.app === IntegrationApps.OOLIO_STORE
    ? originalDocket.placedAt || originalDocket.updatedAt
    : originalDocket.updatedAt;
};

const getTokenNumber = (originalDocket: Order): string | undefined => {
  return Number.isInteger(originalDocket.tokenNumber)
    ? originalDocket.tokenNumber?.toString()
    : '';
};

export const getPrinterColumns = (
  printerSeries: PrinterSeries,
  singleItemLabelPrinting: boolean,
): number => {
  if (singleItemLabelPrinting) {
    switch (printerSeries) {
      case PrinterSeries.TM_M30II:
        return 36;
      case PrinterSeries.TM_U220:
        return 30;
      case PrinterSeries.TM_M30:
        return 30;
      default:
        return 34;
    }
  } else {
    return printerSeries !== PrinterSeries.TM_U220 ? 48 : 42;
  }
};

export const getPrintableDataForLabelPrinting = (args: {
  originalOrder: Order;
  orderItems: OrderItemsPrintDetails[];
  printerSeries: PrinterSeries;
  columns: number;
  currentItemNumber?: number;
  totalItems?: number;
}): {
  orderName: string | undefined;
  divider: string;
  title: string;
  orderType: string;
  footer: string;
  printMode: string;
  currentItemNumber?: number;
  totalItems?: number;
} => {
  const {
    originalOrder: order,
    orderItems,
    printerSeries,
    currentItemNumber,
    totalItems,
    columns,
  } = args;

  const timestamp = getDocketTimeStamp(order);

  const tokenNumber = getTokenNumber(order);

  const time = format(timestamp || new Date(), 'dd MMM - HH:mm').toUpperCase();

  const title = table(
    [[time, `${currentItemNumber}/${totalItems}`]],
    twoColumnConfig,
  );

  const footer = table(
    [
      [
        order?.salesChannel?.name,
        order?.createdBy?.name || order?.createdByDevice?.name,
      ],
    ],
    twoColumnConfig,
  );
  const data = {
    orderName: order.orderName || tokenNumber,
    isTraining: !!order.isTraining,
    orderItems,
    divider: divider(columns) + '\n',
    title: title,
    orderType: getOrderType(order, false, twoColumnConfig),
    footer: footer,
    printMode: printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220',
    currentItemNumber: currentItemNumber,
    totalItems: totalItems,
  };
  return data;
};

export const is58mmTemplate = (template: PrinterTemplate): boolean => {
  return template.name === KITCHEN_DOCKET_58MM;
};

export const getPrintableBuffer = (args: {
  originalOrder: Order;
  template: PrinterTemplate;
  orderItems: OrderItemsPrintDetails[];
  title: String;
  allergens?: AllergensKey[];
  printerSeries: PrinterSeries;
  profileName?: string;
  session: Session;
  currentItemNumber?: number;
  totalItems?: number;
}): Buffer | undefined => {
  const {
    originalOrder,
    template,
    orderItems,
    title,
    allergens = [],
    profileName,
    printerSeries,
    session,
    currentItemNumber,
    totalItems,
  } = args;

  let reasonOrNote = '';
  let customerInfo = '';
  const kitchen58mmTemplate = is58mmTemplate(template);
  const timestamp =
    originalOrder.salesChannel?.name?.toLowerCase() == 'online' ||
    originalOrder.integrationInfo?.app === IntegrationApps.OOLIO_STORE
      ? originalOrder.placedAt || originalOrder.updatedAt
      : originalOrder.updatedAt;

  if (originalOrder.status === OrderStatus.VOID && originalOrder.reason) {
    reasonOrNote = `** REASON: ${originalOrder.reason} **`;
  } else if (originalOrder.orderNote) {
    reasonOrNote = `** ${originalOrder.orderNote} **`;
  }

  if (originalOrder.customer) {
    customerInfo = `CUSTOMER: ${originalOrder.customer.firstName} ${originalOrder.customer.lastName}`;
  }
  const { customerName, customerPhone, customerAddress } =
    getCustomerInfo(originalOrder);

  const deliveryAddress = getFormattedAddress(
    originalOrder.shippingAddress,
    COMBINED_SEGMENT,
  );
  const columns = getPrinterColumns(printerSeries, kitchen58mmTemplate);
  const tokenNumber = Number.isInteger(originalOrder.tokenNumber)
    ? originalOrder.tokenNumber?.toString()
    : '';
  const data = kitchen58mmTemplate
    ? getPrintableDataForLabelPrinting({
        originalOrder,
        orderItems,
        printerSeries,
        columns,
        currentItemNumber,
        totalItems,
      })
    : {
        orderDetails: getTableDetail(originalOrder),
        isTraining: !!originalOrder.isTraining,
        orderName: originalOrder.orderName || tokenNumber,
        orderItems,
        divider: divider(columns) + '\n',
        dashDivider: dashDivider(columns) + '\n',
        title: title + '\n',
        orderType: getOrderType(originalOrder, true),
        timeStamp:
          format(new Date(timestamp || new Date()), 'dd-MM-yyyy hh:mm a') +
          '\n',
        reasonOrNote,
        customerInfo,
        channel: getOrderChannel(originalOrder),
        printerProfile: !profileName ? profileName : profileName + '\n',
        kitchenBuzzer: session?.deviceProfile?.enableKitchenBuzzer,
        ...(allergens.length && {
          allergens: allergens
            .map(a => `**ALLERGY ${ProductAllergens[a].toUpperCase()}**`)
            .join('\n'),
        }),
        customerName: customerName + '\n',
        customerAddress:
          (isOnlineOrder(originalOrder) ? deliveryAddress : customerAddress) +
          '\n',
        customerPhone: customerPhone + '\n',
        printMode: printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220',
      };

  return EscPos.getBufferFromTemplate(
    nameToTemplate[template.name]
      ? nameToTemplate[template.name]
      : kitchenDocketTemplate,
    data,
  ) as unknown as Buffer;
};
