import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { ProductInventory } from './ItemAvailabilityModal';
import { useTheme } from '../../../../hooks/useTheme';
import Icon from '../../../Icon/Icon';
import InputText from '../../../Shared/Inputs/InputText';
import ItemAvailabilityModalStyles from './ItemAvailabilityModal.style';

interface Props {
  item: ProductInventory;
  onChange: (
    id: string,
    key: keyof ProductInventory,
    value: string | boolean,
  ) => void;
  onChangeAvailability?: (id: string, value: string | boolean) => void;
}

interface CheckboxProps {
  checked: boolean;
  onToggle: () => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked = false, onToggle }) => {
  const { colors } = useTheme();
  const styles = ItemAvailabilityModalStyles();

  return (
    <TouchableOpacity onPress={onToggle} style={styles.checkbox}>
      <Icon
        size={20}
        name={checked ? 'check-square' : 'square'}
        color={checked ? colors.green : colors.grey4}
      />
    </TouchableOpacity>
  );
};

const ItemAvailabilityRow: React.FC<Props> = ({
  item,
  onChange,
  onChangeAvailability,
}) => {
  const styles = ItemAvailabilityModalStyles();

  const { isBeingTracked, availableQuantity, name, id, isAvailable } = item;

  const onChangeItem = useCallback(
    (key: keyof ProductInventory, value: string | boolean) => {
      onChange(id, key, value);
    },
    [id, onChange],
  );

  const onChangeAvailable = useCallback(
    (value: string | boolean) => {
      onChangeAvailability && onChangeAvailability(id, value);
    },
    [id, onChangeAvailability],
  );

  return (
    <View testID="table-row" style={styles.row}>
      <Text style={styles.cellName}>{name}</Text>
      <View style={styles.cellCheckbox}>
        <Checkbox
          checked={!(isAvailable === false)}
          onToggle={onChangeAvailable.bind(
            null,
            isAvailable === false ? true : false,
          )}
        />
      </View>
      <View style={styles.cellCheckbox}>
        <Checkbox
          checked={Boolean(isBeingTracked)}
          onToggle={onChangeItem.bind(
            null,
            'isBeingTracked',
            !Boolean(isBeingTracked),
          )}
        />
      </View>
      <InputText
        testID="input-qty"
        placeholder="0"
        alignText="center"
        keyboardType="numeric"
        value={availableQuantity.toString()}
        onChangeText={onChangeItem.bind(null, 'availableQuantity')}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ width: 90 }}
      />
    </View>
  );
};

export default ItemAvailabilityRow;
