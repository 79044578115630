import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { View, StatusBar, ScrollView } from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import ActionsBar, { ActionsBarProps } from '../ActionsBar/ActionsBar';
import theme from '../../../common/default-theme';
import { syncAppUtility } from '../../../state/syncAppUtility';
import LayoutStyles from './ScreenLayout.styles';
import LoadingIndicator from '../../Shared/Loaders/LoadingIndicator';
import { NotificationsList } from '../../Shared/Notifications/NotificationsList';

interface Props extends ActionsBarProps {
  title: string;
  loading?: boolean;
  scrollEnabled?: boolean;
  children: React.ReactNode | React.ReactNode[];
  sidePanel?: React.ReactNode | React.ReactNode[];
  banner?: React.ReactElement;
}

const ScreenLayout: React.FC<Props> = ({
  title,
  loading,
  scrollEnabled = true,
  children,
  sidePanel,
  banner,
  ...props
}) => {
  const safeHeight = theme.useSafeHeight();
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();
  const [syncLoading, setSyncLoading] = useState(false);

  useEffect(() => {
    const subscription = syncAppUtility.getSubscriptionState$.subscribe(
      status => {
        setSyncLoading(status);
      },
    );
    return () => {
      subscription.unsubscribe();
    };
  });

  const styles = LayoutStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <StatusBar barStyle="light-content" />
      <View style={styles.screen}>
        <NotificationsList
          notifications={notifications}
          onDismiss={closeNotification}
        />
        {banner && banner}
        <ActionsBar title={title} loading={loading} {...props} />
        {loading || syncLoading ? (
          <LoadingIndicator
            large
            containerStyles={[styles.loader, { height: safeHeight }]}
          />
        ) : (
          <>
            <ScrollView
              style={{ height: safeHeight }}
              scrollEnabled={scrollEnabled}
            >
              {children}
            </ScrollView>
            {sidePanel}
          </>
        )}
      </View>
    </>
  );
};

export default ScreenLayout;
