import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  inputWrapper: {
    height: 44,
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 6,
  },
  inputContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    fontSize: 14,
    paddingHorizontal: 12,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    fontVariant: ['tabular-nums'],
  },
  inputTitleText: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    paddingLeft: 12,
    color: theme.colors.grey6,
  },
  labelContainer: {
    height: '100%',
    borderRightWidth: 1,
    paddingHorizontal: 12,
    backgroundColor: theme.colors.grey1,
    borderRightColor: theme.colors.grey3,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    marginRight: 1,
  },
  labelText: {
    fontSize: 14,
    color: theme.colors.grey6,
  },
  errorText: {
    paddingTop: 6,
    paddingLeft: 12,
    color: theme.colors.red,
  },
  accessory: {
    width: 42,
    height: 42,
    position: 'absolute',
    right: 1,
    bottom: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: theme.radius.s,
    borderBottomRightRadius: theme.radius.s,
  },
  colour: {
    width: 'auto',
    paddingRight: 6,
    flexDirection: 'row',
  },
  swatch: {
    width: 30,
    height: 22,
    marginRight: 4,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.black,
  },
});

export default styles;
