import React, { PropsWithChildren } from 'react';
import { StyleSheet, View } from 'react-native';
import Gradient from '../../components/Gradient/Gradient';
import theme from '../../common/default-theme';
import LoadingIndicator from '../../components/Shared/Loaders/LoadingIndicator';

export const Loader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Gradient style={styles.screenContainer}>
      {children}
      <View style={styles.modalContainer}>
        <LoadingIndicator large />
      </View>
    </Gradient>
  );
};

export default Loader;

const styles = StyleSheet.create({
  screenContainer: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer: {
    padding: 40,
    borderRadius: 6,
    alignItems: 'center',
    ...theme.shadow.strong,
    backgroundColor: theme.colors.white,
  },
});
