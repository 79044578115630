import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartHeaderStyles = () => {
  const { colors } = useTheme();

  const bgContainer: ViewStyle = {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: colors.elevation0,
  };

  const btn: ViewStyle = {
    ...bgContainer,
    justifyContent: 'center',
    width: 68,
    marginLeft: 4,
  };

  const text: TextStyle = {
    flex: 1,
    fontSize: 14,
    lineHeight: 20,
    color: colors.text,
    fontFamily: theme.fonts.medium,
  };

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    orderType: {
      flex: 1,
      ...bgContainer,
    },
    counter: {
      marginHorizontal: 8,
      maxWidth: 80,
    },
    text: {
      ...text,
    },
    icon: {
      marginRight: 10,
    },
    btnTable: {
      ...btn,
      width: 68,
      paddingHorizontal: 10,
    },
    textTable: {
      ...text,
      textAlign: 'center',
      color: theme.colors.accent,
      textTransform: 'uppercase',
      fontVariant: ['tabular-nums'],
    },
    btnAllergens: {
      ...btn,
      width: 44,
    },
    badge: {
      position: 'absolute',
      top: 6,
      right: 6,
      width: 12,
      height: 12,
      borderRadius: 6,
      backgroundColor: theme.colors.states.focus,
    },
  });
};

export default CartHeaderStyles;
