import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 360,
    backgroundColor: theme.colors.light,
    alignSelf: 'center',
    borderRadius: 10,
    padding: 20,
    ...theme.shadow.elevated1,
  },
  content: { margin: 20 },
  nameText: {
    fontFamily: theme.fonts.semibold,
    fontSize: 16,
    color: theme.colors.dark,
    lineHeight: 22,
  },
  sectionTitle: {
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.grey6,
    lineHeight: 20,
    marginTop: 20,
  },
  sectionInfo: {
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.dark,
    lineHeight: 20,
  },
  emptyInfo: {
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.grey5,
    lineHeight: 20,
  },
  tagsContainer: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    gap: 4,
  },
  tag: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 20,
  },
  tagText: {
    color: theme.colors.light,
    fontSize: 12,
    fontFamily: theme.fonts.regular,
  },
  btnContainer: { margin: 10 },
});

export default styles;
