import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    marginLeft: 6,
    paddingBottom: 74,
  },
  emptyContainer: {
    width: 160,
    margin: 10,
    borderRadius: theme.radius.s,
  },
  page: {
    height: 70,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
  },
  pageText: {
    fontSize: 13,
    lineHeight: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.white,
  },
  selected: {
    width: 4,
    borderRadius: 2,
    backgroundColor: theme.colors.white,
    position: 'absolute',
    top: 4,
    left: 4,
    bottom: 4,
  },
});

export const getTextColor = (background: string) => {
  const darkTextPalette = [
    theme.colors.lime,
    theme.colors.yellow,
    theme.colors.amber,
  ];

  if (darkTextPalette.includes(background)) {
    return theme.colors.black1;
  } else {
    return theme.colors.white;
  }
};

export const getPageTileWidth = (vw: number) => {
  if (vw <= 1024) {
    return 110;
  } else if (vw <= 1180) {
    return 120;
  } else {
    return 160;
  }
};

export default styles;
