import { useCallback, useEffect, useRef, useState } from 'react';

import { App } from '@oolio-group/domain';
import { SsoClient, TokenPayload } from '@oolio-group/pkce-sso-sdk';
import jwt_decode from 'jwt-decode';
import { AuthState, auth0Config } from '../../constants';
import { getExpiresAfter } from '../../state/preferences';
import { tokenUtility } from '../../state/tokenUtility';
import { userUtility } from '../../state/userUtility';
import { useNotification } from '../Notification';
import { useLogout } from './useLogout';

interface AuthenticationOption {
  redirectUrl: string;
  onAuthorized: () => Promise<void>;
}

export const useAuthentication = ({
  redirectUrl,
  onAuthorized,
}: AuthenticationOption) => {
  const { showNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const { logout } = useLogout();

  const onCallbackUrlReturn = useCallback(
    async (payload: TokenPayload) => {
      try {
        const tokenInfo = {
          token: payload.accessToken,
          refreshToken: payload.refreshToken,
          expiresAfter: getExpiresAfter(payload.expiresIn as number),
          activeApp: App.BACKOFFICE,
          idToken: payload.idToken,
        };

        const idTokenPayload = jwt_decode<{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          user_metadata: any;
          email: string;
          sub: string;
        }>(payload.idToken as string);
        userUtility.addOfficeUser({
          name: idTokenPayload.user_metadata?.name,
          email: idTokenPayload.email,
          id: idTokenPayload.user_metadata?.id || idTokenPayload.sub,
        });
        tokenUtility.setTokenInfo({
          ...tokenInfo,
          authState: AuthState.LOGGED_IN,
        });
        setLoading(true);
        await onAuthorized();
      } catch (error) {
        showNotification({
          message: 'Can not login with your credential please try again',
          error: true,
        });
        logout();
      } finally {
        setLoading(false);
      }
    },
    [onAuthorized, showNotification, logout],
  );

  const ssoClientRef = useRef(
    new SsoClient(
      {
        issuer: auth0Config.issuer,
        clientId: auth0Config.clientId,
        audience: auth0Config.additionalParameters.audience,
        scope: auth0Config.scopes.join(' '),
        redirectUrl: redirectUrl,
      },
      onCallbackUrlReturn,
    ),
  );

  useEffect(() => {
    ssoClientRef.current.completeAuthorizationIfPosible();
  }, []);

  const login = useCallback(async () => {
    await ssoClientRef.current.authenticate();
  }, []);

  return {
    login,
    loading: loading,
  };
};
