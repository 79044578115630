import { StyleSheet, TextStyle } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';

const OrderHistoryTableStyles = () => {
  const { colors } = useTheme();

  const rowText: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    color: colors.text,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
  };

  return StyleSheet.create({
    table: {
      marginBottom: 80,
      marginHorizontal: 20,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      rowGap: 4,
      marginVertical: 8,
    },
    tableRow: {
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 12,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableRowTraining: {
      borderWidth: 1,
      borderRadius: theme.radius.s,
      backgroundColor: colors.blueLight,
      borderColor: colors.disclosure,
      borderStyle: 'dashed',
    },
    headerDate: {
      width: 130,
      marginLeft: 54,
    },
    headerNo: {
      width: 140,
      marginLeft: 20,
    },
    headerName: {
      width: 150,
      marginLeft: 20,
    },
    headerPayments: {
      flex: 1,
      marginLeft: 20,
    },
    headerAmt: {
      width: 80,
      textAlign: 'right',
      marginRight: 150,
    },
    cellDate: {
      ...rowText,
      width: 130,
      marginLeft: 10,
    },
    cellNo: {
      ...rowText,
      width: 140,
      marginLeft: 20,
    },
    cellName: {
      ...rowText,
      width: 150,
      marginLeft: 20,
    },
    cellPayments: {
      ...rowText,
      flex: 1,
      marginLeft: 20,
    },

    cellAmt: {
      ...rowText,
      width: 80,
      textAlign: 'right',
    },
  });
};

export default OrderHistoryTableStyles;
