import React, { useCallback } from 'react';
import { Text, View } from 'react-native';
import styles from './BannerAction.styles';
import { translate } from '@oolio-group/localization';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { ButtonStyle } from '../../Shared/TreatButton/Button.types';

interface BannerActionProp {
  show: boolean;
  message: string;
  dismissAction: {
    onPress: () => void;
    label?: string;
    type?: ButtonStyle;
  };
  confirmAction: {
    onPress: () => void;
    label?: string;
    type?: ButtonStyle;
  };
}

const BannerAction: React.FC<BannerActionProp> = ({
  message,
  show,
  dismissAction,
  confirmAction,
}) => {
  const onPressConfirm = useCallback(() => {
    confirmAction?.onPress && confirmAction?.onPress();
  }, [confirmAction]);

  const onPressDismiss = useCallback(() => {
    dismissAction?.onPress && dismissAction?.onPress();
  }, [dismissAction]);

  if (!show) return null;
  return (
    <>
      <View style={styles.bannerContainer}>
        <Text style={styles.textContent}>{message}</Text>
        <View style={styles.actionContent}>
          <TreatButton
            type={dismissAction?.type || 'positive'}
            label={dismissAction?.label || translate('button.notNow')}
            onPress={onPressDismiss}
            height={32}
          />
          <TreatButton
            type={confirmAction?.type || 'cancel'}
            height={32}
            label={confirmAction?.label || translate('button.update')}
            onPress={onPressConfirm}
          />
        </View>
      </View>
    </>
  );
};

export default BannerAction;
