/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { themes, themeType } from '../common/theme-colors';
import * as preferences from '../state/preferences';
import { Theme } from '../common/theme.types';
import { GridSize } from '../components/POS/Menu/MenuGrid';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export interface ThemeContextValue {
  colors: themeType;
  activeTheme: Theme;
  grids: {
    products: GridSize;
    options: GridSize;
  };
  toggleTheme: () => void;
  setGrids: (newGrids: { products: GridSize; options: GridSize }) => void;
}

export const ThemeContext = createContext<ThemeContextValue>({
  colors: themes.light,
  activeTheme: Theme.LIGHT,
  grids: {
    products: { rows: 5, columns: 5 },
    options: { rows: 3, columns: 5 },
  },
  toggleTheme: () => {},
  setGrids: () => {},
} as ThemeContextValue);

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [activeTheme, setActiveTheme] = useState<Theme>(Theme.LIGHT);
  const [grids, setGrids] = useState<{ products: GridSize; options: GridSize }>(
    {
      products: { rows: 5, columns: 5 },
      options: { rows: 3, columns: 5 },
    },
  );

  const toggleTheme = useCallback(async () => {
    const newTheme = activeTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    await preferences.setTheme(newTheme);
    setActiveTheme(newTheme);
  }, [activeTheme]);

  useEffect(() => {
    (async () => {
      const storedTheme = (await preferences.getTheme()) as Theme;
      setActiveTheme(storedTheme || Theme.LIGHT);
    })();
  }, []);

  const value = useMemo(
    () => ({
      colors: themes[activeTheme],
      grids,
      toggleTheme,
      activeTheme,
      setGrids,
    }),
    [activeTheme, grids, toggleTheme, setGrids],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export function useTheme(): ThemeContextValue {
  return useContext(ThemeContext);
}
