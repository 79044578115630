import { useCallback, useEffect, useRef, useState } from 'react';
import {
  OrderStatus,
  OrderTypeCode,
  IntegrationApps,
  TableStatus,
  Order,
  Reservation,
  RESERVATION_STATUS,
  DeviceProfile,
} from '@oolio-group/domain';

import { useOrders } from '../orders/useOrders';
import { isReservationsEnabledVar } from '../../../state/cache';
import { getTablesFromOrder } from '@oolio-group/order-helper';
import { useFocusEffect } from '@react-navigation/native';
import { getCurrentReservationsWithOptimisticUpdates } from '../../../screens/POS/Reservations/optimisticReservationsUtils';
import { addHours, addMinutes, subMinutes } from 'date-fns';
import { useSession } from '../useSession';

export interface useTablesData {
  tableStatusMap: Record<string, TableStatus>;
  tableOrdersMap: Record<string, Order[]>;
  upcomingReservationsMap: Record<string, Reservation | null>;
  getTablesData: () => void;
}

const ALLOWED_RESERVATION_STATUSES = [
  RESERVATION_STATUS.CONFIRMED,
  RESERVATION_STATUS.PRE_PARTIALLY_ARRIVED,
  RESERVATION_STATUS.PRE_ARRIVED,
  RESERVATION_STATUS.PARTIALLY_ARRIVED,
];

const RESERVATIONS_UPDATE_INTERVAL = 60000; // 60 seconds;
export const DEFAULT_RESERVATION_TIME_WINDOW_MINUTES = 30; // 30 minutes;
export const RESERVATION_GRACE_PERIOD_MIN = 15; // Grace period for late reservations
export const UPCOMING_RESERVATION_WINDOW_HOURS = 6;
export const isReservationWithinTimeWindow = ({
  reservationTime,
  startTime,
  endTime,
}: {
  reservationTime: string | Date;
  startTime: Date;
  endTime?: Date;
}): boolean => {
  const reservedTime = new Date(reservationTime);
  const isAfterStart = reservedTime > startTime;
  const isBeforeEnd = !endTime || reservedTime <= endTime;
  return isAfterStart && isBeforeEnd;
};

export const useTablesData = ({
  shouldTrackReservations = false,
} = {}): useTablesData => {
  const [tableStatusMap, setTableStatusMap] = useState<
    Record<string, TableStatus>
  >({} as Record<string, TableStatus>);
  const [tableOrdersMap, setTableOrdersMap] = useState<Record<string, Order[]>>(
    {} as Record<string, Order[]>,
  );
  const [upcomingReservationsMap, setUpcomingReservationsMap] = useState<
    Record<string, Reservation | null>
  >({});
  const { returnOrdersFromCache } = useOrders();
  const [session] = useSession();

  const intervalRef = useRef<number | null>(null);

  const getTablesData = useCallback(() => {
    const inProgressOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
    const onlineInprogressOrders = returnOrdersFromCache(
      OrderStatus.IN_PROGRESS,
      true,
    );
    // Orders from online store v2 are marked as oom orders
    const filteredDineInOnlineOrders = onlineInprogressOrders.filter(
      x =>
        x?.orderType?.code === OrderTypeCode.DINE_IN &&
        (x?.integrationInfo?.app === IntegrationApps.OOLIO_STORE ||
          x?.integrationInfo?.app === IntegrationApps.OOM),
    );
    const holdOrders = returnOrdersFromCache(OrderStatus.ON_HOLD);
    const createdOrders = returnOrdersFromCache(OrderStatus.CREATED);
    const mapToStatus: Record<string, TableStatus> = {};
    const mapToOrder: Record<string, Order[]> = {};

    const orders = [
      ...inProgressOrders,
      ...holdOrders,
      ...createdOrders,
      ...filteredDineInOnlineOrders,
    ];

    orders
      .filter(order => order.orderType?.code === OrderTypeCode.DINE_IN)
      .forEach(order => {
        const tables = getTablesFromOrder(order);

        tables.forEach(table => {
          mapToOrder[table.id] = !!mapToOrder[table.id]
            ? [...mapToOrder[table.id], order].sort(
                (a, b) => a.createdAt - b.createdAt,
              )
            : [order];
          mapToStatus[table.id] = mapToStatus[table.id]
            ? TableStatus.OCCUPIED
            : table.status;
        });
      });

    setTableStatusMap(mapToStatus);
    setTableOrdersMap(mapToOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnOrdersFromCache]);

  const filterValidReservations = useCallback(
    (reservations: Reservation[]): Reservation[] => {
      const currentTime = new Date();
      const startTime = subMinutes(currentTime, RESERVATION_GRACE_PERIOD_MIN);
      const endTime = addHours(currentTime, UPCOMING_RESERVATION_WINDOW_HOURS);

      return reservations.filter(
        ({ status, real_datetime_of_slot: reservationTime }) =>
          ALLOWED_RESERVATION_STATUSES.includes(status as RESERVATION_STATUS) &&
          isReservationWithinTimeWindow({
            reservationTime,
            startTime,
            endTime,
          }),
      );
    },
    [],
  );

  /**
   * Maps reservations to the earliest reservation for each table.
   */
  const mapReservationsToTables = useCallback(
    (reservations: Reservation[]): Record<string, Reservation | null> => {
      const currentTime = new Date();
      const startTime = subMinutes(currentTime, RESERVATION_GRACE_PERIOD_MIN);
      const endTime = addMinutes(
        currentTime,
        DEFAULT_RESERVATION_TIME_WINDOW_MINUTES,
      );
      return reservations.reduce((tableReservationMap, reservation) => {
        const reservationTime = new Date(reservation.real_datetime_of_slot);

        reservation.table_numbers.forEach(tname => {
          const currentMappedReservation = tableReservationMap[tname];

          if (
            !currentMappedReservation ||
            new Date(currentMappedReservation.real_datetime_of_slot) >
              reservationTime
          ) {
            const sections = (session.deviceProfile as DeviceProfile).sections;
            const section = sections.find(
              section =>
                section.name.toLowerCase() ===
                reservation.venue_seating_area_name.toLowerCase(),
            );
            if (!section) return;

            const table = (section?.tables ?? []).find(t => t.name === tname);
            if (!table) return;

            // tableStatus will be undefined for AVAILABLE tables
            const tableOccupied = tableStatusMap[table.id];
            if (
              tableOccupied &&
              !isReservationWithinTimeWindow({
                reservationTime: reservation.real_datetime_of_slot,
                startTime,
                endTime,
              })
            ) {
              return;
            }
            tableReservationMap[tname] = reservation;
          }
        });

        return tableReservationMap;
      }, {} as Record<string, Reservation | null>);
    },
    [session.deviceProfile, tableStatusMap],
  );

  const updateUpcomingReservations = useCallback(() => {
    const currentReservations = getCurrentReservationsWithOptimisticUpdates();
    const validReservations = filterValidReservations(currentReservations);
    const reservationMap = mapReservationsToTables(validReservations);
    setUpcomingReservationsMap(reservationMap);
  }, [filterValidReservations, mapReservationsToTables]);

  useFocusEffect(
    useCallback(() => {
      if (shouldTrackReservations && isReservationsEnabledVar()) {
        if (!intervalRef.current) {
          intervalRef.current = setInterval(() => {
            updateUpcomingReservations();
          }, RESERVATIONS_UPDATE_INTERVAL);
        }
        updateUpcomingReservations();
      }
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }, [shouldTrackReservations, updateUpcomingReservations]),
  );

  useEffect(() => {
    getTablesData();
  }, [getTablesData]);

  return {
    tableStatusMap,
    tableOrdersMap,
    getTablesData,
    upcomingReservationsMap,
  };
};
