/* eslint-disable react-native/no-inline-styles */
import React, { FC, useCallback, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  AdjustmentType,
  Modifier,
  OrderItem,
  OrderItemModifier,
  OrderItemStatus,
  VoidReason,
  OptionValue,
  Resource,
} from '@oolio-group/domain';
import {
  REFUND_ORDER_ITEM,
  computeOrderItemTotal,
  getAdjustmentLabel,
  getItemSavedAmount,
} from '@oolio-group/order-helper';
import {
  useCurrency,
  useTranslation,
  useLocalization,
} from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import CartProductStyles from './CartProduct.styles';
import { useTheme } from '../../../../hooks/useTheme';
import CartNote from '../CartNote/CartNote';
import { CartSelectionState } from '../Cart';
import { useNotification } from '../../../../hooks/Notification';
import { useCart } from '../../../../hooks/orders/useCart';
import { isOrderItemSentToKitchen } from '../../../../utils/orderHelper';
import {
  kdsDevicesInStoreVar,
  restrictOrderItemModificationsVar,
} from '../../../../state/cache';
import usePOSUserAuthorization from '../../../../hooks/app/users/usePOSUserAuthorization';
import Identifier from '../../../../components/Shared/Identifier/Identifier';
import CartComboProducts from '../CartComboProducts/CartComboProducts';
import theme from '../../../../common/default-theme';

const formatPriceWithHiding = (
  value: number,
  formatFn: (value: number) => string,
  hideZeroPrices?: boolean,
): string => {
  if (value === 0 && hideZeroPrices) return '';
  return formatFn(value);
};

export interface CartProductProps {
  disabled?: boolean;
  hideZeroPrices?: boolean;
  isPaid?: boolean;
  isSelected?: boolean;
  minimised?: boolean;
  orderItem: OrderItem;
  seat?: string;
  selectedSubItem?: string;
  testID: string;
  themed?: boolean;
  onSelect?: (state: CartSelectionState) => void;
  onLongPress?: (orderItemId: string) => void;
  onRegisterRef?: (itemId, ref: TouchableOpacity | null) => void;
}

interface ProductParentProps {
  disabled?: boolean;
  hideZeroPrices?: boolean;
  isItemSentToKitchen?: boolean;
  isPaid?: boolean;
  isTransferred: boolean;
  isVariableQty?: boolean;
  isVoided: boolean;
  orderItem: OrderItem;
  seat?: string;
  selectedState?: 'full' | 'partial';
  themed: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  getProductName: (orderItem: OrderItem) => string;
  onRegisterRef?: (itemId, ref: TouchableOpacity | null) => void;
}

const ProductParent: FC<ProductParentProps> = ({
  disabled,
  hideZeroPrices = false,
  isItemSentToKitchen = false,
  isPaid,
  isTransferred,
  isVariableQty,
  isVoided,
  orderItem,
  seat,
  selectedState,
  themed = false,
  onPress,
  onLongPress,
  getProductName,
  onRegisterRef,
}) => {
  const { colors } = useTheme();
  const { formatCurrency } = useCurrency();

  const styles = CartProductStyles({ themed });

  const bgStyle =
    selectedState === 'full'
      ? styles.selectedView
      : selectedState === 'partial'
      ? styles.partialSelectedView
      : isVoided
      ? styles.voidView
      : isTransferred
      ? styles.transferredView
      : isPaid
      ? styles.paidView
      : {};

  const textStyle =
    selectedState === 'full'
      ? styles.selectedText
      : selectedState === 'partial'
      ? styles.partialSelectedText
      : isVoided
      ? styles.voidText
      : isTransferred
      ? styles.transferredText
      : isPaid
      ? styles.paidText
      : {};

  const itemPrice = useMemo(() => {
    const price = computeOrderItemTotal(orderItem);
    return formatPriceWithHiding(price, formatCurrency, hideZeroPrices);
  }, [formatCurrency, hideZeroPrices, orderItem]);

  return (
    <TouchableOpacity
      testID="order-item"
      onPress={onPress}
      onLongPress={onLongPress}
      style={styles.container}
      disabled={isTransferred || disabled}
      ref={ref => onRegisterRef?.(orderItem.id, ref)}
    >
      <View
        style={[
          styles.quantity,
          { backgroundColor: themed ? colors.elevation1 : colors.grey1 },
          bgStyle,
        ]}
      >
        <Text testID="qty" style={[styles.quantityText, textStyle]}>
          {`${orderItem.status === OrderItemStatus.REFUNDED ? '-' : ''}${
            isVariableQty ? '%' : orderItem.quantity
          }`}
        </Text>
      </View>
      <View
        style={[
          styles.name,
          { backgroundColor: themed ? colors.elevation1 : colors.grey1 },
          bgStyle,
        ]}
      >
        {isItemSentToKitchen ? (
          <Text testID="fired" style={[styles.firedText, textStyle]}>
            ✓
          </Text>
        ) : null}
        {orderItem.parentCombo?.id && (
          <Identifier entity="combo" containerStyles={styles.identifier} />
        )}
        <Text
          testID="name"
          numberOfLines={2}
          ellipsizeMode="middle"
          style={[styles.nameText, textStyle]}
        >
          {getProductName(orderItem)}
        </Text>
        <Text testID="price" style={[styles.priceText, textStyle]}>
          {itemPrice}
        </Text>
        {!!seat ? (
          <Text testID="seat" style={[styles.seatText, textStyle]}>
            {seat}
          </Text>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const CartProduct: FC<CartProductProps> = ({
  disabled,
  hideZeroPrices,
  isPaid,
  isSelected,
  minimised,
  orderItem,
  seat,
  selectedSubItem,
  testID,
  themed = false,
  onSelect,
  onLongPress,
  onRegisterRef,
}) => {
  const { locale } = useLocalization();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const [collapseCombo, setCollapseCombo] = useState<Record<string, boolean>>(
    {},
  );

  const { colors } = useTheme();
  const styles = CartProductStyles({ themed });

  const handleCollapseCombo = useCallback(
    (id: string, status: boolean) => {
      if (status === collapseCombo[id]) return;
      setCollapseCombo(preState => ({
        ...preState,
        [id]: status,
      }));
    },
    [collapseCombo],
  );

  const savedAmount = getItemSavedAmount(orderItem);
  const { showNotification } = useNotification();
  const { order } = useCart();
  const { canI } = usePOSUserAuthorization();

  const isDisabledOnPress = useMemo(
    () =>
      !!orderItem.parentCombo?.id ||
      (!!orderItem.comboItems?.length &&
        ![
          OrderItemStatus.DRAFT,
          OrderItemStatus.CREATED,
          OrderItemStatus.ON_HOLD,
        ].includes(orderItem.status)),
    [orderItem.comboItems?.length, orderItem.status, orderItem.parentCombo?.id],
  );

  const isComboItem = !!orderItem.comboItems?.length;
  const variations = orderItem.product?.optionValues;
  const isVoided = orderItem.status === OrderItemStatus.VOID;
  const isTransferred = orderItem.status === OrderItemStatus.TRANSFERRED;
  const isVariableQty = orderItem.product?.variableQuantity;

  const getSizeText = (orderItem: OrderItem) => {
    const units = orderItem.product.measuredBy.units;
    const defaultSize = orderItem.product.measuredBy.defaultSize || 1;
    const amount = parseFloat((orderItem.unitPrice / defaultSize).toFixed(2));
    const sizeText = `${parseFloat(
      (orderItem.quantity * defaultSize).toFixed(3),
    )}${units} NET ${
      orderItem.tareWeight ? `(${orderItem.tareWeight}${units} TARE)` : ''
    } @ ${formatCurrency(amount)}/${units}`;

    return sizeText;
  };

  const getProductName = (orderItem: OrderItem) => {
    const localeEntity = getLocaleEntity(
      orderItem.variant ? orderItem.variant : orderItem.product,
      locale.languageTag,
    );
    const productName =
      orderItem.product?.optionValues?.length > 0
        ? orderItem.product?.name?.split('-')[0]
        : orderItem.product?.name;

    return (
      localeEntity.name ||
      productName ||
      (!orderItem.product?.id ? REFUND_ORDER_ITEM.name : 'Unknown')
    );
  };

  const getModifierName = (modifier: OrderItemModifier) => {
    const entity = getLocaleEntity(
      modifier as unknown as Modifier,
      locale.languageTag,
    );

    return entity.name || modifier.name;
  };

  const onSelectVariation = useCallback(
    (
      variant: string,
      itemSentToKitchen = false,
      comboProduct?: Partial<OrderItem>,
    ): void => {
      if (isDisabledOnPress) return;
      if (comboProduct) {
        const parentOptionGroupId = comboProduct.modifierGroupId,
          productId = comboProduct.product?.id;

        onSelect?.({
          item: orderItem.id,
          product: orderItem.product.id,
          combo: {
            parentModifierGroupId: parentOptionGroupId,
            comboProductId: productId,
            selectedVariantId: comboProduct.variant?.id,
            selectedComboKey: variant,
          },
        });
        return;
      }

      if (itemSentToKitchen) {
        return showNotification({
          error: true,
          message: translate('order.cannotModifyItems'),
        });
      }

      onSelect &&
        onSelect({
          item: orderItem.id,
          product: orderItem.product.id,
          variant: orderItem.variant?.id,
          selectedVariantKey: variant,
        });
    },
    [orderItem, onSelect, isDisabledOnPress, showNotification, translate],
  );

  const onPressProduct = useCallback(() => {
    const quantity = orderItem.quantity;
    onSelect &&
      onSelect({
        item: orderItem.id,
        product: orderItem.product?.id,
        variant: orderItem.variant?.id,
        quantity,
      });
  }, [orderItem, onSelect]);

  const onLongPressProduct = useCallback(() => {
    const hasAccess = canI([{ onResource: Resource.TRANSFER_ORDER }], {
      prompt: true,
    });
    if (!hasAccess) return;
    if (orderItem.status !== OrderItemStatus.IN_PROGRESS) return;
    onLongPress && onLongPress(orderItem.id);
  }, [canI, orderItem.status, orderItem.id, onLongPress]);

  const onSelectOption = useCallback(
    (
      modifier: string,
      modifierGroup: string,
      tempModifierId: string,
      itemSentToKitchen = false,
    ) => {
      if (isDisabledOnPress) return;

      if (!isComboItem && itemSentToKitchen) {
        return showNotification({
          error: true,
          message: translate('order.cannotModifyItems'),
        });
      }

      onSelect &&
        !isComboItem &&
        onSelect({
          item: orderItem.id,
          modifier,
          product: orderItem.product.id,
          modifierGroup,
          selectedModifierKey: tempModifierId,
        });
    },
    [
      onSelect,
      isComboItem,
      orderItem,
      isDisabledOnPress,
      showNotification,
      translate,
    ],
  );

  const onSelectComboOption = useCallback(
    (
      selectedComboKey: string,
      comboItem: Partial<OrderItem>,
      modifier?: OrderItemModifier,
    ) => {
      if (isDisabledOnPress) return;

      const parentOptionGroupId = comboItem.modifierGroupId,
        productId = comboItem.product?.id,
        childOptionGroupId = modifier?.modifierGroupId;
      onSelect?.({
        item: orderItem.id,
        product: orderItem.product?.id,
        combo: {
          parentModifierGroupId: parentOptionGroupId,
          childModifierGroupId: childOptionGroupId,
          comboProductId: productId,
          selectedComboKey,
          ...(modifier &&
            comboItem.variant?.id && {
              selectedVariantId: comboItem.variant.id,
            }),
        },
      });
    },
    [onSelect, orderItem.id, orderItem.product?.id, isDisabledOnPress],
  );

  const onSelectAdjustment = useCallback(() => {
    onSelect &&
      onSelect({
        item: orderItem.id,
        selectedItemKeyForAdjustment: orderItem.id,
      });
  }, [orderItem.id, onSelect]);

  const isPartiallySelected = isSelected && selectedSubItem != undefined;
  const isAdjustmentSelected = selectedSubItem === orderItem.id;
  const itemSentToKitchen = isOrderItemSentToKitchen({
    item: orderItem,
    kdsDevicesInStore: kdsDevicesInStoreVar(),
    restrictOrderItemModifications: restrictOrderItemModificationsVar(),
    orderTypeCode: order?.orderType.code,
  });

  const ParentProduct = (
    <ProductParent
      disabled={disabled}
      hideZeroPrices={hideZeroPrices}
      isItemSentToKitchen={itemSentToKitchen}
      isPaid={isPaid}
      isTransferred={isTransferred}
      isVariableQty={isVariableQty}
      isVoided={isVoided}
      orderItem={orderItem}
      seat={seat}
      selectedState={
        isPartiallySelected ? 'partial' : isSelected ? 'full' : undefined
      }
      themed={themed}
      getProductName={getProductName}
      onLongPress={onLongPressProduct}
      onPress={onPressProduct}
      onRegisterRef={onRegisterRef}
    />
  );

  const renderComboProducts = (orderItem: OrderItem) => {
    if (!orderItem.comboItems?.length) return null;
    const totalQuantity = orderItem.comboItems?.reduce(
      (total, i) => total + (i.quantity || 1),
      0,
    );

    return (
      <View style={styles.comboProduct}>
        <CartComboProducts
          id={orderItem.id}
          themed={themed}
          name={translate('cart.totalItems', { total: totalQuantity })}
          onCollapse={handleCollapseCombo}
          isCollapsed={collapseCombo[orderItem.id]}
        >
          {orderItem.comboItems.map((item, i) => {
            const productQty = item.quantity || 1;
            const modifiers = item.modifiers ?? [];
            const tempProductId = `${orderItem.id}-${item.id}-${i}`;
            const selected = tempProductId === selectedSubItem;
            const partiallySelected = isSelected && !selected;

            const itemPrice =
              (orderItem.quantity || 0) *
              (item.unitPrice || 0) *
              (productQty || 1);

            const formattedPrice = formatPriceWithHiding(
              itemPrice,
              formatCurrency,
              hideZeroPrices,
            );

            const ProductRender = (
              <>
                <TouchableOpacity
                  key={`combo-products-${i}`}
                  testID="btn-comboItems"
                  onPress={() => onSelectComboOption(tempProductId, item)}
                  style={[styles.container, { opacity: isVoided ? 0.3 : 1 }]}
                >
                  <View
                    style={[
                      styles.quantity,
                      {
                        backgroundColor: themed
                          ? colors.elevation1
                          : colors.grey1,
                      },
                      isVoided && productQty > 1 && styles.voidView,
                      selected && productQty > 1 && styles.selectedView,
                      partiallySelected &&
                        productQty > 1 &&
                        styles.partialSelectedView,
                    ]}
                  >
                    <Text
                      testID="combo-product-qty"
                      style={styles.quantityText}
                    >
                      {productQty || 1}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.name,
                      {
                        backgroundColor: themed
                          ? colors.elevation1
                          : colors.grey1,
                      },
                      isVoided && styles.voidView,
                      selected && styles.selectedView,
                      partiallySelected && styles.partialSelectedView,
                    ]}
                  >
                    <Text
                      testID="combo-product-name"
                      style={[
                        styles.nameText,
                        selected && styles.selectedText,
                        partiallySelected && styles.partialSelectedText,
                      ]}
                    >
                      {getProductName(item as OrderItem)}
                    </Text>
                    <Text
                      testID="combo-product-price"
                      style={[
                        styles.priceText,
                        selected && styles.selectedText,
                        partiallySelected && styles.partialSelectedText,
                      ]}
                    >
                      {formattedPrice}
                    </Text>
                  </View>
                </TouchableOpacity>
                {!!item.notes ? (
                  <CartNote
                    testID="note"
                    themed={themed}
                    note={item.notes}
                    productNote={true}
                  />
                ) : null}
                {renderVariations(item.product?.optionValues ?? [], item)}
              </>
            );
            const RenderModifier = renderModifier(modifiers, item);

            return (
              <>
                {ProductRender}
                {RenderModifier}
              </>
            );
          })}
        </CartComboProducts>
      </View>
    );
  };

  const renderModifier = (
    modifiers: OrderItemModifier[],
    comboProduct?: Partial<OrderItem>,
  ) => {
    if (!modifiers?.length) return null;

    return modifiers?.map((modifier, i) => {
      const productId = comboProduct ? `-${comboProduct.id}-` : '-';
      const tempOptionId = `${orderItem.id}${productId}${modifier.id}-${i}`;
      const selected = tempOptionId === selectedSubItem;
      const partiallySelected = isSelected && !selected;
      const comboProductQuantity = comboProduct?.quantity || 1;
      const modQuantity = modifier.quantity || 1;
      const modUnitPrice = modifier.unitPrice || 0;
      const modifierPrice =
        (orderItem.quantity || 1) *
        comboProductQuantity *
        modUnitPrice *
        modQuantity;

      const formattedModifierPrice = formatPriceWithHiding(
        modifierPrice,
        formatCurrency,
        hideZeroPrices,
      );

      return (
        <TouchableOpacity
          key={`modifier-${i}`}
          testID="btn-modifier"
          onPress={
            comboProduct
              ? onSelectComboOption.bind(
                  null,
                  tempOptionId,
                  comboProduct,
                  modifier,
                )
              : onSelectOption.bind(
                  null,
                  modifier.id || '',
                  modifier?.modifierGroupId || '',
                  tempOptionId,
                  itemSentToKitchen,
                )
          }
          style={[
            styles.container,
            { opacity: isVoided || itemSentToKitchen ? 0.3 : 1 },
          ]}
        >
          <View
            style={[
              styles.quantity,
              isVoided && modQuantity > 1 && styles.voidView,
              selected && modQuantity > 1 && styles.selectedView,
              partiallySelected &&
                modQuantity > 1 &&
                styles.partialSelectedView,
            ]}
          >
            <Text
              testID="mod-qty"
              style={[styles.quantityText, isComboItem && styles.modifierText]}
            >
              {modQuantity > 1 ? modQuantity : ''}
            </Text>
          </View>
          <View
            style={[
              styles.name,
              isVoided && styles.voidView,
              selected && styles.selectedView,
              partiallySelected && styles.partialSelectedView,
            ]}
          >
            <Text
              testID="mod-name"
              style={[
                styles.nameText,
                isComboItem && styles.modifierText,
                selected && styles.selectedText,
                partiallySelected && styles.partialSelectedText,
              ]}
            >
              {getModifierName(modifier)}
            </Text>
            <Text
              testID="mod-price"
              style={[
                styles.priceText,
                selected && styles.selectedText,
                partiallySelected && styles.partialSelectedText,
              ]}
            >
              {formattedModifierPrice}
            </Text>
          </View>
        </TouchableOpacity>
      );
    });
  };

  const renderVariations = (
    variations: OptionValue[],
    orderItemComboProduct?: Partial<OrderItem>,
  ) => {
    if (!variations?.length) return null;
    return variations?.map((variant, i) => {
      const selected = variant.key === selectedSubItem && isSelected;
      const partiallySelected = variant.key !== selectedSubItem && isSelected;

      return (
        <TouchableOpacity
          key={`variation-${i}`}
          testID="btn-variant"
          style={[
            styles.container,
            styles.variation,
            isVoided && styles.voidView,
            selected && styles.selectedView,
            partiallySelected && styles.partialSelectedView,
            { opacity: isVoided || itemSentToKitchen ? 0.3 : 1 },
          ]}
          onPress={onSelectVariation.bind(
            null,
            variant.key,
            itemSentToKitchen,
            orderItemComboProduct,
          )}
        >
          <Text
            testID="variant"
            style={[
              styles.nameText,
              selected && styles.selectedText,
              partiallySelected && styles.partialSelectedText,
            ]}
          >
            {variant.value}
          </Text>
        </TouchableOpacity>
      );
    });
  };

  if (minimised) {
    return ParentProduct;
  }

  return (
    <View testID={testID}>
      {/* Parent */}
      {ParentProduct}
      {/* Product Size */}
      {isVariableQty ? (
        <View
          style={[
            styles.container,
            styles.variation,
            isVoided && styles.voidView,
          ]}
        >
          <Text testID="size" style={styles.nameText}>
            {getSizeText(orderItem)}
          </Text>
        </View>
      ) : null}
      {/* Voids */}
      {isVoided ? (
        <View style={[styles.container, styles.voidReason]}>
          <Text testID="void-reason" style={styles.nameText}>
            {translate('order.itemVoidReason', {
              value: translate(`enums.${orderItem.reason as VoidReason}`),
            })}
          </Text>
        </View>
      ) : null}
      {/* Notes */}
      {orderItem.notes?.length > 0 ? (
        <CartNote
          testID="note"
          themed={themed}
          note={orderItem.notes}
          productNote={true}
        />
      ) : null}
      {/* Variations & Options */}
      {renderVariations(variations)}
      {renderComboProducts(orderItem)}
      {renderModifier(orderItem.modifiers)}
      {/* Adjustments */}
      {orderItem.discountAmount &&
      orderItem.adjustments?.length &&
      orderItem.adjustments[0].adjustmentType !== AdjustmentType.REWARD ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <TouchableOpacity
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? themed
                    ? colors.blueLight
                    : theme.colors.blueLight
                  : colors.transparent,
              },
              isAdjustmentSelected && styles.selectedView,
            ]}
            onPress={onSelectAdjustment.bind(null)}
          >
            <Text
              testID="discount-name"
              style={[
                styles.nameText,
                isAdjustmentSelected && styles.selectedText,
              ]}
            >
              {getAdjustmentLabel(orderItem.adjustments?.[0])}
            </Text>
            <Text
              testID="discount-amt"
              style={[
                styles.priceText,
                isAdjustmentSelected && styles.selectedText,
              ]}
            >
              {formatCurrency(-Math.abs(orderItem.discountAmount) || 0)}
            </Text>
          </TouchableOpacity>
        </View>
      ) : null}
      {orderItem.surchargeAmount ? (
        <View style={[styles.container, styles.adjustmentMargin]}>
          <View
            style={[
              styles.name,
              {
                backgroundColor: isSelected
                  ? themed
                    ? colors.blueLight
                    : theme.colors.blueLight
                  : colors.transparent,
              },
            ]}
          >
            <Text testID="surcharge-name" style={styles.nameText}>
              {getAdjustmentLabel(orderItem.adjustments?.[0])}
            </Text>
            <Text testID="surcharge-amt" style={styles.priceText}>
              {formatCurrency(orderItem.surchargeAmount || 0)}
            </Text>
          </View>
        </View>
      ) : null}
      {!!savedAmount && (
        <Text style={styles.savingsText}>
          {translate('order.savedPrice', {
            amount: formatCurrency(savedAmount),
          })}
        </Text>
      )}
    </View>
  );
};

export default CartProduct;
