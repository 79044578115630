import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import EmptyStyles from './Empty.styles';
import EmptyIcon from './Empty.icon';

interface Props {
  label: string;
  themed?: boolean;
  containerStyles?: ViewStyle | ViewStyle[];
}

const Empty: React.FC<Props> = ({ label, themed = false, containerStyles }) => {
  const styles = EmptyStyles({ themed });

  return (
    <View style={[styles.container, containerStyles]}>
      <EmptyIcon themed={themed} containerStyle={styles.icon} />
      <Text style={styles.text}>{label}</Text>
    </View>
  );
};

export default Empty;
