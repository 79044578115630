import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Text,
  View,
  FlatList,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import theme from '../../../common/default-theme';
import SelectStyles from './Select.styles';
import { Icon } from '../../Icon/Icon';

export interface ColourOption {
  value: string;
  label: string;
}

export interface SelectColourProps {
  testID: string;
  title?: string;
  editable?: boolean;
  value?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  onChange?: (value: string) => void;
}

const SelectColour: React.FC<SelectColourProps> = ({
  title,
  testID,
  editable = true,
  containerStyle,
  value: valueProp,
  onChange,
}: SelectColourProps) => {
  const touchable = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | undefined>();

  const styles = SelectStyles({ themed: false });

  const options = useMemo(() => {
    return Object.values(
      theme.colors.pools.allColours.map(color => {
        return {
          label: color.name,
          value: color.color,
        };
      }),
    );
  }, []);

  const selectedColourOption = useMemo(() => {
    return options.find(option => option.value === selectedValue);
  }, [options, selectedValue]);

  const onToggleOptions = useCallback((): void => {
    if (editable) {
      setShowOptions(current => !current);
    }
  }, [editable]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
  }, []);

  const onPressOption = (value: string) => {
    setSelectedValue(value);
    onChange && onChange(value);
    setShowOptions(false);
  };

  useEffect(() => {
    if (valueProp) {
      setSelectedValue(valueProp);
    }
  }, [valueProp]);

  return (
    <View style={containerStyle} testID={testID}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <TouchableOpacity
          ref={touchable}
          onPress={onToggleOptions}
          disabled={!editable}
          style={[
            styles.inputContainer,
            showOptions
              ? theme.containers.active
              : editable
              ? theme.containers.enabled
              : theme.containers.disabled,
          ]}
        >
          <View style={styles.colourValue}>
            <View
              style={[
                styles.colourContainer,
                {
                  backgroundColor: selectedValue || theme.colors.grey2,
                },
              ]}
            />
            <Text>{selectedColourOption?.label || 'Select colour...'}</Text>
          </View>
          {editable && (
            <View style={styles.accessory}>
              <Icon
                size={20}
                color={theme.colors.dark}
                name={showOptions ? 'angle-up' : 'angle-down'}
              />
            </View>
          )}
        </TouchableOpacity>
      </View>
      <Popover
        isVisible={showOptions}
        placement={PopoverPlacement.AUTO}
        onRequestClose={onRequestClosePopover}
        from={touchable}
        backgroundStyle={styles.bgStyle}
        popoverStyle={styles.optionsContainer}
      >
        <View style={styles.optionsPopup}>
          <FlatList
            data={options}
            keyExtractor={(item, i) => `${i}-${item.value}`}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.option}
                onPress={() => onPressOption(item.value)}
              >
                <View
                  style={[
                    styles.colourContainer,
                    { backgroundColor: item.value },
                  ]}
                />
                <Text>{item.label}</Text>
              </TouchableOpacity>
            )}
          />
        </View>
      </Popover>
    </View>
  );
};

export default SelectColour;
