import React, { useCallback, useEffect, useMemo } from 'react';
import { unionBy } from 'lodash';
import { PopoverPlacement } from 'react-native-popover-view';
import {
  BulkUpdateEntityAndCategoryInput,
  DEFAULT_PRODUCT_MODIFIER_GROUP_NAME,
  FeatureIDs,
  ProductModifierGroup,
  UpdateProductInput,
  UpdateVariantInput,
  ProductPricingInput,
  Money,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import { ProductListItem } from './types';
import AssignTags from './Modals/AssignTags';
import { AddStores } from './Modals/AddStores';
import { AssignTaxModal } from './Modals/AssignTax';
import AssignCategoryModal from './Modals/AssignCategory';
import { AssignModifiers } from './Modals/AssignModifiers';
import { AddPrinterProfile } from './Modals/AddPrinterProfile';
import { AssignReportingGroup } from './Modals/AssignReportingGroup';
import { PickerOption } from '../../../../components/Shared/Select/Picker';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import { SetPriceModal } from '../../../../components/Modals/ProductPrices/SetPrices';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import ButtonActions from '../../../../components/Shared/TreatButton/ButtonActions';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { Action } from '../../../../components/Shared/TreatButton/Button.types';

interface ProductBulkOptionsProps {
  dataMaps?: {
    [key: string]: ProductListItem;
  };
  selectedProductListItems: ProductListItem[];
  defaultPricingGroupId?: string;
  taxOptions: { value: string; label: string }[];
  productTypeOptions: { value: string; label: string }[];
  printerProfileOptions: { value: string; label: string }[];
  storeOptions: { value: string; label: string }[];
  updateProducts: (productsDetails: UpdateProductInput[]) => void;
  updateVariants: (variantDetails: UpdateVariantInput[]) => void;
  deleteSelectedItems: () => void;
  categoryOptions: PickerOption[];
  bulkUpdateProductsCategory: (input: BulkUpdateEntityAndCategoryInput) => void;
  bulkUpdateVariantsCategory: (input: BulkUpdateEntityAndCategoryInput) => void;
  updateProductPricings: (input: ProductPricingInput[]) => void;
}
interface UpdateProductDetailsProps {
  productType?: string;
  printerProfiles?: string[];
  stores?: string[];
  dietary?: string[];
  allergens?: string[];
  removePages?: string[];
  selectedProductModifiers?: ProductModifierGroup[];
}

export const ProductBulkOptions: React.FC<ProductBulkOptionsProps> = ({
  dataMaps,
  selectedProductListItems,
  defaultPricingGroupId,
  taxOptions,
  productTypeOptions,
  printerProfileOptions,
  updateProducts,
  updateVariants,
  storeOptions,
  deleteSelectedItems,
  categoryOptions,
  bulkUpdateProductsCategory,
  bulkUpdateVariantsCategory,
  updateProductPricings,
}) => {
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { currency, unAppendCurrency } = useCurrency();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const isAllergensEnabled = isFeatureEnabled(FeatureIDs.ALLERGENS);

  const selectedProducts = useMemo(() => {
    return selectedProductListItems.filter(x => x && !x.isVariant);
  }, [selectedProductListItems]);
  const selectedVariants = useMemo(() => {
    return selectedProductListItems.filter(x => x && x.isVariant);
  }, [selectedProductListItems]);

  const selectedItemCount = useMemo(() => {
    let count = 0;
    selectedProductListItems.map(item => {
      if (item.isVariant) {
        if (item.productIds) {
          count += item.productIds.length;
        }
      } else {
        count++;
      }
    });
    return count;
  }, [selectedProductListItems]);

  useEffect(() => {
    // if there is no default pricing group then close modals
    if (!defaultPricingGroupId) {
      closeModal();
      showNotification({
        error: true,
        message: translate('productBulkOperations.noDefaultPG'),
      });
    }
  }, [closeModal, defaultPricingGroupId, showNotification, translate]);

  const mapModifierGroupsToModifierGroupsInput = useCallback(
    (productModifierGroups: ProductModifierGroup[]) => {
      let defaultModifierGroup = undefined as unknown as ProductModifierGroup;
      const modifierGroups: ProductModifierGroup[] = [];
      productModifierGroups.forEach((productModifier, index) => {
        const productModifierGroup = {
          isGrouped: productModifier.isGrouped,
          isRequired: productModifier.isRequired,
          modifierGroup: productModifier.id,
          priority: index,
          modifiers: productModifier.modifiers.map(eachMod => ({
            id: eachMod.id,
            isDefault: eachMod.isDefault,
          })),
        } as unknown as ProductModifierGroup;
        // id and name  are same in case  of default modifier group
        if (productModifier.id === DEFAULT_PRODUCT_MODIFIER_GROUP_NAME) {
          defaultModifierGroup = productModifierGroup;
        } else {
          modifierGroups.push(productModifierGroup);
        }
      });
      return {
        modifierGroups,
        defaultModifierGroup,
      };
    },
    [],
  );

  const getProductWithNewPriceOrTax = useCallback(
    (
      productId: string,
      newPrice: number | undefined,
      newTax: string | undefined,
    ) => {
      if (dataMaps) {
        const product = dataMaps[productId];
        const price =
          newPrice !== undefined ? newPrice : unAppendCurrency(product.price);
        const tax = newTax !== undefined ? newTax : product.tax;
        return {
          id: product.defaultPriceId,
          sellingPrice: {
            amount: parseFloat(price + ''),
            currency: currency,
          } as Money,
          sellingTax: tax,
          pricingGroupId: defaultPricingGroupId,
          product: product.id,
        } as unknown as ProductPricingInput[];
      }
    },
    [dataMaps, currency, defaultPricingGroupId, unAppendCurrency],
  );

  const saveProductsWithNewPriceOrTax = useCallback(
    (newPrice: number | undefined, newTax: string | undefined) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateProductPricingsInput: any[] = [];
      selectedProducts.forEach(x => {
        updateProductPricingsInput.push(
          getProductWithNewPriceOrTax(x.id, newPrice, newTax),
        );
      });
      selectedVariants.forEach(x => {
        if (x.productIds) {
          x.productIds.forEach(variantId => {
            updateProductPricingsInput.push(
              getProductWithNewPriceOrTax(variantId, newPrice, newTax),
            );
          });
        }
      });
      updateProductPricingsInput.length &&
        updateProductPricings(updateProductPricingsInput);
      showNotification({
        success: true,
        message: translate('common.assignedNotification', {
          entity: translate('backOfficeSettings.taxes'),
        }),
      });
    },
    [
      getProductWithNewPriceOrTax,
      selectedProducts,
      selectedVariants,
      showNotification,
      translate,
      updateProductPricings,
    ],
  );

  const updateProductDetails = useCallback(
    (input: UpdateProductDetailsProps) => {
      const { productType, printerProfiles, stores, selectedProductModifiers } =
        input;
      let updateInput: UpdateProductInput[] = [];
      let updateInputVariant: UpdateVariantInput[] = [];
      if (productType) {
        updateInput = selectedProducts.map(
          x =>
            ({
              id: x.id,
              name: x.name,
              productType: productType,
            } as UpdateProductInput),
        );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              productType: productType,
            } as UpdateVariantInput),
        );
      } else if (selectedProductModifiers?.length) {
        const {
          modifierGroups: selectedModifiers,
          defaultModifierGroup: selectedDefaultModifierGroup,
        } = mapModifierGroupsToModifierGroupsInput(selectedProductModifiers);

        updateInput = selectedProducts.map(x => {
          const defaultModifiers = unionBy(
            selectedDefaultModifierGroup?.modifiers || [],
            'id',
          );
          const defaultModifierGroup = {
            ...(selectedDefaultModifierGroup || {}),
            modifiers: defaultModifiers,
          };

          return {
            id: x.id,
            name: x.name,
            modifierGroups: defaultModifiers.length
              ? [...selectedModifiers, defaultModifierGroup]
              : [...selectedModifiers],
          } as unknown as UpdateProductInput;
        });

        updateInputVariant = selectedVariants.map(x => {
          const defaultModifiers = unionBy(
            selectedDefaultModifierGroup?.modifiers || [],
            'id',
          );
          const defaultModifierGroup = {
            ...(selectedDefaultModifierGroup || {}),
            modifiers: defaultModifiers,
          };

          return {
            id: x.id,
            modifierGroups: defaultModifiers.length
              ? [...selectedModifiers, defaultModifierGroup]
              : [...selectedModifiers],
          } as unknown as UpdateProductInput;
        });
      } else if (printerProfiles) {
        updateInput = selectedProducts.map(
          x =>
            ({
              id: x.id,
              name: x.name,
              printerProfiles,
            } as UpdateProductInput),
        );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              printerProfiles,
            } as UpdateVariantInput),
        );
      } else if (stores?.length) {
        // updates only products, will not update variant products
        updateInput = selectedProducts
          .filter(x => x && !x.isVariantProduct)
          .map(
            x =>
              ({
                id: x.id,
                name: x.name,
                // to get unique ids
                stores,
              } as UpdateProductInput),
          );
        updateInputVariant = selectedVariants.map(
          x =>
            ({
              id: x.id,
              stores,
            } as UpdateVariantInput),
        );
      } else if (input.dietary || input.allergens) {
        updateInput = selectedProducts
          .filter(product => product && !product.isVariantProduct)
          .map(
            x =>
              ({
                id: x.id,
                name: x.name,
                ...(input.dietary?.length && {
                  dietaryTags: input.dietary,
                }),
                ...(input.allergens?.length && {
                  allergens: input.allergens,
                }),
              } as UpdateProductInput),
          );
        updateInputVariant = selectedVariants.map(
          variant =>
            ({
              id: variant.id,
              ...(input.dietary?.length && {
                dietaryTags: input.dietary,
              }),
              ...(input.allergens?.length && {
                allergens: input.allergens,
              }),
            } as UpdateVariantInput),
        );
      }
      updateInput.length && updateProducts(updateInput);
      updateInputVariant.length && updateVariants(updateInputVariant);
      closeModal();
    },
    [
      updateProducts,
      updateVariants,
      closeModal,
      selectedProducts,
      selectedVariants,
      mapModifierGroupsToModifierGroupsInput,
    ],
  );

  const setPrices = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Set Prices',
    });
    if (selectedProducts.length > 0) {
      showModal(
        <SetPriceModal
          mode="set"
          onSubmit={newPrice =>
            saveProductsWithNewPriceOrTax(newPrice, undefined)
          }
          title={translate('productBulkOperations.setProductPrice')}
          submitLabel={translate('productBulkOperations.changePrice')}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('pricings.noItemSelected'),
      });
    }
  }, [
    showModal,
    closeModal,
    showNotification,
    selectedProducts,
    translate,
    saveProductsWithNewPriceOrTax,
  ]);

  const assignTax = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Assign Tax',
    });
    showModal(
      <AssignTaxModal
        countOfproducts={selectedProducts.length}
        onSubmit={newTax => saveProductsWithNewPriceOrTax(undefined, newTax)}
        taxOptions={taxOptions}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    closeModal,
    showModal,
    selectedProducts,
    taxOptions,
    saveProductsWithNewPriceOrTax,
  ]);

  const assignProductType = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Assign Reporting Group',
    });
    if (productTypeOptions.length) {
      showModal(
        <AssignReportingGroup
          countOfproducts={selectedItemCount}
          onSubmit={productType => updateProductDetails({ productType })}
          productTypeOptions={productTypeOptions}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('productBulkOperations.noProductTypes'),
      });
    }
  }, [
    closeModal,
    productTypeOptions,
    selectedItemCount,
    showModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const onBulkAssignCategory = useCallback(
    async (selectedCategory: string) => {
      closeModal();
      const productIds = selectedProducts.map(
        product => product.id,
      ) as string[];
      const variantIds = selectedVariants.map(
        variant => variant.id,
      ) as string[];

      variantIds.length &&
        bulkUpdateVariantsCategory({
          category: selectedCategory,
          entityIds: variantIds,
        });

      productIds.length &&
        bulkUpdateProductsCategory({
          category: selectedCategory,
          entityIds: productIds,
        });
    },
    [
      bulkUpdateProductsCategory,
      bulkUpdateVariantsCategory,
      closeModal,
      selectedProducts,
      selectedVariants,
    ],
  );

  const assignCategory = useCallback(() => {
    if (categoryOptions.length) {
      showModal(
        <AssignCategoryModal
          categoryOptions={categoryOptions}
          onSelectCategory={onBulkAssignCategory}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    } else {
      showNotification({
        error: true,
        message: translate('productBulkOperations.noCategories'),
      });
    }
  }, [
    categoryOptions,
    showModal,
    onBulkAssignCategory,
    closeModal,
    showNotification,
    translate,
  ]);

  const assignTags = useCallback(() => {
    showModal(
      <AssignTags
        onSubmit={(dietary, allergens) =>
          updateProductDetails({ dietary, allergens })
        }
        isAllergensEnabled={isAllergensEnabled}
      />,
      {
        onBackdropPress: closeModal,
      },
    );
  }, [showModal, closeModal, updateProductDetails, isAllergensEnabled]);

  const addPrinterProfile = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Assign Printer Profile',
    });
    if (printerProfileOptions.length) {
      showModal(
        <AddPrinterProfile
          countOfproducts={selectedItemCount}
          onSubmit={printerProfiles => {
            updateProductDetails({ printerProfiles });
            showNotification({
              success: true,
              message: translate('common.assignedNotification', {
                entity: translate('productSettings.printerProfiles'),
              }),
            });
          }}
          printerProfileOptions={printerProfileOptions}
        />,
        { onBackdropPress: closeModal },
      );
    } else {
      showNotification({
        error: true,
        message: translate('productBulkOperations.noPrinterProfile'),
      });
    }
  }, [
    closeModal,
    printerProfileOptions,
    selectedItemCount,
    showModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const addStore = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Store',
    });
    showModal(
      <AddStores
        onSubmit={stores => {
          updateProductDetails({ stores });
          showNotification({
            success: true,
            message: translate('common.assignedNotification', {
              entity: translate('productBulkOperations.stores'),
            }),
          });
        }}
        storeOptions={storeOptions}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    showModal,
    storeOptions,
    closeModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const assignModifiers = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Assign Modifier',
    });
    showModal(
      <AssignModifiers
        onSubmit={selectedProductModifiers => {
          updateProductDetails({ selectedProductModifiers });
          showNotification({
            success: true,
            message: translate('common.assignedNotification', {
              entity: translate('modifiers.options'),
            }),
          });
        }}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    showModal,
    closeModal,
    updateProductDetails,
    showNotification,
    translate,
  ]);

  const deleteProducts = useCallback(() => {
    analyticsService.capture('Bulk Option', {
      action: 'Delete Product',
    });
    showModal(
      <ConfirmationModal
        onConfirm={deleteSelectedItems}
        title={translate('productBulkOperations.deleteProductsTitle', {
          count: selectedProductListItems?.length?.toString(),
        })}
        confirmLabel={translate('productBulkOperations.delete')}
        message={translate('productBulkOperations.deleteProductsDiscription')}
      />,
      { onBackdropPress: closeModal },
    );
  }, [
    showModal,
    deleteSelectedItems,
    translate,
    selectedProductListItems?.length,
    closeModal,
  ]);

  const bulkActions: Action[] = [
    {
      id: 'setPrice',
      label: translate('productBulkOperations.setPrice'),
      action: setPrices,
    },
    {
      id: 'addToCategory',
      label: translate('productBulkOperations.addToCategory'),
      action: assignCategory,
    },
    {
      id: 'assignTags',
      label: translate('productBulkOperations.assignTags'),
      action: assignTags,
    },
    {
      id: 'assignTax',
      label: translate('productBulkOperations.assignTax'),
      action: assignTax,
    },
    {
      id: 'assignProductType',
      label: translate('productBulkOperations.assignProductType'),
      action: assignProductType,
    },
    {
      id: 'addPrinterProfile',
      label: translate('productBulkOperations.addPrinterProfile'),
      action: addPrinterProfile,
    },
    {
      id: 'addToStore',
      label: translate('productBulkOperations.addToStore'),
      action: addStore,
    },
    {
      id: 'assignModifiers',
      label: translate('productBulkOperations.assignModifiers'),
      action: assignModifiers,
    },
    {
      id: 'deleteProducts',
      label: translate('productBulkOperations.deleteProducts'),
      textStyle: 'negative',
      action: deleteProducts,
    },
  ];

  return (
    <ButtonActions
      type="neutral"
      testID="btn-bulkOptions"
      label={translate('productBulkOperations.bulkActions', {
        length: selectedItemCount,
      })}
      actions={bulkActions}
      placement={PopoverPlacement.BOTTOM}
    />
  );
};
