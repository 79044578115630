import { StyleSheet } from 'react-native';
import { WaitlistStatusEnum } from '@oolio-group/domain';
import { useTheme } from '../../../../hooks/useTheme';
import theme, { ACTIONS_BAR_HEIGHT } from '../../../../common/default-theme';

const BookingSidePanelStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 340,
      rowGap: 4,
      padding: 6,
      backgroundColor: colors.background,
      ...theme.shadow.light,
      position: 'absolute',
      top: ACTIONS_BAR_HEIGHT,
      right: 0,
    },
    content: {
      flex: 1,
      padding: 20,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    meta: {
      rowGap: 16,
    },
    row: {
      rowGap: 4,
    },
    label: {
      fontSize: 12,
      lineHeight: 16,
      color: colors.textLight,
      fontFamily: theme.fonts.regular,
      fontVariant: ['tabular-nums'],
    },
    value: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    actions: {
      rowGap: 4,
    },
    actionsRow: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
    },
    btn: {
      flex: 1,
    },
  });
};

export default BookingSidePanelStyles;

export const getStatusTextColor = (
  status: WaitlistStatusEnum,
  colors: Record<string, string>,
) => {
  switch (status) {
    case WaitlistStatusEnum.WAITING:
      return colors.text;
    case WaitlistStatusEnum.CANCELLED:
      return colors.red;
    case WaitlistStatusEnum.NO_SHOW:
      return colors.orange;
    case WaitlistStatusEnum.ARRIVED:
    case WaitlistStatusEnum.COMPLETED:
      return colors.green;
    case WaitlistStatusEnum.READY:
      return colors.blue;
    default:
      return colors.black1;
  }
};
