import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const PaymentCompleteStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 620,
      alignSelf: 'center',
    },
    content: {
      padding: 40,
      height: 474,
      rowGap: 20,
      borderRadius: theme.radius.m,
      backgroundColor: colors.elevation0,
      ...theme.shadow.light,
    },
    response: {
      flex: 1,
      padding: 40,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.greenLightest,
    },
    icon: {
      width: 50,
      height: 50,
      borderWidth: 3,
      borderRadius: 25,
      alignItems: 'center',
      justifyContent: 'center',
      borderColor: colors.green,
      backgroundColor: colors.greenLight,
      marginBottom: 10,
    },
    title: {
      fontSize: 20,
      lineHeight: 28,
      color: colors.green,
      fontFamily: theme.fonts.semibold,
      fontVariant: ['tabular-nums'],
    },
    subtitle: {
      fontSize: 16,
      lineHeight: 22,
      color: colors.green,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
      marginTop: 6,
    },
    textRemaining: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.textLight,
      fontVariant: ['tabular-nums'],
      marginTop: 16,
    },
    actions: {
      rowGap: 8,
    },
    actionsRow: {
      columnGap: 8,
      flexDirection: 'row',
    },
    btn: {
      flex: 1,
    },
  });
};

export default PaymentCompleteStyles;
