import React from 'react';
import { Text, ScrollView, TouchableOpacity } from 'react-native';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import FloorViewStyles from '../../FloorView.styles';
import theme from '../../../../../../common/default-theme';
import { useTheme } from '../../../../../../hooks/useTheme';

export interface TabBarProps {
  tabBar: MaterialTopTabBarProps;
}

const SectionTabs: React.FC<TabBarProps> = ({ tabBar }) => {
  const { width: vw } = theme.useResponsiveDimensions();
  const { state, descriptors, navigation } = tabBar;

  const handlePress = (isFocused: boolean, routeName: string): void => {
    if (!isFocused) navigation.navigate(routeName);
  };

  const { colors } = useTheme();
  const styles = FloorViewStyles();

  return (
    <ScrollView horizontal style={[styles.sections, { maxWidth: vw - 40 }]}>
      {state.routes.map((route, index) => {
        const isFocused = state.index === index;
        const { options } = descriptors[route.key] as {
          options: { tabBarLabel?: string };
        };
        const label: string = (options.tabBarLabel as string) || route.name;

        const textColor = isFocused ? colors.white : colors.text;
        const backgroundColor = isFocused ? colors.accent : colors.transparent;

        return (
          <TouchableOpacity
            key={index}
            testID="btn-section"
            style={[styles.btnSection, { backgroundColor }]}
            onPress={() => handlePress(isFocused, route.name)}
          >
            <Text style={[styles.actionText, { color: textColor }]}>
              {label}
            </Text>
          </TouchableOpacity>
        );
      })}
    </ScrollView>
  );
};

export default SectionTabs;
