import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { View, Text, TouchableOpacity } from 'react-native';
import theme from '../../../../../common/default-theme';
import styles from './SetPauseOrdersModal.styles';
import { PartnerChannel, PauseOrdersChannelInput } from '@oolio-group/domain';
import Icon from '../../../../../components/Icon/Icon';
import ButtonText from '../../../../../components/Shared/TreatButton/TreatButton';
import { addMinutes } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import { DurationOptions } from './DurationOptions';

const UntilTomorrowTmpValue = 24 * 60;

const getPausedUntilValue = (value: number): string => {
  const timezone = 'Australia/Melbourne';
  let pausedUntil = addMinutes(new Date(), value);
  if (value == UntilTomorrowTmpValue) {
    const tomorrowDate = new Date();
    tomorrowDate.setHours(24, 0, 0, 0);
    pausedUntil = utcToZonedTime(tomorrowDate, timezone);
  }
  return format(pausedUntil, "yyyy-MM-dd'T'HH:mm:ssXXX");
};

interface SetPauseOrdersProps {
  oomConnectedChannels: PartnerChannel[];
  onSetPauseOrders: (updatedChannels: PauseOrdersChannelInput[]) => void;
}
const PAUSE_ORDERS_DURATIONS_IN_MINUTES = [
  { value: 15, label: '15m' },
  { value: 30, label: '30m' },
  { value: 45, label: '45m' },
  { value: 60, label: '1h' },
  { value: UntilTomorrowTmpValue, label: 'Until tomorrow' },
  { value: 0, label: 'None' },
];

export const SetPauseOrdersModal: React.FC<SetPauseOrdersProps> = ({
  oomConnectedChannels,
  onSetPauseOrders,
}: SetPauseOrdersProps) => {
  const { translate } = useTranslation();
  const { closeModal } = useModal();
  const [pauseOrderMaps, setPauseOrderMaps] = useState<
    Record<string, PauseOrdersChannelInput>
  >({});
  const originalSettingRef = useRef<Record<string, PauseOrdersChannelInput>>(
    {},
  );

  const onSelectOption = useCallback((channelCode: string, value: number) => {
    const willPauseOrders = value > 0;
    const pausedUntil = willPauseOrders
      ? getPausedUntilValue(value)
      : undefined;
    setPauseOrderMaps(pre => ({
      ...pre,
      [channelCode]: {
        durationInMinutes: value,
        pauseOrders: willPauseOrders,
        pausedUntil: pausedUntil,
        code: channelCode,
      },
    }));
  }, []);

  useEffect(() => {
    if (!oomConnectedChannels) return;
    const pauseSettingsProps = oomConnectedChannels.reduce((maps, channel) => {
      const pausedConfig = channel?.pauseOrdersConfig;
      const isPausedOrders = Boolean(
        pausedConfig?.pausedOrders &&
          pausedConfig?.pausedUntil &&
          new Date(pausedConfig?.pausedUntil) > new Date(),
      );
      const pauseOrderInput: PauseOrdersChannelInput = {
        code: channel.code,
        durationInMinutes: isPausedOrders ? pausedConfig?.durationInMinutes : 0,
        pauseOrders: isPausedOrders,
        pausedUntil: pausedConfig?.pausedUntil,
      };
      maps[channel.code] = pauseOrderInput;
      return maps;
    }, {} as Record<string, PauseOrdersChannelInput>);
    originalSettingRef.current = pauseSettingsProps;
    setPauseOrderMaps(pauseSettingsProps);
  }, [oomConnectedChannels]);

  const handleSetPauseOrders = useCallback(() => {
    closeModal();
    const updatedChannels = Object.values(pauseOrderMaps).filter(input => {
      const originalSetting = originalSettingRef.current;
      return originalSetting[input?.code]?.pausedUntil != input.pausedUntil;
    });
    onSetPauseOrders(updatedChannels);
  }, [closeModal, onSetPauseOrders, pauseOrderMaps]);

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.title}>
          <TouchableOpacity
            testID="btn-close"
            style={styles.btnClose}
            onPress={closeModal}
          >
            <Icon name="times" size={20} color={theme.colors.dark} />
          </TouchableOpacity>
          <Text style={styles.titleText}>
            {translate('onlineOrders.pauseOrders')}
          </Text>
        </View>
      </View>
      <View style={styles.content}>
        {oomConnectedChannels.map(channel => {
          const { code, name } = channel;
          return (
            <View key={channel.code} style={styles.optionContainer}>
              <Text style={styles.channelName}>{name}</Text>
              <DurationOptions
                options={PAUSE_ORDERS_DURATIONS_IN_MINUTES}
                selectedOption={pauseOrderMaps[code]?.durationInMinutes || 0}
                onSelectOption={onSelectOption.bind(null, channel.code)}
              />
            </View>
          );
        })}
        <ButtonText
          type="focus"
          testID="confirm-pause-orders"
          onPress={handleSetPauseOrders}
          containerStyle={styles.btnConfirm}
          label={translate('button.confirm')}
        />
      </View>
    </View>
  );
};
