import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import {
  PaymentType,
  PaymentAction,
  OrderAction,
  DefaultPaymentTypes,
  FeatureIDs,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { usePaymentTypes } from '../../../hooks/app/usePaymentTypes';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import { SKIP_PAYMENT_TYPES_ON_PAY_NOW } from '../../../types/Common';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../screens/POS/Payments/PaymentScreen';
import PaymentOptionsStyles from './PaymentOptions.styles';
import Message from '../../Office/Message/Message';
import PaymentHeader from './PaymentHeader/PaymentHeader';
import PaymentAmount from './PaymentAmount/PaymentAmount';
import PaymentButtons from './PaymentButtons/PaymentButtons';

export interface SplitPaymentProps {
  enableRoundOff?: boolean;
  roundOffValue?: number;
  orderPaymentDetails: OrderPaymentDetails;
  dispatch: React.Dispatch<PaymentActionProp>;
  onPressOtherPayOut: (paymentTypeName: string) => void;
  onPressPayOut: (paymentTypeName: string, amount: number) => void;
  onUpdateProcessingAmount: (type: 'SET' | 'UPDATE') => void;
}

const SplitPayment: React.FC<SplitPaymentProps> = ({
  enableRoundOff,
  roundOffValue,
  orderPaymentDetails,
  onPressOtherPayOut,
  onPressPayOut,
  dispatch,
  onUpdateProcessingAmount,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { paymentTypes } = usePaymentTypes({ fetchPolicy: 'cache-first' });

  const styles = PaymentOptionsStyles();

  const isCustomerAccountEnabled = isFeatureEnabled(
    FeatureIDs.CUSTOMER_ACCOUNT,
  );

  const {
    processingAmount,
    remainingDueAmount,
    totalDueAmount,
    isSplitBySeat,
    isSplitByProduct,
    processingPaymentSeat,
    nthPayment,
    isCustomerBalancePayment,
  } = orderPaymentDetails;

  const otherPaymentTypes: PaymentType[] = useMemo(
    () =>
      paymentTypes
        ?.filter(
          (x: { name: string }) =>
            (!SKIP_PAYMENT_TYPES_ON_PAY_NOW.includes(x.name) &&
              x.name !== DefaultPaymentTypes.ON_ACCOUNT) ||
            (x.name === DefaultPaymentTypes.ON_ACCOUNT &&
              isCustomerAccountEnabled),
        )
        .map(paymentType => {
          const disabled =
            isCustomerBalancePayment &&
            paymentType.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE;
          return { ...paymentType, disabled };
        }) || [],
    [isCustomerBalancePayment, paymentTypes, isCustomerAccountEnabled],
  );

  const amountOutstanding = remainingDueAmount - processingAmount;

  const onCloseSubPaymentScreen = useCallback(() => {
    dispatch({ type: PaymentAction.CANCEL_CURRENT_STEP, payload: {} });
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <PaymentHeader
        title={
          isSplitByProduct
            ? translate('payment.splittingByProduct')
            : isSplitBySeat
            ? translate('payment.splitPaymentBySeat', {
                seat: processingPaymentSeat,
              })
            : translate('payment.splitPaymentStep', {
                step: nthPayment,
              })
        }
        onClose={onCloseSubPaymentScreen}
      />
      <View style={styles.content}>
        <PaymentAmount
          value={processingAmount}
          editable={!isSplitByProduct}
          onPressEdit={() => onUpdateProcessingAmount('UPDATE')}
        />
        {!isSplitByProduct ? (
          <Message
            testID="message-remaining"
            type={totalDueAmount < 0 ? 'positive' : 'neutral'}
            message={translate('payment.remainingAmountOutOfTotal', {
              willRemainingAmount: formatCurrency(amountOutstanding),
              totalDueAmount: formatCurrency(totalDueAmount),
            })}
            containerStyle={styles.marginTop10}
          />
        ) : null}
        {isSplitByProduct && !processingAmount ? (
          <>
            <Message
              type="focus"
              message={translate('payment.splittingByProduct')}
              containerStyle={styles.splitProductMessage}
            />
            <View style={styles.splitPrompt}>
              <Text style={styles.splitPromptText}>
                {translate('payment.selectAProductToStart')}
              </Text>
            </View>
          </>
        ) : (
          <PaymentButtons
            processingAmount={processingAmount}
            enableRoundOff={enableRoundOff}
            roundOffValue={roundOffValue}
            otherPaymentTypes={otherPaymentTypes}
            onPressPayOut={onPressPayOut}
            onPressOtherPayOut={onPressOtherPayOut}
          />
        )}
      </View>
    </View>
  );
};

export default SplitPayment;
