import { useApolloClient, useLazyQuery } from '@apollo/client/react/hooks';
import {
  Connection,
  Order,
  OrderFilterInput,
  ORDERS_LIMIT,
  PageInfo,
} from '@oolio-group/domain';
import { useCallback, useRef, useState } from 'react';
import { GET_ORDER, GET_PAGINATED_ORDER_HISTORY_QUERY } from './graphql';
import { parseApolloError } from '../../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';

export interface UseOrdersProps {
  orders: Order[];
  loading: boolean;
  getPaginatedOrders: (filter: OrderFilterInput) => void;
  onFetchMore: () => void;
  pageInfo?: PageInfo;
  getOrderById: (
    orderId: string,
    withCache?: boolean,
    loading?: boolean,
  ) => Promise<Order | undefined>;
  error?: string;
  updateCachedRefundOrder: (order: Order | null) => void;
}

export function useOrdersHistory(): UseOrdersProps {
  const [orders, setOrdersData] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const lastPageInfoRef = useRef<PageInfo>({});
  const client = useApolloClient();

  const [getPaginatedReq, getPaginatedRes] = useLazyQuery<{
    paginatedOrders: Connection<Order>;
  }>(GET_PAGINATED_ORDER_HISTORY_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: response => {
      const data = response.paginatedOrders;
      const orders = (data.edges || []).map(edge => edge.node);
      setOrdersData(pre => [...pre, ...orders]);
      lastPageInfoRef.current = data.pageInfo;
    },
  });

  const getOrderById = useCallback(
    async (orderId: string, withCache = true, loading = true) => {
      try {
        if (loading) setLoading(true);
        setError('');
        const response = await client.query<{ order: Order }>({
          query: GET_ORDER,
          variables: { orderId },
          /*
            Refund order should be fetch from server as
            we're updating status/reason from adyen webhook
          */
          ...(!withCache && { fetchPolicy: 'network-only' }),
        });
        setLoading(false);
        return response?.data?.order;
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setError((error as any)?.message as string);
        setLoading(false);
      }
    },
    [client],
  );

  const getPaginatedOrders = useCallback(
    (filter: OrderFilterInput) => {
      setOrdersData([]);
      getPaginatedReq({
        variables: {
          first: ORDERS_LIMIT,
          after: '',
          filter,
        },
      });
    },
    [getPaginatedReq],
  );

  const onFetchMore = useCallback(() => {
    if (!lastPageInfoRef.current.hasNextPage) return;
    getPaginatedReq({
      variables: {
        ...getPaginatedRes?.variables,
        after: lastPageInfoRef?.current?.endCursor,
      },
    });
  }, [getPaginatedReq, getPaginatedRes?.variables]);

  const updateCachedRefundOrder = useCallback(refundOrder => {
    if (!refundOrder) return;
    setOrdersData(pre => [refundOrder, ...pre]);
  }, []);

  return {
    orders,
    loading: getPaginatedRes.loading || loading,
    getPaginatedOrders,
    onFetchMore,
    pageInfo: lastPageInfoRef.current,
    getOrderById,
    error:
      error || getPaginatedRes.error
        ? parseApolloError(getPaginatedRes.error as ApolloError)
        : undefined,

    updateCachedRefundOrder,
  };
}
