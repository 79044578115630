import React, { useMemo, useState, useCallback } from 'react';
import { View, Text, FlatList } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { Waitlist } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import BookingTableStyles from './BookingsTable.styles';
import BookingRow from './BookingRow';
import Empty from '../../../../components/Shared/Empty/Empty';
import Pagination from '../../../../components/POS/Pagination/Pagination';

interface Props {
  bookings: Waitlist[];
  currentPage: number;
  onPageChange: (page: number) => void;
  onSelectBooking: (entry: Waitlist) => void;
  onEditBooking: (entry: Waitlist) => void;
}

const PAGE_SIZE = 10;

const BookingsTable: React.FC<Props> = ({
  bookings = [],
  currentPage,
  onPageChange,
  onSelectBooking,
  onEditBooking,
}) => {
  const { translate } = useTranslation();

  const styles = BookingTableStyles();

  const [currentTime, setCurrentTime] = useState(Date.now());

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, 60000);
      return () => clearInterval(interval);
    }, []),
  );

  const paginatedBookings = useMemo(() => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    return bookings.slice(startIndex, startIndex + PAGE_SIZE);
  }, [bookings, currentPage]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.cellPosition} />
        <Text style={[styles.cellQueued, theme.tables.headerText]}>
          {translate('bookings.queued')}
        </Text>
        <Text style={[styles.cellGuests, theme.tables.headerText]}>
          {translate('form.guests')}
        </Text>
        <Text style={[styles.cellName, theme.tables.headerText]}>
          {translate('form.name')}
        </Text>
        <Text style={[styles.cellPhone, theme.tables.headerText]}>
          {translate('form.phone')}
        </Text>
        <Text style={[styles.cellCreated, theme.tables.headerText]}>
          {translate('bookings.joinedAt')}
        </Text>
      </View>
      <FlatList
        data={paginatedBookings}
        contentContainerStyle={styles.body}
        renderItem={({ item, index }) => (
          <BookingRow
            key={`row-${index}`}
            index={(currentPage - 1) * PAGE_SIZE + index + 1}
            booking={item}
            onPress={() => onSelectBooking(item)}
            onEdit={onEditBooking}
            currentTime={currentTime}
          />
        )}
        ListEmptyComponent={() => (
          <Empty themed label={translate('bookings.emptyWaitlists')} />
        )}
      />
      {bookings?.length > PAGE_SIZE && (
        <Pagination
          page={currentPage}
          dataLength={bookings.length}
          pageLength={PAGE_SIZE}
          onPageChange={onPageChange}
        />
      )}
    </View>
  );
};

export default BookingsTable;
