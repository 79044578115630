import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
  Waitlist,
  WaitlistStatusEnum,
  Table,
  IntegrationApps,
  FeatureIDs,
  BookingSource,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import styles from './Bookings.styles';
import BookingsTable from './Table/BookingsTable';
import BookingFormModal, { BookingForm } from './Modal/BookingForm';
import BookingSidePanel from './SidePanel/BookingSidePanel';
import Search from '../../../components/Shared/Search/Search';
import { Option } from '../../../components/Shared/Select/Select';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import FiltersPanel, { Filters } from './SidePanel/Filters';
import { useBookingsV2 } from '../../../hooks/app/bookings/useBookingsV2';
import { getEndOfDayISO, getStartOfDayISO } from '../../../utils/TimeUtils';
import SelectTableModal from '../Orders/FloorView/Sections/Modals/SelectTableModal';
import { useNetworkStatus } from '../../../hooks/app/useNetworkStatus';
import ConfirmationModal from '../../../components/Modals/ConfirmationDialog';
import { useSession } from '../../../hooks/app/useSession';
import { useIntegrationPartners } from '../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { useCheckFeatureEnabled } from '../../../hooks/app/features/useCheckFeatureEnabled';
import Select from '../../../components/Shared/Select/Select';
import ModalDialog from '../../../components/Shared/Modals/ModalDialog/ModalDialog';
import { IconStatus } from '../../../components/Shared/Icons/IconStatus/IconStatus';

const WAITLISTS_POLLING_INTERVAL = 30000;

const BookingsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const [session] = useSession();
  const { showModal, closeModal } = useModal();
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string>('');
  const [selectedWidget, setSelectedWidget] = useState('');
  const [filters, setFilters] = useState<Filters>({
    date: new Date(),
    status: [],
    guestCount: -1,
  });
  const [showFilters, setShowFilters] = useState(false);
  const { isConnected } = useNetworkStatus();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const {
    widgets,
    waitlists,
    getWidgets,
    getWaitlistsByWidget,
    loading,
    statusLoading,
    createBooking,
    editBooking,
    updateBookingStatus,
    createOrderForBooking,
  } = useBookingsV2();

  const { getIntegrationPartnerSettings, integrationPartnerMapsByApp } =
    useIntegrationPartners();

  useEffect(() => {
    getIntegrationPartnerSettings({
      appName: IntegrationApps.OOLIO_WAITLISTS,
      store: session?.currentStore?.id,
    });
  }, [getIntegrationPartnerSettings, session?.currentStore?.id]);

  const locationId = useMemo(() => {
    return integrationPartnerMapsByApp[IntegrationApps.OOLIO_WAITLISTS]
      ?.preferences?.oolioWaitlists?.posLocationId;
  }, [integrationPartnerMapsByApp]);

  useEffect(() => {
    const orgId = session?.currentOrganization?.id;
    if (!orgId || !locationId) return;

    getWidgets(orgId, locationId);
  }, [getWidgets, session?.currentOrganization?.id, locationId]);

  useFocusEffect(
    useCallback(() => {
      const interval = setInterval(() => {
        getWaitlistsByWidget({
          widgetId: selectedWidget,
          fromDate: getStartOfDayISO(filters.date),
          toDate: getEndOfDayISO(filters.date),
          polling: true,
        });
      }, WAITLISTS_POLLING_INTERVAL);

      return () => clearInterval(interval);
    }, [selectedWidget, filters.date, getWaitlistsByWidget]),
  );

  useEffect(() => {
    if (widgets.length === 0) return;

    const firstWidgetId = widgets[0]?.id;
    const widgetId = widgets.some(w => w.id === selectedWidget)
      ? selectedWidget
      : firstWidgetId;

    if (widgetId !== selectedWidget) {
      setSelectedWidget(widgetId);
    }

    getWaitlistsByWidget({
      widgetId,
      fromDate: getStartOfDayISO(filters.date),
      toDate: getEndOfDayISO(filters.date),
    });

    setCurrentPage(1);
  }, [widgets, selectedWidget, filters.date, getWaitlistsByWidget]);

  const filterByStatus = useCallback(
    (bookingStatus: WaitlistStatusEnum) => {
      if (!filters.status.length) {
        return (
          bookingStatus === WaitlistStatusEnum.READY ||
          bookingStatus === WaitlistStatusEnum.WAITING
        );
      }
      return (
        filters.status.includes('All' as WaitlistStatusEnum) ||
        filters.status.includes(bookingStatus)
      );
    },
    [filters.status],
  );

  const filteredList = useMemo(() => {
    return waitlists
      .sort((a, b) => Number(b.priority) - Number(a.priority))
      .filter(entry => {
        const isValidStatus = filterByStatus(entry.status);
        if (!isValidStatus) return false;

        if (searchText) {
          const customerInfo =
            `${entry.customer.firstName} ${entry.customer.lastName} ${entry.customer.phone} ${entry.customer.email}`.toLowerCase();
          if (!customerInfo.includes(searchText.toLowerCase())) return false;
        }
        if (filters.guestCount > 0) {
          return filters.guestCount === 6
            ? entry.guestCount >= 6
            : entry.guestCount === filters.guestCount;
        }
        return true;
      });
  }, [waitlists, filterByStatus, searchText, filters.guestCount]);

  const onCreateBooking = useCallback(
    async (form: BookingForm) => {
      const booking = await createBooking({
        customer: {
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phone,
          email: form.email,
        },
        notes: form.notes,
        priority: form.priority,
        guestCount: form.guestCount,
        widgetId: form.widgetId,
        createdBy: session?.user?.id || '',
        lastUpdatedBy: session?.user?.id || '',
        lastUpdatedBySource: BookingSource.Venue,
      });

      if (booking && selectedWidget !== booking.id) {
        setSelectedWidget(booking.widgetId);
      }
      setFilters({
        ...filters,
        date: new Date(),
      });
      closeModal();
    },
    [closeModal, createBooking, selectedWidget, session?.user?.id, filters],
  );

  const onEditBooking = useCallback(
    async (form: BookingForm, bookingId: string) => {
      await editBooking({
        customer: {
          firstName: form.firstName,
          lastName: form.lastName,
          phone: form.phone,
          email: form.email,
        },
        notes: form.notes,
        priority: form.priority,
        guestCount: form.guestCount,
        waitlistId: bookingId,
        createdBy: session?.user?.id || '',
        lastUpdatedBy: session?.user?.id || '',
        lastUpdatedBySource: BookingSource.Venue,
      });

      closeModal();
    },
    [closeModal, editBooking, session?.user?.id],
  );

  const handleOnAddEntry = useCallback(() => {
    showModal(
      <BookingFormModal
        widgets={widgets}
        onDismiss={closeModal}
        onConfirm={onCreateBooking}
        defaultWidget={selectedWidget}
      />,
    );
  }, [closeModal, onCreateBooking, selectedWidget, showModal, widgets]);

  const handleOnEditEntry = useCallback(
    (booking: Waitlist) => {
      showModal(
        <BookingFormModal
          widgets={widgets}
          onDismiss={closeModal}
          onConfirm={onEditBooking}
          booking={booking}
        />,
      );
    },
    [closeModal, onEditBooking, showModal, widgets],
  );

  const onSelectTable = useCallback(
    async (bookingId: string, table: Table) => {
      const booking = waitlists.find(x => x.id === bookingId);
      if (booking) {
        await createOrderForBooking(booking, table);
        await updateBookingStatus({
          bookingId,
          status: WaitlistStatusEnum.ARRIVED,
          lastUpdatedBy: session?.user?.id || '',
          lastUpdatedBySource: BookingSource.Venue,
        });
      }
      closeModal();
    },
    [
      closeModal,
      createOrderForBooking,
      updateBookingStatus,
      waitlists,
      session?.user?.id,
    ],
  );

  const handleHideSidePanel = useCallback(() => {
    setSelectedBookingId('');
    setShowSidePanel(false);
  }, []);

  const onUpdateStatus = useCallback(
    async (id: string, status: WaitlistStatusEnum) => {
      const { deviceProfile, user } = session || {};
      const venueId = session?.currentVenue?.id;
      const widget = widgets.find(w => w.id === selectedWidget);

      if (status !== WaitlistStatusEnum.ARRIVED || !widget?.enableSeating) {
        await updateBookingStatus({
          bookingId: id,
          status,
          lastUpdatedBy: user?.id || '',
          lastUpdatedBySource: BookingSource.Venue,
        });
        handleHideSidePanel();
        return;
      }

      const enableFloorView = deviceProfile?.enableFloorView ?? false;
      const isTableFeatureEnabled = isFeatureEnabled(
        FeatureIDs.TABLE_MANAGEMENT,
        venueId,
      );
      const sectionIds = widget?.sectionIds || [];

      const canSeatCustomer =
        enableFloorView &&
        isTableFeatureEnabled &&
        sectionIds.length > 0 &&
        deviceProfile?.sections?.some(section =>
          sectionIds.includes(section.id),
        );

      if (!canSeatCustomer) {
        showModal(
          <ModalDialog
            testID="mdl-dialog"
            title={translate('bookings.cannotSeat')}
            type="focus"
            icon={<IconStatus name="warning" />}
            message={translate('bookings.cannotSeatErrorMessage')}
            onDismiss={{ action: closeModal }}
          />,
        );
        return;
      }

      showModal(
        <SelectTableModal
          onSelection={table => onSelectTable(id, table)}
          onClose={closeModal}
          canSelectSubTable={false}
          sectionIds={sectionIds}
        />,
      );

      handleHideSidePanel();
    },
    [
      closeModal,
      handleHideSidePanel,
      onSelectTable,
      showModal,
      updateBookingStatus,
      session,
      widgets,
      selectedWidget,
      isFeatureEnabled,
      translate,
    ],
  );

  const onUpdateFilters = useCallback(
    (id: string, value: Date | WaitlistStatusEnum | number) => {
      setFilters(prevFilters => {
        if (id === 'status') {
          const updatedStatuses = prevFilters.status.includes(
            value as WaitlistStatusEnum,
          )
            ? prevFilters.status.filter(s => s !== value)
            : ([...prevFilters.status, value] as WaitlistStatusEnum[]);

          return { ...prevFilters, status: updatedStatuses };
        }

        return { ...prevFilters, [id]: value };
      });
    },
    [],
  );

  const onPressRefresh = useCallback(() => {
    const orgId = session?.currentOrganization?.id;
    if (orgId && locationId) {
      getWidgets(orgId, locationId);
    }
  }, [getWidgets, session?.currentOrganization?.id, locationId]);

  const onSelectBooking = useCallback((entry: Waitlist) => {
    setSelectedBookingId(entry.id);
    setShowSidePanel(true);
  }, []);

  const selectedBooking = useMemo(() => {
    return waitlists.find(x => x.id === selectedBookingId);
  }, [waitlists, selectedBookingId]);

  const navigateBack = useCallback(() => {
    closeModal();
    navigation.goBack();
  }, [closeModal, navigation]);

  useFocusEffect(
    useCallback(() => {
      if (isConnected) {
        closeModal();
      } else {
        showModal(
          <ConfirmationModal
            title={translate('offline.noInternet')}
            message={translate('bookings.offlineMessage')}
            confirmLabel={translate('shift.goBack')}
            onConfirm={navigateBack}
            hideCancel
          />,
        );
      }
    }, [isConnected, closeModal, showModal, translate, navigateBack]),
  );

  const widgetOptions: Option[] = useMemo(
    () =>
      widgets.map(x => ({
        value: x.id,
        label: x.name,
      })),
    [widgets],
  );

  return (
    <>
      <ScreenLayout
        loading={loading}
        title={translate('bookings.waitlists')}
        onBack={() => navigation.goBack()}
      >
        <View style={styles.container}>
          <View style={styles.filters}>
            <ButtonIcon
              testID="btn-filter"
              themed
              size={44}
              icon="sliders-v"
              type="cancel"
              onPress={() => setShowFilters(prev => !prev)}
            />
            <Select
              themed
              testID="select-widget"
              options={widgetOptions}
              selectedValue={selectedWidget}
              containerStyle={styles.dropdown}
              onValueChange={setSelectedWidget}
            />
            <Search
              themed
              testID="search-bookings"
              onChangeText={setSearchText}
              value={searchText}
              placeholder={translate('bookings.searchWaitlists')}
              containerStyle={styles.search}
            />
            <ButtonIcon
              testID="btn-refresh"
              size={44}
              icon="sync"
              type="neutral"
              onPress={onPressRefresh}
            />
            <ButtonIcon
              testID="btn-create"
              size={44}
              icon="plus"
              type="positive"
              onPress={handleOnAddEntry}
            />
          </View>
          <BookingsTable
            bookings={filteredList}
            onSelectBooking={onSelectBooking}
            onEditBooking={handleOnEditEntry}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </View>
      </ScreenLayout>
      <BookingSidePanel
        show={showSidePanel}
        booking={selectedBooking}
        onDismiss={handleHideSidePanel}
        onUpdate={onUpdateStatus}
        loading={statusLoading}
      />
      <FiltersPanel
        filters={filters}
        show={showFilters}
        onDismiss={() => setShowFilters(false)}
        onUpdate={onUpdateFilters}
        waitlists={waitlists}
      />
    </>
  );
};

export default BookingsScreen;
