import React, { FC } from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import styles from './NumberPad.styles';

export interface NumberPadProps {
  width?: number;
  keypadButtons: { value: string }[];
  zeroButtonWidth: number;
  onPressKey: (key: string) => void;
}

const NumberPad: FC<NumberPadProps> = ({
  width = 280,
  keypadButtons,
  zeroButtonWidth,
  onPressKey,
}) => {
  const buttonWidth = width / 3 - 4;
  return (
    <View style={styles.keypad}>
      {keypadButtons.map(({ value }) => (
        <TouchableOpacity
          key={`key-${value}`}
          testID={`key-${value}`}
          onPress={() => onPressKey(value || '0')}
          style={[
            styles.btnKeypad,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              width: value === '0' ? zeroButtonWidth : buttonWidth,
            } as ViewStyle,
          ]}
        >
          <Text style={styles.textKeypad}>{value}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default NumberPad;
