import { StyleSheet } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const CartNoteStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      marginBottom: 4,
      paddingHorizontal: 10,
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.orderNote : theme.colors.amberLight,
    },
    product: {
      marginLeft: 32,
      color: colors.textLight,
    },
    text: {
      fontSize: CART_FONT_SIZE,
      lineHeight: 20,
      paddingVertical: 3,
      textAlign: 'center',
      color: themed ? colors.text : theme.colors.black1,
      fontFamily: theme.fonts.medium,
    },
    productNote: {
      textAlign: 'left',
    },
  });
};

export default CartNoteStyles;
