import * as Sentry from '@sentry/browser';
import { SENTRY_IGNORE_URLS, filterNoiseFetchCall } from './utils/breadcrumb';

const environment =
  process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV;

if (!['local', 'development'].includes(environment)) {
  Sentry.init({
    denyUrls: SENTRY_IGNORE_URLS,
    dsn: 'https://73e47659bca34fe39b4257910d4ce51f@o314552.ingest.us.sentry.io/1830344',
    environment: process.env['REACT_APP_LOGGING_ENV'] || process.env.NODE_ENV,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0, // no need to collect sample for info event
    integrations: [
      Sentry.breadcrumbsIntegration({
        xhr: false,
        dom: {
          serializeAttribute: ['data-testid', 'textContent'],
        },
      }),
      Sentry.httpClientIntegration(),
      Sentry.inboundFiltersIntegration({
        ignoreErrors: [
          // These network error need to be investigate later before we decide its not important
          'Failed to fetch',
          'Network request failed',
          'AbortError',
          'Load failed',
          "Failed to execute 'readText' on 'Clipboard'",
        ],
      }),
      // not enable at the moment due to we have so many error
      // will fail error capture due to quota limit
      // Sentry.replayIntegration(),
    ],
    beforeBreadcrumb: breadcrumb => {
      return filterNoiseFetchCall(breadcrumb);
    },
  });
  console.log('init');
}

export default Sentry;
