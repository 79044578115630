import { useLazyQuery, useMutation } from '@apollo/client/react/hooks';

import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../hooks/Notification';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import {
  GET_DEVICE_PROFILE_BY_ID_QUERY,
  CREATE_DEVICE_PROFILE,
  GET_ORDER_TYPES_QUERY,
  UPDATE_DEVICE_PROFILE_DETAILS,
  UPDATE_DEVICE_PROFILE_OPTIONS,
  UPDATE_DEVICE_PROFILE_WORKFLOW,
  DELETE_DEVICE_PROFILE,
  UPDATE_DEVICE_PROFILES,
  GET_FUNCTION_MAPS_QUERY,
  UPDATE_DEVICE_PROFILE_CUSTOMER_DISPLAY,
  UPLOAD_DEVICE_PROFILE_CUSTOMER_DISPLAY_IMAGE,
} from '../../graphql/deviceProfiles';
import { parseApolloError, noopHandler } from '../../utils/errorHandlers';
import {
  DeviceProfile,
  CreateDeviceProfileInput,
  OrderType,
  UpdateDeviceProfileWorkflowInput,
  UpdateDeviceProfileOptionsInput,
  UpdateDeviceProfileDetailsInput,
  UpdateDeviceProfileInput,
  FunctionMap,
  UploadDeviceProfileCdsImageInput,
  UpdateDeviceProfileCustomerDisplayInput,
} from '@oolio-group/domain';
import { Operation } from '../../types/Operation';
import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
} from '@apollo/client';
import { GET_DEVICE_PROFILES_QUERY } from '../../graphql/settings';
import { DELETE_DEVICE } from '../../graphql/devices';
import keyBy from 'lodash/keyBy';
import { useSession } from './useSession';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import unionBy from 'lodash/unionBy';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { cloneDeep } from 'lodash';

export interface useDeviceProfilesProps {
  loading: boolean;
  error: string | undefined;
  operation: Operation;
  orderTypes: OrderType[];
  functionMaps: FunctionMap[];
  deviceProfiles: { [key: string]: DeviceProfile };
  createdDeviceProfileId: string;
  updatedDeviceProfileId: string;
  updatedDeviceProfileIds: string[];
  unAssignedDeviceProfileId: string;
  deletedDeviceProfile: boolean;
  getAllDeviceProfiles: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => void;
  getOrderTypes: () => void;
  getFunctionMaps: () => void;
  createDeviceProfile: (deviceProfileCreate: CreateDeviceProfileInput) => void;
  updateDeviceProfiles: (
    deviceProfilesInput: UpdateDeviceProfileInput[],
  ) => void;
  updateDeviceProfileDetails: (
    deviceProfileDetails: UpdateDeviceProfileDetailsInput,
  ) => void;
  updateDeviceProfileOptions: (
    deviceProfileDetails: UpdateDeviceProfileOptionsInput,
  ) => void;
  updateDeviceProfileWorkflow: (
    deviceProfileDetails: UpdateDeviceProfileWorkflowInput,
  ) => void;
  unAssignDeviceProfile: (deviceProfileId: string, deviceId: string) => void;
  deleteDeviceProfile: (id: string) => void;
  uploadDeviceProfileCustomerDisplayImage: (
    deviceProfileDetails: UploadDeviceProfileCdsImageInput,
  ) => void;
  updateDeviceProfileCustomerDisplay: (
    deviceProfileDetails: UpdateDeviceProfileCustomerDisplayInput,
  ) => void;
}

export interface Props {
  deviceProfileId?: string;
  storeId?: string;
  venueId?: string;
}

export const useDeviceProfiles = (props?: Props): useDeviceProfilesProps => {
  const { deviceProfileId, storeId, venueId } = props || {};
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const [deviceProfiles, setDeviceProfiles] = useState<
    Record<string, DeviceProfile>
  >({});
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [functionMaps, setFunctionMaps] = useState<FunctionMap[]>([]);
  const [operation, setOperation] = useState<Operation>(Operation.READ);
  const [createdDeviceProfileId, setCreatedDeviceProfileId] = useState('');
  const [updatedDeviceProfileId, setUpdatedDeviceProfileId] = useState('');
  const [updatedDeviceProfileIds, setUpdatedDeviceProfileIds] = useState<
    string[]
  >([]);
  const [unAssignedDeviceProfileId, setUnAssignDeviceProfileId] = useState('');
  const [deletedDeviceProfile, setDeletedDeviceProfile] = useState(false);
  const deviceProfilesRef = useRef<Record<string, DeviceProfile>>({});
  const [session, setSession] = useSession();

  // Update deviceProfile in session
  const updateDeviceProfileInSession = useCallback(
    (deviceProfiles: { [key: string]: DeviceProfile }) => {
      try {
        const updatedSession = { ...session };
        if (
          updatedSession.currentStore &&
          updatedSession.currentStore?.id === storeId
        ) {
          updatedSession.currentStore.deviceProfiles = unionBy(
            Object.values(deviceProfiles),
            session.currentStore?.deviceProfiles || [],
            'id',
          );
        }

        const attachedDeviceProfile = Object.values(deviceProfiles).find(
          deviceProfile => deviceProfile.id === session.deviceProfile?.id,
        );
        if (attachedDeviceProfile) {
          const updatedDeviceProfileData = pick(
            deviceProfiles[attachedDeviceProfile.id],
            [
              'id',
              'name',
              'defaultSaleScreen',
              'postSaleScreen',
              'status',
              'functionMap',
              'orderTypes',
              'enableCashManagement',
              'enableFloorView',
              'enableAllergens',
              'enableBarcodeScan',
              'enableCollapseSentItems',
              'hideCartTotal',
              'hideSendButton',
              'showCartKeypad',
              'enableOnlineOrders',
              'enableCourses',
              'enableQRPrint',
              'enableKitchenBuzzer',
              'enableSeatManagement',
              'enableHideZeroPrices',
              'enableTareWeight',
              'enableComplimentaryMode',
              'receiptPrintOption',
              'showPrinterProfileInDocket',
              'printZeroValueItems',
              'printZeroValueModifiers',
              'printNotes',
              'menu',
              'defaultOrderType',
              'sections',
              'showGuestCount',
              'printerOptions',
              'lockScreenTimeout',
              'enableAudio',
              'signatureAndTipping',
              'pricingGroupId',
              'orderIdentifier',
              'promptGuestCount',
              'takingOrders',
              'payAtTable',
            ],
          );

          updatedSession.deviceProfile = {
            ...session.deviceProfile,
            ...updatedDeviceProfileData,
            defaultOrderType:
              updatedDeviceProfileData.defaultOrderType ||
              (updatedDeviceProfileData.orderTypes?.[0] as OrderType),
          };
        }

        // update deviceProfile in session if updated
        setSession(updatedSession);
      } catch (error) {
        console.error(
          `Error while updating device profile in session: ${error}`,
        );
      }
    },
    [session, setSession, storeId],
  );

  // Remove deviceProfile from session if deleted
  const resetDeviceProfileInSession = useCallback(
    (id: string): void => {
      const updatedSession = { ...session };
      if (
        updatedSession.currentStore &&
        updatedSession.currentStore?.id === storeId
      ) {
        updatedSession.currentStore.deviceProfiles =
          session.currentStore?.deviceProfiles?.filter(
            deviceProfile => deviceProfile.id !== id,
          ) || [];
      }

      if (session.deviceProfile?.id === id)
        updatedSession.deviceProfile = undefined;
      setSession(updatedSession);
    },
    [session, setSession, storeId],
  );

  // Whenever deviceProfiles change call updateDeviceProfileInSession
  useEffect(() => {
    // return if deviceProfiles are not changed
    if (isEqual(deviceProfiles, deviceProfilesRef.current)) return;
    deviceProfilesRef.current = deviceProfiles;
    updateDeviceProfileInSession(deviceProfiles);
  }, [deviceProfiles, updateDeviceProfileInSession]);

  // order types
  const onCompleteGetOrderTypesRequest = useCallback(
    data => {
      if (data) {
        setOrderTypes(data.orderTypes as OrderType[]);
      }
    },
    [setOrderTypes],
  );

  const onCompleteFunctionMapRequest = useCallback(
    data => {
      if (data) {
        setFunctionMaps(data.functionMaps as FunctionMap[]);
      }
    },
    [setFunctionMaps],
  );

  const [getOrderTypes, getOrderTypesRequest] = useLazyQuery(
    GET_ORDER_TYPES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteGetOrderTypesRequest,
      onError: noopHandler,
    },
  );

  const [getFunctionMaps, getFunctionMapsRequest] = useLazyQuery(
    GET_FUNCTION_MAPS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: onCompleteFunctionMapRequest,
      onError: noopHandler,
    },
  );

  // get deviceProfile
  const onCompleteGetDeviceProfileRequest = useCallback(
    data => {
      if (data) {
        const deviceProfileData = data.deviceProfile as DeviceProfile;
        setDeviceProfiles(deviceProfiles => {
          const deviceProfilesTemp = { ...deviceProfiles };
          deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
          return deviceProfilesTemp;
        });
      }
    },
    [setDeviceProfiles],
  );

  const [getDeviceProfile, getDeviceProfileRequest] = useLazyQuery(
    GET_DEVICE_PROFILE_BY_ID_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId, venue: venueId },
      },
      onCompleted: onCompleteGetDeviceProfileRequest,
      onError: noopHandler,
    },
  );

  // get deviceProfiles
  const onCompleteGetDeviceProfilesRequest = useCallback(data => {
    if (data) {
      const deviceProfilesData = data.deviceProfiles as DeviceProfile[];
      const deviceProfilesDict: Record<string, DeviceProfile> = keyBy(
        deviceProfilesData,
        'id',
      );
      setDeviceProfiles(deviceProfilesDict);
    }
  }, []);

  const [getDeviceProfiles, getDeviceProfilesRequest] = useLazyQuery(
    GET_DEVICE_PROFILES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      context: {
        headers: { store: storeId, venue: venueId },
      },
      onCompleted: onCompleteGetDeviceProfilesRequest,
      onError: noopHandler,
    },
  );

  const onCompleteDeviceProfileCreation = useCallback(
    data => {
      if (data) {
        const deviceProfileData = data.createDeviceProfile as DeviceProfile;
        setDeviceProfiles(registers => {
          return { ...registers, [deviceProfileData.id]: deviceProfileData };
        });
        setCreatedDeviceProfileId(deviceProfileData.id);

        const updatedSession = cloneDeep({
          ...session,
        });
        if (
          updatedSession.user &&
          updatedSession.user.venues &&
          updatedSession.user.venues?.[0]?.stores?.[0]
        ) {
          updatedSession.user.venues[0].stores[0].deviceProfiles =
            updatedSession.user.venues?.[0].stores[0].deviceProfiles
              ? [
                  ...updatedSession.user.venues?.[0].stores[0].deviceProfiles,
                  deviceProfileData,
                ]
              : [deviceProfileData];
          setSession(updatedSession);
        }
        if (session.currentStore) {
          setSession({
            ...session,
            currentStore: {
              ...session.currentStore,
              deviceProfiles: session.currentStore.deviceProfiles
                ? [...session.currentStore.deviceProfiles, deviceProfileData]
                : [deviceProfileData],
            },
          });
        }
      }
    },
    [session, setSession],
  );
  // deviceProfile mutations
  const [createDeviceProfile, createDeviceProfileRequest] = useMutation(
    CREATE_DEVICE_PROFILE,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId, venue: venueId },
      },
      onCompleted: onCompleteDeviceProfileCreation,
    },
  );

  // delete deviceProfile
  const onCompleteDeleteDeviceProfileRequest = useCallback(
    (deviceProfileId, data) => {
      if (deviceProfileId && data?.deleteDeviceProfile) {
        resetDeviceProfileInSession(deviceProfileId);
      }
    },
    [resetDeviceProfileInSession],
  );

  const [deleteDeviceProfileRequest, deleteDeviceProfileResponse] = useMutation(
    DELETE_DEVICE_PROFILE,
    {
      onError: noopHandler,
      context: {
        headers: { store: storeId, venue: venueId },
      },
      onCompleted: onCompleteDeleteDeviceProfileRequest.bind(
        null,
        deviceProfileId,
      ),
    },
  );

  const [
    updateDeviceProfileDetailsRequest,
    updateDeviceProfileDetailsResponse,
  ] = useMutation(UPDATE_DEVICE_PROFILE_DETAILS, {
    onError: noopHandler,
    context: {
      headers: { store: storeId, venue: venueId },
    },
  });

  const [
    updateDeviceProfileOptionsRequest,
    updateDeviceProfileOptionsResponse,
  ] = useMutation(UPDATE_DEVICE_PROFILE_OPTIONS, {
    onError: noopHandler,
    context: {
      headers: { store: storeId, venue: venueId },
    },
  });

  const [
    updateDeviceProfileWorkflowRequest,
    updateDeviceProfileWorkflowResponse,
  ] = useMutation(UPDATE_DEVICE_PROFILE_WORKFLOW, {
    onError: noopHandler,
    context: {
      headers: { store: storeId, venue: venueId },
    },
  });

  useEffect(() => {
    if (deviceProfileId) {
      getDeviceProfile({ variables: { id: deviceProfileId } });
      setOperation(Operation.READ);
    }
  }, [getDeviceProfile, deviceProfileId]);

  // update
  useEffect(() => {
    if (updateDeviceProfileDetailsResponse.data) {
      const deviceProfileData = updateDeviceProfileDetailsResponse.data
        .updateDeviceProfileDetails as DeviceProfile;
      setDeviceProfiles(deviceProfiles => {
        const deviceProfilesTemp = { ...deviceProfiles };
        deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
        return deviceProfilesTemp;
      });
      setUpdatedDeviceProfileId(deviceProfileData.id);
    }
  }, [updateDeviceProfileDetailsResponse.data]);

  useEffect(() => {
    if (updateDeviceProfileOptionsResponse.data) {
      const deviceProfileData = updateDeviceProfileOptionsResponse.data
        .updateDeviceProfileOptions as DeviceProfile;
      setDeviceProfiles(deviceProfiles => {
        const deviceProfilesTemp = { ...deviceProfiles };
        deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
        return deviceProfilesTemp;
      });
      setUpdatedDeviceProfileId(deviceProfileData.id);
    }
  }, [updateDeviceProfileOptionsResponse.data]);

  useEffect(() => {
    if (updateDeviceProfileWorkflowResponse.data) {
      const deviceProfileData = updateDeviceProfileWorkflowResponse.data
        .updateDeviceProfileWorkflow as DeviceProfile;
      setDeviceProfiles(deviceProfiles => {
        const deviceProfilesTemp = { ...deviceProfiles };
        deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
        return deviceProfilesTemp;
      });
      setUpdatedDeviceProfileId(deviceProfileData.id);
    }
  }, [updateDeviceProfileWorkflowResponse.data]);

  const updateDeviceProfileDetails = useCallback(
    (deviceProfile: UpdateDeviceProfileInput) => {
      setUpdatedDeviceProfileId('');
      updateDeviceProfileDetailsRequest({
        variables: {
          input: deviceProfile,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateDeviceProfileDetailsRequest],
  );

  const updateDeviceProfileOptions = useCallback(
    (deviceProfile: UpdateDeviceProfileOptionsInput) => {
      setUpdatedDeviceProfileId('');
      updateDeviceProfileOptionsRequest({
        variables: {
          input: deviceProfile,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateDeviceProfileOptionsRequest],
  );

  const updateDeviceProfileWorkflow = useCallback(
    (deviceProfile: UpdateDeviceProfileInput) => {
      setUpdatedDeviceProfileId('');
      updateDeviceProfileWorkflowRequest({
        variables: {
          input: deviceProfile,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateDeviceProfileWorkflowRequest],
  );

  const deleteDeviceProfile = useCallback(
    (id: string) => {
      setUpdatedDeviceProfileId('');
      deleteDeviceProfileRequest({
        variables: {
          id,
        },
      });
      setOperation(Operation.DELETE);
    },
    [deleteDeviceProfileRequest],
  );

  // delete
  useEffect(() => {
    if (deleteDeviceProfileResponse.data) {
      setDeletedDeviceProfile(
        deleteDeviceProfileResponse.data.deleteDeviceProfile,
      );
    }
  }, [deleteDeviceProfileResponse.data]);

  const createDeviceProfileDetails = useCallback(
    (deviceProfileInput: CreateDeviceProfileInput) => {
      createDeviceProfile({
        variables: {
          input: deviceProfileInput,
        },
      });
      setOperation(Operation.CREATE);
    },
    [createDeviceProfile],
  );

  // update multi
  const [updateDeviceProfilesRequest, updateDeviceProfilesResponse] =
    useMutation(UPDATE_DEVICE_PROFILES, {
      onError: noopHandler,
      context: {
        headers: { store: storeId, venue: venueId },
      },
    });

  useEffect(() => {
    if (updateDeviceProfilesResponse.data) {
      const deviceProfilesData = updateDeviceProfilesResponse.data
        .updateDeviceProfiles as DeviceProfile[];

      setDeviceProfiles(deviceProfiles => {
        return {
          ...deviceProfiles,
          ...keyBy(deviceProfilesData || [], 'id'),
        };
      });

      setUpdatedDeviceProfileIds(deviceProfilesData.map(reason => reason.id));
    }
  }, [updateDeviceProfilesResponse.data]);

  const updateDeviceProfiles = useCallback(
    (deviceProfilesInput: UpdateDeviceProfileInput[]) => {
      setUpdatedDeviceProfileIds([]);
      updateDeviceProfilesRequest({
        variables: {
          input: deviceProfilesInput,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [updateDeviceProfilesRequest],
  );

  // unassign
  const [unAssignDeviceProfileRequest, unAssignDeviceProfileResponse] =
    useMutation(DELETE_DEVICE, {
      onError: noopHandler,
      context: {
        headers: { store: storeId, venue: venueId },
      },
    });

  const unAssignDeviceProfile = useCallback(
    (deviceProfileId: string, deviceId: string) => {
      unAssignDeviceProfileRequest({
        variables: {
          id: deviceProfileId,
          deviceProfile: deviceId,
        },
      });
      setOperation(Operation.UPDATE);
    },
    [unAssignDeviceProfileRequest],
  );

  useEffect(() => {
    if (unAssignDeviceProfileResponse.data) {
      getDeviceProfile({ variables: { id: deviceProfileId } });
      deviceProfileId && setUnAssignDeviceProfileId(deviceProfileId);
    }
  }, [getDeviceProfile, unAssignDeviceProfileResponse.data, deviceProfileId]);

  // upload image customer display
  const onCompleteUploadDeviceProfileCustomerDisplayImage = useCallback(
    data => {
      if (data) {
        showNotification({
          success: true,
          message: translate(
            'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
          ),
        });
        const deviceProfileData =
          data.uploadDeviceProfileCustomerDisplayImage as DeviceProfile;
        setDeviceProfiles(deviceProfiles => {
          const deviceProfilesTemp = { ...deviceProfiles };
          deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
          return deviceProfilesTemp;
        });
      }
    },
    [showNotification, translate],
  );

  const [
    uploadDeviceProfileCdsImageRequest,
    uploadDeviceProfileCdsImageResponse,
  ] = useMutation<{
    uploadDeviceProfileCustomerDisplayImage: DeviceProfile;
  }>(UPLOAD_DEVICE_PROFILE_CUSTOMER_DISPLAY_IMAGE, {
    onError: noopHandler,
    onCompleted: onCompleteUploadDeviceProfileCustomerDisplayImage,
    context: {
      headers: { store: storeId, venue: venueId },
    },
  });

  const uploadDeviceProfileCustomerDisplayImage = useCallback(
    (deviceProfile: UploadDeviceProfileCdsImageInput) => {
      uploadDeviceProfileCdsImageRequest({
        variables: {
          input: deviceProfile,
        },
      });
    },
    [uploadDeviceProfileCdsImageRequest],
  );

  // update customer display
  const onCompleteUpdateDeviceProfileCustomerDisplay = useCallback(
    data => {
      showNotification({
        success: true,
        message: translate(
          'backOfficeDeviceProfiles.deviceProfilesUpdatedSuccessfully',
        ),
      });
      const deviceProfileData =
        data.updateDeviceProfileCustomerDisplay as DeviceProfile;
      setDeviceProfiles(deviceProfiles => {
        const deviceProfilesTemp = { ...deviceProfiles };
        deviceProfilesTemp[deviceProfileData.id] = deviceProfileData;
        return deviceProfilesTemp;
      });
    },
    [showNotification, translate],
  );

  const [
    updateDeviceProfileCustomerDisplayRequest,
    updateDeviceProfileCustomerDisplayResponse,
  ] = useMutation<{
    updateDeviceProfileCustomerDisplay: DeviceProfile;
  }>(UPDATE_DEVICE_PROFILE_CUSTOMER_DISPLAY, {
    onError: noopHandler,
    onCompleted: onCompleteUpdateDeviceProfileCustomerDisplay,
    context: {
      headers: { store: storeId, venue: venueId },
    },
  });

  const updateDeviceProfileCustomerDisplay = useCallback(
    (deviceProfile: UploadDeviceProfileCdsImageInput) => {
      updateDeviceProfileCustomerDisplayRequest({
        variables: {
          input: deviceProfile,
        },
      });
    },
    [updateDeviceProfileCustomerDisplayRequest],
  );

  const RESPONSES = [
    getDeviceProfileRequest,
    getDeviceProfilesRequest,
    getOrderTypesRequest,
    getFunctionMapsRequest,
    updateDeviceProfilesResponse,
    updateDeviceProfileDetailsResponse,
    updateDeviceProfileOptionsResponse,
    updateDeviceProfileWorkflowResponse,
    unAssignDeviceProfileResponse,
    deleteDeviceProfileResponse,
    createDeviceProfileRequest,
    uploadDeviceProfileCdsImageResponse,
    updateDeviceProfileCustomerDisplayResponse,
  ];

  const error: ApolloError | undefined = getError(RESPONSES);
  const loading: boolean = isLoading(RESPONSES);

  return useMemo(
    () => ({
      loading,
      error: error ? parseApolloError(error) : undefined,
      operation,
      orderTypes,
      functionMaps,
      deviceProfiles,
      createdDeviceProfileId,
      updatedDeviceProfileId,
      updatedDeviceProfileIds,
      unAssignedDeviceProfileId,
      deletedDeviceProfile,
      getOrderTypes,
      getAllDeviceProfiles: getDeviceProfiles,
      createDeviceProfile: createDeviceProfileDetails,
      updateDeviceProfiles,
      updateDeviceProfileDetails,
      updateDeviceProfileOptions,
      updateDeviceProfileWorkflow,
      unAssignDeviceProfile,
      deleteDeviceProfile,
      getFunctionMaps,
      uploadDeviceProfileCustomerDisplayImage,
      updateDeviceProfileCustomerDisplay,
    }),
    [
      loading,
      error,
      operation,
      orderTypes,
      functionMaps,
      deviceProfiles,
      createdDeviceProfileId,
      updatedDeviceProfileId,
      updatedDeviceProfileIds,
      unAssignedDeviceProfileId,
      deletedDeviceProfile,
      getOrderTypes,
      getDeviceProfiles,
      createDeviceProfileDetails,
      updateDeviceProfiles,
      updateDeviceProfileDetails,
      updateDeviceProfileOptions,
      updateDeviceProfileWorkflow,
      unAssignDeviceProfile,
      deleteDeviceProfile,
      getFunctionMaps,
      uploadDeviceProfileCustomerDisplayImage,
      updateDeviceProfileCustomerDisplay,
    ],
  );
};
