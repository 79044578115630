import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    overflow: 'hidden',
  },
  initials: {
    fontSize: 36,
    lineHeight: 100,
    letterSpacing: -0.5,
    textAlign: 'center',
    color: theme.colors.white,
    fontFamily: theme.fonts.bold,
    zIndex: -1,
  },
  // eslint-disable-next-line react-native/no-color-literals
  btnChange: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.30)',
    borderBottomLeftRadius: theme.radius.m,
    borderBottomRightRadius: theme.radius.m,
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
  },
  btnChangeText: {
    fontSize: 12,
    paddingVertical: 8,
    textAlign: 'center',
    color: theme.colors.white,
    textTransform: 'uppercase',
    zIndex: 1,
  },
  btnChangeIcon: {
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.neutral,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: [{ translateX: -19 }, { translateY: -19 }],
  },
  btnRemove: {
    width: 28,
    height: 28,
    borderRadius: 28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.states.negative,
    position: 'absolute',
    zIndex: 10,
    top: 4,
    right: 4,
  },
  webInput: {
    transform: [{ scale: 0 }],
    zIndex: -2,
  },
});

export default styles;
