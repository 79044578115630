import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useCurrency } from '@oolio-group/localization';
import PaymentAmountStyles from './PaymentAmount.styles';
import ButtonIcon from '../../../Shared/TreatButton/ButtonIcon';

export interface PaymentAmountProps {
  value: number | string;
  disableCustomInput?: boolean;
  editable?: boolean;
  onPressEdit?: () => void;
}

const PaymentAmount: React.FC<PaymentAmountProps> = ({
  value,
  onPressEdit,
  editable,
  disableCustomInput,
}) => {
  const { formatCurrency } = useCurrency();

  const styles = PaymentAmountStyles();

  return (
    <TouchableOpacity style={styles.container} disabled={disableCustomInput}>
      <Text testID="payment-amount" style={styles.text}>
        {formatCurrency(+value || 0)}
      </Text>
      {editable && !disableCustomInput ? (
        <ButtonIcon
          testID="btn-editAmount"
          icon="pen"
          type="interface"
          onPress={onPressEdit}
          containerStyle={styles.btnEdit}
        />
      ) : null}
    </TouchableOpacity>
  );
};

export default PaymentAmount;
