import React from 'react';
import { View } from 'react-native';
import { IntegrationApps, Order, OrderItemStatus } from '@oolio-group/domain';
import CartNote from '../../../../../components/POS/Cart/CartNote/CartNote';
import CartProduct from '../../../../../components/POS/Cart/CartProduct/CartProduct';
import CartName from '../../../../../components/POS/Cart/CartName/CartName';

interface CartSectionProps {
  order: Order;
}

const CartSection: React.FC<CartSectionProps> = ({ order }) => {
  const showCancelledOrders =
    order?.integrationInfo?.app === IntegrationApps.OOM;
  return (
    <View>
      {order.orderName ? (
        <CartName testID="order-name" name={order.orderName} />
      ) : null}
      {order.orderNote ? (
        <CartNote themed testID="order-note" note={order.orderNote} />
      ) : null}
      {order.orderItems
        .filter(
          item =>
            showCancelledOrders || item.status !== OrderItemStatus.CANCELLED,
        )
        .map((item, i) => (
          <CartProduct
            themed
            key={i}
            orderItem={item}
            testID={`order-item-${i}`}
          />
        ))}
    </View>
  );
};

export default CartSection;
