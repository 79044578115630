/* eslint-disable react-native/no-inline-styles */
import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import theme from '../default-theme';

const WarningFillIcon = ({ color = theme.colors.states.negative }) => {
  return (
    <Svg width="14px" height="12px" viewBox="0 0 14 12">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(-6, -6)" fill={color} fillRule="nonzero">
          <Path d="M7.82347328,18 L18.1765267,18 C19.3120229,18 20,17.2547368 20,16.2884211 C20,15.9915789 19.9064885,15.6821053 19.7395038,15.4042105 L14.5562977,6.86526316 C14.2089695,6.29052632 13.6145038,6 13,6 C12.3854962,6 11.7843511,6.29052632 11.4437023,6.86526316 L6.26049618,15.4042105 C6.08015267,15.6884211 6,15.9915789 6,16.2884211 C6,17.2547368 6.6879771,18 7.82347328,18 Z M13.0066794,13.7810526 C12.6593511,13.7810526 12.4656489,13.5915789 12.4589695,13.2568421 L12.3721374,9.82105263 C12.365458,9.48631579 12.6259542,9.24631579 13,9.24631579 C13.360687,9.24631579 13.6412214,9.49263158 13.634542,9.82736842 L13.5343511,13.2568421 C13.5276718,13.5978947 13.3339695,13.7810526 13.0066794,13.7810526 Z M13.0066794,15.8968421 C12.605916,15.8968421 12.2585878,15.5936842 12.2585878,15.2210526 C12.2585878,14.8421053 12.5992366,14.5389474 13.0066794,14.5389474 C13.4074427,14.5389474 13.7480916,14.8357895 13.7480916,15.2210526 C13.7480916,15.6 13.4007634,15.8968421 13.0066794,15.8968421 Z"></Path>
        </G>
      </G>
    </Svg>
  );
};

export default WarningFillIcon;
