import { Platform } from 'react-native';
import { MMKVStorageWrapper, LocalForageWrapper } from 'apollo3-cache-persist';
import { storageInterface } from './mmkv';
import localforage from 'localforage';

export const getPreferredApolloStorage = async () => {
  switch (Platform.OS) {
    case 'web':
      localforage.config({
        driver: localforage.INDEXEDDB,
        name: 'pos-app',
        version: 1.0,
        size: 10485760, // Size of database, in bytes.
        storeName: 'apollocache', // Should be alphanumeric, with underscores.
      });
      return new LocalForageWrapper(localforage);
    default:
      return new MMKVStorageWrapper(storageInterface);
  }
};
