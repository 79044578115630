import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const OnlineOrdersStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      overflow: 'hidden',
    },
    filters: {
      width: 220,
      paddingVertical: 20,
      paddingHorizontal: 10,
      borderRightWidth: 1,
      backgroundColor: colors.elevation0,
      borderRightColor: colors.borderColor,
      ...theme.shadow.light,
    },
    tableContainer: {
      flex: 1,
      padding: 20,
      overflow: 'scroll',
    },
    message: {
      marginBottom: 20,
    },
  });
};

export default OnlineOrdersStyles;
