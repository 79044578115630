import { intervalToDuration } from 'date-fns';

export const getDurationInMinutes = (
  timestamp: number,
  currentTime: number,
): number => {
  const duration = intervalToDuration({
    start: new Date(timestamp),
    end: new Date(currentTime),
  });

  const hours = duration.hours || 0;
  const minutes = (duration.minutes || 0) + hours * 60;

  return minutes;
};

export const getDurationInHHMM = (
  timestamp: number,
  currentTime: number,
): string => {
  const duration = intervalToDuration({
    start: new Date(timestamp),
    end: new Date(currentTime),
  });

  const hours = duration.hours || 0;
  const minutes = duration.minutes || 0;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0',
  )}`;
};

export const getStartOfDayISO = (date: Date): string => {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay.toISOString();
};

export const getEndOfDayISO = (date: Date): string => {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay.toISOString();
};
