import gql from 'graphql-tag';

const adjustmentFragment = `
  adjustments {
    adjustmentType
    adjustmentUnit
    allowOnPaymentType
    amount
    id
    name
}`;

const integrationPartnersFragment = `
  id
  appName
  isActive
  preferences{
    onlineOrdering{
      menu
      pricingGroup
      pricingGroups
      menuLayout
      devices
      autoAcceptOrders
      autoCompleteOrders
      autoCompletePeriod
      isPrepTimeEnabled
      defaultPrepTime
      storeSlug
      merchantCode
      operatingHours {
        isActive
        day
        timeSlots {
          startTime
          endTime
        }
      }
      printDevice
      accentColour
      favImage
      orderTypes
      deliverySettings {
        deliveryFee
        deliveryTime
        enablePayNow
        enablePayLater
        ${adjustmentFragment}
      }
      pickUpSettings {
        enablePayNow
        enablePayLater
        ${adjustmentFragment}
      }
      dineInSettings {
        enablePayNow
        enablePayLater
        ${adjustmentFragment}
      }
      enableTip
      enableSmsCommunication
      merchantStoreRef
      connectedChannels {
        code
        name
        pauseOrdersConfig {
          pausedOrders
          pausedUntil
          durationInMinutes
        }
        delayConfig {
          delayUntil
          durationInMinutes
          apiSupport
        }
      }
    }
    oolioReservation {
      posLocationId
      partnerLocationId
      posLocationName
      partnerId
      isConnected
    }
    oolioWaitlists {
      posLocationId
    }
  },
  modules{
    onlineOrdering
  }
  store
  venue
  accountId
`;

export const FRAGMENTS = {
  merchantCodeState: gql`
    fragment MerchantCodeStateFragment on MerchantCodeState {
      active
      exist
    }
  `,
};

export const GET_INTEGRATION_PARTNERS = gql`
  query integrationPartners ($filter : OrganizationIntegrationsFilter){
    integrationPartners (filter : $filter){
      ${integrationPartnersFragment}
    }
  }
`;

export const CREATE_INTEGRATION_PARTNER = gql`
  mutation createIntegrationPartner($input: CreateIntegrationInput) {
    addIntegrationPartner(input: $input) {
      ${integrationPartnersFragment}
    }
  }
`;

export const UPDATE_INTEGRATION_PARTNERS = gql`
  mutation updateIntegrationPartners($input: [UpdateIntegrationInput]) {
    updateIntegrationPartners(input: $input) {
      ${integrationPartnersFragment}
    }
  }
`;

export const UPDATE_ONLINE_ORDER_STORE_SETTINGS = gql`
  mutation updateOnlineOrderStoreSettings($input: UpdateOnlineOrderStoreSettingsInput) {
    updateOnlineOrderStoreSettings(input: $input) {
      ${integrationPartnersFragment}
    }
  }
`;

export const UPLOAD_ONLINE_STORE_FAVIMAGE = gql`
  mutation uploadOnlineStoreFavImage(
    $input: UploadOnlineStoreFavImageInput
    $integrationId: String
  ) {
    uploadOnlineStoreFavImage(input: $input, integrationId: $integrationId) {
      ${integrationPartnersFragment}
    }
  }
`;

export const VERIFY_STORE_SLUG = gql`
  query verifyStoreSlug($appName: App!, $storeSlug: String!) {
    verifyStoreSlug(appName: $appName, storeSlug: $storeSlug)
  }
`;

export const VERIFY_MERCHANT_CODE = gql`
  query verifyMerchantCode(
    $appName: App!
    $merchantCode: String!
    $merchantStoreRef: String
  ) {
    verifyMerchantCode(
      appName: $appName
      merchantCode: $merchantCode
      merchantStoreRef: $merchantStoreRef
    ) {
      ...MerchantCodeStateFragment
    }
  }
  ${FRAGMENTS.merchantCodeState}
`;
