import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import ModalTitleStyles from './ModalTitle.styles';
import Icon from '../../../Icon/Icon';

interface Props {
  title: string;
  themed?: boolean;
  onDismiss?: () => void;
}

const ModalTitle: React.FC<Props> = ({ title, themed, onDismiss }) => {
  const { closeModal } = useModal();
  const styles = ModalTitleStyles({ themed });

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-close"
        onPress={onDismiss ? onDismiss : closeModal}
        style={styles.btnClose}
      >
        <Icon size={20} name="times" style={styles.icon} />
      </TouchableOpacity>
      <Text testID="mdl-title" style={styles.title}>
        {title}
      </Text>
    </View>
  );
};

export default ModalTitle;
