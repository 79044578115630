import { translate, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { StyleFn, Colors } from '@oolio-group/domain';
import React, { useCallback, useState, useMemo } from 'react';
import { useFela } from 'react-fela';
import { View } from 'react-native';
import { addToValue } from '../../../utils/keypadHelper';
import Button from '../../Button/Button';
import IconButton from '../../Button/IconButton';
import InputDisplay from '../../InputDisplay/InputDisplay';
import NumberInput from '../../NumberInput/NumberInput';
import Modal from '../Modal';

export interface SetPriceModalProps {
  title: string;
  mode?: 'set' | 'modify';
  onSubmit: (price: number, sign: boolean, percent: boolean) => void;
  submitLabel?: string;
}

const resetIconStyle: StyleFn = ({ theme }) => ({
  height: 50,
  width: 50,
  borderRadius: theme.radius.small,
  backgroundColor: theme.colors.primaryDark,
});

const keypadStyle: StyleFn = ({ theme }) => ({
  flexDirection: 'column',
  marginTop: theme.spacing.small,
  marginBottom: theme.spacing.medium * 1.25,
});

const numberInputStyle: StyleFn = ({ theme }) => ({
  marginTop: theme.spacing.medium,
});

const containerStyle: StyleFn = ({ mode }) => ({
  width: 350,
  minHeight: 665,
  alignSelf: 'center',
  height: mode == 'modify' ? 786 : 665,
});

export const SetPriceModal: React.FC<SetPriceModalProps> = ({
  title,
  mode,
  onSubmit,
  submitLabel,
}) => {
  const { css } = useFela({ mode });
  const { closeModal } = useModal();
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();

  const [input, setInput] = useState('');
  const [isPositive, setIsPositive] = useState(true);
  const [isPercentage, setIsPercentage] = useState(false);

  const formatInput = useMemo(() => {
    const roundInput = unFormatCurrency(input).toFixed(2);
    const temp = isPercentage ? roundInput + '%' : formatCurrency(+roundInput);
    const sign = isPositive ? '+' + temp : '-' + temp;
    return sign;
  }, [input, formatCurrency, isPositive, isPercentage, unFormatCurrency]);

  const defaultKey = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];
  const inputKeys = useMemo(() => {
    return [
      {
        name: 'exposure-alt',
        iconColor: isPositive ? Colors.amberDark : Colors.brandPrimary,
        onPress: () => setIsPositive(!isPositive),
      },
      {
        name: 'percentage',
        iconColor: isPercentage ? Colors.amberDark : Colors.brandPrimary,
        onPress: () => setIsPercentage(!isPercentage),
      },
      {
        name: 'dollar-alt',
        iconColor: !isPercentage ? Colors.amberDark : Colors.brandPrimary,
        onPress: () => setIsPercentage(!isPercentage),
      },
    ];
  }, [isPercentage, isPositive]);

  const onPressKey = useCallback(
    (key: string): void => {
      setInput(value =>
        addToValue({ value: value.toString(), key, decimalSeparator }),
      );
    },
    [decimalSeparator],
  );

  const resetInputDisplay = useCallback(() => {
    setInput('');
  }, []);

  const onPressSubmit = useCallback(() => {
    closeModal();
    onSubmit(+input, isPositive, isPercentage);
  }, [closeModal, onSubmit, input, isPositive, isPercentage]);

  return (
    <Modal
      contentStyle={css(containerStyle)}
      onDismiss={closeModal}
      title={title}
    >
      <InputDisplay
        value={formatInput}
        right={
          <IconButton
            testID="reset-button"
            onPress={resetInputDisplay}
            icon="times-circle"
            iconSize={32}
            iconColor={Colors.primaryDarkGrey}
            containerStyle={css(resetIconStyle)}
          />
        }
      />
      <View style={css(keypadStyle)}>
        <NumberInput
          rowStyle={css(numberInputStyle)}
          keyWidth={85}
          keyHeight={70}
          onPressKey={onPressKey}
          keys={mode == 'set' ? defaultKey : [...inputKeys, ...defaultKey]}
        />
      </View>
      <Button
        fluid
        success
        testID="submit-button"
        onPress={onPressSubmit}
        title={submitLabel || translate('button.applyChanges')}
      />
    </Modal>
  );
};
