import React, { forwardRef, useState } from 'react';
import { Text, View, ViewStyle, TouchableOpacity } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import format from 'date-fns/format';
import DatePickerComponent, { ReactDatePickerProps } from 'react-datepicker';
import theme, { DISPLAY_DATE_FORMAT } from '../../../common/default-theme';
import SelectStyles from './Select.styles';
import { Icon } from '../../Icon/Icon';
import 'react-datepicker/dist/react-datepicker.css';
import { useTheme } from '../../../hooks/useTheme';

interface SelectDateWebProps extends Omit<ReactDatePickerProps, 'onChange'> {
  onClick?(): void;
  onChangeDate?: (date: Date | undefined) => void;
  testID?: string;
  title?: string;
  valueDate?: Date;
  placeholder?: string;
  editable?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
  minDate?: Date;
  maxDate?: Date;
  optional?: boolean;
  customView?: React.ReactNode;
  themed?: boolean;
}

const SelectDate: React.FC<SelectDateWebProps> = ({
  title,
  testID,
  valueDate,
  editable = true,
  containerStyle,
  onChangeDate,
  minDate,
  maxDate,
  optional,
  customView,
  themed = false,
}) => {
  const { translate } = useTranslation();

  const { colors } = useTheme();
  const styles = SelectStyles({ themed });

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(valueDate);

  const onSelectDate = (date: Date | null) => {
    const setDate = date === null ? undefined : date;
    setSelectedDate(setDate);
    onChangeDate && onChangeDate(setDate);
  };

  const isClearable = editable && optional && valueDate;

  // eslint-disable-next-line react/display-name
  const Touchable = forwardRef<TouchableOpacity, SelectDateWebProps>(
    (props, ref) => {
      const date = valueDate || selectedDate;
      return (
        <TouchableOpacity
          ref={ref}
          disabled={!editable}
          style={styles.inputContainer}
          onPress={props.onClick}
        >
          <Text style={date ? styles.valueText : styles.placeholder}>
            {date
              ? format(date, DISPLAY_DATE_FORMAT)
              : translate('form.datePlaceholder')}
          </Text>
          <View style={styles.btnCalendar}>
            {isClearable ? (
              <TouchableOpacity onPress={() => onSelectDate(null)}>
                <Icon
                  size={20}
                  name="times-circle"
                  color={themed ? colors.text : theme.colors.dark}
                />
              </TouchableOpacity>
            ) : editable ? (
              <TouchableOpacity onPress={props.onClick}>
                <Icon
                  size={20}
                  name="schedule"
                  color={themed ? colors.text : theme.colors.dark}
                />
              </TouchableOpacity>
            ) : null}
          </View>
        </TouchableOpacity>
      );
    },
  );

  // eslint-disable-next-line react/display-name
  const CustomView = forwardRef<TouchableOpacity, SelectDateWebProps>(
    (props, ref) => {
      return (
        <TouchableOpacity
          ref={ref}
          disabled={!editable}
          onPress={props.onClick}
        >
          {customView}
        </TouchableOpacity>
      );
    },
  );

  return (
    <View style={containerStyle} testID={testID}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <DatePickerComponent
          portalId="root-portal"
          withPortal={true}
          selected={selectedDate}
          onChange={onSelectDate}
          customInput={!!!customView ? <Touchable /> : <CustomView />}
          minDate={minDate}
          maxDate={maxDate}
        />
      </View>
    </View>
  );
};

export default SelectDate;
