import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import {
  Customer,
  LoyaltySettings,
  OrderPayment,
  RewardRule,
} from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { getOnAccountPayment } from '@oolio-group/order-helper';
import { analyticsService } from '../../../analytics/AnalyticsService';
import theme from '../../../common/default-theme';
import AssignCustomerStyles from './AssignCustomer.styles';
import Icon from '../../Icon/Icon';
import SearchCustomer from '../../Modals/Customer/SearchCustomer';
import Loading from '../../Shared/Loaders/LoadingIndicator';
import { useCart } from '../../../hooks/orders/useCart';
import { useRewards } from '../../../hooks/orders/useRewards';

export interface AssignCustomerProps {
  assignedCustomer?: Customer;
  containerStyles?: ViewStyle | ViewStyle[];
  credit?: string | number;
  loading?: boolean;
  loyaltySettings: Partial<LoyaltySettings>;
  name?: string;
  orderId: string;
  payments: OrderPayment[] | undefined;
  rewardRules: RewardRule[];
  secondary?: boolean;
  themed?: boolean;
  onAssign: (customer: Customer) => void;
  onUnassign: () => void;
}

const AssignCustomer: React.FC<AssignCustomerProps> = ({
  assignedCustomer,
  containerStyles,
  credit,
  loading,
  loyaltySettings,
  name,
  payments = [],
  rewardRules,
  secondary = false,
  themed = false,
  onAssign,
  onUnassign,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { order } = useCart();
  const { redeemRewards } = useRewards(rewardRules);

  const customerId = assignedCustomer?.id;
  const styles = AssignCustomerStyles({ themed, secondary, customerId });

  const onPressCustomer = useCallback(() => {
    analyticsService.capture('assign_customer', {
      location: 'Payment Screen',
    });

    const isOnAccountPayment = getOnAccountPayment(payments);
    if (!isOnAccountPayment) {
      showModal(
        <SearchCustomer
          assignCustomerToOrder={onAssign}
          assignedCustomer={assignedCustomer}
          unassignCustomerToOrder={onUnassign}
          rewardRules={rewardRules}
          loyaltySettings={loyaltySettings}
          onRewardRedeem={redeemRewards}
          orderId={order?.id}
        />,
        { onBackdropPress: closeModal },
      );
    }
  }, [
    payments,
    showModal,
    onAssign,
    assignedCustomer,
    onUnassign,
    rewardRules,
    loyaltySettings,
    redeemRewards,
    order?.id,
    closeModal,
  ]);

  return (
    <TouchableOpacity
      testID="btn-assignCustomer"
      onPress={onPressCustomer}
      style={[styles.container, containerStyles]}
    >
      <View style={styles.icon}>
        <Icon size={20} name="user-circle" style={styles.iconColor} />
      </View>
      <Text testID="customer-name" style={styles.text}>
        {name || translate('form.assignCustomer')}
      </Text>
      {credit ? (
        <Text testID="customer-points">{formatCurrency(+credit || 0)}</Text>
      ) : null}
      <View style={styles.icon}>
        {loading ? (
          <Loading />
        ) : (
          <Icon
            size={20}
            name={customerId ? 'times' : 'plus'}
            color={
              customerId
                ? theme.colors.states.negative
                : theme.colors.states.positive
            }
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default AssignCustomer;
