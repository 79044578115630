import React, { useCallback, useMemo } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { format } from 'date-fns';
import { Waitlist, WaitlistStatusEnum } from '@oolio-group/domain';
import { formatPhoneNumber, useTranslation } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';
import BookingSidePanelStyles, {
  getStatusTextColor,
} from './BookingSidePanel.styles';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import PanelTitle from '../../../../components/POS/SidePanels/PanelTitle';
import LoadingIndicator from '../../../../components/Shared/Loaders/LoadingIndicator';
import { STATUS_OPTIONS } from './Filters';

interface Props {
  show: boolean;
  loading: boolean;
  booking?: Waitlist;
  onDismiss: () => void;
  onUpdate: (id: string, status: WaitlistStatusEnum) => void;
}

interface RowProps {
  label: string;
  value: string;
  color?: string;
}

const DATE_FORMAT = 'MMM dd, hh:mm a';

const Row: React.FC<RowProps> = ({ label, value, color }) => {
  const { colors } = useTheme();
  const styles = BookingSidePanelStyles();

  const formattedValue = value ? value : 'N/A';

  return (
    <View style={styles.row}>
      <Text style={styles.label}>{label}</Text>
      <Text style={[styles.value, { color: color ?? colors.text }]}>
        {formattedValue}
      </Text>
    </View>
  );
};

const BookingSidePanel: React.FC<Props> = ({
  show,
  loading,
  booking,
  onDismiss,
  onUpdate,
}) => {
  const { colors } = useTheme();
  const { translate } = useTranslation();
  const safeHeight = theme.useSafeHeight();

  const styles = BookingSidePanelStyles();

  const onUpdateStatus = useCallback(
    (status: WaitlistStatusEnum) => {
      if (booking) {
        onUpdate(booking.id, status);
      }
    },
    [booking, onUpdate],
  );

  const statusLabel = useMemo(
    () =>
      STATUS_OPTIONS.find(opt => opt.value === booking?.status)?.label ?? '',
    [booking?.status],
  );

  if (!show || !booking) return <></>;

  const { customer, status, priority, guestCount } = booking || {};
  const customerName = `${customer?.firstName} ${customer?.lastName}`;

  const info = [
    {
      label: translate('bookings.entryId'),
      value: booking.id.split('-')[0].toUpperCase(),
    },
    {
      label: translate('printing.status'),
      value: statusLabel,
      color: getStatusTextColor(status, colors),
    },
    {
      label: translate('bookings.prioritised'),
      value: priority ? 'Yes' : 'No',
      color: priority ? colors.green : colors.red,
    },
    {
      label: `${translate('onlineOrders.customerName')} (${translate(
        'form.guests',
      )})`,
      value: `${customerName} (${guestCount})`,
    },
    {
      label: translate('onlineOrders.customerPhone'),
      value: formatPhoneNumber(customer.phone, { countryCode: 'AU' }),
    },
    {
      label: translate('onlineOrders.customerEmail'),
      value: customer.email,
    },
    {
      label: translate('bookings.joinedAt'),
      value: format(booking.createdAt, DATE_FORMAT),
    },
    {
      label: translate('common.lastUpdatedAt'),
      value: format(booking.updatedAt, DATE_FORMAT),
    },
    {
      label: translate('openOrders.notes'),
      value: booking.notes ? booking.notes : translate('moneyMovements.noNote'),
      color: booking.notes ? colors.text : colors.textLight,
    },
  ];

  const canCancel = [
    WaitlistStatusEnum.WAITING,
    WaitlistStatusEnum.READY,
    WaitlistStatusEnum.NO_SHOW,
  ].includes(status);

  const canCheckIn = [
    WaitlistStatusEnum.READY,
    WaitlistStatusEnum.NO_SHOW,
  ].includes(status);

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <PanelTitle themed title={customerName} onClose={onDismiss} />
          <ScrollView style={styles.content}>
            <View style={styles.meta}>
              {info.map((item, index) => (
                <Row
                  key={index}
                  label={item.label}
                  value={item.value}
                  color={item.color}
                />
              ))}
            </View>
          </ScrollView>
          <View style={styles.actions}>
            {status === WaitlistStatusEnum.WAITING && (
              <TreatButton
                key="btn-ready"
                testID="btn-ready"
                height={50}
                type="neutral"
                label={translate('bookings.notifyReady')}
                onPress={() => onUpdateStatus(WaitlistStatusEnum.READY)}
              />
            )}
            {canCheckIn && (
              <TreatButton
                key="btn-checkedIn"
                testID="btn-checkedIn"
                height={50}
                type="positive"
                label={translate('bookings.markArrived')}
                onPress={() => onUpdateStatus(WaitlistStatusEnum.ARRIVED)}
              />
            )}
            {status === WaitlistStatusEnum.READY && (
              <View style={styles.actionsRow}>
                <TreatButton
                  key="btn-noShow"
                  testID="btn-noShow"
                  height={50}
                  type="focus"
                  label={translate('bookings.noShow')}
                  onPress={() => onUpdateStatus(WaitlistStatusEnum.NO_SHOW)}
                  containerStyle={styles.btn}
                />
                <TreatButton
                  testID="btn-cancel"
                  height={50}
                  type="negative"
                  label={translate('button.cancel')}
                  onPress={() => onUpdateStatus(WaitlistStatusEnum.CANCELLED)}
                  containerStyle={styles.btn}
                />
              </View>
            )}
            {canCancel && status !== WaitlistStatusEnum.READY && (
              <TreatButton
                testID="btn-cancel"
                height={50}
                type="negative"
                label={translate('bookings.cancelEntry')}
                onPress={() => onUpdateStatus(WaitlistStatusEnum.CANCELLED)}
              />
            )}
            {status === WaitlistStatusEnum.ARRIVED && (
              <TreatButton
                testID="btn-complete"
                height={50}
                type="positive"
                label={translate('button.complete')}
                onPress={() => onUpdateStatus(WaitlistStatusEnum.COMPLETED)}
              />
            )}
          </View>
        </>
      )}
    </View>
  );
};

export default BookingSidePanel;
