import React, { PropsWithChildren } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { OrderItem, RewardAdjustment } from '@oolio-group/domain';
import { computeOrderItemTotals } from '@oolio-group/order-helper';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import CartSentItemsStyles from './CartSentItems.styles';
import Icon from '../../../Icon/Icon';

interface Props extends PropsWithChildren {
  isExpanded: boolean;
  sentItems: (OrderItem | RewardAdjustment)[];
  show?: boolean;
  subTotal: number;
  themed: boolean;
  onExpand: (state: boolean) => void;
}

const CartSentItems: React.FC<Props> = ({
  children,
  isExpanded = false,
  sentItems,
  show,
  subTotal,
  themed,
  onExpand,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();

  const styles = CartSentItemsStyles({ themed, expanded: isExpanded });

  const totalFiredItems = sentItems.reduce((total, item) => {
    total += item.quantity;
    return total;
  }, 0);

  const sentItemLabel = translate('cart.sentItems', {
    value: totalFiredItems,
  });
  const totalValue = formatCurrency(
    sentItems.reduce((total, item) => {
      const totals = computeOrderItemTotals(item as OrderItem, subTotal, []);
      total += totals.subTotal || 0;
      return total;
    }, 0),
  );

  if (!show || !sentItems.length) {
    return null;
  }
  return (
    <View>
      <TouchableOpacity
        onPress={() => onExpand(!isExpanded)}
        style={styles.container}
      >
        <Icon
          size={18}
          style={styles.fgColor}
          name={isExpanded ? 'angle-down' : 'angle-right'}
        />
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <Text style={[styles.text, { flex: 1 }]}>{sentItemLabel}</Text>
        <Text style={styles.text}>{totalValue}</Text>
      </TouchableOpacity>
      <Collapsible collapsed={!isExpanded} duration={300}>
        {children}
      </Collapsible>
      {isExpanded && <View style={styles.divider} />}
    </View>
  );
};

export default CartSentItems;
