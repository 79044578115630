import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const ModalTitleStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  const fgColor = themed ? colors.text : colors.black1;

  return StyleSheet.create({
    container: {
      height: 44,
      alignItems: 'center',
      flexDirection: 'row',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : colors.white,
      ...theme.shadow.elevated1,
    },
    btnClose: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      flex: 1,
      marginRight: 44,
      textAlign: 'center',
      fontFamily: theme.fonts.medium,
      color: fgColor,
    },
    icon: {
      color: fgColor,
    },
  });
};

export default ModalTitleStyles;
