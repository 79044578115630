import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const SearchStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    inputContainer: {
      height: 44,
      paddingHorizontal: 12,
      borderRadius: theme.radius.s,
      color: themed ? colors.text : colors.black1,
      borderWidth: 1,
      borderColor: themed ? colors.borderColor : colors.grey3,
      backgroundColor: themed ? colors.elevation0 : colors.white,
    },
    inputTitleText: {
      marginBottom: 6,
      paddingLeft: 12,
      color: themed ? colors.disclosure : colors.grey6,
    },
    accessory: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default SearchStyles;
