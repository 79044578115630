import {
  FeatureIDs,
  Printer,
  Terminal,
  TerminalDiagnosisInfo,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Text, View } from 'react-native';
import theme from '../../../../common/default-theme';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import ConnectPaymentTerminalModal from '../../../../components/POS/Modals/ConnectTerminal/ConnectTerminalModal';
import TerminalDiagnosis from '../../../../components/POS/Modals/TerminalDiagnosis/TerminalDiagnosis';
import {
  SettingsAction,
  SettingsIcon,
  SettingsSelect,
} from '../../../../components/POS/SettingsOptions/SettingsOptions';
import { useNotification } from '../../../../hooks/Notification';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import { useCustomerDisplay } from '../../../../hooks/app/useCustomerDisplay';
import { useSession } from '../../../../hooks/app/useSession';
import { useTerminals } from '../../../../hooks/app/useTerminals';
import SettingsScreenStyles from '../SettingsScreen.styles';
import PrinterRow from './PrinterRow';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { usePrinting } from '../../../../hooks/PrintingProvider';

const DEFAULT_TERMINAL_DATA = {
  id: '',
  uuid: '',
  name: 'Not Assigned',
} as Terminal;

const HardwareSettings: React.FC = () => {
  const hostDiagnosisCalledRef = useRef<boolean>(false);

  const [session] = useSession();
  const { translate } = useTranslation();
  const { printTestTemplate } = usePrinting();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const { forgetCdsOnDevice } = useCustomerDisplay();
  const {
    error,
    terminal,
    diagnosisResponse,
    deleteTerminal,
    diagnoseTerminal,
  } = useTerminals();

  const styles = SettingsScreenStyles();

  const [selectedPrinter, setSelectedPrinter] = useState<string>('');
  const [loadingDiagnosis, setLoadingDiagnosis] = useState<boolean>(false);

  const isCDSEnabled = isFeatureEnabled(FeatureIDs.CDS);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (terminal) {
      showNotification({
        success: true,
        message: translate('paymentTerminal.deletedSuccessfully'),
      });
    }
  }, [terminal, showNotification, translate]);

  const onPressPrinter = useCallback((id: string) => {
    setSelectedPrinter(selectedPrinter => {
      if (id === selectedPrinter) {
        return '';
      } else return id;
    });
  }, []);

  const onPressTestPrinter = useCallback(
    (printer: Printer, template?: string) => {
      template
        ? printTestTemplate(printer, template)
        : printTestTemplate(printer);
      analyticsService.capture('printer_status', {
        action: 'test_print',
        location: 'Hardware settings',
        printerIp: printer.ipAddress,
      });
    },
    [printTestTemplate],
  );

  const printers: Printer[] = (session.device?.printingOptions || [])
    .reduce((acc: Printer[], cur) => {
      if (
        cur.printer &&
        !acc.find((printer: Printer) => printer.id === cur.printer?.id)
      ) {
        acc.push(cur.printer);
      }
      return acc;
    }, [])
    .sort((a: Printer, b: Printer) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const paymentTerminal = useMemo(() => {
    return session.device?.paymentTerminal || DEFAULT_TERMINAL_DATA;
  }, [session.device?.paymentTerminal]);

  const onConnectTerminal = useCallback(() => {
    analyticsService.capture('payment_terminal', {
      event: 'connect',
    });
    showModal(<ConnectPaymentTerminalModal />);
  }, [showModal]);

  const onForgetTerminal = useCallback(() => {
    analyticsService.capture('payment_terminal', {
      event: 'forget',
    });
    deleteTerminal(paymentTerminal.id);
  }, [deleteTerminal, paymentTerminal.id]);

  const onForgetCDS = useCallback(() => {
    analyticsService.capture('customer_display', {
      event: 'forget',
    });

    const forgetCds = async () => {
      closeModal();
      await forgetCdsOnDevice({
        deviceId: session.device?.id as string,
      });
      showNotification({
        message: translate('settings.forgetCustomerDisplaySuccess'),
        success: true,
      });
    };

    showModal(
      <ConfirmationModal
        title={translate('settings.forgetDisplay')}
        message={translate('settings.forgetDisplayDescription')}
        onConfirm={forgetCds}
      />,
    );
  }, [
    showModal,
    forgetCdsOnDevice,
    session.device?.id,
    showNotification,
    translate,
    closeModal,
  ]);

  const onDiagnoseTerminal = useCallback(() => {
    setLoadingDiagnosis(true);
    hostDiagnosisCalledRef.current = false;
    diagnoseTerminal({
      deviceId: session.device?.deviceCode as string,
      terminalId: paymentTerminal.uuid,
    });
  }, [diagnoseTerminal, paymentTerminal, session.device?.deviceCode]);

  useEffect(() => {
    if (diagnosisResponse) {
      if (diagnosisResponse.success) {
        showModal(
          <TerminalDiagnosis
            diagnosisData={
              diagnosisResponse.additionalInfo as TerminalDiagnosisInfo
            }
          />,
        );

        setLoadingDiagnosis(false);

        if (
          diagnosisResponse.additionalInfo &&
          diagnosisResponse.additionalInfo?.unconfirmedBatchCount > 0 &&
          !hostDiagnosisCalledRef.current
        ) {
          diagnoseTerminal({
            deviceId: session.device?.deviceCode as string,
            terminalId: paymentTerminal.uuid,
            hostDiagnosis: true,
          });

          hostDiagnosisCalledRef.current = true;
        }
      } else {
        showNotification({
          error: true,
          message: diagnosisResponse.message,
        });
      }
    }
  }, [
    diagnoseTerminal,
    diagnosisResponse,
    paymentTerminal.uuid,
    session.device?.deviceCode,
    showModal,
    showNotification,
  ]);

  const isValidCDSSession = !!session.device?.customerDisplay;
  const renderPrinters = () => {
    if (!printers.length) {
      return null;
    }

    return (
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('backOfficeSettings.printers')}
        </Text>
        {printers.map((printer, i) => (
          <PrinterRow
            key={i}
            printer={printer}
            isSelected={selectedPrinter === printer.id}
            onPress={onPressPrinter}
            onPressTestPrinter={onPressTestPrinter}
          />
        ))}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {renderPrinters()}
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('settings.paymentTerminal')}
        </Text>
        {paymentTerminal.uuid ? (
          <SettingsIcon
            icon="info-circle"
            loading={loadingDiagnosis}
            testID="paymentTerminalInfo"
            title={`${paymentTerminal.name} (${paymentTerminal.uuid})`}
            onPress={onDiagnoseTerminal}
            iconColor={theme.colors.states.neutral}
          />
        ) : null}
        {!paymentTerminal.id ? (
          <SettingsAction
            testID="btn-connectTerminal"
            type="positive"
            onPress={onConnectTerminal}
            label={translate('settings.connectPaymentTerminal')}
          />
        ) : (
          <SettingsAction
            testID="btn-disconnectTerminal"
            type="negative"
            onPress={onForgetTerminal}
            label={translate('settings.forgetPaymentTerminal')}
          />
        )}
      </View>
      {isCDSEnabled && isValidCDSSession ? (
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>
            {translate('settings.customerDisplay')}
          </Text>
          <SettingsSelect
            testID="btn-cds"
            title={translate('settings.customerDisplay')}
            isSelected={true}
            onPress={() => undefined}
          />
          <SettingsAction
            type="negative"
            testID="btn-disconnectCDS"
            onPress={onForgetCDS}
            label={translate('settings.forgetDisplay')}
          />
        </View>
      ) : null}
    </View>
  );
};

export default HardwareSettings;
