import React from 'react';
import { View } from 'react-native';
import { Notification } from '../../../hooks/Notification';
import NotificationStyles from './Notification.styles';
import NotificationComponent from './Notification';

interface Props {
  notifications: Array<Notification>;
  onDismiss: (id: symbol | undefined) => void;
}

export const NotificationsList: React.FC<Props> = ({
  notifications,
  onDismiss,
}) => {
  const styles = NotificationStyles({});

  return (
    <View style={styles.list}>
      {notifications.length > 0
        ? notifications.map((notification, index) => (
            <NotificationComponent
              key={index}
              {...notification}
              onClose={onDismiss.bind(null, notification.id)}
            />
          ))
        : null}
    </View>
  );
};
