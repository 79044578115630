import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import { useTheme } from '../../../../../hooks/useTheme';

const OnlineOrdersTableStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    headerContainer: {
      marginBottom: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    search: {
      flex: 1,
      marginLeft: 10,
    },
    btnRefresh: {
      marginHorizontal: 10,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      marginVertical: 8,
    },
    tableRow: {
      height: 50,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    cellAge: {
      width: 70,
      marginLeft: 12,
      color: colors.text,
      textAlign: 'center',
    },
    cellHundred: {
      width: 100,
      marginLeft: 10,
      color: colors.text,
      fontFamily: theme.fonts.regular,
    },
    cellType: {
      width: 80,
      marginLeft: 10,
      color: colors.text,
      fontFamily: theme.fonts.regular,
    },
    cellDue: {
      width: 110,
      marginLeft: 10,
      color: colors.text,
      fontFamily: theme.fonts.regular,
    },
    cellAmount: {
      flex: 1,
      marginLeft: 10,
      color: colors.text,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.regular,
    },
  });
};

export default OnlineOrdersTableStyles;
