import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartComboProductsStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      height: 26,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation1 : colors.grey1,
      marginBottom: 4,
      paddingHorizontal: 10,
    },
    text: {
      flex: 1,
      fontSize: 13,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
  });
};

export default CartComboProductsStyles;
