import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const ReservationSidePanelStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 340,
      padding: 6,
      rowGap: 4,
      backgroundColor: colors.background,
      ...theme.shadow.strong,
    },
    placeholder: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
    },
    content: {
      flex: 1,
      padding: 10,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    row: {
      padding: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    rowTitle: {
      fontSize: 13,
      lineHeight: 20,
      marginBottom: 6,
      color: colors.textLight,
      fontFamily: theme.fonts.regular,
    },
    rowValues: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontFamily: theme.fonts.regular,
    },
    rowValue: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    // Actions
    actionsRow: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
    },
    btnAction: {
      flex: 1,
    },
  });
};

export default ReservationSidePanelStyles;
