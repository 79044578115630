import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const SegmentControlStyles = ({ themed = false, secondary = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      padding: 4,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: secondary
        ? theme.colors.grey2
        : themed
        ? colors.elevation1
        : theme.colors.tertiary2,
    },
    tab: {
      flex: 1,
      height: 36,
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {
      fontSize: 14,
      lineHeight: 20,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
    },
    activeTab: {
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.white,
      ...theme.shadow.light,
    },
    badge: {
      width: 12,
      height: 12,
      borderRadius: 12,
      backgroundColor: theme.colors.states.focus,
      position: 'absolute',
      top: 6,
      right: 6,
    },
  });
};

export default SegmentControlStyles;
