import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const FloorViewStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    layoutContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.background,
    },
    layout: {
      width: 1004,
      height: 680,
      borderRadius: theme.radius.m,
      backgroundColor: colors.elevation0,
      ...theme.shadow.light,
    },
    sections: {
      position: 'absolute',
      top: 20,
      zIndex: 999,
      alignSelf: 'center',
      flexDirection: 'row',
      padding: 4,
      borderWidth: 1,
      borderRadius: theme.radius.s,
      borderColor: colors.borderColor,
      backgroundColor: colors.elevation0,
      ...theme.shadow.elevated1,
    },
    btnSection: {
      minWidth: 100,
      paddingVertical: 8,
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
    },
    footer: {
      position: 'absolute',
      bottom: 20,
      alignSelf: 'center',
      alignItems: 'center',
    },
    actions: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: 12,
      borderWidth: 1,
      borderRadius: theme.radius.m,
      borderColor: colors.borderColor,
      backgroundColor: colors.elevation0,
      ...theme.shadow.light,
    },
    actionGroup: {
      marginRight: 12,
      paddingRight: 12,
      flexDirection: 'row',
      alignItems: 'center',
      borderRightWidth: 1,
      borderRightColor: colors.borderColor,
    },
    btnAction: {
      paddingVertical: 12,
      paddingHorizontal: 16,
      borderRadius: theme.radius.s,
    },
    btnInfo: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionText: {
      fontSize: 14,
      lineHeight: 20,
      textAlign: 'center',
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    legend: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    status: {
      marginRight: 12,
      flexDirection: 'row',
      alignItems: 'center',
    },
    marker: {
      width: 12,
      height: 12,
      marginRight: 4,
      borderRadius: 12,
      backgroundColor: theme.colors.accent,
    },
    statusText: {
      fontSize: 14,
      lineHeight: 16,
      color: colors.textLight,
      fontFamily: theme.fonts.medium,
    },
  });
};

export default FloorViewStyles;
