import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Customer, FeatureIDs } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import {
  useCurrency,
  useTranslation,
  formatPhoneNumber,
} from '@oolio-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import { useLoyalty } from '../../../../hooks/app/loyalty/useLoyalty';
import { useCheckFeatureEnabled } from '../../../../hooks/app/features/useCheckFeatureEnabled';
import CustomersStyles from '../Customers.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import KeypadModal from '../../../../components/POS/Modals/Keypad/KeypadModal';
import AddCustomer from '../../../../components/Modals/Customer/AddCustomer';
import { postSalesObservableForCustomerBalance } from '../../../../hooks/app/usePostSalesNavigation';
import { useSession } from '../../../../hooks/app/useSession';
import LoadingIndicator from '../../../../components/Shared/Loaders/LoadingIndicator';
import { useOolioLoyalty } from '../../../../hooks/useOolioLoyalty';
import Empty from '../../../../components/Shared/Empty/Empty';
import { useTheme } from '../../../../hooks/useTheme';

interface Props {
  currentPage: number;
  data: Customer[];
  isCustomerAccountEnabled?: boolean;
  itemsPerPage?: number;
  loading?: boolean;
  onPageChange: (page: number) => void;
  onSuccessUpdateCustomer?: () => void;
}

const CustomerView: React.FC<Props> = ({
  currentPage,
  data,
  isCustomerAccountEnabled = false,
  itemsPerPage = 10,
  loading,
  onPageChange,
  onSuccessUpdateCustomer,
}) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { loyaltySettings: legacyLoyaltySettings } = useLoyalty({
    fetchPolicy: 'cache-first',
  });
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const isFeatureEnabled = useCheckFeatureEnabled();
  const [session] = useSession();
  const orgCountry = session.currentOrganization?.country as unknown as string;
  const isLegacyLoyaltyEnabled = isFeatureEnabled(FeatureIDs.LOYALTY);
  const { isLoyaltyEnabled: isNewLoyaltyEnabled, program } = useOolioLoyalty();
  const loyaltySettings = program || legacyLoyaltySettings;
  const isLoyaltyEnabled = isNewLoyaltyEnabled || isLegacyLoyaltyEnabled;

  const { colors } = useTheme();
  const styles = CustomersStyles();

  const navigateToPayment = useCallback(
    (amount: number, customer: Customer) => {
      navigation.navigate('Orders', {
        screen: 'Payment',
        params: {
          customerAmount: amount,
          customerId: customer.id,
        },
      });
    },
    [navigation],
  );

  const onUpdateProcessingAmount = useCallback(
    (customerAmount: number, customer: Customer) => {
      const onSubmitAmount = (amount: number) => {
        if (amount === 0) {
          showNotification({
            message: translate('payment.enterAmount'),
            error: true,
          });
        } else if (amount > customerAmount) {
          showNotification({
            message: translate('payment.checkSetAmountOnCustomer'),
            error: true,
          });
        } else {
          postSalesObservableForCustomerBalance.next(false);
          navigateToPayment(amount, customer);
          closeModal();
        }
      };
      return showModal(
        <KeypadModal
          mode="currency"
          title={translate('payment.setPaymentAmount')}
          initialValue={String(customerAmount)}
          onConfirm={onSubmitAmount}
          onDismiss={closeModal}
        />,
      );
    },
    [closeModal, navigateToPayment, showModal, showNotification, translate],
  );

  const onEditCustomer = useCallback(
    (customer: Customer) => {
      showModal(
        <AddCustomer
          customer={customer}
          isEditing
          onSuccess={onSuccessUpdateCustomer}
        />,
        {
          onBackdropPress: closeModal,
        },
      );
    },
    [showModal, onSuccessUpdateCustomer, closeModal],
  );

  const pageItems = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  }, [data, currentPage, itemsPerPage]);

  const renderPageItems = useMemo(() => {
    if (loading)
      return <LoadingIndicator containerStyles={styles.loadingContainer} />;
    if (data.length > 0)
      return (
        <View>
          <View style={styles.tableBody}>
            {pageItems.map((customer, i) => {
              const accountsEnabled =
                (isCustomerAccountEnabled &&
                  customer.customerAccountDetails?.accountPayment) ||
                false;

              const loyaltyEnabled = Boolean(customer.loyaltyPointsBalance);
              const customerCountry =
                customer?.preferredAddress?.isoCountryCode;
              return (
                <TouchableOpacity
                  key={i}
                  testID="row-customer"
                  style={styles.tableRow}
                  disabled={!accountsEnabled}
                  onPress={() =>
                    navigation.navigate('OnAccountOrders', {
                      name: `${customer.firstName} ${customer.lastName}`,
                      email: customer.email,
                      customerId: customer.id,
                      currentBalance:
                        customer.customerAccountDetails?.currentBalance ?? 0,
                    })
                  }
                >
                  <Text
                    numberOfLines={1}
                    style={styles.cellName}
                    testID="customer-name"
                  >{`${customer.firstName} ${customer.lastName}`}</Text>
                  <Text
                    testID="customer-phone"
                    style={styles.cellPhone}
                    numberOfLines={1}
                  >
                    {customer?.phone
                      ? formatPhoneNumber(customer.phone, {
                          countryCode: customerCountry,
                          intl: customerCountry != orgCountry,
                        })
                      : null}
                  </Text>
                  <Text
                    testID="customer-email"
                    style={styles.cellFlex}
                    numberOfLines={1}
                  >
                    {customer.email}
                  </Text>
                  <Text
                    testID="customer-accountBalance"
                    style={[
                      styles.cellAmount,
                      {
                        color:
                          isCustomerAccountEnabled &&
                          customer.customerAccountDetails?.currentBalance
                            ? colors.red
                            : colors.disclosure,
                        fontFamily: theme.fonts.medium,
                      },
                    ]}
                  >
                    {!accountsEnabled
                      ? 'N/A'
                      : formatCurrency(
                          customer.customerAccountDetails?.currentBalance || 0,
                        )}
                  </Text>
                  {isLoyaltyEnabled ? (
                    <Text
                      testID="customer-loyaltyPoints"
                      style={[
                        styles.cellAmount,
                        {
                          color: loyaltyEnabled
                            ? colors.text
                            : colors.disclosure,
                        },
                      ]}
                    >
                      {loyaltyEnabled ? customer.loyaltyPointsBalance : '–'}
                    </Text>
                  ) : null}
                  <ButtonIcon
                    testID="btn-payBalance"
                    size={34}
                    themed
                    icon="usd-circle"
                    type="positiveLight"
                    disabled={!accountsEnabled}
                    containerStyle={styles.cellButton}
                    onPress={() => {
                      customer.customerAccountDetails?.accountPayment &&
                        onUpdateProcessingAmount(
                          parseFloat(
                            (
                              customer.customerAccountDetails?.currentBalance ||
                              0
                            ).toFixed(2),
                          ),
                          customer,
                        );
                    }}
                  />
                  <ButtonIcon
                    testID="btn-editCustomer"
                    size={34}
                    themed
                    icon="pen"
                    type="neutralLight"
                    onPress={() => onEditCustomer(customer)}
                    containerStyle={styles.cellButton}
                  />
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={colors.disclosure}
                      // eslint-disable-next-line react-native/no-inline-styles
                      style={{ opacity: accountsEnabled ? 1 : 0 }}
                    />
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>
          {data.length > itemsPerPage ? (
            <Pagination
              pageLength={itemsPerPage}
              page={currentPage}
              onPageChange={onPageChange}
              dataLength={data.length}
            />
          ) : null}
        </View>
      );
    return <Empty themed label={translate('customerView.emptyCustomers')} />;
  }, [
    colors,
    currentPage,
    data.length,
    formatCurrency,
    isCustomerAccountEnabled,
    isLoyaltyEnabled,
    itemsPerPage,
    loading,
    navigation,
    onEditCustomer,
    onPageChange,
    onUpdateProcessingAmount,
    orgCountry,
    pageItems,
    styles,
    translate,
  ]);

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellName, theme.tables.headerText]}>
          {translate('form.name')}
        </Text>
        <Text style={[styles.cellPhone, theme.tables.headerText]}>
          {translate('form.phone')}
        </Text>
        <Text style={[styles.cellFlex, theme.tables.headerText]}>
          {translate('form.email')}
        </Text>
        <Text style={[styles.cellAmount, theme.tables.headerText]}>
          {translate('customerView.outstanding')}
        </Text>
        {isLoyaltyEnabled ? (
          <Text style={[styles.cellAmount, theme.tables.headerText]}>
            {loyaltySettings.pluralTerm || 'Points'}
          </Text>
        ) : null}
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={{ width: 120 }} />
      </View>
      {renderPageItems}
    </View>
  );
};

export default CustomerView;
