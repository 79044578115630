import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../../common/default-theme';
import { useTheme } from '../../../../../hooks/useTheme';

const OpenOrderRowStyles = () => {
  const { colors } = useTheme();

  const textStyle: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.regular,
    fontVariant: ['tabular-nums'],
    marginRight: 20,
  };

  return StyleSheet.create({
    container: {
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
      paddingLeft: 12,
    },
    tableRowTraining: {
      borderWidth: 1,
      borderRadius: theme.radius.s,
      backgroundColor: colors.blueLight,
      borderColor: theme.colors.grey4,
      borderStyle: 'dashed',
    },
    cellAge: {
      minWidth: 70,
      maxWidth: 70,
      marginHorizontal: 10,
    },
    cellPlacedAt: {
      width: 124,
      marginRight: 20,
      ...textStyle,
      color: colors.text,
    },
    cell100: {
      width: 100,
      marginRight: 20,
      ...textStyle,
      color: colors.text,
    },
    cellName: {
      flex: 1,
      marginRight: 20,
      ...textStyle,
      color: colors.text,
    },
    cellNote: {
      flex: 1,
      marginRight: 20,
      ...textStyle,
      color: colors.text,
    },
    cellLastAction: {
      width: 100,
      ...textStyle,
      color: colors.text,
      textAlign: 'right',
      marginRight: 20,
    },
    cellAmount: {
      width: 74,
      ...textStyle,
      color: colors.text,
      textAlign: 'right',
      fontVariant: ['tabular-nums'],
      marginRight: 20,
    },
    actions: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 10,
    },
  });
};

export default OpenOrderRowStyles;
