import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  View,
  Text,
  FlatList,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { keyBy, orderBy, uniqBy, isEmpty, sortBy } from 'lodash';
import {
  Page,
  Product,
  UpdateProductAvailabilityInput,
  DEFAULT_ENTITY_ID,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import {
  inventoryProductFragment,
  useProducts,
} from '../../../../hooks/app/products/useProducts';
import { useSession } from '../../../../hooks/app/useSession';
import { IMap } from '../../../../screens/BackOffice/Reports/types';
import ItemAvailabilityModalStyles from './ItemAvailabilityModal.style';
import ItemAvailabilityRow from './ItemAvailabilityRow';
import Search from '../../../Shared/Search/Search';
import Message from '../../../Office/Message/Message';
import TreatPicker from '../../../Shared/Select/Picker';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import ModalTitle from '../ModalTitle/ModalTitle';

export interface ProductInventory {
  id: string;
  name: string;
  isBeingTracked?: boolean;
  availableQuantity: number;
  isAvailable?: boolean;
}

interface Props {
  pages: Page[];
  productsInventory: IMap<ProductInventory>;
  onSave: (productsInventory: IMap<ProductInventory>) => void;
}

const ItemAvailability: React.FC<Props> = ({
  pages,
  productsInventory: productsInventoryProps,
  onSave,
}) => {
  const updatedProductsOnSave = useRef<
    UpdateProductAvailabilityInput[] | undefined
  >([]);

  const originalProductQuantitiesRef = useRef<Record<string, ProductInventory>>(
    {},
  );

  const [session] = useSession();
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const styles = ItemAvailabilityModalStyles();

  const [searchValue, setSearchValue] = useState('');
  const [selectedPageId, setSelectedPageId] = useState('');
  const [productsInventory, setProductsInventory] = useState<
    IMap<ProductInventory>
  >({});

  const userId = session?.user?.id;
  const deviceId = session?.device?.id || '';
  const currentStoreId = session?.currentStore?.id ?? '';

  const { updateProductsAvailability, syncProductsAvailabilityEvent, loading } =
    useProducts(undefined, inventoryProductFragment);

  const pageMaps = useMemo(() => keyBy(pages, 'id'), [pages]) as IMap<Page>;

  useEffect(() => {
    const updatedProducts = updatedProductsOnSave.current;
    if (!loading && !updatedProducts) {
      closeModal();
      updatedProductsOnSave.current = [];
    } else if (!loading && updatedProducts && updatedProducts.length) {
      closeModal();
      syncProductsAvailabilityEvent({
        products: updatedProducts,
        deviceId,
        storeId: currentStoreId || '',
        triggeredBy: userId,
      });
      updatedProductsOnSave.current = [];
    }
  }, [
    closeModal,
    currentStoreId,
    deviceId,
    loading,
    syncProductsAvailabilityEvent,
    userId,
  ]);

  const onPressSaveButton = async () => {
    const input: UpdateProductAvailabilityInput[] = Object.values(
      productsInventory,
    ).map(x => {
      const isAvailable =
        x?.availableQuantity === 0 && x?.isBeingTracked
          ? false
          : x?.isAvailable;
      return {
        id: x.id,
        storesInventory: {
          [currentStoreId]: {
            isAvailable,
            availableQuantity: +(x.availableQuantity || 0),
            isBeingTracked: x?.isBeingTracked,
          },
        },
      };
    });
    onSave(productsInventory);
    updateProductsAvailability(input);
    updatedProductsOnSave.current = input;
  };

  const onChangeProductInventory = useCallback(
    (id: string, key: keyof ProductInventory, value: string | boolean) => {
      setProductsInventory(pre => {
        return {
          ...pre,
          [id]: {
            ...pre[id],
            id,
            [key]: value,
            isAvailable:
              key === 'availableQuantity' && value === '0'
                ? false
                : pre?.[id]?.isAvailable,
          },
        };
      });
    },
    [],
  );

  const onChangeAvailability = useCallback(
    (id: string, value: string | boolean) => {
      setProductsInventory(pre => {
        return {
          ...pre,
          [id]: {
            ...pre[id],
            id,
            isAvailable:
              +pre?.[id]?.availableQuantity === 0 && pre?.[id]?.isBeingTracked
                ? false
                : Boolean(value),
          },
        };
      });
    },
    [],
  );

  const sortProductItems = useCallback((items: ProductInventory[]) => {
    const itemsByName = orderBy(items, ['name'], ['asc']);
    return sortBy(itemsByName, [
      function (item) {
        if (item?.isBeingTracked === true) {
          return item;
        }
      },
    ]);
  }, []);

  const getAllProductInPage = useCallback(
    (page: Page): ProductInventory[] => {
      const { products = [], variants = [] } = page;
      const allProducts = variants
        .map(variant => variant.products)
        .flat()
        .concat(products) as Product[];

      return allProducts.map(({ id, name, storesInventory }) => {
        const isAvailable =
          storesInventory?.[currentStoreId]?.availableQuantity === 0 &&
          storesInventory?.[currentStoreId]?.isBeingTracked
            ? false
            : storesInventory?.[currentStoreId]?.isAvailable;
        return {
          id,
          name,
          isBeingTracked: storesInventory?.[currentStoreId]?.isBeingTracked,
          availableQuantity: +(
            storesInventory?.[currentStoreId]?.availableQuantity || 0
          ),
          isAvailable,
        };
      });
    },
    [currentStoreId],
  );

  const inventoryProducts = useMemo<ProductInventory[]>(() => {
    if (!selectedPageId) return [];
    let formattedProducts: ProductInventory[];
    if (selectedPageId === DEFAULT_ENTITY_ID) {
      formattedProducts = Object.values(pageMaps)
        .map(getAllProductInPage)
        .flat();
    } else {
      formattedProducts = getAllProductInPage(pageMaps[selectedPageId]);
    }
    return sortProductItems(uniqBy(formattedProducts, 'id'));
  }, [getAllProductInPage, pageMaps, selectedPageId, sortProductItems]);

  useEffect(() => {
    const productInventoryMaps = keyBy(inventoryProducts, 'id');
    originalProductQuantitiesRef.current = {
      ...originalProductQuantitiesRef.current,
      ...productInventoryMaps,
    };
  }, [inventoryProducts]);

  const filteredProducts = useMemo(() => {
    return inventoryProducts.reduce<ProductInventory[]>((acc, product) => {
      if (product.name.toLowerCase().includes(searchValue.toLowerCase())) {
        acc.push({ ...product, ...productsInventory[product.id] });
      }
      return acc;
    }, []);
  }, [inventoryProducts, productsInventory, searchValue]);

  const pageOptions = useMemo(
    () =>
      [
        {
          label: translate('functionMaps.itemAvailability.allPages'),
          value: DEFAULT_ENTITY_ID,
        },
      ].concat(pages.map(p => ({ label: p.name, value: p.id }))),
    [pages, translate],
  );

  useEffect(() => {
    setProductsInventory({
      ...productsInventoryProps,
      ...keyBy(inventoryProducts, 'id'),
    });
  }, [inventoryProducts, productsInventoryProps]);

  useEffect(() => {
    if (
      pageOptions?.length > 0 &&
      !selectedPageId &&
      isEmpty(productsInventory)
    ) {
      const firstPage = pageOptions[0]?.value;
      setSelectedPageId(firstPage);
    }
  }, [
    inventoryProducts,
    pageOptions,
    productsInventory,
    productsInventoryProps,
    selectedPageId,
  ]);

  const hasDataChanged = Object.keys(productsInventory).length > 0;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      style={styles.container}
    >
      <ModalTitle
        title={translate('functionMaps.itemAvailability.title')}
        onDismiss={closeModal}
      />
      <View style={styles.content}>
        <Message
          type="neutral"
          message={translate(
            'functionMaps.itemAvailability.affectedChannelMessage',
          )}
        />
        <View style={styles.filters}>
          <TreatPicker
            testID="select-page"
            selectedValue={selectedPageId}
            options={pageOptions}
            onValueChange={setSelectedPageId}
            containerStyle={styles.dropdown}
          />
          <Search
            testID="search-items"
            onChangeText={setSearchValue}
            placeholder={translate('functionMaps.itemAvailability.search')}
            containerStyle={styles.search}
          />
        </View>
        <View style={styles.header}>
          <Text style={styles.colName}>{translate('modifiers.product')}</Text>
          <Text style={styles.colCheckbox}>
            {translate('functionMaps.itemAvailability.available')}
          </Text>
          <Text style={styles.colCheckbox}>
            {translate('functionMaps.itemAvailability.trackItem')}
          </Text>
          <Text style={styles.colQty}>
            {translate('functionMaps.itemAvailability.quantity')}
          </Text>
        </View>
        <View style={styles.rows}>
          <FlatList
            data={filteredProducts}
            keyExtractor={item => item.id}
            showsVerticalScrollIndicator={false}
            renderItem={({ item }) => (
              <ItemAvailabilityRow
                key={item.id}
                item={item}
                onChange={onChangeProductInventory}
                onChangeAvailability={onChangeAvailability}
              />
            )}
          />
        </View>
        <TreatButton
          testID="save-item-info-btn"
          type="neutral"
          isLoading={loading}
          disabled={!hasDataChanged}
          onPress={onPressSaveButton}
          label={translate('button.saveChanges')}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default ItemAvailability;
