import React, {
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { OTHER_COURSE } from '@oolio-group/domain';
import CartCourseStyles from './CartCourse.styles';
import Icon from '../../../Icon/Icon';

interface Props extends PropsWithChildren {
  id: string;
  name: string;
  disabled?: boolean;
  autoFire: boolean;
  isCollapsed?: boolean;
  readonlyCourse?: boolean;
  controlledCollapse?: boolean;
  themed?: boolean;
  onPress?: () => void;
  onToggleAutoFire?: (courseId: string) => void;
  onPressCourseHeader?: (id: string) => void;
  onCollapseCourse: (id: string, status: boolean) => void;
}

const CartCourse: React.FC<Props> = ({
  id,
  name,
  disabled,
  autoFire,
  isCollapsed: isCollapsedProp,
  readonlyCourse,
  children,
  controlledCollapse,
  themed = false,
  onToggleAutoFire,
  onPressCourseHeader,
  onCollapseCourse,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const isOtherCourse = id === OTHER_COURSE;

  const styles = CartCourseStyles({
    themed,
    isOtherCourse,
    disabled,
    autoFire,
  });

  const handleCollapseCourse = useCallback(() => {
    if (controlledCollapse) {
      onCollapseCourse && onCollapseCourse(id, !isCollapsed);
      return;
    }
    setIsCollapsed(!isCollapsed);
  }, [controlledCollapse, id, isCollapsed, onCollapseCourse]);

  const handleToggleAutoFire = useCallback(() => {
    onToggleAutoFire && onToggleAutoFire(id);
  }, [id, onToggleAutoFire]);

  const handlePressCourseHeader = useCallback(() => {
    onPressCourseHeader && onPressCourseHeader(id);
  }, [id, onPressCourseHeader]);

  useEffect(() => {
    if (controlledCollapse) {
      setIsCollapsed(!!isCollapsedProp);
    }
  }, [controlledCollapse, isCollapsedProp]);

  return (
    <View>
      <View style={styles.parent}>
        {!readonlyCourse && !isOtherCourse ? (
          <TouchableOpacity
            testID="btn-toggleFire"
            disabled={disabled}
            onPress={handleToggleAutoFire}
            style={styles.indicator}
          >
            <Icon
              size={16}
              style={styles.iconFire}
              name={autoFire ? 'bolt-alt' : 'bolt-slash'}
            />
          </TouchableOpacity>
        ) : null}
        <TouchableOpacity
          testID="btn-toggleCourseProducts"
          onPress={handlePressCourseHeader}
          style={styles.name}
        >
          <Text
            testID="name"
            style={[styles.nameText, isOtherCourse && styles.textAlign]}
          >
            {name}
          </Text>
          {!readonlyCourse ? (
            <TouchableOpacity
              testID="toggle-collapse"
              onPress={handleCollapseCourse}
            >
              <Icon
                size={20}
                style={styles.fgColor}
                name={isCollapsed ? 'angle-down' : 'angle-up'}
              />
            </TouchableOpacity>
          ) : null}
        </TouchableOpacity>
      </View>
      <Collapsible collapsed={isCollapsed && !readonlyCourse}>
        {children}
      </Collapsible>
    </View>
  );
};

export default CartCourse;
