import { gql } from '@apollo/client';

const courseFragment = `
id
name
autoFire
priority
products {
  id
  name
}
`;

export const productsFragment = `
id
name
`;

export const GET_COURSES_QUERY = gql`
  query getCourses {
    courses {
      ${courseFragment}
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation CreateCourse($input: CreateCourseInput) {
    createCourse(input: $input) {
      id
      name
      autoFire
      priority
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation deleteCourse($id: ID!) {
    deleteCourse(id: $id)
  }
`;

export const UPDATE_COURSE = gql`
  mutation updateCourses($input: [UpdateCourseInput]) {
    updateCourses(input: $input) {
      id
      name
      autoFire
      priority
    }
  }
`;

export const GET_COURSE_QUERY = gql`
  query Query($courseId: ID!) {
    course(id: $courseId) {
      ${courseFragment}
    }
  }
`;

export const UPDATE_PRODUCTS_IN_COURSE = gql`
  mutation updateProductsInCourse($input: UpdateProductsInCourseInput) {
    updateProductsInCourse(input: $input)
  }
`;

export const getCoursesQuery = (customFragment?: string) => {
  return gql`
  query getCourses {
    courses {
      ${customFragment || courseFragment}
    }
  }
`;
};

export const COURSE_AS_OPTIONS = `
id
name
autoFire
priority
`;
