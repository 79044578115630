import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    height: 22,
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: 2,
    marginVertical: 5,
    paddingHorizontal: 4,
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.states.focus,
  },
  label: {
    fontSize: 11,
    lineHeight: 14,
    color: theme.colors.white,
    fontFamily: theme.fonts.semibold,
  },
  line: {
    position: 'absolute',
    left: 0,
    right: 0,
    borderBottomWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.states.focus,
  },
});

export default styles;
