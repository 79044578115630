import React, { useMemo, useState, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import { Order, RejectionReason } from '@oolio-group/domain';
import { keyBy } from 'lodash';
import theme from '../../../../../common/default-theme';
import OnlineOrderSidePanelStyles from './OnlineOrderSidePanel.styles';
import SegmentControl from '../../../../../components/Shared/SegmentControl/SegmentControl';
import CartTotals from '../../../../../components/POS/Cart/CartTotals/CartTotals';
import OnlineOrderActions from './OnlineOrderActions';
import { getTableNameFromOrder } from '@oolio-group/order-helper';
import PanelTitle from '../../../../../components/POS/SidePanels/PanelTitle';
import LoadingIndicator from '../../../../../components/Shared/Loaders/LoadingIndicator';

interface Props {
  loading?: boolean;
  order?: Order;
  showPanel: boolean;
  disableBtn?: boolean;
  tabOptions: { title: string; value: string; component: React.ReactNode }[];
  onClose: () => void;
  onPressComplete: (id: string) => void;
  onPressAccept: (ids: string[]) => void;
  onOrderView?: (orderId: string) => void;
  onPressReprintDocket: (id: string) => void;
  onPressReject: (id: string, reason: RejectionReason) => void;
}

const OnlineOrderSidePanel: React.FC<Props> = ({
  loading,
  order,
  showPanel,
  tabOptions,
  disableBtn,
  onClose,
  onOrderView,
  onPressAccept,
  onPressReject,
  onPressComplete,
  onPressReprintDocket,
}) => {
  const safeHeight = theme.useSafeHeight();

  const styles = OnlineOrderSidePanelStyles();

  const [selectedTab, setSelectedTab] = useState(tabOptions?.[0]?.value || '');
  const tabOptionsMap = useMemo(() => keyBy(tabOptions, 'value'), [tabOptions]);

  const selectedTabContainer = useMemo(
    () => tabOptionsMap[selectedTab]?.component || <></>,
    [selectedTab, tabOptionsMap],
  );

  const onChangeTabs = useCallback(value => {
    setSelectedTab(value);
  }, []);

  const isDineIn = order?.table?.name;

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading ? (
        <LoadingIndicator containerStyles={styles.placeholder} />
      ) : order ? (
        <>
          <PanelTitle
            themed
            title={order?.orderNumber}
            onClose={onClose}
            sticker={{
              themed: true,
              testID: 'sticker-orderType',
              type: isDineIn ? 'neutralLight' : 'teal',
              label: getTableNameFromOrder(order) || order?.orderType?.code,
            }}
          />
          <ScrollView style={styles.content}>
            <SegmentControl
              themed
              tabs={tabOptions.map(x => ({ label: x.title, value: x.value }))}
              selectedTab={selectedTab}
              onPress={onChangeTabs}
              containerStyle={styles.segments}
            />
            {selectedTabContainer}
          </ScrollView>
          <CartTotals themed order={order} />
          <OnlineOrderActions
            onPressAccept={onPressAccept}
            onPressComplete={onPressComplete}
            onPressReject={onPressReject}
            onPressReprintDocket={onPressReprintDocket}
            order={order}
            disableBtn={disableBtn}
            onOrderView={onOrderView}
          />
        </>
      ) : null}
    </View>
  );
};

export default OnlineOrderSidePanel;
