import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { Device, DeviceMode, CreateDeviceInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useDevices } from '../../../hooks/app/useDevices';
import { useSession } from '../../../hooks/app/useSession';
import { useDeviceProfiles } from '../../../hooks/app/useDeviceProfiles';
import theme from '../../../common/default-theme';
import styles from './Transition.styles';
import Icon from '../../../components/Icon/Icon';
import Search from '../../../components/Shared/Search/Search';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import ConfirmationDialog from '../../../components/Modals/ConfirmationDialog';
import LoadingIndicator from '../../../components/Shared/Loaders/LoadingIndicator';
import CreateDeviceModal from './CreateDeviceModal';
import Empty from '../../../components/Shared/Empty/Empty';

interface Props {
  loading: boolean;
  onSelect?: (device: Device) => void;
}

const DeviceList: React.FC<Props> = ({
  loading,
  onSelect = () => undefined,
}) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();

  const {
    devices,
    createDevice,
    getDevices,
    loading: loadingDevices,
  } = useDevices({
    storeId: session.currentStore?.id,
  });

  const {
    deviceProfiles,
    getAllDeviceProfiles,
    loading: loadingDeviceProfiles,
  } = useDeviceProfiles({
    storeId: session.currentStore?.id,
    venueId: session.currentVenue?.id,
  });

  const [searchText, setSearchText] = useState('');
  const [devicesList, setDevicesList] = useState<Device[]>([]);

  const createNewDevice = useCallback(
    (form: CreateDeviceInput) => {
      const input = {
        ...form,
        store: session.currentStore?.id || '',
        venue: session.currentVenue?.id || '',
      };
      createDevice(input);
    },
    [createDevice, session.currentStore?.id, session.currentVenue?.id],
  );

  const filteredDevices = useMemo(() => {
    return Object.values(devices).filter(
      device =>
        device.mode === DeviceMode.F_POS &&
        device.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [devices, searchText]);

  const onSearch = useCallback((searchString: string) => {
    setSearchText(searchString);
  }, []);

  useEffect(() => {
    getDevices();
    getAllDeviceProfiles();
  }, [getAllDeviceProfiles, getDevices]);

  useEffect(() => {
    setDevicesList(filteredDevices);
  }, [filteredDevices]);

  const onPressSelect = useCallback(
    (device: Device) => {
      if (!device.isPaired) {
        onSelect(device);
      } else {
        showModal(
          <ConfirmationDialog
            title={translate('dialog.deviceInUse')}
            message={translate('dialog.overrideConfirmation', {
              label: device.name,
            })}
            confirmLabel={translate('dialog.continue')}
            onConfirm={() => {
              closeModal();
              onSelect(device);
            }}
          />,
        );
      }
    },
    [showModal, translate, closeModal, onSelect],
  );

  const onPressCreateDevice = useCallback(() => {
    showModal(
      <CreateDeviceModal
        deviceProfiles={Object.values(deviceProfiles)}
        onSubmit={createNewDevice}
      />,
    );
  }, [deviceProfiles, createNewDevice, showModal]);

  const isLoading = loading || loadingDevices || loadingDeviceProfiles;

  return (
    <View>
      <View style={styles.filters}>
        <Search
          testID="search"
          maxLength={50}
          onChangeText={onSearch}
          placeholder={translate('transition.search', {
            entity: translate('backOfficeSettings.devices').toLowerCase(),
          })}
          containerStyle={styles.search}
        />
        <ButtonIcon
          size={44}
          icon="plus"
          type="positive"
          testID="btn-createDevice"
          onPress={onPressCreateDevice}
        />
      </View>
      {isLoading ? (
        <LoadingIndicator containerStyles={theme.tables.emptyView} />
      ) : (
        <ScrollView style={styles.list}>
          {devicesList.length > 0 ? (
            devicesList.map(device => {
              const isPaired = device.isPaired;
              const hasAssignedPrinters =
                !device.printingOptions || device.printingOptions?.length === 0;

              return (
                <TouchableOpacity
                  key={device.id}
                  onPress={() => onPressSelect(device)}
                  style={styles.rowDevice}
                >
                  <View style={styles.cellName}>
                    <Text
                      style={styles.rowTitle}
                    >{`${device.name} (${device.deviceProfile.name})`}</Text>
                    {isPaired ? (
                      <Text style={styles.rowSubtitle}>{device.details}</Text>
                    ) : (
                      <Text style={styles.unpairedText}>
                        {translate('backOfficeDevices.unpaired')}
                      </Text>
                    )}
                  </View>
                  {hasAssignedPrinters && (
                    <Text style={styles.rowError}>
                      {translate('assignRegister.noPrinters')}
                    </Text>
                  )}
                  <Icon
                    size={20}
                    name="angle-right"
                    color={theme.colors.grey5}
                  />
                </TouchableOpacity>
              );
            })
          ) : (
            <Empty
              label={translate('common.emptyTableSearch', {
                entity: translate('backOfficeSettings.devices').toLowerCase(),
                searchText: searchText,
              })}
            />
          )}
        </ScrollView>
      )}
    </View>
  );
};

export default DeviceList;
