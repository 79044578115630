import { useReactiveVar } from '@apollo/client';
import {
  optimisticReservationsMap,
  currentReservations,
} from '../../../state/cache';
import { useMemo } from 'react';
import { Reservation } from '@oolio-group/domain';
import { mergeReservationsWithOptimisticUpdates } from '../../../screens/POS/Reservations/optimisticReservationsUtils';

export const useCurrentReservationsWithOptimisticUpdates =
  (): Reservation[] => {
    const curReservations = useReactiveVar(currentReservations);
    const optimisticUpdates = useReactiveVar(optimisticReservationsMap);
    return useMemo(
      () =>
        mergeReservationsWithOptimisticUpdates(
          curReservations,
          optimisticUpdates,
        ),
      [curReservations, optimisticUpdates],
    );
  };
