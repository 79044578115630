import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View, Text, ScrollView, KeyboardAvoidingView } from 'react-native';
import { Customer, BookingWidget, Waitlist } from '@oolio-group/domain';
import {
  getFullFormattedPhoneNumber,
  isValidPhoneNumber,
  listCountries,
  useTranslation,
} from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import theme from '../../../../common/default-theme';
import styles from './BookingForm.styles';
import CustomerTile from './CustomerTile';
import CustomerSearch from './CustomerSearch';
import InputText from '../../../../components/Shared/Inputs/InputText';
import InputPhone from '../../../../components/Shared/Inputs/InputPhone';
import InputEmail from '../../../../components/Shared/Inputs/InputEmail';
import TreatButton from '../../../../components/Shared/TreatButton/TreatButton';
import InputToggle from '../../../../components/Shared/Inputs/InputToggle';
import { DEFAULT_COUNTRY_CODE } from '../../../../constants';
import { Option } from '../../../../components/Shared/Select/Select';
import { isValidEmail } from '../../../../utils/validator';
import Select from '../../../../components/Shared/Select/Select';

interface Props {
  widgets: BookingWidget[];
  onDismiss: () => void;
  onConfirm: (input: BookingForm, bookingId: string) => void;
  defaultWidget?: string;
  booking?: Waitlist;
}

export interface BookingForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phone: string;
  countryCode: string;
  email: string;
  notes: string;
  priority: boolean;
  guestCount: number;
  widgetId: string;
}

const CreateOrEditBookingModal: React.FC<Props> = ({
  widgets = [],
  onDismiss,
  onConfirm,
  defaultWidget = '',
  booking,
}) => {
  const [session] = useSession();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { maxHeight } = theme.useModalMaxHeight();

  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const [errorMessage, setErrorMessage] = useState('');
  const [existingCustomer, setExistingCustomer] = useState<Customer>();

  const [form, setForm] = useState<BookingForm>({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: country,
    phoneNumber: getFullFormattedPhoneNumber(country),
    phone: '',
    priority: false,
    notes: '',
    guestCount: 1,
    widgetId: (defaultWidget || widgets[0]?.id) ?? '',
  });

  useEffect(() => {
    if (booking) {
      const phoneNumber = booking.customer.phone.substring(
        booking.customer.phone.indexOf(' ') + 1,
      );

      const country =
        listCountries().find(
          country =>
            country.phone ===
            booking.customer.phone.substring(
              0,
              booking.customer.phone.indexOf(' '),
            ),
        )?.code || DEFAULT_COUNTRY_CODE;

      setForm({
        firstName: booking.customer.firstName,
        lastName: booking.customer.lastName,
        email: booking.customer.email,
        countryCode: country,
        phoneNumber,
        phone: booking.customer.phone,
        priority: booking.priority,
        notes: booking.notes,
        guestCount: booking.guestCount,
        widgetId: booking.widgetId,
      });
    }
  }, [booking]);

  const onChangeFormInput = useCallback(
    (key: keyof BookingForm, value: string | boolean | number) => {
      setForm(form => ({
        ...form,
        [key]: value,
      }));
    },
    [],
  );

  const widgetOptions: Option[] = useMemo(
    () =>
      widgets.map(x => ({
        value: x.id,
        label: x.name,
      })),
    [widgets],
  );

  const selectedWidget = useMemo(() => {
    return widgets.find(w => w.id === form.widgetId);
  }, [widgets, form.widgetId]);

  const guestOptions = useMemo(() => {
    if (selectedWidget && selectedWidget.minGuest && selectedWidget.maxGuest) {
      return [
        ...Array(selectedWidget.maxGuest - selectedWidget.minGuest + 1),
      ].map((_, i) => ({
        label: `${i + selectedWidget.minGuest}`,
        value: `${i + selectedWidget.minGuest}`,
      }));
    }
    return [];
  }, [selectedWidget]);

  const handleOnConfirm = useCallback(() => {
    if (!booking?.id && !selectedWidget?.isActive) {
      setErrorMessage(translate('bookings.errWaitlistInactive'));
      return;
    }

    if (existingCustomer?.id) {
      return onConfirm(
        {
          ...form,
          email: existingCustomer?.email,
          phone: getFullFormattedPhoneNumber(
            DEFAULT_COUNTRY_CODE,
            existingCustomer.phone,
          ),
          firstName: existingCustomer?.firstName,
          lastName: existingCustomer?.lastName,
          ...(existingCustomer?.phoneNumber && {
            phoneNumber: existingCustomer.phoneNumber,
          }),
        },
        booking?.id || '',
      );
    }

    const isFirstNameValid = form.firstName.trim().length > 0;
    if (!isFirstNameValid) {
      setErrorMessage(translate('customer.emptyNameMessage'));
      return;
    }

    const phone = getFullFormattedPhoneNumber(
      form.countryCode,
      form.phoneNumber,
    );

    const isPhoneValid = isValidPhoneNumber(phone);
    if (!isPhoneValid) {
      setErrorMessage(translate('customer.invalidPhoneMessage'));
      return;
    }

    const isEmailValid = !!form.email ? isValidEmail(form.email) : true;
    if (!isEmailValid) {
      setErrorMessage(translate('customer.invalidEmailMessage'));
      return;
    }
    onConfirm({ ...form, phone }, booking?.id || '');
  }, [
    form,
    onConfirm,
    translate,
    existingCustomer,
    booking?.id,
    selectedWidget?.isActive,
  ]);

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  return (
    <KeyboardAvoidingView
      behavior="padding"
      style={styles.keyboardAvoidingView}
    >
      <View style={[styles.container, { maxHeight: maxHeight }]}>
        <Text style={styles.title}>
          {booking?.id
            ? translate('bookings.editEntry')
            : translate('bookings.addEntry')}
        </Text>
        <ScrollView contentContainerStyle={styles.body}>
          <CustomerSearch onSelect={value => setExistingCustomer(value)} />
          <View style={styles.body}>
            {existingCustomer ? (
              <CustomerTile
                customer={existingCustomer}
                onRemove={() => setExistingCustomer(undefined)}
              />
            ) : (
              <>
                <View style={styles.row}>
                  <InputText
                    testID="input-firstName"
                    title={translate('form.firstName')}
                    value={form.firstName}
                    placeholder={translate('common.enterPlaceholder')}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'firstName')}
                  />
                  <InputText
                    testID="input-lastName"
                    title={translate('form.lastName')}
                    value={form.lastName}
                    placeholder={translate('form.optional')}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'lastName')}
                  />
                </View>
                <View style={styles.row}>
                  <InputPhone
                    testID="input-phone"
                    title={translate('form.phone')}
                    placeholder={translate('common.enterPlaceholder')}
                    containerStyle={theme.forms.inputHalf}
                    value={form.phoneNumber}
                    onChangeText={onChangeFormInput.bind(null, 'phoneNumber')}
                    onPressCountry={onChangeFormInput.bind(null, 'countryCode')}
                    defaultCountry={form.countryCode}
                  />
                  <InputEmail
                    testID="input-email"
                    title={translate('form.email')}
                    placeholder={translate('form.optional')}
                    value={form.email}
                    containerStyle={theme.forms.inputHalf}
                    onChangeText={onChangeFormInput.bind(null, 'email')}
                  />
                </View>
              </>
            )}
            <View style={styles.row}>
              <Select
                testID="input-widget"
                options={widgetOptions}
                selectedValue={form.widgetId}
                onValueChange={(value: string) =>
                  onChangeFormInput('widgetId', value)
                }
                title={translate('bookings.waitlist')}
                containerStyle={{ ...theme.forms.inputHalf }}
                editable={!booking}
              />
              {selectedWidget?.enableGuestCount ? (
                <Select
                  title={translate('form.guests')}
                  options={guestOptions}
                  selectedValue={form.guestCount.toString()}
                  onValueChange={(value: string) =>
                    onChangeFormInput('guestCount', parseInt(value))
                  }
                  testID="input-guestCount"
                  containerStyle={{ ...theme.forms.inputHalf }}
                />
              ) : (
                <InputText
                  testID="input-notes"
                  title={translate('openOrders.notes')}
                  placeholder={translate('form.optional')}
                  containerStyle={theme.forms.inputHalf}
                  value={form.notes}
                  onChangeText={onChangeFormInput.bind(null, 'notes')}
                />
              )}
            </View>

            <View style={styles.row}>
              {selectedWidget?.enableGuestCount && (
                <InputText
                  testID="input-notes"
                  title={translate('openOrders.notes')}
                  placeholder={translate('form.optional')}
                  containerStyle={theme.forms.inputHalf}
                  value={form.notes}
                  onChangeText={onChangeFormInput.bind(null, 'notes')}
                />
              )}

              <InputToggle
                testID="toggle-priority"
                isToggled={form.priority}
                title={translate('bookings.priority')}
                onToggle={onChangeFormInput.bind(
                  null,
                  'priority',
                  !form.priority,
                )}
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={{
                  flex: 1,
                  ...(selectedWidget?.enableGuestCount && {
                    paddingTop: 20,
                  }),
                }}
              />
            </View>
          </View>
        </ScrollView>
        <View style={styles.actions}>
          <TreatButton
            testID="btn-cancel"
            type="cancel"
            label={translate('button.dismiss')}
            onPress={onDismiss}
          />
          <TreatButton
            testID="btn-confirm"
            type="positive"
            label={
              booking
                ? translate('bookings.editEntry')
                : translate('bookings.addEntry')
            }
            onPress={handleOnConfirm}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default CreateOrEditBookingModal;
