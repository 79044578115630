import React, { useEffect, useCallback, useState, useRef } from 'react';
import { View, Text } from 'react-native';
import {
  Course,
  DEFAULT_ENTITY_ID,
  UpdateCourseInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useCourses } from '../../../../hooks/app/courses/useCourses';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { differenceWith, isEmpty, isEqual, omit, sortBy } from 'lodash';
import { useNotification } from '../../../../hooks/Notification';
import theme from '../../../../common/default-theme';
import styles from './Courses.styles';
import CoursesRow from './CoursesRow/CoursesRow';
import DraggableFlatList from '../../../../components/DraggableFlatList/DraggableFlatList';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import Search from '../../../../components/Shared/Search/Search';

const validateDuplicationName = (
  courses: Course[],
): Record<number, boolean> => {
  const errors: Record<number, boolean> = {};

  const duplicateNameMap = courses.reduce((nameObj, current) => {
    const key = current.name?.toLowerCase().trim();
    if (key) {
      nameObj[key] = (nameObj[key] || 0) + 1;
    }
    return nameObj;
  }, {} as Record<string, number>);
  courses.forEach(({ name }, index) => {
    if (duplicateNameMap[name?.toLowerCase().trim() || ''] > 1) {
      errors[index] = true;
    }
  });
  return errors;
};

const Courses: React.FC = () => {
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { getCourses, courseMaps, loading, updateCourses, error } = useCourses({
    onUpdateCoursesComplete: handleOnCompleteUpdateCourse,
  });

  const originalCourseRef = useRef<Course[]>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);

  useEffect(() => {
    if (!isFocused) return;
    getCourses();
  }, [getCourses, isFocused]);

  function handleOnCompleteUpdateCourse() {
    showNotification({
      message: translate('backOfficeProducts.coursesUpdatedSuccessfully'),
      success: true,
    });
  }

  const handleCreateCourse = useCallback((): void => {
    setSearchQuery('');
    navigation.navigate('CourseSettings', {
      courseId: DEFAULT_ENTITY_ID,
      title: translate('backOfficeProducts.newCourse'),
    });
  }, [navigation, translate]);

  const onEditCourse = useCallback(
    (item: Course) => {
      setSearchQuery('');
      navigation.navigate('CourseSettings', {
        courseId: item.id,
        title: item.name,
      });
    },
    [navigation],
  );

  const onPressSave = useCallback((): void => {
    const errors = validateDuplicationName(courses);
    if (isEmpty(errors)) {
      const coursesToUpdate = differenceWith(
        courses,
        originalCourseRef.current,
        isEqual,
      ).map(course =>
        omit(course, ['products', '__typename']),
      ) as UpdateCourseInput[];
      updateCourses(coursesToUpdate);
    } else {
      showNotification({
        message: translate(
          'backOfficeProducts.duplicateNameCourseWarningMessage',
        ),
        error: true,
      });
    }
  }, [courses, showNotification, translate, updateCourses]);

  const onDragEnd = useCallback(
    (data: Array<Course>) => {
      const updatedCourses = data.map((course, index) => ({
        ...course,
        priority: index,
      }));
      setCourses(updatedCourses);
    },
    [setCourses],
  );

  useEffect(() => {
    const sortedCourses = sortBy(
      Object.values(courseMaps) || [],
      course => course.priority,
    );
    setCourses(sortedCourses);
    originalCourseRef.current = sortedCourses;
  }, [courseMaps]);

  useEffect(() => {
    if (!error) return;
    showNotification({ error: true, message: error });
  }, [error, showNotification]);

  useEffect(() => {
    const filterCourses = () => {
      if (!searchQuery) {
        setFilteredCourses(courses);
        return;
      }
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = courses.filter(course =>
        course.name.toLowerCase().includes(lowercasedQuery),
      );
      setFilteredCourses(filtered);
    };
    filterCourses();
  }, [searchQuery, courses]);

  const hasDataChanged = !isEqual(courses, originalCourseRef.current);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter={!hasDataChanged}
      onSave={onPressSave}
    >
      <Section
        title={translate('backOfficeProducts.courses')}
        subtitle={translate('backOfficeProducts.courseDescription')}
      >
        <View style={styles.filters}>
          <Search
            testID="search-courses"
            value={searchQuery}
            onChangeText={setSearchQuery}
            placeholder={translate('backOfficeProducts.searchCourses')}
            containerStyle={styles.search}
          />
          <CreateButton onPress={handleCreateCourse} />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerProducts]}>
              {translate('backOfficeProducts.products')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerAutoFire]}>
              {translate('backOfficeProducts.autoFire')}
            </Text>
          </View>
          <DraggableFlatList
            columns={[]}
            data={filteredCourses}
            normalRows
            onDragEnd={onDragEnd}
            renderRow={(
              item: Course,
              index: number,
              drag: () => void,
            ): React.ReactNode => (
              <CoursesRow
                course={item}
                key={index}
                onDrag={drag}
                onEditCourse={onEditCourse.bind(null, item)}
              />
            )}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};

export default Courses;
