import React, { useCallback, useMemo } from 'react';
import { OrderTypeCode } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNavigation } from '@react-navigation/native';
import { noopHandler } from '../../../utils/errorHandlers';
import { useCart } from '../../../hooks/orders/useCart';
import { useSession } from '../../../hooks/app/useSession';
import ModalPicker from '../Modals/ModalPicker/ModalPicker';
import { analyticsService } from '../../../analytics/AnalyticsService';
import ButtonIcon from '../../Shared/TreatButton/ButtonIcon';

export interface NewOrderButtonProps {
  hideDineIn?: boolean;
  isCartUnsaved?: boolean;
  onSave?: (callback?: Function) => void;
}

const NewOrderButton: React.FC<NewOrderButtonProps> = ({
  hideDineIn,
  isCartUnsaved,
  onSave,
}: NewOrderButtonProps) => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const [{ deviceProfile }] = useSession();
  const { showModal, closeModal } = useModal();
  const { resetCart, setCartParams, closeOrderCart } = useCart();

  const nonDineInOrderTypes = useMemo(
    () =>
      deviceProfile?.orderTypes?.filter(
        orderType => orderType.code !== OrderTypeCode.DINE_IN,
      ) ?? [],
    [deviceProfile],
  );

  const dineInOrderTypes = useMemo(
    () =>
      deviceProfile?.orderTypes?.filter(
        orderType => orderType.code === OrderTypeCode.DINE_IN,
      ) ?? [],
    [deviceProfile],
  );

  const onCreateNewOrder = useCallback(
    async (orderTypeId: string) => {
      closeOrderCart();
      setCartParams(undefined, orderTypeId, '', false);
      const newOrderId = await resetCart();
      closeModal();
      navigation.navigate('Orders', {
        screen: 'TakeOrder',
        params: {
          id: newOrderId,
          isExisting: false,
          orderType: orderTypeId,
        },
      });
    },
    [closeOrderCart, setCartParams, resetCart, closeModal, navigation],
  );

  const setOrderType = useCallback(() => {
    let orderTypes = deviceProfile?.orderTypes || [];
    if (hideDineIn) orderTypes = nonDineInOrderTypes;
    if (orderTypes.length === 1) {
      onCreateNewOrder(orderTypes[0].id);
    } else if (orderTypes.length === 0 && dineInOrderTypes.length > 0) {
      onCreateNewOrder(dineInOrderTypes[0].id);
    } else {
      showModal(
        <ModalPicker
          title={translate('button.takeNewOrder')}
          onSelect={onCreateNewOrder}
          onClose={noopHandler}
          options={orderTypes.map((x: { name: string; id: string }) => ({
            label: x.name,
            value: x.id,
          }))}
        />,
      );
    }
  }, [
    deviceProfile?.orderTypes,
    hideDineIn,
    nonDineInOrderTypes,
    dineInOrderTypes,
    onCreateNewOrder,
    showModal,
    translate,
  ]);

  const onInitiateNewOrder = useCallback(async () => {
    analyticsService.capture('btn_new_order', {
      location: 'Action Bar',
    });

    if (isCartUnsaved && onSave) {
      onSave(setOrderType);
    } else {
      setOrderType();
    }
  }, [isCartUnsaved, setOrderType, onSave]);

  return (
    <ButtonIcon
      testID="btn-newOrder"
      size={44}
      type="neutral"
      icon="plus-circle"
      onPress={onInitiateNewOrder}
    />
  );
};

export default NewOrderButton;
