import React from 'react';
import { View, Text, ViewStyle } from 'react-native';
import StickerStyles from './Sticker.styles';

export interface StickerProps {
  testID: string;
  type?:
    | 'primary'
    | 'primaryLight'
    | 'accent'
    | 'positive'
    | 'negative'
    | 'neutral'
    | 'positiveLight'
    | 'negativeLight'
    | 'neutralLight'
    | 'focus'
    | 'focusLight'
    | 'cancel'
    | 'teal';
  label: string;
  rounded?: boolean;
  showIndicator?: boolean;
  themed?: boolean;
  containerStyle?: ViewStyle | ViewStyle[];
}

const Sticker: React.FC<StickerProps> = ({
  testID,
  type = 'cancel',
  label,
  rounded = false,
  showIndicator = false,
  themed = false,
  containerStyle,
}: StickerProps) => {
  const styles = StickerStyles({ type, themed, rounded });

  return (
    <View style={[containerStyle, styles.container]}>
      <Text testID={testID} numberOfLines={1} style={styles.text}>
        {label}
      </Text>
      {showIndicator && <View style={styles.indicator}></View>}
    </View>
  );
};

export default Sticker;
