import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import PaymentAmount from '../../PaymentsScreen/PaymentAmount/PaymentAmount';
import PaymentOptionsStyles from '../../PaymentsScreen/PaymentOptions.styles';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import PaymentHeader from '../../PaymentsScreen/PaymentHeader/PaymentHeader';
import LoadingIndicator from '../../../Shared/Loaders/LoadingIndicator';

export interface ProcessCardPaymentModalProps {
  orderTotal: number;
  cancellationLoading: boolean;
  onPressCancel: () => void;
  onPressVerify: () => void;
}

const ProcessCardPaymentModal: React.FC<ProcessCardPaymentModalProps> = ({
  orderTotal,
  cancellationLoading,
  onPressCancel,
  onPressVerify,
}: ProcessCardPaymentModalProps) => {
  const { translate } = useTranslation();

  const styles = PaymentOptionsStyles();

  return (
    <View style={styles.container}>
      <PaymentHeader title={translate('payment.paymentAmount')} />
      <View style={styles.content}>
        <PaymentAmount value={orderTotal} />
        <View style={styles.cardProcessState}>
          <LoadingIndicator large />
          <Text style={styles.statusText}>
            {translate('payment.processingPayment')}
          </Text>
        </View>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={[styles.actionRow, { marginBottom: 0 }]}>
          <TreatButton
            testID="btn-cancelPayment"
            height={60}
            type="negativeLight"
            label={translate('payment.cancelOolioPay')}
            onPress={onPressCancel}
            disabled={cancellationLoading}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ flex: 1, marginRight: 5 }}
          />
          <TreatButton
            testID="btn-verifyPayment"
            height={60}
            type="neutralLight"
            label={translate('payment.verifyOolioPay')}
            onPress={onPressVerify}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ flex: 1, marginLeft: 5 }}
          />
        </View>
      </View>
    </View>
  );
};

export default ProcessCardPaymentModal;
