import React, { useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { format } from 'date-fns';
import { OrderStatus, OrderTypeCode } from '@oolio-group/domain';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { OrderHistoryItem } from '../../../POS/Orders/OpenOrders/OpenOrders.types';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';
import OrderHistoryTableStyles from './OrderHistoryTable.styles';
import Icon from '../../../../components/Icon/Icon';
import Identifier from '../../../../components/Shared/Identifier/Identifier';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';
import { getTableNameFromOrder } from '@oolio-group/order-helper';
import { useTrainingMode } from '../../../../hooks/app/orders/useTrainingMode';

interface Props {
  order: OrderHistoryItem;
  onPress: () => void;
  onPressSendReceipt: (order: OrderHistoryItem) => void;
  onPressReprintDocket: (orderId: string) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
}

const OrderHistoryRow: React.FC<Props> = ({
  order,
  onPress,
  onPressSendReceipt,
  onPressPrintReceipt,
  onPressReprintDocket,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { canPerformActionOnOrder } = useTrainingMode();

  const canPerformOrderHistoryAction = useCallback(
    (callback: Function) => {
      if (canPerformActionOnOrder(order.isTraining)) return;
      callback();
    },
    [canPerformActionOnOrder, order.isTraining],
  );

  const getOrderName = (): string => {
    const { orderType, customerName, tokenNumber } = order;
    const hasCustomer = order.customerName !== 'N/A';
    const name = getTableNameFromOrder(order);
    const tableName = name
      ? `${translate('reservations.table')} ${getTableNameFromOrder(order)}`
      : '';

    switch (orderType.code as OrderTypeCode) {
      case OrderTypeCode.DINE_IN:
        return `${tableName}${hasCustomer ? ` (${customerName})` : ''}`;
      case OrderTypeCode.TAKEAWAY:
        return hasCustomer
          ? `${
              tokenNumber ? `#${tokenNumber} (${customerName})` : customerName
            }`
          : tokenNumber
          ? `#${tokenNumber}`
          : 'N/A';
      default:
        return customerName;
    }
  };

  const getPaymentsLabel = (): string => {
    if (!order?.payTypes?.length) return '';
    const first = order.payTypes[0];
    const label = `${first.name} (${formatCurrency(first.amount)})`;

    if (order.payTypes.length > 1) {
      return `${label} + ${order.payTypes.length - 1} more`;
    } else {
      return label;
    }
  };

  const isRefund = Boolean(order.refundOf);
  const orderName = getOrderName();
  const orderAmt = `${isRefund ? '-' : ''}${formatCurrency(
    Math.abs(order.totalValue),
  )}`;

  const { colors } = useTheme();
  const styles = OrderHistoryTableStyles();

  const textColor = isRefund ? colors.red : colors.text;

  return (
    <TouchableOpacity
      testID="row-openOrder"
      onPress={() => canPerformOrderHistoryAction(onPress)}
      style={[styles.tableRow, order.isTraining && styles.tableRowTraining]}
    >
      <Identifier themed orderType={order.orderType.code as OrderTypeCode} />
      <Text
        numberOfLines={1}
        testID="order-completedAt"
        style={styles.cellDate}
      >
        {format(order.completedAt, 'MMM dd, hh:mm a')}
      </Text>
      <Text
        testID="order-no"
        numberOfLines={1}
        style={[styles.cellNo, { color: textColor }]}
      >
        {order.orderNumber}
      </Text>
      <Text
        testID="order-name"
        numberOfLines={1}
        style={[
          styles.cellName,
          {
            color: orderName !== 'N/A' ? colors.text : colors.disclosure,
          },
        ]}
      >
        {getOrderName()}
      </Text>
      <Text
        testID="order-payments"
        numberOfLines={1}
        style={styles.cellPayments}
      >
        {getPaymentsLabel()}
      </Text>
      <Text
        testID="order-amount"
        style={[styles.cellAmt, { color: textColor }]}
      >
        {orderAmt}
      </Text>
      <PrintReceiptButton
        themed
        options={order.payTypes}
        onPrintReceipt={paymentIndex =>
          canPerformOrderHistoryAction(() =>
            onPressPrintReceipt(order.id, paymentIndex),
          )
        }
        reprintDocket={() => {
          canPerformOrderHistoryAction(() => onPressReprintDocket(order.id));
        }}
        orderStatus={OrderStatus.COMPLETED}
        // eslint-disable-next-line react-native/no-inline-styles
        containerStyle={{ marginLeft: 20, marginRight: 10 }}
      />
      <ButtonIcon
        themed
        testID="btn-send"
        icon="envelope"
        type="neutralLight"
        onPress={() =>
          canPerformOrderHistoryAction(() => onPressSendReceipt(order))
        }
      />
      <View style={theme.tables.disclosure}>
        <Icon name="angle-right" size={20} color={colors.disclosure} />
      </View>
    </TouchableOpacity>
  );
};

export default OrderHistoryRow;
