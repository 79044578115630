import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IntegrationApps, Feature } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import FeatureGrid from '../Layout/FeatureGrid/FeatureGrid';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useFeatures } from '../../../../hooks/app/features/useFeatures';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../components/Office/Section/Section';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { FEATURES } from '../../../../constants';

const INTEGRATIONS_SETTINGS_FILES: Record<string, string> = {
  [IntegrationApps.DOSHII]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERIT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.OOLIO_STORE]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.DELIVERECT]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.XERO]: 'XeroSettings',
  [IntegrationApps.DEPUTY]: 'DeputySettings',
  [IntegrationApps.OOM]: 'OnlineOrderIntegrationsSettings',
  [IntegrationApps.OOLIO_RESERVATION]: 'OolioReservationIntegrationsSettings',
  [IntegrationApps.OOLIO_WAITLISTS]: 'OolioWaitlistsIntegrationsSettings',
};

export const ManageIntegrationsScreen: React.FC = () => {
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const isFocused = useIsFocused();
  const { integrations, getIntegrations, loading } = useFeatures();
  const [featuresEnabled, setFeaturesEnabled] = useState({
    reservations: false,
    waitlists: false,
  });

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const [waitlists, reservations] = await Promise.all([
        analyticsService.isFeatureEnabled(FEATURES.BOOKINGS),
        analyticsService.isFeatureEnabled(FEATURES.OOLIO_RESERVATION),
      ]);
      setFeaturesEnabled({ reservations, waitlists });
    };

    fetchFeatureFlags();
  }, []);

  useEffect(() => {
    if (isFocused) getIntegrations();
  }, [getIntegrations, isFocused]);

  const onPressIntegrationTile = useCallback(
    (integration: Feature) => {
      navigation.navigate(INTEGRATIONS_SETTINGS_FILES[integration.id], {
        app: integration.app,
      });
    },
    [navigation],
  );

  const filteredIntegrations = useMemo(() => {
    return integrations.filter(integration => {
      if (
        integration.app === IntegrationApps.OOLIO_RESERVATION &&
        !featuresEnabled.reservations
      ) {
        return false;
      }
      if (
        integration.app === IntegrationApps.OOLIO_WAITLISTS &&
        !featuresEnabled.waitlists
      ) {
        return false;
      }
      return true;
    });
  }, [integrations, featuresEnabled]);

  return (
    <ScreenLayout
      loading={loading}
      hideFooter
      title={translate('navigation.integrationsPage', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="large">
        <FeatureGrid
          features={filteredIntegrations}
          onPressFeature={onPressIntegrationTile}
        />
      </Section>
    </ScreenLayout>
  );
};
