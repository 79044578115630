import React from 'react';
import {
  useNotification,
  useNotificationsList,
} from '../../hooks/Notification';
import Loader from './Loader';
import { NotificationsList } from '../../components/Shared/Notifications/NotificationsList';

export const LoadingScreen: React.FC = () => {
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();
  return (
    <Loader>
      <NotificationsList
        notifications={notifications}
        onDismiss={closeNotification}
      />
    </Loader>
  );
};

export default LoadingScreen;
