import 'react-native-get-random-values';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback, useMemo, useState } from 'react';
import { noopHandler, parseApolloError } from '../../utils/errorHandlers';
import { ApolloError } from '@apollo/client';
import { getError, isLoading } from '../../utils/apolloErrorResponse.util';
import { GET_BASE64_LOGO } from '../../graphql/base64Logo';

export interface UseBase64Image {
  getBase64Img: (logoUrl?: string) => void;
  loading: boolean;
  error: string | undefined;
  base64Logo?: string;
}

export function useBase64Image(): UseBase64Image {
  const [base64Logo, setBase64Logo] = useState<string>();
  const [base64Request, base64Response] = useLazyQuery(GET_BASE64_LOGO, {
    fetchPolicy: 'network-only',
    onError: noopHandler,
    onCompleted: data => {
      if (data.base64LogoUrl) {
        //remove data:image/png;base64, from the base64 string
        const out = data.base64LogoUrl.split(',')[1];
        if (out) {
          setBase64Logo(out);
        }
      }
    },
  });

  /**
   * get base64 string for a given valid image url
   *
   * @param logoUrl
   */
  const getBase64Img = useCallback(
    (url?: string): void => {
      if (url) {
        base64Request({
          variables: { url },
        });
      } else {
        setBase64Logo(undefined);
      }
    },
    [base64Request],
  );

  const error: ApolloError | undefined = getError([base64Response]);

  const loading: boolean = isLoading([base64Response]);

  return useMemo(
    () => ({
      base64Logo,
      getBase64Img,
      loading,
      error: error ? parseApolloError(error) : undefined,
    }),
    [base64Logo, getBase64Img, loading, error],
  );
}
