import React from 'react';
import { View, Text, TouchableOpacity, ViewStyle } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import { getContrastYIQ } from '../../../../utils/color';
import theme from '../../../../common/default-theme';
import styles from './GridItem.styles';
import Icon from '../../../Icon/Icon';

export type ItemSize = {
  width: number;
  height: number;
};

interface Props {
  testID: string;
  type?: 'empty' | 'back' | 'done' | 'variation' | string;
  label?: string;
  selected?: boolean;
  disabled?: boolean;
  isAvailable?: boolean;
  warning?: boolean;
  trackedItemQuantity?: number;
  size: ItemSize;
  color?: string;
  onPress?: () => void;
  onLongPress?: () => void;
}

const MenuGridItem: React.FC<Props> = ({
  testID,
  type,
  label,
  selected = false,
  disabled = false,
  isAvailable,
  warning = false,
  trackedItemQuantity,
  color = '#ffffff',
  size = {
    width: 0,
    height: 0,
  },
  onPress,
  onLongPress,
}) => {
  const { colors } = useTheme();

  const isDone = type === 'done';
  const isBack = type === 'back';
  const isEmpty = type === 'empty';
  const isVariation = label && type === 'variation';
  const isOption =
    [
      'modifierGroup',
      'modifierGroupNotSelected',
      'modifierGroupDanger',
      'modifierDeSelect',
      'modifier',
    ].includes(type || '') && label;

  const isLowInStock =
    typeof trackedItemQuantity === 'number' && trackedItemQuantity < 1;
  const discouragePress = !isAvailable || isLowInStock;

  const { width, height } = size;
  const tileSize: ViewStyle = {
    width,
    height,
  };

  if (isEmpty) {
    return (
      <View
        style={[
          styles.container,
          tileSize,
          { backgroundColor: colors.emptyTile },
        ]}
      />
    );
  }

  if (isDone || isBack) {
    return (
      <TouchableOpacity onPress={onPress} style={[styles.container, tileSize]}>
        {type === 'back' ? (
          <Icon name="arrow-left" color={theme.colors.black1} />
        ) : (
          <Icon size={28} name="check" color={theme.colors.green} />
        )}
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      onLongPress={onLongPress}
      style={[
        styles.container,
        tileSize,
        {
          backgroundColor: isOption
            ? theme.colors.white
            : discouragePress
            ? colors.emptyTile
            : color,
        },
      ]}
    >
      {label && (
        <Text
          numberOfLines={2}
          style={[
            styles.text,
            {
              color: !discouragePress
                ? getContrastYIQ(color)
                : !isOption
                ? colors.text
                : colors.black1,
            },
          ]}
        >
          {label}
        </Text>
      )}
      <View style={styles.lhsAccessories}>
        {warning && (
          <View style={styles.warning}>
            <Icon size={16} name="exclamation" color={theme.colors.white} />
          </View>
        )}
      </View>
      <View style={styles.rhsAccessories}>
        {isAvailable && (
          <View
            style={[
              styles.count,
              {
                backgroundColor: isLowInStock
                  ? theme.colors.red
                  : theme.colors.transparent,
              },
            ]}
          >
            <Text
              style={[
                styles.countText,
                { color: isVariation ? colors.black1 : colors.white },
              ]}
            >
              {trackedItemQuantity}
            </Text>
          </View>
        )}
        {isVariation && <View style={styles.variant} />}
      </View>
      {selected && <View style={styles.selection} />}
    </TouchableOpacity>
  );
};

export default MenuGridItem;
