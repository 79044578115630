import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartSentItemsStyles = ({ themed = false, expanded = false }) => {
  const { colors } = useTheme();

  const backgroundColor = expanded
    ? colors.accent
    : themed
    ? colors.elevation1
    : colors.grey1;

  const fgColor = expanded
    ? colors.white
    : themed
    ? colors.text
    : colors.black1;

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 8,
      paddingVertical: 6,
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
      marginBottom: expanded ? 4 : 0,
      backgroundColor,
    },
    text: {
      fontSize: 13,
      lineHeight: 20,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
      color: fgColor,
    },
    divider: {
      marginVertical: 12,
      borderBottomWidth: 2,
      borderBottomColor: themed ? colors.borderColor : colors.grey3,
    },
    fgColor: {
      color: fgColor,
    },
  });
};

export default CartSentItemsStyles;
