import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { ColumnType, Widget } from '@oolio-group/domain';
import { View, Text, ViewStyle } from 'react-native';
import { useReporting } from '../../../../hooks/app/useReporting';
import { CubejsApi, ResultSet } from '@cubejs-client/core';
import LoadingIndicator from '../../../../components/Shared/Loaders/LoadingIndicator';
import { useNotification } from '../../../../hooks/Notification';
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryVoronoiContainer,
  VictoryTooltip,
} from 'victory-native';
import { Styles } from '../styles/Component.styles';
import { IMap, DisplayLabels } from '../types';
import {
  CHART_CONTAINER_STYLE,
  CHART_Y_AXIS_STYLE,
  BAR_CHART_STYLE,
  CHART_TOOLTIP_STYLE,
  BLANK_LABEL_COLOR,
  CHART_X_AXIS_STYLE,
  TOOLTIP_GAP,
  arePropsEqual,
  getLabelAccordingToType,
} from '../reportsHelper';
import { useCurrency } from '@oolio-group/localization';
import theme from '../../../../common/default-theme';

interface BarChartProps {
  widget: Widget;
  widgetName?: string;
  helper: string;
  keys: IMap<string>;
  cubejsApi: CubejsApi;
  updateCount: number;
  columnType?: ColumnType;
}

const BarChartComponent: React.FC<BarChartProps> = ({
  widget,
  helper,
  keys,
  widgetName,
  cubejsApi,
  columnType,
}) => {
  const { appendCurrency } = useCurrency();
  const { showNotification } = useNotification();
  const { width: vw } = theme.useResponsiveDimensions();

  const { loading, error, widgetData, getWidgetData } = useReporting(cubejsApi);

  const [boundingRect, setBoundingRect] = useState({ width: 0, height: 0 });
  const graphRef = useCallback(node => {
    if (node !== null) {
      node.getBoundingClientRect &&
        setBoundingRect(node.getBoundingClientRect());
    }
  }, []);

  const styles = Styles();

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    widget.query && getWidgetData(widget, helper);
  }, [widget, getWidgetData, helper]);

  const data = useMemo(() => {
    if (!widgetData || !widget?.name || !widgetData[widget.name])
      return {} as ResultSet;
    return widgetData[widget.name] as ResultSet;
  }, [widgetData, widget]);

  const chartMargin =
    CHART_CONTAINER_STYLE.marginLeft + CHART_CONTAINER_STYLE.marginRight;
  const chartStyle = {
    ...CHART_CONTAINER_STYLE,
    width: vw - chartMargin,
  };

  const serializedData = (data.tablePivot && data.tablePivot()) || [];
  const chartData =
    serializedData.length > 0
      ? serializedData
          .map(row => {
            return {
              x:
                typeof row[keys.name] === 'string' && row[keys.name] !== ''
                  ? row[keys.name]
                  : DisplayLabels.BLANK_LABEL,
              y:
                typeof row[keys.value] === 'string' && row[keys.value] !== ''
                  ? parseFloat(row[keys.value] as string)
                  : 0,
            };
          })
          .sort((a, b) => {
            if (a.x === DisplayLabels.BLANK_LABEL) {
              return 1;
            } else if (b.x === DisplayLabels.BLANK_LABEL) {
              return -1;
            } else {
              return 0;
            }
          })
      : [{ x: DisplayLabels.NO_RECORD, y: 0 }];

  return (
    <View style={chartStyle as ViewStyle} ref={graphRef}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <VictoryChart
          width={boundingRect.width}
          height={boundingRect.height ? boundingRect.height : 0}
          domainPadding={{ x: 20, y: 50 }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={({ datum }) =>
                getLabelAccordingToType(datum, appendCurrency, columnType)
              }
              labelComponent={
                <VictoryTooltip
                  dy={TOOLTIP_GAP}
                  style={CHART_TOOLTIP_STYLE.style}
                  flyoutStyle={CHART_TOOLTIP_STYLE.flyoutStyle}
                />
              }
            />
          }
        >
          <VictoryAxis
            style={CHART_X_AXIS_STYLE}
            fixLabelOverlap={true}
            tickFormat={t => {
              let text;
              if (typeof t === 'string' && t.length > 8) {
                text = `${t.substring(0, 5)}...`;
              } else {
                text = t;
              }
              return text;
            }}
          />
          <VictoryAxis
            dependentAxis
            orientation="left"
            style={CHART_Y_AXIS_STYLE}
            tickFormat={t => {
              if (t < 1e-4) return 0;
              return t >= 1000 ? `${t / 1000}k` : `${t}`;
            }}
          />
          <VictoryBar
            data={chartData}
            style={{
              data: {
                fill: props => {
                  return props.datum.x === DisplayLabels.BLANK_LABEL
                    ? BLANK_LABEL_COLOR
                    : BAR_CHART_STYLE.data.fill;
                },
              },
              labels: BAR_CHART_STYLE.labels,
            }}
            barWidth={15}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseOver: () => {
                    return [
                      {
                        target: 'data',
                        mutation: props => {
                          return {
                            style: Object.assign({}, props.style, {
                              fill: '#fec108',
                            }),
                          };
                        },
                      },
                    ];
                  },
                  onMouseOut: () => {
                    return [
                      {
                        target: 'data',
                        mutation: props => {
                          return {
                            style: Object.assign({}, props.style, {
                              fill:
                                props.datum.x === DisplayLabels.BLANK_LABEL
                                  ? BLANK_LABEL_COLOR
                                  : BAR_CHART_STYLE.data.fill,
                            }),
                          };
                        },
                      },
                    ];
                  },
                },
              },
            ]}
          />
        </VictoryChart>
      )}
      <Text style={styles.chartTitleStyle}>{widgetName ?? widget.name}</Text>
    </View>
  );
};

export const BarChart = React.memo(BarChartComponent, arePropsEqual);
