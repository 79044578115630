/* eslint-disable react-native/no-inline-styles */
import React, { FC } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import styles from './MenuItem.styles';
import theme from '../../../common/default-theme';
import IconButton from '../../Button/IconButton';
import { useCurrency } from '@oolio-group/localization';

export interface MenuItemProps {
  name: string;
  selected?: boolean;
  color?: string;
  isAvailable?: boolean;
  disabled?: boolean;
  onPress: () => void;
  onPressIncrease: () => void;
  action?: React.ReactNode;
  quantity?: number;
  amount?: number;
  isMultiSelection?: boolean;
  onPressDecrease: () => void;
}

const MenuItem: FC<MenuItemProps> = ({
  name,
  selected = false,
  disabled = false,
  isAvailable = true,
  onPress,
  onPressIncrease,
  color: colorProp,
  quantity = 0,
  amount = 0,
  isMultiSelection,
  onPressDecrease,
}) => {
  const { formatCurrency } = useCurrency();
  const color = selected ? theme.colors.states.positive : colorProp;
  const disabledDecrease = quantity === 0;

  return (
    <View
      style={[
        styles.container,
        {
          height: isMultiSelection ? 120 : 70,
          ...(!isAvailable && { backgroundColor: theme.colors.grey3 }),
        },
      ]}
    >
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={styles.title}
      >
        <View>
          <Text numberOfLines={2} style={styles.text}>
            {name}
          </Text>
        </View>
        <View style={styles.addonPrice}>
          <Text
            style={{
              color: amount ? theme.colors.black : theme.colors.grey5,
            }}
          >
            {amount ? `+${formatCurrency(amount)}` : formatCurrency(0)}
          </Text>
        </View>
      </TouchableOpacity>
      {isMultiSelection ? (
        <View style={styles.selectionAction}>
          <IconButton
            fluid
            icon={'minus-circle'}
            onPress={onPressDecrease}
            iconSize={20}
            iconColor={
              disabledDecrease
                ? theme.colors.grey5
                : theme.colors.states.negative
            }
            disabled={disabledDecrease}
            containerStyle={{ height: 30 }}
          />
          <Text
            style={{
              color: quantity === 0 ? theme.colors.grey5 : theme.colors.black1,
              paddingHorizontal: 10,
            }}
          >
            {quantity}
          </Text>
          <IconButton
            fluid
            icon={'plus-circle'}
            onPress={onPressIncrease}
            iconSize={20}
            iconColor={
              disabled ? theme.colors.grey5 : theme.colors.states.positive
            }
            disabled={disabled}
          />
        </View>
      ) : null}
      {color && <View style={[styles.triangle, { borderTopColor: color }]} />}
    </View>
  );
};

export default MenuItem;
