import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';

const styles = StyleSheet.create({
  keypad: {
    gap: 4,
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 20,
  },
  btnKeypad: {
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    backgroundColor: theme.colors.grey1,
  },
  textKeypad: {
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.dark,
    fontFamily: theme.fonts.semibold,
    fontVariant: ['tabular-nums'],
  },
});

export default styles;
