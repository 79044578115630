import React, { PropsWithChildren, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import CartComboStyles from './CartComboProducts.styles';
import Icon from '../../../Icon/Icon';

interface Props extends PropsWithChildren {
  id: string;
  name: string;
  themed?: boolean;
  isCollapsed?: boolean;
  onCollapse: (id: string, status: boolean) => void;
}

const CartComboProductsStyles: React.FC<Props> = ({
  id,
  name,
  children,
  themed = false,
  isCollapsed,
  onCollapse,
}) => {
  const { colors } = useTheme();
  const styles = CartComboStyles({ themed });

  const handleCollapse = useCallback(() => {
    onCollapse(id, !isCollapsed);
  }, [id, isCollapsed, onCollapse]);

  return (
    <View>
      <TouchableOpacity
        onPress={handleCollapse}
        testID="btn-toggleComboProducts"
        style={styles.container}
      >
        <Text testID="combo-name" style={styles.text}>
          {name}
        </Text>
        <Icon
          size={20}
          color={themed ? colors.text : colors.black1}
          name={`angle-${isCollapsed ? 'down' : 'up'}`}
        />
      </TouchableOpacity>
      {!isCollapsed && <View>{children}</View>}
    </View>
  );
};

export default CartComboProductsStyles;
