import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartTotalStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      padding: 16,
      paddingTop: 10,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : colors.white,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    groupPaid: {
      marginTop: 16,
      paddingTop: 16,
      borderTopWidth: 1,
      borderTopColor: themed ? colors.borderColor : colors.grey3,
    },
    textMinor: {
      lineHeight: 16,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.regular,
      color: themed ? colors.textLight : colors.grey6,
    },
    textMajor: {
      fontSize: 16,
      lineHeight: 18,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
    textPayment: {
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
    btnFailedPayment: {
      marginLeft: 6,
    },
    // Change Due
    changeDue: {
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      justifyContent: 'space-between',
      backgroundColor: themed ? colors.elevation0 : colors.white,
    },
    changeDueText: {
      fontSize: 16,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
  });
};

export default CartTotalStyles;
