import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ImageUploadInput, ImageType } from '@oolio-group/domain';
import {
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { SUPPORTED_IMAGE_FORMATS } from '../../../types/Common';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';
import styles from './ImagePicker.styles';
import { convertBase64ToDithered } from '../../../utils/printImageUtils';

export interface ImagePickerProps {
  initials?: string;
  imageUrl?: string;
  large?: boolean;
  maxSize?: number;
  maxWidth?: number;
  minWidth?: number;
  minHeight?: number;
  monochromatic?: boolean;
  onClear?: () => void;
  onFail?: (error: string) => void;
  onComplete: (data: ImageUploadInput) => void;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  initials,
  imageUrl,
  large,
  maxSize,
  minWidth = 256,
  maxWidth,
  minHeight = 256,
  monochromatic = false,
  onClear,
  // onFail = () => undefined,
  onComplete,
}: ImagePickerProps) => {
  const { translate } = useTranslation();
  const [imageURI, setImageURI] = useState<string | undefined>(imageUrl);
  const [, setImgInfo] = useState<{ width: number; height: number }>();
  const { showNotification } = useNotification();
  const inputRef = useRef<HTMLInputElement>(null);
  const [, setFileName] = useState<string>('');

  const getContainerSize = (isLarge: boolean) => {
    if (isLarge) {
      return { width: theme.layoutWidth.s, height: 300 };
    } else {
      return { width: 100, height: 100 };
    }
  };

  useEffect(() => {
    if (imageURI) {
      const img = new Image();
      img.src = imageURI;
      img.onload = function () {
        setImgInfo({
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [imageURI]);

  const onChangeImage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const file = event.target.files[0];
      const format = (file?.type || '').replace(/^image\//, '');
      if (!/^image\//.test(file?.type)) {
        showNotification({
          error: true,
          message: translate('avatar.notAnImage'),
        });
        return;
      }
      if (file && !SUPPORTED_IMAGE_FORMATS.includes(format)) {
        showNotification({
          error: true,
          message: translate('avatar.formatNotSupported', {
            format,
          }),
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = event => {
        const base64String = event.target?.result as string;
        setFileName(file.name);

        const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
        if (maxSize && fileSize > maxSize) {
          showNotification({
            error: true,
            message: translate('avatar.imageExceedSize', { size: maxSize }),
          });
          return;
        }

        const image = new Image();
        image.src = base64String;
        image.onload = async () => {
          const { width, height } = image;
          if (width < minWidth || height < minHeight) {
            showNotification({
              error: true,
              message: translate('avatar.imageSizeError', {
                minHeight,
                minWidth,
              }),
            });
            return;
          }

          if (maxWidth && width > maxWidth) {
            showNotification({
              error: true,
              message: translate('avatar.imageMaxWidthError', { maxWidth }),
            });
            return;
          }

          if (monochromatic) {
            try {
              const ditheredBase64 = await convertBase64ToDithered(
                base64String,
              );
              setImageURI(ditheredBase64);
              onComplete({
                base64: ditheredBase64,
                name: 'temp',
                type: format as ImageType,
              });
            } catch (error) {
              showNotification({
                error: true,
                message: translate('avatar.ditheringFailed'),
              });
            }
          } else {
            setImageURI(base64String);
            onComplete({
              base64: base64String,
              name: 'temp',
              type: format as ImageType,
            });
          }
        };
      };

      reader.onerror = err => {
        showNotification({
          error: true,
          message: JSON.stringify(err),
        });
      };

      reader.readAsDataURL(file);
    },
    [
      showNotification,
      translate,
      maxSize,
      minWidth,
      minHeight,
      monochromatic,
      onComplete,
      maxWidth,
    ],
  );

  useEffect(() => {
    setImageURI(imageUrl);
  }, [imageUrl]);

  const onClearImage = useCallback(
    (e?: GestureResponderEvent): void => {
      e?.stopPropagation();
      setFileName('');
      setImageURI(undefined);
      onClear && onClear();
    },
    [onClear],
  );

  const imageTempURL = (imageURI || imageUrl)?.includes('base64')
    ? imageURI || imageUrl
    : (imageURI || imageUrl) &&
      `${imageURI || imageUrl}?cache=${new Date().getTime()}`;

  return (
    <View
      style={[
        styles.container,
        getContainerSize(large || false),
        {
          backgroundColor: imageUrl
            ? theme.colors.transparent
            : theme.colors.primary,
        },
      ]}
    >
      <ImageBackground
        testID="image-background"
        source={{ uri: imageTempURL }}
        style={getContainerSize(large || false)}
      >
        {imageUrl && onClear && (
          <TouchableOpacity
            testID="btn-remove"
            onPress={onClearImage}
            style={styles.btnRemove}
          >
            <Icon name="times" size={20} color={theme.colors.white} />
          </TouchableOpacity>
        )}
        {large ? (
          <TouchableOpacity
            testID="btn-change"
            style={styles.btnChangeIcon}
            onPress={() => {
              inputRef.current?.click();
            }}
          >
            <Icon name="cloud-upload" size={20} color={theme.colors.white} />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            testID="btn-change"
            style={styles.btnChange}
            onPress={() => {
              inputRef.current?.click();
            }}
          >
            <Text testID="text-prompt" style={styles.btnChangeText}>
              {imageUrl
                ? translate('button.changeImage')
                : translate('button.addImage')}
            </Text>
          </TouchableOpacity>
        )}
        <View>
          {!imageUrl && !imageTempURL && (
            <Text testID="text-initials" style={styles.initials}>
              {initials}
            </Text>
          )}
          <View style={styles.webInput}>
            <label onChange={event => onChangeImage(event)}>
              <input type="file" accept="image/*" ref={inputRef} />
            </label>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};

export default ImagePicker;
