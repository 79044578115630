import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { ButtonIconProps } from './Button.types';
import ButtonStyles from './Button.styles';
import Icon from '../../Icon/Icon';

const ButtonIcon: React.FC<ButtonIconProps> = ({
  testID,
  type = 'cancel',
  icon = 'star',
  size = 34,
  disabled,
  themed = false,
  transparent = false,
  onPress,
  containerStyle,
}: ButtonIconProps) => {
  const styles = ButtonStyles({ type, themed, disabled, transparent });

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        onPress={onPress}
        disabled={disabled}
        style={[
          styles.container,
          styles.btnIcon,
          { width: size, height: size },
        ]}
      >
        <Icon name={icon} size={20} style={styles.fgColor} />
      </TouchableOpacity>
    </View>
  );
};

export default ButtonIcon;
