import React, { FC, useCallback, useMemo } from 'react';
import { View, ScrollView, Text, TouchableOpacity } from 'react-native';
import { CatalogueItem } from '@oolio-group/domain';
import { useLocalization } from '@oolio-group/localization';
import { getLocaleEntity } from '@oolio-group/client-utils';
import sortBy from 'lodash/sortBy';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';
import styles, { getTextColor, getPageTileWidth } from './MenuPages.styles';

interface MenuPagesProps {
  selectedPage: string;
  data: CatalogueItem[];
  onPressPage: (id: string) => void;
}

interface PageTileProps {
  item: CatalogueItem;
  handlePagePress: (pageId: string) => void;
  selectedPage: string;
  languageTag: string;
}

const PageTileComponent: FC<PageTileProps> = ({
  item,
  handlePagePress,
  selectedPage,
  languageTag,
}) => {
  return (
    <TouchableOpacity
      key={item.id}
      testID={`page-${item.id}`}
      onPress={() => handlePagePress(item.page.id)}
      style={[styles.page, { backgroundColor: item.color }]}
    >
      {selectedPage === item.page.id && <View style={styles.selected} />}
      <Text
        numberOfLines={2}
        style={[styles.pageText, { color: getTextColor(item.color) }]}
      >
        {getLocaleEntity(item.page, languageTag).name}
      </Text>
    </TouchableOpacity>
  );
};

const PageTile = React.memo(PageTileComponent);

const ensureUniquePriorities = (data: CatalogueItem[]): CatalogueItem[] => {
  if (!data?.length) return [];

  const sortedData = sortBy(data, item => item.priority);
  const priorities = new Set(sortedData.map(item => item.priority));

  if (priorities.size !== sortedData.length) {
    return sortedData.map((item, index) => ({ ...item, priority: index }));
  }

  return sortedData;
};

const MenuPages: FC<MenuPagesProps> = ({ data, selectedPage, onPressPage }) => {
  const { colors } = useTheme();
  const { locale } = useLocalization();
  const { width: vw } = theme.useResponsiveDimensions();

  const sortedData = useMemo(() => ensureUniquePriorities(data), [data]);
  const formattedData = useMemo(
    () =>
      sortedData.reduce((acc, item) => {
        acc[item.priority] = item;
        return acc;
      }, Array(sortedData.length).fill(null)),
    [sortedData],
  );

  const handlePagePress = useCallback(
    (pageId: string) => {
      onPressPage(pageId);
    },
    [onPressPage],
  );

  const containerStyle = useMemo(
    () => [styles.container, { maxWidth: getPageTileWidth(vw) }],
    [vw],
  );

  if (formattedData.length > 0) {
    return (
      <ScrollView
        testID="pages"
        showsVerticalScrollIndicator={false}
        style={containerStyle}
        // eslint-disable-next-line react-native/no-inline-styles
        contentContainerStyle={{ rowGap: 4 }}
      >
        {formattedData.map((item, index) =>
          item ? (
            <PageTile
              key={item.id}
              item={item}
              handlePagePress={handlePagePress}
              selectedPage={selectedPage}
              languageTag={locale.languageTag}
            />
          ) : (
            <View
              key={`empty-${index}`}
              style={[styles.page, { backgroundColor: colors.emptyTile }]}
            />
          ),
        )}
      </ScrollView>
    );
  }

  return <View testID="pages" style={styles.emptyContainer} />;
};

export default MenuPages;
