import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { debounce } from 'lodash';
import { Customer } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useCustomers } from '../../../../hooks/orders/useCustomers';
import styles from './CustomerSearch.styles';
import theme from '../../../../common/default-theme';
import Icon from '../../../../components/Icon/Icon';
import Search from '../../../../components/Shared/Search/Search';

interface Props {
  onSelect: (customer: Customer) => void;
}

const CustomerSearch: React.FC<Props> = ({ onSelect }) => {
  const { translate } = useTranslation();
  const {
    paginatedCustomers,
    loading: loadingCustomers,
    getPaginatedCustomers,
  } = useCustomers();

  const [searchText, setSearchText] = useState('');

  const customers = useMemo(() => {
    if (!searchText) return [];
    return paginatedCustomers.slice(0, 5);
  }, [paginatedCustomers, searchText]);

  const debouncedSearch = React.useRef(
    debounce(async (searchValue: string) => {
      setSearchText(searchValue);
    }, 300),
  ).current;

  const onChangeSearch = useCallback(
    (searchValue: string) => {
      debouncedSearch(searchValue);
    },
    [debouncedSearch],
  );

  const handleSelect = useCallback(
    (customer: Customer) => {
      onSelect(customer);
      setSearchText('');
    },
    [onSelect],
  );

  useEffect(() => {
    if (!searchText.trim() || searchText.length <= 2) return;
    getPaginatedCustomers(searchText.trim());
  }, [searchText, getPaginatedCustomers]);

  return (
    <View style={styles.search}>
      <Search
        testID="search-customer"
        placeholder={translate('bookings.searchExistingCustomers')}
        value={searchText}
        onChangeText={onChangeSearch}
        loading={loadingCustomers}
      />
      {customers.length > 0 && (
        <View style={styles.popup}>
          {customers.map((customer, i) => (
            <TouchableOpacity
              key={`searchResult-${i}`}
              style={styles.result}
              onPress={() => handleSelect(customer)}
            >
              <View style={styles.text}>
                <Text style={styles.title}>{customer.name}</Text>
                <Text style={styles.subtitle}>{customer.phone}</Text>
              </View>
              <View style={styles.icon}>
                <Icon size={20} name="angle-right" color={theme.colors.grey4} />
              </View>
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  );
};

export default CustomerSearch;
