import React, { useState } from 'react';
import {
  View,
  Platform,
  KeyboardAvoidingView,
  Text,
  TouchableOpacity,
} from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import SetGridSizeModalStyles from './SetGridSizeModal.styles';
import { useTheme } from '../../../../hooks/useTheme';
import { useTranslation } from '@oolio-group/localization';
import ModalTitle from '../ModalTitle/ModalTitle';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

interface Props {
  grid: 'product' | 'options';
}

const SetGridSizeModal: React.FC<Props> = ({ grid }) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { grids, setGrids } = useTheme();

  const styles = SetGridSizeModalStyles();

  const initialGrid = grid === 'product' ? grids.products : grids.options;
  const possibleValues = Array.from({ length: 10 - 3 + 1 }, (_, i) => i + 3);

  const [selectedRows, setSelectedRows] = useState<number>(initialGrid.rows);
  const [selectedColumns, setSelectedColumns] = useState<number>(
    initialGrid.columns,
  );

  const onConfirm = () => {
    if (grid === 'product') {
      setGrids({
        ...grids,
        products: { rows: selectedRows, columns: selectedColumns },
      });
    } else {
      setGrids({
        ...grids,
        options: { rows: selectedRows, columns: selectedColumns },
      });
    }
    closeModal();
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.container}>
        <ModalTitle
          themed
          title={`Set ${
            grid.charAt(0).toUpperCase() + grid.slice(1)
          } Grid Size`}
          onDismiss={closeModal}
        />
        <View style={styles.content}>
          <View style={styles.group}>
            <Text style={styles.text}>Columns</Text>
            <View style={styles.grid}>
              {possibleValues.map(value => {
                const isSelected = selectedColumns === value;

                return (
                  <TouchableOpacity
                    key={`col-${value}`}
                    testID={`col-${value}`}
                    onPress={() => setSelectedColumns(value)}
                    style={[
                      styles.btnGrid,
                      isSelected && styles.btnGridSelected,
                    ]}
                  >
                    <Text
                      style={[
                        styles.btnGridText,
                        isSelected && styles.btnGridTextSelected,
                      ]}
                    >
                      {value}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
          <View style={styles.group}>
            <Text style={styles.text}>Rows</Text>
            <View style={styles.grid}>
              {possibleValues.map(value => {
                const isSelected = selectedRows === value;

                return (
                  <TouchableOpacity
                    key={`row-${value}`}
                    testID={`row-${value}`}
                    onPress={() => setSelectedRows(value)}
                    style={[
                      styles.btnGrid,
                      isSelected && styles.btnGridSelected,
                    ]}
                  >
                    <Text
                      style={[
                        styles.btnGridText,
                        isSelected && styles.btnGridTextSelected,
                      ]}
                    >
                      {value}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
          <TreatButton
            testID="btn-confirm"
            type="positive"
            label={translate('button.save')}
            onPress={onConfirm}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default SetGridSizeModal;
