import React, { useCallback } from 'react';
import { useFela } from 'react-fela';
import { OrderItem, StyleFn } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { Text, View, StyleSheet, ViewStyle, ScrollView } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import ModalDialog from '.././Modal';
import IconButton from '../../Button/IconButton';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import TreatButton from '../../Shared/TreatButton/TreatButton';

export interface PrinterProfilePromptProps {
  onConfirm?: () => void;
  containerStyle?: ViewStyle;
  orderItems: OrderItem[];
}

const headingStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.large,
  lineHeight: 27,
  letterSpacing: 0,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.orange,
  fontFamily: theme.font.medium,
});

const confirmationStyle: StyleFn = ({ theme }) => ({
  fontSize: theme.fontSize.small,
  lineHeight: 24,
  letterSpacing: -0.1,
  width: '100%',
  textAlign: 'center',
  color: theme.colors.grey2,
  fontFamily: theme.font.medium,
  marginTop: theme.spacing.medium + theme.spacing.medium,
  marginBottom: theme.spacing.big + theme.spacing.medium,
  paddingHorizontal: theme.spacing.big + theme.spacing.small / 2,
});

const alertIconStyle: StyleFn = ({ theme }) => ({
  color: theme.colors.warning,
  marginTop: -theme.spacing.big,
  alignSelf: 'center',
  width: 54,
  height: 54,
});

const styles = StyleSheet.create({
  container: {
    width: 360,
  },
});

const PrinterProfilePrompt: React.FC<PrinterProfilePromptProps> = ({
  onConfirm,
  orderItems,
  containerStyle,
}: PrinterProfilePromptProps) => {
  const { css, theme } = useFela({ alert });
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressConfirm = useCallback(() => {
    closeModal();
    onConfirm && onConfirm();
  }, [closeModal, onConfirm]);

  const onPressReject = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalDialog showCloseButton={false}>
      <IconButton
        testID={'error-icon'}
        icon="exclamationTriangle"
        iconColor={theme.colors.orange}
        iconSize={32}
        containerStyle={css(alertIconStyle)}
        disabled
      />
      <View style={[styles.container, containerStyle]}>
        <Text style={css(headingStyle)} testID={'tittle'}>
          {translate('printerProfilePrompt.title')}
        </Text>
        <Text style={css(confirmationStyle)}>
          {translate('printerProfilePrompt.subTitle')}
        </Text>

        <ScrollView contentContainerStyle={css({ height: 150 })}>
          {orderItems
            .filter(item => !item.product.printerProfiles?.length)
            .map(item => {
              return (
                <CartProduct
                  minimised
                  testID="product"
                  key={item?.id}
                  orderItem={item}
                />
              );
            })}
        </ScrollView>
        <Text style={css(confirmationStyle)}>
          {translate('printerProfilePrompt.description')}
        </Text>
        <TreatButton
          testID={'alert-accept'}
          height={50}
          type="focus"
          onPress={onPressConfirm}
          label={translate('button.continue')}
        />
        <TreatButton
          testID={'alert-dismiss'}
          height={50}
          type="cancel"
          onPress={onPressReject}
          label={translate('button.dismiss')}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 10 }}
        />
      </View>
    </ModalDialog>
  );
};

export default PrinterProfilePrompt;
