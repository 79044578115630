/**
 * Determines the best contrasting colour (white or black) for a given hex colour or CSS colour variable based on the YIQ colour space.
 */
export function getContrastYIQ(color: string): string {
  let resolvedColor: string;

  if (color.startsWith('var(')) {
    const variableName = color.slice(4, -1).trim();
    const computedStyle = getComputedStyle(document.documentElement);
    resolvedColor = computedStyle.getPropertyValue(variableName).trim();
  } else {
    resolvedColor = color;
  }

  if (resolvedColor.startsWith('#')) {
    const r = parseInt(resolvedColor.slice(1, 3), 16);
    const g = parseInt(resolvedColor.slice(3, 5), 16);
    const b = parseInt(resolvedColor.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 180 ? 'black' : 'white';
  }

  throw new Error(
    'Invalid color format. Please provide a hex color or a valid CSS colour variable.',
  );
}
