import React, { useMemo } from 'react';
import { Text, View, Image, TouchableOpacity } from 'react-native';
import { OrderType, Reservation } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useTheme } from '../../../../hooks/useTheme';
import { formatReservationTimeAndDate } from '../utils';
import theme from '../../../../common/default-theme';
import ReservationsTableStyles from './ReservationsTable.styles';
import Icon from '../../../../components/Icon/Icon';
import Empty from '../../../../components/Shared/Empty/Empty';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';

const IMG_SEVEN_ROOMS =
  'https://till-x-storage-development.s3.ap-south-1.amazonaws.com/integrations/logos/sevenrooms.jpeg';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
  code: string;
}

interface Props {
  data: Reservation[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  onSelectReservation: (reservation: Reservation) => void;
}

const ReservationsTable: React.FC<Props> = ({
  data,
  currentPage,
  setCurrentPage,
  onSelectReservation,
}) => {
  const { colors } = useTheme();
  const { translate } = useTranslation();

  const styles = ReservationsTableStyles();

  const reservationButtonMapByStatus: Record<
    string,
    {
      title: string;
      type: 'positive' | 'focus' | 'neutral' | 'negative' | 'cancel';
      action?: (id: string) => void;
      disabled?: boolean;
    }
  > = useMemo(
    () => ({
      ARRIVED: {
        title: translate('reservations.seated'),
        type: 'positive',
      },
      ORDER_PLACED: {
        title: translate('reservations.orderPlaced'),
        type: 'positive',
      },
      CHECK_DROPPED: {
        title: translate('reservations.checkDropped'),
        type: 'positive',
      },
      PARTIALLY_ARRIVED: {
        title: translate('reservations.partiallyArrived'),
        type: 'neutral',
      },
      PRE_ARRIVED: {
        title: translate('reservations.arrived'),
        type: 'positive',
      },
      PRE_PARTIALLY_ARRIVED: {
        title: translate('reservations.partiallyArrived'),
        type: 'neutral',
      },
      CONFIRMED: {
        title: translate('reservations.confirmed'),
        type: 'focus',
      },
      BOOKED: {
        title: translate('reservations.booked'),
        type: 'focus',
      },
      NOT_RECONCILED: {
        title: translate('reservations.booked'),
        type: 'focus',
      },
    }),
    [translate],
  );

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[styles.colGuests, theme.tables.headerText]}>
          {translate('reservations.guests')}
        </Text>
        <Text style={[styles.colTime, theme.tables.headerText]}>
          {translate('reservations.reservationTime')}
        </Text>
        <Text style={[styles.colTable, theme.tables.headerText]}>
          {translate('reservations.table')}
        </Text>
        <Text style={[styles.colCustomer, theme.tables.headerText]}>
          {translate('reservations.customer')}
        </Text>
        <Text style={[styles.colNotes, theme.tables.headerText]}>
          {translate('reservations.notes')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((reservation, i) => {
            const { reservationTime, dateString } =
              formatReservationTimeAndDate(
                reservation.real_datetime_of_slot,
                reservation.duration,
              );

            return (
              <TouchableOpacity
                key={reservation.reference_code}
                testID="row-online-reservation"
                style={styles.tableRow}
                onPress={() => onSelectReservation(reservation)}
              >
                <Image
                  style={styles.logo}
                  source={{ uri: IMG_SEVEN_ROOMS }}
                  testID={`online-reservation-${i}-source`}
                />
                <Sticker
                  themed
                  type="primaryLight"
                  label={`${reservation.max_guests}`}
                  testID={`online-reservation-${i}-guests`}
                />
                <View style={styles.colTime}>
                  <Text
                    numberOfLines={1}
                    style={styles.title}
                    testID={`online-reservation-${i}-reservation-date`}
                  >
                    {dateString}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={styles.subtitle}
                    testID={`online-reservation-${i}-reservation-time-range`}
                  >
                    {reservationTime}
                  </Text>
                </View>
                <View style={styles.colTable}>
                  <Text
                    numberOfLines={1}
                    style={styles.title}
                    testID={`online-reservation-${i}-table-no`}
                  >
                    {reservation.table_numbers.join(', ')}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={styles.subtitle}
                    testID={`online-reservation-${i}-table-area`}
                  >
                    {reservation.venue_seating_area_name}
                  </Text>
                </View>
                <View style={styles.colCustomer}>
                  <Text
                    numberOfLines={1}
                    style={styles.title}
                    testID={`online-reservation-${i}-customer-name`}
                  >
                    {reservation.full_name}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={styles.subtitle}
                    testID={`online-reservation-${i}-customer-phone`}
                  >
                    {reservation.phone_number}
                  </Text>
                </View>
                <View style={styles.colNotes}>
                  <Text
                    numberOfLines={1}
                    style={styles.title}
                    testID={`online-reservation-${i}-reservation-notes`}
                  >
                    {reservation.notes}
                  </Text>
                  <Text style={styles.subtitle} />
                </View>
                <View style={styles.colStatus}>
                  {reservationButtonMapByStatus[reservation.status] && (
                    <Sticker
                      themed
                      rounded
                      testID={`online-reservation-${i}-status-button`}
                      type={
                        reservationButtonMapByStatus[reservation.status]
                          ?.type || 'neutral'
                      }
                      label={
                        reservationButtonMapByStatus[reservation.status]
                          ?.title || ''
                      }
                    />
                  )}
                  <View style={theme.tables.disclosure}>
                    <Icon
                      size={20}
                      name="angle-right"
                      color={colors.disclosure}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            );
          })
        ) : (
          <Empty themed label={translate('reservations.noResults')} />
        )}
        {data.length > 10 ? (
          <Pagination
            pageLength={10}
            page={currentPage}
            dataLength={data.length}
            onPageChange={setCurrentPage}
          />
        ) : null}
      </View>
    </View>
  );
};
export default ReservationsTable;
