import React, { useCallback, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import isToday from 'date-fns/isToday';
import { capitalCase } from 'change-case';
import { RESERVATION_STATUS, Reservation } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../hooks/Notification';
import { useReservations } from '../../../hooks/app/reservations/useReservations';
import ReservationSidePanelStyles from './ReservationSidePanel.styles';
import theme from '../../../common/default-theme';
import { addOptimisticReservation } from '../../../screens/POS/Reservations/optimisticReservationsUtils';
import { formatReservationTimeAndDate } from '../../../screens/POS/Reservations/utils';
import PanelTitle from './PanelTitle';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import LoadingIndicator from '../../Shared/Loaders/LoadingIndicator';

interface Props {
  disableBtn?: boolean;
  loading?: boolean;
  reservation: Reservation;
  selectedDate?: string;
  setReservations: Function;
  showPanel: boolean;
  onClose: () => void;
  onOrderView?: (orderId: string) => void;
}

interface DetailsTileProps {
  testID: string;
  title: string;
  value: string | number;
}

export const DetailRow: React.FC<DetailsTileProps> = ({
  testID,
  title,
  value,
}) => {
  const styles = ReservationSidePanelStyles();

  return (
    <View style={styles.row}>
      <Text style={styles.rowTitle}>{title}</Text>
      <View style={styles.rowValues}>
        <Text testID={testID} style={styles.rowValue}>
          {value}
        </Text>
      </View>
    </View>
  );
};

const ReservationSidePanel: React.FC<Props> = ({
  loading,
  reservation,
  selectedDate,
  setReservations,
  showPanel,
  onClose,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const safeHeight = theme.useSafeHeight();
  const { showNotification } = useNotification();
  const { createOrderForReservation, unseatReservation } = useReservations();

  const styles = ReservationSidePanelStyles();

  const [isUpdatingReservation, setIsUpdatingReservation] = useState(false);

  const { reservationDateTime } = formatReservationTimeAndDate(
    reservation.real_datetime_of_slot,
    reservation.duration,
  );

  const detailsData: DetailsTileProps[] = [
    {
      testID: 'reservation-duration',
      title: translate('reservations.reservationTime'),
      value: reservationDateTime,
    },
    {
      testID: 'reservation-status',
      title: translate('reservations.status'),
      value: capitalCase(reservation.status_display || ''),
    },
    {
      testID: 'reservation-note',
      title: translate('reservations.reservationNote'),
      value: reservation.notes || '-',
    },
    {
      testID: 'reservation-sectionAndTable',
      title: translate('reservations.sectionAndTable'),
      value:
        reservation?.venue_seating_area_name +
        '  ' +
        (reservation.table_numbers?.join(', ') || ''),
    },
    {
      testID: 'reservation-deposit',
      title: translate('reservations.deposit'),
      value: formatCurrency(reservation.deposit || 0) || '-',
    },
    {
      testID: 'reservation-customerGuests',
      title: translate('reservations.customerGuests'),
      value: `${reservation.full_name} (x ${reservation.max_guests})`,
    },
    {
      testID: 'reservation-customerContact',
      title: translate('reservations.customerContact'),
      value: `${reservation.phone_number}\n${reservation.email}`,
    },
    {
      testID: 'reservation-integration',
      title: translate('reservations.integration'),
      value: 'Sevenrooms',
    },
  ];

  const updateReservationStatus = useCallback(
    (referenceCode: string, newStatus: string, newStatusDisplay: string) => {
      if (isToday(new Date(selectedDate || 0))) {
        addOptimisticReservation(referenceCode, {
          status: newStatus,
          status_display: newStatusDisplay,
        });
      } else {
        setReservations((prevReservations: Reservation[]) =>
          prevReservations.map(preRes =>
            preRes.reference_code === referenceCode
              ? {
                  ...preRes,
                  status: newStatus,
                  status_display: newStatusDisplay,
                }
              : preRes,
          ),
        );
      }
    },
    [setReservations, selectedDate],
  );

  const onUnseatReservation = useCallback(async () => {
    setIsUpdatingReservation(true);
    try {
      await unseatReservation(reservation.reference_code);
      onClose();
      updateReservationStatus(
        reservation.reference_code,
        RESERVATION_STATUS.CONFIRMED,
        RESERVATION_STATUS.CONFIRMED,
      );
    } catch (e) {
      const errMsg = (e as Error)?.message || '';
      showNotification({
        error: true,
        message: errMsg,
      });
    } finally {
      setIsUpdatingReservation(false);
    }
  }, [
    updateReservationStatus,
    unseatReservation,
    reservation,
    showNotification,
    onClose,
  ]);

  const onSeatReservation = useCallback(async () => {
    setIsUpdatingReservation(true);
    try {
      const { message, success } = await createOrderForReservation(reservation);
      if (!success) {
        showNotification({
          error: true,
          message: `${translate('reservations.failedSeatedReservation', {
            id: reservation.reference_code,
          })} - ${message ?? ''}`,
        });
      } else {
        onClose();
        updateReservationStatus(
          reservation.reference_code,
          RESERVATION_STATUS.ARRIVED,
          RESERVATION_STATUS.SEATED,
        );
      }
    } finally {
      setIsUpdatingReservation(false);
    }
  }, [
    updateReservationStatus,
    createOrderForReservation,
    reservation,
    showNotification,
    onClose,
    translate,
  ]);

  const showSeatAction =
    [
      RESERVATION_STATUS.BOOKED,
      RESERVATION_STATUS.NOT_RECONCILED,
      RESERVATION_STATUS.CONFIRMED,
      RESERVATION_STATUS.PRE_PARTIALLY_ARRIVED,
      RESERVATION_STATUS.PRE_ARRIVED,
      RESERVATION_STATUS.PARTIALLY_ARRIVED,
      RESERVATION_STATUS.ARRIVED,
    ].includes(reservation.status as RESERVATION_STATUS) &&
    reservation.status_display?.toLowerCase() !==
      RESERVATION_STATUS.SEATED.toLowerCase();

  if (!showPanel) {
    return <></>;
  }

  return (
    <View style={[styles.container, { height: safeHeight }]}>
      {loading || isUpdatingReservation ? (
        <LoadingIndicator containerStyles={styles.placeholder} />
      ) : reservation ? (
        <>
          <PanelTitle
            themed
            onClose={onClose}
            title={translate('reservations.reservationCodeTitle', {
              id: reservation?.reference_code,
            })}
            sticker={{
              testID: 'sticker-tableNo',
              themed: true,
              type: 'teal',
              label: reservation?.table_numbers
                ? reservation.table_numbers.join(', ')
                : '',
            }}
          />
          <View style={styles.content}>
            <ScrollView>
              {detailsData.map((data, index) => (
                <DetailRow
                  testID={data.testID}
                  title={data.title}
                  value={data.value}
                  key={`${index} ${data.value}`}
                />
              ))}
            </ScrollView>
          </View>
          <View style={styles.actionsRow}>
            <TreatButton
              testID="btn-seatReservation"
              themed
              height={50}
              type="positive"
              isLoading={loading}
              label={
                showSeatAction
                  ? translate('reservations.seatReservation')
                  : translate('reservations.unseatReservation')
              }
              onPress={showSeatAction ? onSeatReservation : onUnseatReservation}
              containerStyle={styles.btnAction}
            />
          </View>
        </>
      ) : null}
    </View>
  );
};

export default ReservationSidePanel;
