import React from 'react';
import { View, Text } from 'react-native';
import {
  EntityStyleMap,
  OrderTypeStyleMap,
  DefaultStyle,
} from './Identifier.maps';
import styles from './Identifier.styles';
import { IdentifierStyle, IdentifierProps } from './Identifier.types';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';

const Identifier: React.FC<IdentifierProps> = ({
  entity,
  orderType,
  themed = false,
  containerStyles,
}) => {
  const style: IdentifierStyle =
    EntityStyleMap[entity!] || OrderTypeStyleMap[orderType!] || DefaultStyle;

  const displayChar = style.displayChar;

  const { colors } = useTheme();
  const palette = themed ? colors : theme.colors;

  return (
    <View
      style={[
        styles.container,
        entity && styles.entity,
        { backgroundColor: palette[style.background] },
        containerStyles,
      ]}
    >
      <Text style={[styles.text, { color: palette[style.foreground] }]}>
        {displayChar}
      </Text>
    </View>
  );
};

export default Identifier;
