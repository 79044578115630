import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const PaymentAmountStyles = () => {
  return StyleSheet.create({
    container: {
      height: 80,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.tertiary1,
    },
    text: {
      fontSize: 32,
      lineHeight: 44,
      textAlign: 'center',
      color: theme.colors.states.focus,
      fontFamily: theme.fonts.semibold,
      fontVariant: ['tabular-nums'],
    },
    btnEdit: {
      position: 'absolute',
      right: 20,
    },
  });
};

export default PaymentAmountStyles;
