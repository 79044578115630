export const addToValue = ({
  value,
  key,
  decimalSeparator,
  length,
  decimalPlaces = 2,
}: {
  value: string;
  key: string;
  decimalSeparator: string;
  length?: number;
  decimalPlaces?: number;
}): string => {
  const maxLength =
    value.indexOf(decimalSeparator) > 0
      ? value.indexOf(decimalSeparator) + (decimalPlaces + 1)
      : length || Infinity;

  if (value.length < maxLength) {
    if (value.length === 0 && key === decimalSeparator) {
      // if the first key input is decimalSeparator (, or .)
      return '0.';
    } else if (
      value.indexOf(decimalSeparator) > 0 &&
      key === decimalSeparator
    ) {
      return value;
    } else return value + key;
  } else if (
    value.length === maxLength &&
    key === decimalSeparator &&
    !value.includes(decimalSeparator)
  ) {
    // eg. if value is 10000 and I want to input 10000.99
    return value + key;
  } else {
    // if integer, max length is 5, if float, max length is 8
    return key === decimalSeparator ? '0.' : key;
  }
};
