import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const PaymentButtonStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      flex: 1,
      height: 230,
      marginTop: 30,
    },
    groupTitle: {
      fontSize: 14,
      lineHeight: 20,
      textAlign: 'center',
      color: colors.textLight,
      fontFamily: theme.fonts.medium,
    },
    options: {
      marginTop: 20,
    },
    fixedOption: {
      marginTop: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    btnOption: {
      flex: 1,
      marginRight: 10,
    },
  });
};

export default PaymentButtonStyles;
