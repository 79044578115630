import React, { useEffect, useState, useCallback } from 'react';
import { View } from 'react-native';
import { Product, UpdateProductInput } from '@oolio-group/domain';
import { ProductModel } from '@oolio-group/dal';
import { useNotification } from '../../../../../hooks/Notification';
import { useTranslation } from '@oolio-group/localization';
import { useProducts } from '../../../../../hooks/app/products/useProducts';
import { PRODUCT_AVAILABILITY_FRAGMENT } from '../../../../../hooks/app/products/graphql';
import theme from '../../../../../common/default-theme';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../components/Office/Section/Section';
import SelectMultiple from '../../../../../components/Shared/Select/SelectMultiple';
import LoadingIndicator from '../../../../../components/Shared/Loaders/LoadingIndicator';
import {
  CUSTOM_CHANNELS_STORES_OPTIONS_QUERY,
  useAvailabilityOptions,
} from '../../../../../hooks/app/useAvailabilityOptions';

interface AvailabilityProps {
  productId: string;
}
export const AvailabilityProduct: React.FC<AvailabilityProps> = ({
  productId,
}) => {
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [product, setProduct] = useState({} as ProductModel);

  const {
    error: prodErr,
    products,
    updateProduct,
    loading: prodLoading,
  } = useProducts(productId, PRODUCT_AVAILABILITY_FRAGMENT);

  const error = prodErr;

  const { salesChannels, stores } = useAvailabilityOptions(
    CUSTOM_CHANNELS_STORES_OPTIONS_QUERY,
  );

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (productId && products && products[productId]) {
      const productData = products[productId] as Product;
      const productModal = {
        ...productData,
        salesChannels: productData.salesChannels.map(x => x.id),
        stores: productData.stores.map(x => x.id),
      } as unknown as ProductModel;
      setProduct(productModal);
    }
  }, [productId, products]);

  const onChange = useCallback((prop: string, value): void => {
    setProduct(form => {
      return {
        ...form,
        [prop]: value,
      };
    });
  }, []);

  const onSaveProduct = useCallback((): void => {
    const updateProductInput = {
      id: product.id,
      name: product.name,
      salesChannels: product.salesChannels,
      stores: product.stores,
    } as UpdateProductInput;

    updateProduct(updateProductInput);
  }, [product, updateProduct]);

  if (prodLoading) {
    return <LoadingIndicator />;
  }

  return (
    <ScreenLayout
      title={`${product.name || 'Product'} Availability | Oolio`}
      onSave={onSaveProduct}
    >
      <Section
        title={translate('productSettings.availability')}
        subtitle={translate('productSettings.availabilityDescription')}
      >
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-channels"
            title={translate('productSettings.channels')}
            options={salesChannels || []}
            selectedValues={product.salesChannels || []}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChange('salesChannels', value)}
          />
        </View>
        <View style={theme.forms.row}>
          <SelectMultiple
            testID="select-stores"
            title={translate('backOfficeUsers.stores')}
            options={stores || []}
            selectedValues={product.stores || []}
            containerStyle={theme.forms.inputFluid}
            onValueChange={value => onChange('stores', value)}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
