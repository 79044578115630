import BackgroundWorker from '../workers/BackgroundWorker';
import { makeVar } from '@apollo/client';
import {
  IntegrationApps,
  OrderEvent,
  KitchenDisplay,
  Reservation,
} from '@oolio-group/domain';

export interface AlertAndPrintTrigger {
  alert: boolean;
  print: boolean;
  app?: IntegrationApps;
}

export const workerInstanceVar = makeVar<BackgroundWorker | undefined>(
  undefined,
);
export const alertAndPrintTriggerVar = makeVar<AlertAndPrintTrigger>({
  alert: false,
  print: false,
});

export const trainingModeVar = makeVar<boolean>(false);
export const failedPrintJobsCountVar = makeVar<number>(0);
export const pendingOnlineOrdersCountVar = makeVar<number>(0);
export const ordersUpdatedViaSubscription = makeVar<string[]>([]);
export const ordersReceivedViaPollingAt = makeVar<number>(0);
export const isAdvanceDiscountEnabledVar = makeVar<boolean>(false);
export const docketPrintedVar = makeVar<OrderEvent | undefined>(undefined);
export const areProductsAvailabilityUpdatedViaSubscription =
  makeVar<boolean>(false);
export const isOolioPayRefundLoading = makeVar<boolean>(false);
export const autoCompletedOrdersVar = makeVar<string[]>([]);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subscriptionDeviceEvent = makeVar<any>(undefined);
export const forceUpdateVersionVar = makeVar<string>('');

// Reservations
export const isReservationsEnabledVar = makeVar<boolean>(false);
export const currentReservations = makeVar<Reservation[]>([]);
export const optimisticReservationsMap = makeVar<
  Record<string, { reservation: Partial<Reservation>; addedAt: number }>
>({});

export const kdsDevicesInStoreVar = makeVar<KitchenDisplay[] | undefined>(
  undefined,
);
export const restrictOrderItemModificationsVar = makeVar(false);
export const lastEnteredTareWeightVar = makeVar('');

export const isWaitlistsEnabledVar = makeVar(false);
