import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  CreateCustomerRequest,
  Customer,
  CustomerGroup,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { debounce } from 'lodash';
import ModalWrapper from '../../../../hooks/ModalWrapper';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { useCustomers } from '../../../../hooks/orders/useCustomers';
import { useExportCustomers } from '../../../../hooks/app/exportEntities/useExportCustomers';
import styles from './ManageCustomers.styles';
import theme from '../../../../common/default-theme';
import CustomerRow from './CustomerRow';
import Search from '../../../../components/Shared/Search/Search';
import Section from '../../../../components/Office/Section/Section';
import TreatPicker from '../../../../components/Shared/Select/Picker';
import CreateCustomerModal from './CreateCustomerModal/CreateCustomerModal';
import Pagination from '../../../../components/Office/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import ScreenLayout from '../../../../components/Office/ScreenLayout/ScreenLayout';
import CreateButton from '../../../../components/Office/CreateButton/CreateButton';
import { useOolioLoyalty } from '../../../../hooks/useOolioLoyalty';
import LoadingIndicator from '../../../../components/Shared/Loaders/LoadingIndicator';

const CUSTOMERS_PER_PAGE = 10;

const ManageCustomers: React.FC = () => {
  const [session] = useSession();
  const isFocused = useIsFocused();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { processCustomersExport, isExportEntityRunning } =
    useExportCustomers();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const [customerGroup, setCustomerGroup] = useState('');
  const [showCreateModal, setShowModal] = useState(false);

  const orgCountry = session.currentOrganization?.country as unknown as string;

  const GROUP_OPTIONS = [
    {
      label: translate('backOfficeCustomers.customerGroups.allCustomers'),
      value: CustomerGroup.ALL,
    },
    {
      label: translate('backOfficeCustomers.customerGroups.loyaltyMembers'),
      value: CustomerGroup.LOYALTY,
    },
    {
      label: translate('backOfficeCustomers.customerGroups.regular'),
      value: CustomerGroup.REGULAR,
    },
  ];

  const {
    error,
    loading,
    paginatedCustomers: customers,
    onFetchMore,
    addNewCustomer,
    getPaginatedCustomers,
  } = useCustomers();

  const { isLoyaltyEnabled: isOolioLoyaltyEnabled } = useOolioLoyalty();

  const pageItems = useMemo(() => {
    return customers?.slice(
      (currentPage - 1) * CUSTOMERS_PER_PAGE,
      currentPage * CUSTOMERS_PER_PAGE,
    );
  }, [customers, currentPage]);

  const debouncedSearch = React.useRef(
    debounce(async (searchValue: string) => {
      setSearchString(searchValue);
    }, 300),
  ).current;

  useEffect(() => {
    if (searchString.length > 0 && searchString.length <= 2) return; // Skip short searches
    getPaginatedCustomers(searchString, customerGroup as CustomerGroup);
    setCurrentPage(1);
  }, [isFocused, getPaginatedCustomers, searchString, customerGroup]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const onSearchTextChange: (value: string) => void = useCallback(
    (value: string) => {
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const createCustomer = useCallback(
    async (customerInfo: Partial<CreateCustomerRequest>) => {
      const createdCustomer = await addNewCustomer(customerInfo);
      if (createdCustomer) {
        setShowModal(false);
        setSearchString('');
        getPaginatedCustomers('');
        setCurrentPage(1);
      }
    },
    [addNewCustomer, getPaginatedCustomers],
  );

  const onPressCreate = useCallback(() => {
    setShowModal(true);
  }, []);

  const onPressCustomerRow: (customer: Customer) => void = useCallback(
    (customer: Customer) => {
      navigation &&
        navigation.navigate('ManageCustomersTab', {
          customer: customer,
        });
    },
    [navigation],
  );

  const exportDataToCSV = useCallback(async () => {
    processCustomersExport();
  }, [processCustomersExport]);

  const onPageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      // calculate to fetch more
      const totalPagesRequired = Math.ceil(
        customers.length / CUSTOMERS_PER_PAGE,
      );
      if (page >= totalPagesRequired - 1) {
        onFetchMore();
      }
    },
    [customers.length, onFetchMore],
  );

  const renderCustomerItems = useMemo(() => {
    if (loading && !showCreateModal)
      return <LoadingIndicator containerStyles={styles.loading} />;

    return (
      <View>
        {pageItems.map((customer: Customer, i: number) => {
          return (
            <CustomerRow
              key={i}
              customer={customer}
              orgCountry={orgCountry}
              showPoints={isOolioLoyaltyEnabled}
              onPress={() => onPressCustomerRow(customer)}
            />
          );
        })}
        <Pagination
          page={currentPage}
          onPageChange={onPageChange}
          dataLength={customers.length}
          pageLength={pageItems.length}
          entityName={translate('navigation.customers')}
        />
      </View>
    );
  }, [
    loading,
    showCreateModal,
    pageItems,
    currentPage,
    onPageChange,
    customers.length,
    translate,
    orgCountry,
    isOolioLoyaltyEnabled,
    onPressCustomerRow,
  ]);

  return (
    <ScreenLayout
      hideFooter={true}
      title={translate('navigation.generalSettingsPageTitle', {
        appName: translate('appName'),
      })}
    >
      <Section layoutWidth="large">
        <View style={styles.filters}>
          {isOolioLoyaltyEnabled && (
            <TreatPicker
              testID="select-customersType"
              options={GROUP_OPTIONS}
              containerStyle={styles.dropdown}
              onValueChange={value => setCustomerGroup(value)}
            />
          )}
          <Search
            testID="search-customers"
            onChangeText={onSearchTextChange}
            value={searchString}
            placeholder={translate('backOfficeCustomers.search')}
            // eslint-disable-next-line react-native/no-inline-styles
            containerStyle={{ flex: 1 }}
          />
          <CreateButton onPress={onPressCreate} />
          <ButtonIcon
            testID="download-csv"
            type="neutral"
            size={44}
            icon="cloud-download"
            onPress={() => exportDataToCSV()}
            disabled={isExportEntityRunning}
          />
        </View>
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.cellName]}>
              {translate('form.name')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellPhone]}>
              {translate('form.phone')}
            </Text>
            <Text style={[theme.tables.headerText, styles.cellEmail]}>
              {translate('form.email')}
            </Text>
            {isOolioLoyaltyEnabled ? (
              <Text style={[theme.tables.headerText, styles.headerPoints]}>
                Points
              </Text>
            ) : (
              <></>
            )}
          </View>
        </View>
        {renderCustomerItems}
      </Section>
      <ModalWrapper isVisible={showCreateModal}>
        <CreateCustomerModal
          loading={loading}
          onSubmit={createCustomer}
          onDismiss={() => setShowModal(false)}
        />
      </ModalWrapper>
    </ScreenLayout>
  );
};

export default ManageCustomers;
