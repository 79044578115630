import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const ReservationsTableStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    filters: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 8,
      margin: 20,
      marginBottom: 0,
    },
    search: {
      flex: 1,
    },
    date: {
      width: 200,
      zIndex: 4,
    },
    table: {
      margin: 20,
      rowGap: 8,
    },
    tableHeader: {
      height: 38,
      columnGap: 20,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
      paddingLeft: 68,
    },
    tableBody: {
      rowGap: 4,
    },
    tableRow: {
      padding: 12,
      paddingLeft: 20,
      columnGap: 20,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    logo: {
      width: 28,
      height: 28,
      borderRadius: 4,
    },
    colGuests: {
      width: 60,
      textAlign: 'center',
    },
    colTime: {
      width: 160,
      rowGap: 4,
    },
    colTable: {
      width: 100,
      rowGap: 4,
    },
    colCustomer: {
      width: 140,
      rowGap: 4,
    },
    colNotes: {
      flex: 1,
      rowGap: 4,
    },
    colStatus: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    title: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    subtitle: {
      fontSize: 12,
      lineHeight: 16,
      color: colors.textLight,
      fontFamily: theme.fonts.regular,
      fontVariant: ['tabular-nums'],
    },
  });
};

export default ReservationsTableStyles;
