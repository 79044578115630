import { StyleSheet } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme, { ACTIONS_BAR_HEIGHT } from '../../../../common/default-theme';

const BookingFilterStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    backdrop: {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    container: {
      rowGap: 8,
      width: 220,
      ...theme.shadow.light,
      position: 'absolute',
      backgroundColor: colors.elevation0,
      top: ACTIONS_BAR_HEIGHT,
      left: 0,
      padding: 10,
    },
    content: {
      flex: 1,
    },
    section: {
      rowGap: 4,
      marginBottom: 10,
    },
    sectionLabel: {
      fontSize: 14,
      lineHeight: 20,
      paddingHorizontal: 10,
      color: colors.textLight,
      fontFamily: theme.fonts.medium,
    },
    filterTitleContainer: {
      marginVertical: 12,
    },
    filterRow: {
      alignItems: 'center',
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
    },
    filterRowContent: {
      height: 44,
      width: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    specificDate: {
      paddingBottom: 12,
      alignSelf: 'flex-start',
    },
    specificDateText: {
      fontSize: 12,
      color: colors.textLight,
    },
    grid: {
      gap: 4,
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    gridItem: {
      width: 64,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
    },
    filterText: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    countContainer: {
      height: 20,
      width: 20,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation2,
    },
    countText: {
      fontSize: 12,
      lineHeight: 14,
      color: colors.text,
      fontFamily: theme.fonts.semibold,
    },
  });
};

export default BookingFilterStyles;
