import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import CartActionStyles from './CartActions.styles';
import Icon from '../../../Icon/Icon';
import IconCoin from '../../../../common/assets/Coin';
import IconSplit from '../../../../common/assets/Split';
import IconPlusMinus from '../../../../common/assets/PlusMinus';

export interface CartAction {
  icon: string;
  iconColor: string;
  testID: string;
  disabled?: boolean;
  action: () => void;
}

interface CartActionProps {
  actions: CartAction[];
}

const CartActions: React.FC<CartActionProps> = ({ actions }) => {
  const { colors } = useTheme();
  const styles = CartActionStyles();

  return (
    <View testID="cart-actions" style={styles.container}>
      {actions.map((action, i) => {
        const iconColor = action.disabled
          ? colors.disclosure
          : action.iconColor;

        return (
          <TouchableOpacity
            key={i}
            testID={action.testID}
            disabled={action.disabled}
            onPress={action.action}
            style={styles.button}
          >
            {action.icon === 'plus-minus' ? (
              <IconPlusMinus color={iconColor} />
            ) : action.icon === 'coin' ? (
              <IconCoin color={iconColor} />
            ) : action.icon === 'split' ? (
              <IconSplit color={iconColor} />
            ) : (
              <Icon name={action.icon} color={iconColor} size={20} />
            )}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default CartActions;
