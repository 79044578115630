import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { Money, MoneyEventType, MoneyMovement } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { format } from 'date-fns';
import { capitalCase } from 'change-case';
import ManageCashStyles from '../ManageCash.styles';
import theme from '../../../../common/default-theme';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import Empty from '../../../../components/Shared/Empty/Empty';
import { useTheme } from '../../../../hooks/useTheme';

export interface ManageCashTableProps {
  data: MoneyMovement[];
}

const ManageCashTable: React.FC<ManageCashTableProps> = ({
  data,
}: ManageCashTableProps) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { printMoneyMovementReceipt } = usePrinting();

  const { colors } = useTheme();
  const styles = ManageCashStyles();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPressPrint = useCallback(
    (event: MoneyMovement) => {
      analyticsService.capture('manage_cash', {
        event: 'Print Receipt',
      });
      printMoneyMovementReceipt(event);
    },
    [printMoneyMovementReceipt],
  );

  const onPressInfo = useCallback(
    (e: MoneyMovement) => {
      showModal(
        <ConfirmationDialog
          hideCancel
          type="neutral"
          title={`${capitalCase(e.eventType)} (${formatCurrency(
            e.amount.amount,
          )})`}
          message={`${e.reason.name} by ${e.user.name} on ${format(
            +e.timestamp,
            'MMMM d, yyyy h:mm a',
          )}\n\n${e.notes}`}
          confirmLabel={translate('button.dismiss')}
          onConfirm={() => {
            closeModal();
          }}
        />,
      );
    },
    [closeModal, formatCurrency, showModal, translate],
  );

  const pageItems = useMemo(() => {
    return data?.slice((currentPage - 1) * 10, currentPage * 10);
  }, [data, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellType, theme.tables.headerText]}>
          {translate('moneyMovements.event')}
        </Text>
        <Text style={[styles.cellDate, theme.tables.headerText]}>
          {translate('moneyMovements.timestamp')}
        </Text>
        <Text style={[styles.cellUser, theme.tables.headerText]}>
          {translate('moneyMovements.user')}
        </Text>
        <Text style={[styles.cellReason, theme.tables.headerText]}>
          {translate('moneyMovements.reason')}
        </Text>
        <Text style={[styles.cellNote, theme.tables.headerText]}>
          {translate('moneyMovements.note')}
        </Text>
        <Text style={[styles.headerAmount, theme.tables.headerText]}>
          {translate('moneyMovements.amount')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((event: MoneyMovement, i) => (
            <View key={i} testID="row-moneyMovement" style={styles.tableRow}>
              <Sticker
                testID="eventType"
                themed
                type={
                  event.eventType === MoneyEventType.MONEY_IN
                    ? 'positiveLight'
                    : 'negativeLight'
                }
                label={
                  event.eventType === MoneyEventType.MONEY_IN ? 'IN' : 'OUT'
                }
                containerStyle={styles.cellType}
              />
              <Text testID="event-time" style={styles.cellDate}>
                {format(+event.timestamp, 'dd/MM hh:mm a')}
              </Text>
              <Text
                testID="event-user"
                numberOfLines={1}
                style={styles.cellUser}
              >
                {event.user.name}
              </Text>
              <Text
                testID="event-reason"
                numberOfLines={1}
                style={styles.cellReason}
              >
                {event.reason.name}
              </Text>
              <Text
                testID="event-note"
                numberOfLines={1}
                style={[
                  styles.cellNote,
                  {
                    color: event.notes ? colors.text : colors.disclosure,
                  },
                ]}
              >
                {event.notes || 'No note added.'}
              </Text>
              <Text testID="event-amount" style={styles.cellAmount}>
                {formatCurrency(+(event.amount as Money).amount || 0)}
              </Text>
              <ButtonIcon
                size={34}
                themed
                icon="info-circle"
                type="neutralLight"
                testID="btn-showInfo"
                onPress={() => onPressInfo(event)}
                containerStyle={styles.cellInfo}
              />
              <ButtonIcon
                size={34}
                themed
                icon="print"
                type="neutralLight"
                testID="btn-print"
                onPress={() => onPressPrint(event)}
                containerStyle={styles.cellPrint}
              />
            </View>
          ))
        ) : (
          <Empty themed label={translate('moneyMovements.emptyMovements')} />
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={currentPage}
          onPageChange={setCurrentPage}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default ManageCashTable;
