import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const PickerStyles = ({ themed = false, secondary = false }) => {
  const { colors } = useTheme();

  const getBgColor = useCallback(() => {
    if (themed) {
      return secondary ? colors.elevation1 : colors.elevation0;
    } else {
      return secondary ? theme.colors.grey1 : theme.colors.light;
    }
  }, [colors, secondary, themed]);

  return StyleSheet.create({
    container: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
      borderWidth: 1,
      borderRadius: theme.radius.s,
      borderColor: themed ? colors.borderColor : theme.colors.grey3,
      overflow: 'hidden',
    },
    pickerStyle: {
      width: '100%',
      height: '100%',
      borderWidth: 0,
      paddingLeft: 8,
      paddingRight: secondary ? 8 : 12,
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.regular,
      color: themed ? colors.text : theme.colors.black1,
      backgroundColor: getBgColor(),
    },
    secondaryContainer: {
      height: 38,
      marginHorizontal: 2,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      overflow: 'hidden',
    },
  });
};

export default PickerStyles;
