import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Text,
  View,
  ViewStyle,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import keyBy from 'lodash/keyBy';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import theme from '../../../common/default-theme';
import SelectStyles from './Select.styles';
import { Icon } from '../../Icon/Icon';
import Search from '../Search/Search';
import { useTheme } from '../../../hooks/useTheme';

export interface Option {
  value: string;
  label: string;
}

interface Props {
  title?: string;
  testID: string;
  value?: string | undefined;
  placeholder?: string;
  errorMessage?: string;
  containerStyle?: ViewStyle | ViewStyle[];
  options: Option[];
  selectedValue?: string;
  editable?: boolean;
  onAddOption?: (name: string) => void;
  onValueChange?: (itemValue: string, itemPosition: number) => void;
  prefixLabel?: string;
  isCreate?: boolean;
  hideSearch?: boolean;
  themed?: boolean;
}

const Select: React.FC<Props> = ({
  title,
  testID,
  editable = true,
  placeholder,
  containerStyle,
  options,
  selectedValue,
  onAddOption,
  onValueChange,
  prefixLabel,
  isCreate = true,
  hideSearch = true,
  themed = false,
}) => {
  const isOpening = useRef(false);
  const isAddingOption = useRef(false);

  const { colors } = useTheme();
  const styles = SelectStyles({ themed, disabled: !editable });

  const [searchString, setSearchString] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  const valuesDictionary = useMemo(() => {
    return keyBy(options, 'value');
  }, [options]);

  const onToggleOptions = useCallback((): void => {
    if (editable && !isOpening.current) {
      setShowOptions(current => {
        if (!current) {
          isOpening.current = true;
        } else {
          isOpening.current = false;
        }
        return !current;
      });
    }
  }, [editable]);

  const onRequestClosePopover = useCallback(() => {
    setShowOptions(false);
    setSearchString('');
  }, []);

  const onPressOption = (value: string, index: number) => {
    onValueChange && onValueChange(value, index);
    setShowOptions(false);
  };

  // Searchable

  const searchResults = useMemo(() => {
    if (searchString !== '' && options && options.length) {
      const searchLabel = options.filter((eachValue: Option) =>
        eachValue.label.toLowerCase().includes(searchString.toLowerCase()),
      );
      return searchLabel;
    } else {
      return options;
    }
  }, [searchString, options]);

  const filteredOptions = useMemo(() => {
    return searchResults;
  }, [searchResults]);

  const onSearchTextChange = useCallback((value): void => {
    setSearchString(value);
  }, []);

  const addOption = useCallback((): void => {
    if (searchString && onAddOption) {
      onAddOption(searchString);
    }
  }, [onAddOption, searchString]);

  const onPressAddOption = useCallback(() => {
    isAddingOption.current = true;
    setShowOptions(false);
  }, []);

  const touchable = useRef(null);

  const onOpenComplete = useCallback(() => {
    isAddingOption.current = false;
    isOpening.current = false;
  }, []);

  const onCloseComplete = useCallback(() => {
    if (isAddingOption.current) {
      addOption();
    }
  }, [addOption, isAddingOption]);

  return (
    <View style={containerStyle} testID={testID}>
      {!!title && <Text style={styles.inputTitleText}>{title}</Text>}
      <View>
        <TouchableOpacity
          ref={touchable}
          onPress={onToggleOptions}
          disabled={!editable}
          style={styles.inputContainer}
        >
          {prefixLabel ? (
            <View
              style={styles.prefixLabelContainerStyle}
              testID="prefix-label"
            >
              <Text style={styles.prefixLabelTextStyle}>{prefixLabel}</Text>
            </View>
          ) : null}
          <Text style={styles.valueText}>
            {(selectedValue &&
              valuesDictionary.hasOwnProperty(selectedValue) &&
              valuesDictionary[selectedValue]?.label) ||
              selectedValue ||
              placeholder ||
              'Select...'}
          </Text>
          {editable && (
            <View style={styles.accessory}>
              <Icon
                size={20}
                color={themed ? colors.text : theme.colors.dark}
                name={showOptions ? 'angle-up' : 'angle-down'}
              />
            </View>
          )}
        </TouchableOpacity>
      </View>
      <Popover
        isVisible={showOptions}
        placement={PopoverPlacement.AUTO}
        onRequestClose={onRequestClosePopover}
        from={touchable}
        backgroundStyle={styles.bgStyle}
        popoverStyle={styles.optionsContainer}
        onOpenComplete={onOpenComplete}
        onCloseComplete={onCloseComplete}
        animationConfig={{ duration: 200 }}
      >
        <View style={styles.optionsPopup}>
          {!hideSearch && (
            <Search
              testID="search-option"
              placeholder="Search..."
              containerStyle={styles.search}
              onChangeText={onSearchTextChange}
            />
          )}
          <ScrollView showsVerticalScrollIndicator={false}>
            {filteredOptions.map((option, i: number) => {
              const isSelected = selectedValue === option.value;
              return (
                <TouchableOpacity
                  key={i}
                  style={styles.option}
                  testID={`option-${option.value}`}
                  onPress={() => onPressOption(option.value, i)}
                >
                  <Text style={styles.optionValue}>{option.label}</Text>
                  <Icon
                    size={20}
                    name={isSelected ? 'check' : 'angle-right'}
                    color={
                      isSelected
                        ? theme.colors.states.positive
                        : themed
                        ? colors.disclosure
                        : theme.colors.grey5
                    }
                  />
                </TouchableOpacity>
              );
            })}
          </ScrollView>
          {onAddOption && !!searchString && isCreate && (
            <TouchableOpacity
              style={styles.optionCreate}
              onPress={() => onPressAddOption()}
            >
              <Text
                style={styles.optionCreateText}
              >{`Create '${searchString}'`}</Text>
              <Icon name="plus" size={20} color={theme.colors.green} />
            </TouchableOpacity>
          )}
        </View>
      </Popover>
    </View>
  );
};

export default Select;
