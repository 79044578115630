import React, { useEffect, useState } from 'react';
import { LayoutChangeEvent, View, ViewStyle } from 'react-native';
import { maxBy, keyBy, sortBy, isNumber } from 'lodash';
import PaginationTile from './Pagination/PaginationTile';
import { ItemSize } from './GridItem/GridItem';

interface MenuGridItemType {
  priority?: number;
}

export type GridSize = {
  rows: number;
  columns: number;
};

interface Props<DataType extends MenuGridItemType> {
  testID?: string;
  gridSize: GridSize;
  data: Array<DataType>;
  ignorePositionByPriority?: boolean;
  renderItem: (
    index: number,
    size: {
      width: number;
      height: number;
    },
    item?: DataType,
  ) => React.ReactNode;
  containerStyle?: ViewStyle;
}

const MenuGrid = <DataType extends MenuGridItemType>({
  testID,
  gridSize,
  data,
  ignorePositionByPriority,
  renderItem,
  containerStyle,
}: Props<DataType>) => {
  const [gridWidth, setGridWidth] = useState(0);
  const [gridHeight, setGridHeight] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const { rows, columns } = gridSize;

  const paginate = data.length > rows * columns;
  const showPagination = paginate || (data || [])?.length > rows * columns;

  // excluding the pagination button cell
  const itemsPerPage = rows * columns - (showPagination ? 1 : 0);
  const paginationStartIndex = (currentPage - 1) * itemsPerPage;
  const paginationEndIndex = currentPage * itemsPerPage;

  const formattedData = ignorePositionByPriority
    ? data.map((item, index) => ({ ...item, priority: index }))
    : checkDuplicatedPriority(data);

  const maxPriority = maxBy(formattedData, d => d.priority)?.priority || 0;

  const numOfRequiredPage = Math.ceil((maxPriority + 1) / itemsPerPage);
  const totalItems = numOfRequiredPage * itemsPerPage;
  const itemPriorityMaps = keyBy(formattedData, 'priority');

  const updatedData = Array(totalItems)
    .fill(null)
    .map((_, index) => itemPriorityMaps[index]);

  const paginatedData = updatedData.slice(
    paginationStartIndex,
    paginationEndIndex,
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const handleLayout = (event: LayoutChangeEvent) => {
    setGridHeight(event.nativeEvent.layout.height);
    setGridWidth(event.nativeEvent.layout.width);
  };

  const size: ItemSize = {
    width: gridWidth ? (gridWidth - (columns - 1) * 4) / columns : 0,
    height: gridHeight ? (gridHeight - 4 * (rows - 1)) / rows : 0,
  };

  return (
    <View testID={testID} style={containerStyle} onLayout={handleLayout}>
      {paginatedData.map((item, index) => renderItem(index, size, item))}
      {showPagination ? (
        <PaginationTile
          currentPage={currentPage}
          totalPages={numOfRequiredPage}
          onPressNext={() => setCurrentPage(prev => prev + 1)}
          onPressBack={() => setCurrentPage(prev => prev - 1)}
          containerStyle={{
            width: size.width,
            height: size.height,
          }}
        />
      ) : null}
    </View>
  );
};

export default MenuGrid;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkDuplicatedPriority = (data: any[]) => {
  if (data?.length <= 0) return [];
  const sortedData = sortBy(data, item => item?.priority);
  let duplicated = false;
  for (let index = 0; index < sortedData.length; index++) {
    if (
      !isNumber(sortedData[index]?.priority) ||
      sortedData[index]?.priority === sortedData[index + 1]?.priority
    ) {
      duplicated = true;
    }
  }

  if (duplicated) {
    return sortedData.map((item, index) => ({ ...item, priority: index }));
  }
  return sortedData;
};
