import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartKeypadStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    input: {
      height: 60,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 12,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
      marginBottom: 4,
    },
    button: {
      width: 38,
      height: 38,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
    },
    inputText: {
      fontSize: 26,
      lineHeight: 30,
      fontFamily: theme.fonts.semibold,
      color: theme.colors.states.focus,
    },
    numpad: {
      flex: 1,
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    keypad: {
      height: 172,
      flexDirection: 'row',
    },
    numpadText: {
      fontSize: 16,
      lineHeight: 16,
      color: colors.text,
      fontFamily: theme.fonts.semibold,
    },
    keyNumber: {
      height: 40,
      marginRight: 4,
      marginBottom: 4,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    actions: {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    keyAction: {
      width: 74,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
  });
};

export default CartKeypadStyles;
