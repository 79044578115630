import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const PaginationStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
    },
    contextView: {
      flex: 1,
      height: 38,
      paddingHorizontal: 12,
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    contextText: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.textLight,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.regular,
    },
    btnPagination: {
      width: 44,
      height: 38,
      marginLeft: 6,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
  });
};

export default PaginationStyles;
