import React, { useCallback, useState, useMemo } from 'react';
import { Text, View } from 'react-native';
import ModalTitle from '../../POS/Modals/ModalTitle/ModalTitle';
import KeypadDisplay from '../../POS/Modals/Keypad/KeypadDisplay/KeypadDisplay';
import NumberPad from '../../POS/Modals/Keypad/NumberPad/NumberPad';
import { useCurrency } from '@oolio-group/localization';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';
import { addToValue } from '../../../utils/keypadHelper';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import { Product } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import styles from './EnterWeight.styles';
import { translate } from '@oolio-group/localization';
import { lastEnteredTareWeightVar } from '../../../state/cache';
import { useNotification } from '../../../hooks/Notification';

export interface EnterWeightProps {
  onSubmit: (qty: number, tareWeight?: number) => void;
  product: Product;
  unitPrice?: number;
}

const WeightInputSection: React.FC<{
  title: string;
  inputValue: string;
  onPressClear: () => void;
  onPressKey: (key: string) => void;
}> = ({ title, inputValue, onPressClear, onPressKey }) => {
  const { decimalSeparator } = useCurrency();

  const keypadButtons = useMemo(
    () => [
      { value: '1' },
      { value: '2' },
      { value: '3' },
      { value: '4' },
      { value: '5' },
      { value: '6' },
      { value: '7' },
      { value: '8' },
      { value: '9' },
      { value: '0' },
      { value: decimalSeparator },
    ],
    [decimalSeparator],
  );

  return (
    <View style={styles.column}>
      <Text style={styles.textCenter}>{title}</Text>
      <View style={styles.inputWrapper}>
        <KeypadDisplay displayValue={inputValue} onPressClear={onPressClear} />
        <NumberPad
          keypadButtons={keypadButtons}
          zeroButtonWidth={185}
          onPressKey={onPressKey}
        />
      </View>
    </View>
  );
};

export const EnterWeight: React.FC<EnterWeightProps> = ({
  onSubmit,
  product,
  unitPrice = 0,
}) => {
  const { showNotification } = useNotification();
  const { closeModal } = useModal();
  const { decimalSeparator } = useCurrency();
  const [grossInput, setGrossInput] = useState(
    product?.measuredBy?.defaultSize?.toFixed(3) || '',
  );
  const [tareInput, setTareInput] = useState(lastEnteredTareWeightVar());

  const handleKeyPress = useCallback(
    (setInput: React.Dispatch<React.SetStateAction<string>>) =>
      (key: string) => {
        setInput(prevValue =>
          addToValue({
            value: prevValue,
            key,
            decimalSeparator,
            length: MAX_KEYPAD_INPUT,
            decimalPlaces: 3,
          }),
        );
      },
    [decimalSeparator],
  );

  const handleConfirm = useCallback(
    (netWeight: number, tareWeight: number) => {
      if (netWeight <= 0) {
        return showNotification({
          error: true,
          message: translate('order.netWeightWarning'),
        });
      }
      lastEnteredTareWeightVar(`${tareWeight}`);
      onSubmit(netWeight, tareWeight);
    },
    [onSubmit, showNotification],
  );

  const grossWeight = Number(grossInput);
  const tareWeight = Number(tareInput);
  const netWeight = parseFloat((grossWeight - tareWeight).toFixed(3));
  const units = product.measuredBy.units.toLowerCase();
  const pricePerUnit = (unitPrice / product.measuredBy.defaultSize).toFixed(2);

  return (
    <View style={styles.container}>
      <ModalTitle
        title={translate('order.enterWeight', { productName: product.name })}
        onDismiss={closeModal}
      />
      <View style={styles.content}>
        <View style={styles.row}>
          <WeightInputSection
            title={translate('order.enterGrossWeight')}
            inputValue={`${grossWeight.toFixed(3)} ${units}`}
            onPressClear={() => setGrossInput('')}
            onPressKey={handleKeyPress(setGrossInput)}
          />
          <View style={styles.divider} />
          <WeightInputSection
            title={translate('order.enterTareWeight')}
            inputValue={`${tareWeight.toFixed(3)} ${units}`}
            onPressClear={() => setTareInput('')}
            onPressKey={handleKeyPress(setTareInput)}
          />
        </View>

        <TreatButton
          testID="btn-confirm"
          height={50}
          type="positive"
          label={`${translate('order.confirmWeight')} (${netWeight}${units})`}
          onPress={() => handleConfirm(netWeight, tareWeight)}
          containerStyle={styles.paddingHorizontal}
        />
        <Text style={styles.summaryText}>
          {netWeight} {units} NET ({grossWeight}
          {units} GROSS, {tareWeight}
          {units} TARE) @ ${pricePerUnit}/{units}
        </Text>
      </View>
    </View>
  );
};
