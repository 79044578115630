import palette from './colours.json';

export const themes = {
  light: {
    ...palette,
    // Interface Colors
    background: palette.grey2,
    elevation0: palette.white,
    elevation1: palette.grey1,
    elevation2: palette.grey2,
    // Typography
    text: palette.black1,
    textBody: palette.black2,
    textLight: palette.grey6,
    textPrimary: palette.primary,
    textInverted: palette.grey3,
    textBodyInverted: palette.grey4,
    textLightInverted: palette.grey5,
    // Iconography
    disclosure: palette.grey5,
    // Container
    borderColor: palette.grey3,
    switchBg: palette.grey4,
    popupBg: palette.white,
    popupHover: palette.grey1,
    // Specific
    orderNote: palette.amberLight,
    emptyTile: palette.grey3,
  },
  dark: {
    ...palette,
    // Interface Colors
    background: palette.tertiary1,
    elevation0: palette.tertiary2,
    elevation1: palette.tertiary3,
    elevation2: palette.tertiary4,
    // Typography
    text: palette.grey3,
    textBody: palette.grey4,
    textLight: palette.grey5,
    textPrimary: '#b388ff',
    textInverted: palette.black1,
    textBodyInverted: palette.black2,
    textLightInverted: palette.grey6,
    // Iconography
    disclosure: palette.grey6,
    // Light Palette
    amberLight: '#4b3c10',
    amberLightest: '#332a10',
    blueLight: '#16344c',
    blueLightest: '#142736',
    cyanLight: '#0e3b41',
    cyanLightest: '#102a2d',
    deepOrangeLight: '#4b2316',
    deepOrangeLightest: '#331c14',
    deepPurple: '#7c4dff',
    deepPurpleDark: '#651fff',
    deepPurpleLight: '#2c1f44',
    deepPurpleLightest: '#231a32',
    greenLight: '#203821',
    greenLightest: '#1a281a',
    indigoLight: '#1d2139',
    indigoLightest: '#191d30',
    lightBlueLight: '#0f3648',
    lightBlueLightest: '#102732',
    lightGreenLight: '#303d20',
    lightGreenLightest: '#232b1a',
    limeLight: '#40431c',
    limeLightest: '#2c2e17',
    orangeLight: '#54370d',
    orangeLightest: '#3d2a0f',
    pinkLight: '#461526',
    pinkLightest: '#30131d',
    purpleLight: '#331738',
    purpleLightest: '#251528',
    redLight: '#57211d',
    redLightest: '#3f1b19',
    tealLight: '#0e322e',
    tealLightest: '#0f2422',
    yellowLight: '#4b461c',
    yellowLightest: '#0f2422',
    // Brand
    primary: '#b388ff',
    primaryLight: '#2c1f44',
    primaryLightest: '#231a32',
    accentLight: '#203455',
    // Container
    borderColor: palette.black2,
    switchBg: palette.tertiary4,
    popupBg: palette.tertiary2,
    popupHover: palette.tertiary3,
    // Specific
    orderNote: '#332a10',
    emptyTile: palette.tertiary2,
  },
};

export type themeType = typeof themes.light;
