import { StyleSheet } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';

const PanelTitleStyles = (themed = false) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 8,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : colors.white,
    },
    btnClose: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      textAlign: 'center',
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
      color: themed ? colors.text : colors.black1,
    },
    sticker: {
      marginRight: 8,
    },
    spacer: {
      width: 44,
      height: 44,
    },
  });
};

export default PanelTitleStyles;
