import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import theme from '../default-theme';

const IconSplit = ({ size = 20, color = theme.colors.black }) => {
  return (
    <Svg width={size * 1.1} height={size} viewBox="0 0 22 20">
      <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <G transform="translate(-101, -12)" fill={color}>
          <G transform="translate(76, 0)">
            <Path d="M46.9219557,21.62 C46.8743375,21.4972487 46.802937,21.3851037 46.7118509,21.29 L44.2106037,18.79 C43.9568185,18.5363414 43.5869187,18.4372765 43.2402418,18.530122 C42.8935648,18.6229674 42.6227793,18.8936179 42.5298875,19.240122 C42.4369957,19.586626 42.5361101,19.9563414 42.7898952,20.21 L43.5902943,21 L39.9990022,21 C39.4464419,21 38.9985033,21.4477153 38.9985033,22 C38.9985033,22.5522847 39.4464419,23 39.9990022,23 L43.5902943,23 L42.7898952,23.79 C42.6004881,23.9777666 42.4939493,24.2333625 42.4939493,24.5 C42.4939493,24.7666375 42.6004881,25.0222334 42.7898952,25.21 C42.9777555,25.3993127 43.2334789,25.5057983 43.5002494,25.5057983 C43.76702,25.5057983 44.0227434,25.3993127 44.2106037,25.21 L46.7118509,22.71 C46.802937,22.6148963 46.8743375,22.5027513 46.9219557,22.38 C47.0220236,22.1365386 47.0220236,21.8634614 46.9219557,21.62 Z M31.9980044,21 L28.4067123,21 L29.2071114,20.21 C29.5994292,19.8178778 29.5994292,19.1821222 29.2071114,18.79 C28.8147936,18.3978778 28.1787208,18.3978778 27.7864029,18.79 L25.2851557,21.29 C25.1940697,21.3851037 25.1226691,21.4972487 25.0750509,21.62 C24.974983,21.8634614 24.974983,22.1365386 25.0750509,22.38 C25.1226691,22.5027513 25.1940697,22.6148963 25.2851557,22.71 L27.7864029,25.21 C27.9742632,25.3993127 28.2299866,25.5057983 28.4967572,25.5057983 C28.7635277,25.5057983 29.0192511,25.3993127 29.2071114,25.21 C29.3965185,25.0222334 29.5030573,24.7666375 29.5030573,24.5 C29.5030573,24.2333625 29.3965185,23.9777666 29.2071114,23.79 L28.4067123,23 L31.9980044,23 C32.5505647,23 32.9985033,22.5522847 32.9985033,22 C32.9985033,21.4477153 32.5505647,21 31.9980044,21 Z M35.9985033,12 C36.5507881,12 36.9985033,12.4477153 36.9985033,13 L36.9985033,31 C36.9985033,31.5522847 36.5507881,32 35.9985033,32 C35.4462186,32 34.9985033,31.5522847 34.9985033,31 L34.9985033,13 C34.9985033,12.4477153 35.4462186,12 35.9985033,12 Z"></Path>
          </G>
        </G>
      </G>
    </Svg>
  );
};

export default IconSplit;
