import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';
import { useTheme } from '../../../../hooks/useTheme';

const CartSeatsStyles = () => {
  const { colors } = useTheme();

  const btn: ViewStyle = {
    flexGrow: 1,
    height: 30,
    maxWidth: 55,
    marginBottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.s,
    borderWidth: 1,
  };

  const text: TextStyle = {
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: theme.fonts.semibold,
    fontVariant: ['tabular-nums'],
  };

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      marginBottom: 8,
      paddingBottom: 4,
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    btn: {
      ...btn,
      borderColor: colors.borderColor,
      backgroundColor: colors.elevation2,
    },
    btnSelected: {
      ...btn,
      borderColor: colors.accent,
      backgroundColor: colors.accent,
    },
    btnText: {
      ...text,
      color: colors.text,
    },
    btnTextSelected: {
      ...text,
      color: colors.white,
    },
    btnAdd: {
      marginLeft: 4,
      borderWidth: 0,
      backgroundColor: colors.greenLight,
    },
  });
};

export default CartSeatsStyles;
