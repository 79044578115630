import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  Table,
  OrderType,
  AllergensKey,
  OrderTypeCode,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { extractCounter } from '../../../../hooks/orders/useOrderNumber';
import { useTheme } from '../../../../hooks/useTheme';
import CartHeaderStyles from './CartHeader.styles';
import Icon from '../../../Icon/Icon';
import Sticker from '../../../Shared/Sticker/Sticker';

export interface CartHeaderProps {
  table?: Table;
  orderNumber: string;
  tableNumber?: string;
  sectionName?: string;
  orderType?: OrderType;
  isAllergensEnabled?: boolean;
  allergens?: AllergensKey[];
  onShowAllergens?: () => void;
  onSelectOrderType?: () => void;
  onSelectTableNumber?: () => void;
}

const CartHeader: React.FC<CartHeaderProps> = ({
  orderType,
  orderNumber,
  tableNumber,
  allergens,
  isAllergensEnabled,
  onShowAllergens,
  onSelectOrderType,
  onSelectTableNumber,
}) => {
  const { colors } = useTheme();
  const styles = CartHeaderStyles();
  const { translate } = useTranslation();

  const isDineInOrder = orderType && orderType.code === OrderTypeCode.DINE_IN;

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-changeOrderType"
        style={styles.orderType}
        onPress={onSelectOrderType}
      >
        <Sticker
          testID="sticker-counter"
          type="accent"
          label={`${extractCounter(orderNumber)}`}
          containerStyle={styles.counter}
        />
        <Text testID="order-type" style={styles.text} numberOfLines={1}>
          {orderType?.name || translate('common.assignPlaceholder')}
        </Text>
        <Icon
          name="angle-down"
          size={20}
          style={styles.icon}
          color={colors.text}
        />
      </TouchableOpacity>
      {isDineInOrder ? (
        <TouchableOpacity
          testID="btn-changeTable"
          style={styles.btnTable}
          onPress={onSelectTableNumber}
        >
          {!tableNumber ? (
            <Icon name="plus" size={20} color={colors.accent} />
          ) : (
            <Text testID="table" style={styles.textTable} numberOfLines={1}>
              {tableNumber}
            </Text>
          )}
        </TouchableOpacity>
      ) : null}
      {isAllergensEnabled ? (
        <TouchableOpacity
          testID="allergens-filter"
          onPress={onShowAllergens}
          style={styles.btnAllergens}
        >
          {allergens && allergens?.length >= 1 ? (
            <View style={styles.badge} />
          ) : null}
          <Icon name="sliders-v" size={20} color={colors.text} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default CartHeader;
