import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const SettingsScreenStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      alignSelf: 'center',
    },
    section: {
      marginTop: 40,
      width: theme.layoutWidth.s,
    },
    sectionTitle: {
      fontSize: 14,
      lineHeight: 14,
      paddingLeft: 14,
      marginBottom: 12,
      color: colors.textLight,
      fontFamily: theme.fonts.medium,
    },
    credit: {
      opacity: 0.2,
      marginVertical: 60,
      alignItems: 'center',
      justifyContent: 'center',
    },
    creditText: {
      fontSize: 12,
      lineHeight: 14,
      marginBottom: 12,
      color: colors.textLight,
      fontFamily: theme.fonts.semibold,
    },
    locales: {
      paddingLeft: 20,
      marginBottom: 8,
    },
    printers: {
      marginTop: 6,
      marginLeft: 20,
      marginBottom: 14,
    },
    printerAction: {
      marginBottom: 4,
    },
  });
};

export default SettingsScreenStyles;
