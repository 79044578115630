import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const SelectListStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      marginBottom: 20,
    },
    title: {
      fontSize: 14,
      lineHeight: 20,
      marginBottom: 12,
      color: colors.textLight,
      paddingHorizontal: 14,
    },
    optionContainer: {
      padding: 12,
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: theme.radius.s,
    },
    optionLabel: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
  });
};

export default SelectListStyles;
