import React, { useCallback } from 'react';
import { Device, OrderStatus, UpdateDeviceInput } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../useSession';
import { useDevices } from '../useDevices';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { useNavigation } from '@react-navigation/native';
import { useCart } from '../../orders/useCart';
import { useOrders } from './useOrders';
import { useNotification } from '../../Notification';
import { useModal } from '@oolio-group/rn-use-modal';
import ModalDialog from '../../../components/Shared/Modals/ModalDialog/ModalDialog';
import Icon from '../../../components/Icon/Icon';
import theme from '../../../common/default-theme';
import { useFunctionMapHelper } from '../../orders/useFunctionMapHelper';
import { IconStatus } from '../../../components/Shared/Icons/IconStatus/IconStatus';

export const useTrainingMode = () => {
  const [session] = useSession();
  const { order, resetCart, setCartParams } = useCart();
  const navigation = useNavigation();
  const { showNotification } = useNotification();
  const { returnOrdersFromCache } = useOrders();
  const { hasAccessToAnyManagerFunctions } = useFunctionMapHelper();
  const { showModal, closeModal } = useModal();

  const { translate } = useTranslation();
  const { updateDevice } = useDevices({
    storeId: session.currentStore?.id ?? '',
  });

  const resetActiveCart = useCallback(async () => {
    const selectedOrderType =
      order?.orderType?.id || session.deviceProfile?.defaultOrderType?.id;
    setCartParams('', selectedOrderType);
    const newOrderId = await resetCart(
      undefined,
      !session.device?.trainingMode,
    );
    navigation.navigate('Orders', {
      screen: 'TakeOrder',
      params: {
        id: newOrderId,
        isExisting: false,
        orderType: selectedOrderType,
      },
    });
  }, [
    navigation,
    order?.orderType?.id,
    resetCart,
    session.device?.trainingMode,
    session.deviceProfile?.defaultOrderType?.id,
    setCartParams,
  ]);

  const updateDeviceRequest = useCallback(() => {
    const device = (session.device ?? {}) as Device;

    analyticsService.capture('training_mode', {
      event: 'toggle_action',
      status: !device.trainingMode,
    });

    updateDevice(
      {
        id: device.id,
        name: device.name,
        salesPrefix: device.salesPrefix,
        returnPrefix: device.returnPrefix,
        deviceProfile: device.deviceProfile.id,
        cashDrawer: device.cashDrawer,
        trainingMode: !device.trainingMode,
      } as UpdateDeviceInput,
      translate(
        !device.trainingMode
          ? 'trainingMode.enabledForDevice'
          : 'trainingMode.disabledForDevice',
      ),
    );
    resetActiveCart();
  }, [resetActiveCart, session.device, translate, updateDevice]);

  const verifyAndUpdateTrainingMode = useCallback(async () => {
    if (!hasAccessToAnyManagerFunctions()) {
      showModal(
        <ModalDialog
          testID="mdl-notAuthorised"
          type="focus"
          icon={<IconStatus name="warning" />}
          title={translate('authorization.notAuthorizedTitle')}
          message={translate('authorization.cannotPerformAction')}
          onDismiss={{
            action: closeModal,
            label: translate('button.dismiss'),
          }}
        />,
      );
      return;
    }

    const trainingMode = session.device?.trainingMode;
    /*
     If trainingMode is true and if we're disabling it then we need to verify that
     if any of the active training order is present if yes then we won't allow to disable trainingMode
    */
    if (trainingMode) {
      const openOrders = returnOrdersFromCache(OrderStatus.IN_PROGRESS);
      const isActiveTrainingOrder = openOrders.find(o => o.isTraining);
      if (isActiveTrainingOrder) {
        showNotification({
          message: translate('trainingMode.openTrainingOrderWarning'),
          error: true,
        });
        return;
      }
    }
    updateDeviceRequest();
  }, [
    hasAccessToAnyManagerFunctions,
    session.device?.trainingMode,
    updateDeviceRequest,
    showModal,
    translate,
    closeModal,
    returnOrdersFromCache,
    showNotification,
  ]);

  const renderTrainingModeModal = useCallback(
    ({
      title = translate('trainingMode.trainingModeActive'),
      description = translate('trainingMode.trainingModeWarningMessage'),
      iconName = 'exclamationCircle',
      iconColor = theme.colors.red,
      type = 'negative',
      onConfirm,
      onDismiss,
    }: {
      title?: string;
      description?: string;
      iconName?: string;
      iconColor?: string;
      type?: 'positive' | 'neutral' | 'focus' | 'negative';
      onConfirm?: () => void;
      onDismiss?: () => void;
    } = {}) => {
      if (session.device?.trainingMode) {
        showModal(
          <ModalDialog
            testID="training-mode-warning"
            title={title}
            message={description}
            type={type}
            onConfirm={
              onConfirm
                ? {
                    label: translate('trainingMode.takeCashPayment'),
                    action: () => {
                      closeModal();
                      onConfirm();
                    },
                  }
                : undefined
            }
            icon={<Icon name={iconName} size={40} color={iconColor} />}
            onDismiss={{
              label: translate('button.dismiss'),
              action: onDismiss || closeModal,
            }}
          />,
        );
      }
    },
    [session.device?.trainingMode, translate, showModal, closeModal],
  );

  const canPerformActionOnOrder = useCallback(
    (isTrainingOrder?: boolean) => {
      let isRestrictedOnPress = false;
      const trainingMode = !!session.device?.trainingMode;
      if (trainingMode && !isTrainingOrder) {
        showNotification({
          message: translate('trainingMode.invalidActionForNonTrainingOrder'),
          error: true,
        });
        isRestrictedOnPress = true;
      } else if (!trainingMode && isTrainingOrder) {
        showNotification({
          message: translate('trainingMode.invalidActionForTrainingOrder'),
          error: true,
        });
        isRestrictedOnPress = true;
      }
      return isRestrictedOnPress;
    },
    [session.device?.trainingMode, showNotification, translate],
  );

  return {
    verifyAndUpdateTrainingMode,
    renderTrainingModeModal,
    canPerformActionOnOrder,
  };
};
