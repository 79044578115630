import {
  OrderItem,
  PrinterSeries,
  LOCALE,
  UnitType,
} from '@oolio-group/domain';
import { EscPos } from '@tillpos/xml-escpos-helper';
import { table } from 'table';
import { Session } from '../../state/Session';
import { dashDivider, divider } from './printDivider';
import { transferItemsSummaryDocketTemplate } from './xmlTemplates';
import { upperCase } from 'upper-case';
import {
  appendModifiers,
  getPrinterColumns,
  getProductDetails,
  tableOrderItemConfig,
  tableOrderItemModifierConfig,
  tableOrderItemNoteConfig,
} from './kitchenReceiptLayout';

export type PrintableProductRow = [string | undefined, string | undefined];

interface PrintableLineItems {
  product: string;
  note: string;
  modifiers: string;
}

export const getOrderItemsPrintString = (
  printItems: OrderItem[],
  locale?: LOCALE,
): PrintableLineItems[] => {
  const lineItems: PrintableLineItems[] = [];
  printItems.forEach(item => {
    const product = getProductDetails(item, locale);
    const modifierRows: PrintableProductRow[] = [];
    const quantity = `${parseFloat(
      (item.quantity * (item.product?.measuredBy?.defaultSize || 1)).toFixed(3),
    )}x`;

    const rows: PrintableProductRow[] = [];

    const weightedQuantity = [UnitType.Volume, UnitType.Weight].includes(
      item.product?.measuredBy?.unitType,
    );

    const productRow: PrintableProductRow = [
      weightedQuantity ? '' : quantity,
      product,
    ];
    rows.push(productRow);

    if (weightedQuantity) {
      const units = item.product?.measuredBy?.units;
      const unitPrice = parseFloat(
        (item.unitPrice / (item.product?.measuredBy?.defaultSize || 1)).toFixed(
          2,
        ),
      );
      const weightedQuantityRow: PrintableProductRow = [
        '',
        `${quantity}${units} NET ${
          item.tareWeight ? `(${item.tareWeight}${units} TARE)` : ''
        } @ ${unitPrice}/${units}`,
      ];
      rows.push(weightedQuantityRow);
    }

    appendModifiers(modifierRows, item, locale);
    const printableLineItem = {
      product: table(rows, tableOrderItemConfig),
      note: item?.notes
        ? table([[`** ${item?.notes} **`]], tableOrderItemNoteConfig())
        : '',
      modifiers:
        modifierRows.length > 0
          ? '\n' + table(modifierRows, tableOrderItemModifierConfig())
          : '',
    };
    lineItems.push(printableLineItem);
  });
  return lineItems;
};

export const getPrintableBuffer = (args: {
  printItems: OrderItem[];
  staffName: string;
  fromTableName: string;
  toTableName: string;
  printerSeries: PrinterSeries;
  session: Session;
  locale?: LOCALE;
}): Buffer | undefined => {
  const {
    printItems,
    printerSeries,
    session,
    staffName,
    fromTableName,
    toTableName,
    locale,
  } = args;

  const columns = getPrinterColumns(
    printerSeries || PrinterSeries.TM_M30II,
    false,
  );
  const data = {
    summaryText: `MOVE TO TABLE ${upperCase(
      toTableName || '',
    )} FROM ${upperCase(fromTableName || '')}\n`,
    staffNameText: `BY ${upperCase(staffName || '')}\n`,
    orderItems: getOrderItemsPrintString(printItems, locale),
    divider: divider(columns) + '\n',
    dashDivider: dashDivider(columns) + '\n',
    kitchenBuzzer: session?.deviceProfile?.enableKitchenBuzzer,
    printMode: printerSeries !== PrinterSeries.TM_U220 ? 'REST' : 'U220',
  };

  return EscPos.getBufferFromTemplate(
    transferItemsSummaryDocketTemplate,
    data,
  ) as unknown as Buffer;
};
