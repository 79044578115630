import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import PanelTitleStyles from './PanelTitle.styles';
import { useTheme } from '../../../hooks/useTheme';
import Icon from '../../Icon/Icon';
import Sticker, { StickerProps } from '../../Shared/Sticker/Sticker';

interface Props {
  title: string;
  themed?: boolean;
  sticker?: StickerProps;
  onClose: () => void;
}

const PanelTitle: React.FC<Props> = ({
  title,
  sticker,
  themed = false,
  onClose,
}) => {
  const { colors } = useTheme();
  const styles = PanelTitleStyles(themed);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        testID="btn-close"
        style={styles.btnClose}
        onPress={onClose}
      >
        <Icon name="times" color={colors.text} />
      </TouchableOpacity>
      <Text style={styles.title}>{title}</Text>
      {sticker ? (
        <Sticker
          {...sticker}
          containerStyle={StyleSheet.flatten([
            sticker.containerStyle,
            styles.sticker,
          ])}
        />
      ) : (
        <View style={styles.spacer} />
      )}
    </View>
  );
};

export default PanelTitle;
