import React, { createContext } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/src/types';
import { useTranslation } from '@oolio-group/localization';
import { SettingsTab } from './Settings/Settings';
import TabBar from '../../../../../components/TabBar/TabBar';
import { useRoute } from '@react-navigation/native';
import { IntegrationApps } from '@oolio-group/domain';
import { AboutTab } from './About/About';

const Tab = createMaterialTopTabNavigator();
export const SettingsContext = createContext(
  {} as { params: { app: IntegrationApps } },
);

const OolioWaitlistsIntegrationsSettingsTabs: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const params = route.params as { app: IntegrationApps };

  return (
    <SettingsContext.Provider value={{ params }}>
      <Tab.Navigator
        tabBar={(props): React.ReactNode => (
          <TabBar
            tabBar={props as MaterialTopTabBarProps}
            previousRoute={'ManageFeatures'}
          />
        )}
        initialRouteName="AboutTab"
      >
        <Tab.Screen
          name="AboutTab"
          component={AboutTab}
          options={{ tabBarLabel: translate('tabNames.about') }}
          initialParams={params}
        />
        <Tab.Screen
          name="SettingsTab"
          component={SettingsTab}
          options={{ tabBarLabel: translate('tabNames.settings') }}
          initialParams={params}
        />
      </Tab.Navigator>
    </SettingsContext.Provider>
  );
};

export default OolioWaitlistsIntegrationsSettingsTabs;
