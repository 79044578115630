import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';

const RefundModalStyles = () => {
  return StyleSheet.create({
    container: {
      width: 340,
      alignSelf: 'center',
      flex: 1,
    },
    content: {
      padding: 20,
      marginTop: 8,
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.white,
      ...theme.shadow.strong,
    },
    items: {
      height: 250,
    },
    actions: {
      rowGap: 8,
    },
  });
};

export default RefundModalStyles;
