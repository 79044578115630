import { IntegrationApps } from '@oolio-group/domain';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { SettingsContext } from '../OolioWaitlistsIntegrationsSettingsTabs';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import FeatureDetail from '../../../Layout/FeatureDetail/FeatureDetail';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import uniqBy from 'lodash/uniqBy';

export const AboutTab: React.FC = () => {
  const { translate } = useTranslation();
  const route = useRoute();
  const context = useContext(SettingsContext);
  const navigation = useNavigation();
  const {
    getIntegrations,
    integrations,
    loading: loadingIntegrations,
  } = useFeatures();

  const { loading, getIntegrationPartnerSettings, integrationPartners } =
    useIntegrationPartners();

  const params = context.params || (route.params as { app: IntegrationApps });

  const integrationData = useMemo(
    () => integrations?.find(integration => integration.app === params.app),
    [integrations, params.app],
  );

  useFocusEffect(getIntegrations);

  useFocusEffect(
    useCallback(
      () => getIntegrationPartnerSettings({ appName: params.app }),
      [getIntegrationPartnerSettings, params.app],
    ),
  );

  const locationsConnected = useMemo(() => {
    const connectedLocations = uniqBy(
      Object.values(integrationPartners),
      'store',
    );
    return connectedLocations?.length || 0;
  }, [integrationPartners]);

  const toggleConnect = useCallback(() => {
    locationsConnected <= 0 && navigation.navigate('SettingsTab');
  }, [locationsConnected, navigation]);

  return (
    <ScreenLayout
      hideFooter
      loading={loading || loadingIntegrations}
      title={translate('navigation.integrationsPage', {
        appName: translate('appName'),
      })}
    >
      <FeatureDetail
        feature={integrationData}
        onEnable={toggleConnect}
        totalLocationConnected={locationsConnected}
      />
    </ScreenLayout>
  );
};
