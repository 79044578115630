import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const SetPriceModalStyles = () => {
  return StyleSheet.create({
    container: {
      width: 460,
      alignSelf: 'center',
      rowGap: 8,
    },
    content: {
      padding: 20,
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.white,
      ...theme.shadow.strong,
    },
    item: {
      rowGap: 6,
      marginBottom: 10,
    },
    display: {
      rowGap: 6,
      padding: 20,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: theme.colors.tertiary1,
      marginBottom: 20,
    },
    title: {
      fontSize: 28,
      lineHeight: 40,
      color: theme.colors.orange,
      fontFamily: theme.fonts.semibold,
      fontVariant: ['tabular-nums'],
    },
    subtitle: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.grey6,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
      position: 'absolute',
      right: 20,
      top: 30,
    },
    keypad: {
      flexDirection: 'row',
      columnGap: 16,
    },
    numberPad: {
      width: 284,
    },
    actions: {
      rowGap: 4,
      width: 120,
    },
  });
};

export default SetPriceModalStyles;
