import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    minHeight: 44,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: theme.radius.s,
  },
  messageText: {
    flex: 1,
    padding: 12,
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  },
  icon: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowCell: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    marginLeft: 10,
  },
});

export const getBackgroundColor = (type: string) => {
  switch (type) {
    case 'positive':
      return theme.colors.states.positiveXL;
    case 'negative':
      return theme.colors.states.negativeXL;
    case 'focus':
      return theme.colors.states.focusXL;
    default:
      return theme.colors.states.neutralXL;
  }
};

export const getTextColor = (type: string) => {
  switch (type) {
    case 'positive':
      return theme.colors.states.positive;
    case 'negative':
      return theme.colors.states.negative;
    case 'focus':
      return theme.colors.states.focus;
    default:
      return theme.colors.states.neutral;
  }
};

export default styles;
