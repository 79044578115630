import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const PaymentHeaderStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 8,
      borderRadius: theme.radius.m,
      backgroundColor: colors.elevation0,
      ...theme.shadow.light,
    },
    icon: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      textAlign: 'center',
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
  });
};

export default PaymentHeaderStyles;
