import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const EmptyStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
      rowGap: 8,
      paddingVertical: 40,
      paddingHorizontal: 20,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : theme.colors.grey1,
    },
    icon: {
      transform: [{ scale: 0.8 }],
      marginBottom: 8,
    },
    text: {
      fontSize: 14,
      lineHeight: 20,
      textAlign: 'center',
      fontFamily: theme.fonts.regular,
      color: themed ? colors.textLight : theme.colors.grey6,
    },
  });
};

export default EmptyStyles;
