import React, { useState } from 'react';
import { View, Text } from 'react-native';
import { Resource } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useSettingsSync } from '../../../hooks/app/useSettingsSync';
import usePOSUserAuthorization from '../../../hooks/app/users/usePOSUserAuthorization';
import SettingsScreenStyles from './SettingsScreen.styles';
import Logo from '../../../components/Logo/Logo';
import GeneralSettings from './General/GeneralSettings';
import HardwareSettings from './Hardware/HardwareSettings';
import ScreenLayout from '../../../components/POS/ScreenLayout/ScreenLayout';
import SegmentControl from '../../../components/Shared/SegmentControl/SegmentControl';
import { useTheme } from '../../../hooks/useTheme';

interface RouteParams {
  tab?: SettingsTab;
}

export enum SettingsTab {
  GENERAL = 'GENERAL',
  HARDWARE = 'HARDWARE',
}

const Settings: React.FC = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const styles = SettingsScreenStyles();
  const { loading } = useSettingsSync();
  const { translate } = useTranslation();
  const { canI } = usePOSUserAuthorization();

  const { activeTheme } = useTheme();

  const params = route.params as RouteParams;

  const TABS = [
    {
      label: translate('settings.general'),
      value: SettingsTab.GENERAL,
    },
    {
      label: translate('settings.hardware'),
      value: SettingsTab.HARDWARE,
    },
  ];

  const initialTab = params?.tab ?? TABS[0].value;
  const [currentTab, setCurrentTab] = useState<string>(initialTab);

  const onPressSettingsTab = (tabValue: string): void => {
    const canManageHardware = canI([{ onResource: Resource.MANAGE_HARDWARE }], {
      prompt: true,
    });
    if (tabValue === SettingsTab.HARDWARE && !canManageHardware) return;
    const selectedTab = TABS.find(tab => tab.value === tabValue) || TABS[0];
    setCurrentTab(selectedTab.value);
  };

  const tabs = (
    <SegmentControl
      tabs={TABS}
      selectedTab={currentTab}
      onPress={onPressSettingsTab}
    />
  );

  return (
    <ScreenLayout
      title="Settings"
      loading={loading}
      tabs={tabs}
      onBack={() => navigation.goBack()}
    >
      {currentTab === SettingsTab.GENERAL ? (
        <GeneralSettings />
      ) : (
        <HardwareSettings />
      )}
      <View style={styles.credit}>
        <Text style={styles.creditText}>
          {translate('cdsCartScreen.poweredBy')}
        </Text>
        <Logo
          width={68}
          height={18}
          type="logotype"
          color={activeTheme === 'light' ? 'dark' : 'light'}
        />
      </View>
    </ScreenLayout>
  );
};

export default Settings;
