import { Platform, StyleSheet } from 'react-native';
import scale from '../../../common/theme';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const isWeb = Platform.OS === 'web';

const SelectStyles = ({ themed = false, disabled = false }) => {
  const { colors } = useTheme();

  const textColor = themed ? colors.text : theme.colors.black1;
  const textLight = themed ? colors.textLight : theme.colors.grey6;
  const borderColor = themed ? colors.borderColor : theme.colors.grey3;
  const disclosureColor = themed ? colors.disclosure : theme.colors.grey5;

  const getBgColor = () => {
    if (themed) {
      return disabled ? colors.elevation1 : colors.elevation0;
    } else {
      return disabled ? theme.colors.grey1 : theme.colors.white;
    }
  };

  return StyleSheet.create({
    inputContainer: {
      height: 44,
      maxHeight: 44,
      alignItems: 'center',
      flexDirection: 'row',
      columnGap: 12,
      borderRadius: theme.radius.s,
      borderWidth: 1,
      backgroundColor: getBgColor(),
      borderColor: disabled
        ? colors.transparent
        : themed
        ? colors.borderColor
        : theme.colors.grey3,
      overflow: 'hidden',
      zIndex: 2,
    },
    inputTitleText: {
      fontSize: 14,
      lineHeight: 14,
      marginBottom: 6,
      paddingLeft: 12,
      color: textLight,
    },
    errorText: {
      paddingTop: 6,
      paddingLeft: 12,
      color: theme.colors.red,
    },
    accessory: {
      width: 20,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 12,
      zIndex: 1,
    },
    bgStyle: {
      width: '100%',
      position: 'absolute',
      height: scale.deviceHeight,
    },
    optionsContainer: {
      width: 260,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.popupBg : theme.colors.white,
    },
    optionsPopup: {
      padding: 10,
      maxHeight: 300,
      borderWidth: isWeb ? 0 : 2,
      borderRadius: theme.radius.m,
      borderColor: borderColor,
      backgroundColor: themed ? colors.elevation0 : theme.colors.white,
      ...theme.shadow.light,
    },
    search: {
      marginBottom: 10,
    },
    option: {
      padding: 10,
      flexDirection: 'row',
      alignItems: 'center',
    },
    optionValue: {
      flex: 1,
      color: textColor,
    },
    optionText: {
      fontSize: 14,
      lineHeight: 20,
      paddingLeft: 8,
      fontFamily: theme.fonts.medium,
      color: textColor,
    },
    optionCreateText: {
      color: theme.colors.green,
      fontFamily: theme.fonts.medium,
    },
    optionCreate: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 44,
      padding: 12,
      marginHorizontal: 10,
      borderRadius: theme.radius.s,
      backgroundColor: themed
        ? colors.greenLightest
        : theme.colors.greenLightest,
    },
    valueText: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      paddingLeft: 12,
      color: disabled ? textLight : textColor,
    },
    placeholder: {
      flex: 1,
      paddingLeft: 12,
      color: disclosureColor,
    },
    values: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    valueContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: 4,
      borderRadius: 3,
      paddingVertical: 8,
      paddingHorizontal: 10,
      backgroundColor: themed ? colors.blueLight : theme.colors.blueLight,
    },
    value: {
      fontSize: 14,
      color: theme.colors.blue,
      fontFamily: theme.fonts.medium,
    },
    separator: {
      height: 1,
      marginVertical: 10,
      backgroundColor: borderColor,
    },
    labelContainer: {
      height: '100%',
      borderRightWidth: 1,
      paddingHorizontal: 12,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 1,
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
      borderRightColor: borderColor,
      backgroundColor: themed ? colors.elevation1 : theme.colors.grey1,
    },
    labelText: {
      fontSize: 14,
      color: textLight,
      fontFamily: theme.fonts.regular,
    },
    colourValue: {
      marginRight: 8,
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 12,
    },
    colourContainer: {
      width: 22,
      height: 22,
      marginRight: 8,
      borderRadius: 28,
    },
    prefixLabelContainerStyle: {
      height: '100%',
      alignItems: 'center',
      flexDirection: 'row',
      borderTopLeftRadius: theme.radius.s,
      borderBottomLeftRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation1 : theme.colors.grey1,
    },
    prefixLabelTextStyle: {
      paddingHorizontal: theme.spacing.m,
      color: disclosureColor,
    },
    btnCalendar: {
      width: 20,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default SelectStyles;
