import { StyleSheet, TextStyle } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';

const ItemAvailabilityModalStyles = () => {
  const { colors } = useTheme();

  const text: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    color: colors.grey6,
    textAlign: 'center',
    fontFamily: theme.fonts.regular,
  };

  return StyleSheet.create({
    container: {
      width: 600,
      alignSelf: 'center',
      rowGap: 8,
    },
    content: {
      padding: 20,
      borderRadius: theme.radius.s,
      backgroundColor: colors.white,
    },
    filters: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 8,
      marginVertical: 10,
    },
    dropdown: {
      width: 160,
    },
    search: {
      flex: 1,
    },
    header: {
      height: 38,
      paddingHorizontal: 12,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.grey1,
    },
    colName: {
      ...text,
      flex: 1,
      marginRight: 10,
      textAlign: 'left',
    },
    colCheckbox: {
      ...text,
      width: 80,
      marginRight: 10,
    },
    colQty: {
      ...text,
      width: 90,
    },
    rows: {
      height: 360,
      marginBottom: 10,
    },
    row: {
      paddingVertical: 6,
      paddingHorizontal: 12,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: colors.grey3,
    },
    cellName: {
      ...text,
      flex: 1,
      marginRight: 10,
      textAlign: 'left',
      color: colors.black1,
    },
    cellCheckbox: {
      width: 80,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 10,
    },
    checkbox: {
      width: 38,
      height: 38,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default ItemAvailabilityModalStyles;
