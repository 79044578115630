import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

export const CART_FONT_SIZE = 13;

const CartStyles = ({ themed = true }) => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      flex: 1,
      maxWidth: 320,
      rowGap: 4,
      padding: 6,
    },
    cart: {
      flex: 1,
      padding: 8,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation0 : colors.white,
      overflow: 'hidden',
    },
    itemsContainer: {
      flex: 1,
      width: '100%',
      overflow: 'hidden',
    },
    grow: {
      flexGrow: 1,
    },
    items: {
      paddingBottom: 8,
    },
    scrollButtons: {
      marginTop: 8,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.elevation1 : colors.grey1,
    },
    scrollButton: {
      flex: 1,
      height: 32,
      alignItems: 'center',
      justifyContent: 'center',
    },
    cartButtons: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: 4,
    },
    btnCartPrimary: {
      flex: 1,
    },
    onHoldContainer: {
      paddingTop: 6,
      borderTopWidth: 4,
      borderTopColor: colors.red,
    },
    onHoldText: {
      textAlign: 'center',
      textTransform: 'capitalize',
      fontFamily: theme.fonts.medium,
      color: colors.red,
    },
    emptyCart: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 40,
    },
    emptyCartText: {
      fontSize: 14,
      lineHeight: 20,
      textAlign: 'center',
      color: colors.textLight,
    },
    transferredItemsHeader: {
      height: 28,
      marginBottom: 4,
      justifyContent: 'space-between',
      borderRadius: theme.radius.s,
      backgroundColor: colors.pinkLight,
      padding: 4,
    },
    transferredItemsText: {
      fontSize: CART_FONT_SIZE,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      alignSelf: 'center',
      textTransform: 'uppercase',
      color: colors.pink,
    },
    collapseBtn: {
      position: 'absolute',
      right: 10,
    },
  });
};

export default CartStyles;
