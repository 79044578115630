import { IdentifierStyle, OrderTypeIdentifierCode } from './Identifier.types';

export const EntityStyleMap: Record<string, IdentifierStyle> = {
  modifier: {
    displayChar: 'M',
    background: 'blueLight',
    foreground: 'blue',
  },
  product: {
    displayChar: 'P',
    background: 'orangeLight',
    foreground: 'orange',
  },
  variant: {
    displayChar: 'V',
    background: 'tealLight',
    foreground: 'teal',
  },
  category: {
    displayChar: 'C',
    background: 'deepPurpleLight',
    foreground: 'deepPurple',
  },
  combo: {
    displayChar: 'C',
    background: 'orange',
    foreground: 'white',
  },
};

export const OrderTypeStyleMap: Record<
  OrderTypeIdentifierCode,
  IdentifierStyle
> = {
  D: {
    displayChar: 'DI',
    background: 'blueLight',
    foreground: 'blue',
  },
  T: {
    displayChar: 'TA',
    background: 'orangeLight',
    foreground: 'orange',
  },
  DL: {
    displayChar: 'DE',
    background: 'tealLight',
    foreground: 'teal',
  },
  P: {
    displayChar: 'PU',
    background: 'deepPurpleLight',
    foreground: 'deepPurple',
  },
  C: {
    displayChar: 'CT',
    background: 'pinkLight',
    foreground: 'pink',
  },
  other: {
    displayChar: 'O',
    background: 'elevation2',
    foreground: 'grey6',
  },
};

export const DefaultStyle: IdentifierStyle = {
  displayChar: 'O',
  background: 'elevation2',
  foreground: 'grey6',
};
