import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Product } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { translate, useCurrency } from '@oolio-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import { addToValue } from '../../../../utils/keypadHelper';
import { MAX_KEYPAD_INPUT } from '../../../../types/Common';
import SetVariablePriceModalStyles from './SetVariablePriceModal.styles';
import Icon from '../../../Icon/Icon';
import ModalTitle from '../ModalTitle/ModalTitle';
import NumberPad from '../Keypad/NumberPad/NumberPad';
import ButtonText from '../../../Shared/TreatButton/TreatButton';
import Message from '../../../Office/Message/Message';

interface Props {
  item?: Product;
  defaultPrice: number;
  onSubmit: (price: number) => void;
}

export const VariablePriceModal: React.FC<Props> = ({
  item,
  onSubmit,
  defaultPrice,
}) => {
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);
  const { formatCurrency, decimalSeparator, unFormatCurrency } = useCurrency();

  const styles = SetVariablePriceModalStyles();

  const minSellingPrice = item?.minSellingPrice || 0;
  const maxSellingPrice = item?.maxSellingPrice || 0;

  const [input, setInput] = useState('');

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: decimalSeparator },
  ];

  const onPressKey = useCallback(
    (key: string): void => {
      setInput((preInput: string) => {
        const newValue = addToValue({
          value: preInput,
          key,
          decimalSeparator,
          length: MAX_KEYPAD_INPUT,
        });
        const isHigherThanMaximum = maxSellingPrice <= Number(newValue);
        if (isHigherThanMaximum) return maxSellingPrice.toString();
        return newValue;
      });
      if (!isKeyPressed) setIsKeyPressed(true);
    },
    [decimalSeparator, isKeyPressed, maxSellingPrice, setIsKeyPressed],
  );

  const handlePressDeleteInput = useCallback((): void => {
    setInput(prev => {
      return prev.slice(0, -1);
    });
    if (!isKeyPressed) setIsKeyPressed(true);
  }, [isKeyPressed, setIsKeyPressed]);

  const handleApplyChange = useCallback(() => {
    const isLowerThanMinimum = minSellingPrice > Number(input);
    if (isLowerThanMinimum && isKeyPressed) {
      setInput('0');
      showNotification({
        message: translate('variablePriceModal.minPriceError', {
          value: `${formatCurrency(minSellingPrice)}`,
        }),
        error: true,
      });
      return;
    }
    if (!!input) {
      onSubmit(Number(input));
    } else {
      onSubmit(Number(defaultPrice));
    }
  }, [
    defaultPrice,
    formatCurrency,
    input,
    isKeyPressed,
    minSellingPrice,
    onSubmit,
    showNotification,
  ]);

  return (
    <View style={styles.container}>
      <ModalTitle
        title={translate('variablePriceModal.title')}
        onDismiss={closeModal}
      />
      <View style={styles.content}>
        {item && (
          <View style={styles.item}>
            <Text style={styles.itemText}>{item?.name}</Text>
          </View>
        )}
        <View style={styles.display} testID="price-display">
          <Text style={styles.text}>
            {isKeyPressed
              ? formatCurrency(unFormatCurrency(input))
              : formatCurrency(Number(defaultPrice))}
          </Text>
          <TouchableOpacity
            style={styles.btnClear}
            onPress={handlePressDeleteInput}
          >
            <Icon size={20} name="cancel" color="#ffffff" />
          </TouchableOpacity>
        </View>
        <Message
          type="neutral"
          message={`${formatCurrency(minSellingPrice)} ↔ ${formatCurrency(
            maxSellingPrice,
          )}`}
        />
        <View style={styles.numberPad}>
          <NumberPad
            width={340}
            keypadButtons={numberKeys}
            zeroButtonWidth={222.66}
            onPressKey={onPressKey}
          />
        </View>
        <ButtonText
          testID="submit-button"
          type="positive"
          label={translate('button.applyChanges')}
          onPress={handleApplyChange}
        />
      </View>
    </View>
  );
};
