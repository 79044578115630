import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    rowGap: 20,
    padding: 20,
  },
  filters: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  dropdown: {
    width: 200,
  },
  search: {
    flex: 1,
  },
});

export default styles;
