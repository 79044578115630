import { StyleSheet, ViewStyle } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';

const MenuStyles = () => {
  const { colors } = useTheme();

  const gridStyle: ViewStyle = {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    columnGap: 4,
    rowGap: 4,
  };

  return StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      rowGap: 8,
      padding: 6,
      paddingLeft: 0,
    },
    main: {
      flex: 1,
      flexDirection: 'column',
      rowGap: 8,
    },
    products: {
      flex: 5,
      ...gridStyle,
    },
    options: {
      flex: 3,
      ...gridStyle,
    },
    separator: {
      height: 2,
      borderBottomWidth: 2,
      borderStyle: 'dashed',
      borderColor: colors.borderColor,
    },
  });
};

export default MenuStyles;
