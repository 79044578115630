import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import { View, Text } from 'react-native';
import {
  IntegrationApps,
  IntegrationPartner,
  OnlineOrderingPreferences,
  Store,
  UpdateIntegrationPartnerInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { keyBy } from 'lodash';
import { useStores } from '../../../../../../hooks/app/useStores';
import { useNotification } from '../../../../../../hooks/Notification';
import { useIntegrationPartners } from '../../../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import { Operation } from '../../../../../../types/Operation';
import { stripProperties } from '../../../../../../utils/stripObjectProps';
import {
  onChangeStoreSettings,
  onChangeOnlineIntegrationStoreSettings,
  onUpdateDeliverItSettings,
} from '../../../../../../utils/OnlineOrderIntegrationHelpers';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { SettingsContext } from '../OnlineOrderIntegrationsSettingsTabs';
import { useModal } from '@oolio-group/rn-use-modal';
import IntegrationWebhookInfoModal from './IntegrationWebhookInfoModal';
import { useFeatures } from '../../../../../../hooks/app/features/useFeatures';
import { useDeviceProfiles } from '../../../../../../hooks/app/useDeviceProfiles';
import { useDevices } from '../../../../../../hooks/app/useDevices';
import { StoreRow } from './StoreRow';
import styles from './Settings.styles';
import theme from '../../../../../../common/default-theme';
import { Option } from '../../../../../../components/Shared/Select/Select';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import {
  mappingScheduleToTimeBlocks,
  useMenuSchedules,
} from '../../../../../../hooks/app/menuSchedules/useMenuSchedules';

const IntegrationWebhookPath = {
  [IntegrationApps.DELIVERECT]: '/webhook/deliverect/registerStore',
  [IntegrationApps.OOM]: '/webhook/oom/all',
} as Record<IntegrationApps, string>;

export const SettingsTab: React.FC = () => {
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { showModal } = useModal();
  const [integrationSettings, setIntegrationSettings] = useState<
    Record<string, IntegrationPartner & { isNew?: boolean }>
  >({});

  const {
    stores,
    getStores,
    loading: storesLoading,
    error: storesErr,
  } = useStores();
  const { menuSchedules, getMenuSchedules } = useMenuSchedules();

  const {
    loading: integrationPartnerLoading,
    getIntegrationPartnerSettings,
    integrationPartners,
    updateIntegrationPartnerSettings,
    operation,
    error: integrationPartnerError,
  } = useIntegrationPartners();

  const { orderTypes, getOrderTypes } = useDeviceProfiles();

  const { getDevicesSynchronously } = useDevices();

  const route = useRoute();
  const context = useContext(SettingsContext);

  const params = context.params || (route.params as { app: IntegrationApps });
  const app = params.app;

  const isOolioStore = app == IntegrationApps.OOLIO_STORE;
  const isOOMIntegration = app == IntegrationApps.OOM;

  const {
    getIntegrations,
    integrations,
    loading: loadingIntegrations,
  } = useFeatures();

  const featureData = integrations.find(integration => integration.app === app);

  useFocusEffect(getIntegrations);

  useFocusEffect(
    useCallback(() => {
      getStores();
      getIntegrationPartnerSettings({ appName: app });
      getOrderTypes();
      if (isOOMIntegration) {
        getMenuSchedules();
      }
    }, [
      getStores,
      getIntegrationPartnerSettings,
      app,
      getOrderTypes,
      isOOMIntegration,
      getMenuSchedules,
    ]),
  );

  const storesArray = useMemo(() => Object.values(stores), [stores]);

  const loading =
    integrationPartnerLoading || storesLoading || loadingIntegrations;
  const error = integrationPartnerError || storesErr;
  const isUpdated = !error && !loading && operation === Operation.UPDATE;

  const scheduleMenuMaps = useMemo(() => {
    return keyBy(menuSchedules, 'menu');
  }, [menuSchedules]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  const allOptions: Record<
    string,
    {
      menuOptions: Option[];
      pricingGroupOptions: Option[];
    }
  > = useMemo(() => {
    const result: Record<
      string,
      {
        menuOptions: Option[];
        pricingGroupOptions: Option[];
      }
    > = {};

    const storesValues = Object.values(stores);

    storesValues.forEach(eachStore => {
      const menuOptions: Option[] = [];
      const pricingGroupOptions: Option[] = [];

      (eachStore?.catalogues || []).forEach(eachCat => {
        menuOptions.push({ label: eachCat.name, value: eachCat.id });
      });

      (eachStore?.pricingGroups || []).forEach(eachPG => {
        pricingGroupOptions.push({ label: eachPG.name, value: eachPG.id });
      });

      result[eachStore.id] = {
        pricingGroupOptions,
        menuOptions,
      };
    });
    return result;
  }, [stores]);

  useEffect(() => {
    const integrationPartnerSettings = Object.values(integrationPartners).map(
      x => ({ ...x, isNew: false }),
    );
    const integrationSettingsByStore = keyBy(
      integrationPartnerSettings,
      'store',
    );
    setIntegrationSettings(integrationSettingsByStore);
  }, [integrationPartners]);

  useEffect(() => {
    if (isUpdated) {
      showNotification({
        success: true,
        message: translate('backOfficeFeatures.settingsUpdatedSuccessfully', {
          appName: app,
        }),
      });
    }
  }, [app, isUpdated, showNotification, translate]);

  const onChange = useCallback(
    async (storeId, key, value) => {
      const devices = await getDevicesSynchronously(storeId);
      const defaultPrinter = devices?.[0];

      setIntegrationSettings(prev => {
        let storeSettingsOld = { ...prev[storeId] };

        if (app === IntegrationApps.DELIVERIT) {
          storeSettingsOld = onChangeStoreSettings(
            storeId,
            key,
            value,
            storeSettingsOld,
            stores,
            app,
            orderTypes,
            defaultPrinter?.id,
          );
        } else if (
          app === IntegrationApps.DOSHII ||
          app === IntegrationApps.DELIVERECT ||
          app === IntegrationApps.OOM
        ) {
          storeSettingsOld = onChangeOnlineIntegrationStoreSettings(
            key,
            value,
            storeSettingsOld,
          );
        } else {
          storeSettingsOld = onChangeStoreSettings(
            storeId,
            key,
            value,
            storeSettingsOld,
            stores,
            app,
            orderTypes,
            defaultPrinter?.id,
            allOptions[storeId]?.menuOptions[0]?.value,
            allOptions[storeId]?.pricingGroupOptions[0]?.value,
          );
        }
        return {
          ...prev,
          [storeSettingsOld.store as string]: storeSettingsOld,
        };
      });
    },
    [allOptions, app, getDevicesSynchronously, orderTypes, stores],
  );

  const isValidOnlineOrderingSetting = useCallback(
    (onlineOrderingSettings: UpdateIntegrationPartnerInput[] = []) => {
      return onlineOrderingSettings
        .filter(setting => setting.isActive)
        .every(setting => {
          const onlineOrderSetting = setting.preferences?.onlineOrdering;
          const isPricingGroupExist =
            (isOolioStore && onlineOrderSetting?.pricingGroups?.length) ||
            onlineOrderSetting?.pricingGroup;
          return onlineOrderSetting?.menu !== '' && isPricingGroupExist;
        });
    },
    [isOolioStore],
  );

  const onUpdateSettings = useCallback(() => {
    let integrationSettingsArray = Object.values(
      stripProperties(
        integrationSettings,
        '__typename',
        'token',
        'isNew',
        'accountId',
        'oolioReservation',
        'oolioWaitlists',
      ),
    ) as UpdateIntegrationPartnerInput[];

    if (app === IntegrationApps.DELIVERIT) {
      integrationSettingsArray = onUpdateDeliverItSettings(
        integrationSettingsArray,
        stores,
      );
    }

    if (integrationSettingsArray.length) {
      const isValid = isValidOnlineOrderingSetting(integrationSettingsArray);
      if (isValid) {
        const formattedIntegrationSettingsArray = integrationSettingsArray.map(
          integrationSettings => {
            const onlineOrderingSetting =
              integrationSettings.preferences?.onlineOrdering;
            if (isOolioStore) {
              //only delete this field for online store
              delete onlineOrderingSetting?.['pricingGroup'];
            }
            const selectedMenu = onlineOrderingSetting?.menu as string;
            const menuSchedules = scheduleMenuMaps[selectedMenu];
            let operatingHours;
            let orderTypes = [] as string[];

            if (isOOMIntegration && menuSchedules) {
              operatingHours = mappingScheduleToTimeBlocks(
                menuSchedules.schedules?.[0]?.timeBlocks,
              );
              orderTypes = (menuSchedules?.orderTypes || []).map(
                orderType => orderType.id,
              );
            }
            return {
              ...integrationSettings,
              preferences: integrationSettings.preferences && {
                ...integrationSettings.preferences,
                onlineOrdering: onlineOrderingSetting && {
                  ...onlineOrderingSetting,
                  deliverySettings: {
                    ...onlineOrderingSetting?.deliverySettings,
                    adjustments:
                      onlineOrderingSetting?.deliverySettings?.adjustments?.map(
                        adjustment => adjustment.id,
                      ),
                  },
                  pickUpSettings: {
                    ...onlineOrderingSetting?.pickUpSettings,
                    adjustments:
                      onlineOrderingSetting.pickUpSettings?.adjustments?.map(
                        adjustment => adjustment.id,
                      ),
                  },
                  dineInSettings: {
                    ...onlineOrderingSetting?.dineInSettings,
                    adjustments:
                      onlineOrderingSetting?.dineInSettings?.adjustments?.map(
                        adjustment => adjustment.id,
                      ),
                  },
                  ...(isOOMIntegration && { operatingHours, orderTypes }),
                },
              },
            };
          },
        );
        updateIntegrationPartnerSettings(
          formattedIntegrationSettingsArray as unknown as UpdateIntegrationPartnerInput[],
        );
      } else {
        showNotification({
          error: true,
          message: translate(
            'backOfficeFeatures.pleaseSelectMenuAndPricingGroup',
            {
              appName: app,
            },
          ),
        });
      }
    } else {
      showNotification({
        info: true,
        message: translate('backOfficeFeatures.noStoresIntegrationsEnabled', {
          appName: app,
        }),
      });
    }
  }, [
    integrationSettings,
    app,
    stores,
    isValidOnlineOrderingSetting,
    updateIntegrationPartnerSettings,
    isOolioStore,
    scheduleMenuMaps,
    isOOMIntegration,
    showNotification,
    translate,
  ]);

  const onOpenStoreInfo = useCallback(
    (storeId: string) => {
      const storeInfo = stores[storeId];
      const baseWebhookUrl = featureData?.webhookUrl || '';
      const path = IntegrationWebhookPath[app] || '';
      const webhookUrl = baseWebhookUrl + path;

      showModal(
        <IntegrationWebhookInfoModal
          storeId={storeId}
          webhookUrl={webhookUrl}
          storeName={storeInfo.name}
          app={app}
        />,
      );
    },
    [app, featureData?.webhookUrl, showModal, stores],
  );

  const getPriceLists = useCallback(
    (settings?: OnlineOrderingPreferences) => {
      if (isOolioStore && settings?.pricingGroups?.length) {
        return settings?.pricingGroups;
      }
      return [];
    },
    [isOolioStore],
  );

  const screenTitle = () => {
    switch (app) {
      case IntegrationApps.DELIVERECT:
        return 'Deliverect';
      case IntegrationApps.DOSHII:
        return 'Doshii';
      case IntegrationApps.OOM:
        return 'Oolio Orders';
      default:
        return 'Online Store';
    }
  };

  return (
    <ScreenLayout
      loading={loading}
      title={`${screenTitle()} Settings | Oolio`}
      onSave={onUpdateSettings}
    >
      <Section layoutWidth="medium">
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerName]}>
              {translate('backOfficeFeatures.venuesAndSettings')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerDropdown]}>
              {translate('backOfficeFeatures.menu')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerDropdown]}>
              {translate('backOfficeFeatures.priceList')}
            </Text>
          </View>
          <View>
            {storesArray?.map((store: Store, i: number) => {
              const settings =
                integrationSettings[store.id]?.preferences?.onlineOrdering;
              const isActive = integrationSettings[store.id]?.isActive;

              return (
                <StoreRow
                  key={i}
                  id={
                    app === IntegrationApps.DELIVERIT
                      ? integrationSettings[store.id]?.id
                      : store.id
                  }
                  isEnabled={isActive}
                  menu={settings?.menu || ''}
                  pricingGroups={getPriceLists(settings)}
                  pricingGroup={settings?.pricingGroup || ''}
                  name={store.name}
                  menusOptions={allOptions[store.id]?.menuOptions}
                  pricingGroupsOptions={
                    allOptions[store.id]?.pricingGroupOptions
                  }
                  onChange={onChange}
                  storeId={store.id}
                  integrationApp={app}
                  onOpenStoreInfo={onOpenStoreInfo}
                />
              );
            })}
          </View>
        </View>
      </Section>
    </ScreenLayout>
  );
};
