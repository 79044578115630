import { StyleSheet } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';

const CustomersStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    filters: {
      margin: 20,
      columnGap: 10,
      flexDirection: 'row',
    },
    search: {
      flex: 1,
    },
    dropdown: {
      width: 180,
    },
    table: {
      marginBottom: 80,
      marginHorizontal: 20,
    },
    tableHeader: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    tableBody: {
      marginVertical: 8,
    },
    tableRow: {
      height: 50,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    // Customers
    cellName: {
      width: 200,
      marginLeft: 12,
      marginRight: 20,
      color: colors.text,
    },
    cellPhone: {
      width: 140,
      marginRight: 10,
      color: colors.text,
    },
    cellFlex: {
      flex: 1,
      marginRight: 20,
      color: colors.text,
    },
    cellAmount: {
      width: 100,
      marginRight: 20,
      textAlign: 'right',
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
      color: colors.text,
    },
    cellButton: {
      marginLeft: 10,
    },
    // Customer History
    info: {
      height: 44,
      marginTop: -60,
      marginBottom: 60,
      marginHorizontal: 20,
      paddingHorizontal: 20,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
    },
    infoAmount: {
      alignItems: 'center',
      flexDirection: 'row',
      columnGap: 8,
    },
    infoText: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.regular,
    },
    balance: {
      color: theme.colors.red,
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.medium,
    },
    cellType: {
      width: 60,
      marginLeft: 12,
      marginRight: 20,
      textAlign: 'center',
    },
    cellNo: {
      width: 100,
      marginRight: 20,
      textAlign: 'center',
    },
    cellDate: {
      width: 130,
      marginRight: 20,
      color: colors.text,
    },
    btnReceipt: {
      marginHorizontal: 10,
    },
    loadingContainer: {
      marginTop: 80,
    },
  });
};

export default CustomersStyles;
