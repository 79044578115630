import React, { FC, PropsWithChildren } from 'react';
import { View } from 'react-native';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@oolio-group/localization';
import styles from './ScreenLayout.styles';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import LoadingIndicator from '../../Shared/Loaders/LoadingIndicator';
import KeyboardAwareScrollView from '../../KeyboardAwareScrollView/KeyboardAwareScrollView';
import useBehaviorSubjectState from '../../../hooks/app/useSubjectState';
import { ScrollEnabledState } from '../../../state/scrollState';

interface Props extends PropsWithChildren {
  title?: string;
  loading?: boolean;
  onSaveLabel?: string;
  onSaveDisabled?: boolean;
  onDeleteLabel?: string;
  onDeleteDisabled?: boolean;
  onExternalLabel?: string;
  hideFooter?: boolean;
  hideSaveButton?: boolean;
  onSave?: () => void;
  onDelete?: () => void;
  onExternalAction?: () => void;
}

export const ScreenLayout: FC<Props> = ({
  title,
  loading,
  onSave,
  onDelete,
  onExternalAction,
  onSaveLabel,
  onSaveDisabled,
  onDeleteLabel,
  onDeleteDisabled,
  onExternalLabel,
  hideFooter = false,
  children,
  hideSaveButton,
}) => {
  const { translate } = useTranslation();
  const scrollEnabledState = useBehaviorSubjectState(ScrollEnabledState);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <View style={styles.container}>
        {loading ? (
          <LoadingIndicator containerStyles={styles.loader} />
        ) : (
          <View style={styles.screen}>
            <KeyboardAwareScrollView
              extraScrollHeight={40}
              scrollEnabled={scrollEnabledState.value}
            >
              <View style={styles.content}>{children}</View>
            </KeyboardAwareScrollView>
            {!hideFooter && (
              <View style={styles.footer}>
                {onDelete && (
                  <TreatButton
                    type="negativeLight"
                    testID="btn-delete"
                    onPress={onDelete}
                    label={onDeleteLabel || translate('button.delete')}
                    isLoading={loading}
                    disabled={loading || onDeleteDisabled}
                    containerStyle={styles.btnDelete}
                  />
                )}
                {onSave && !hideSaveButton && (
                  <>
                    {onExternalAction && (
                      <TreatButton
                        type="neutralLight"
                        testID="btn-external"
                        onPress={onExternalAction}
                        label={onExternalLabel || translate('button.save')}
                        icon="arrow-up-right"
                        containerStyle={styles.btnExternal}
                      />
                    )}
                    <TreatButton
                      type="positive"
                      testID="btn-save"
                      onPress={onSave}
                      label={onSaveLabel || translate('button.save')}
                      isLoading={loading}
                      disabled={loading || onSaveDisabled}
                    />
                  </>
                )}
              </View>
            )}
          </View>
        )}
      </View>
    </>
  );
};

export default ScreenLayout;
