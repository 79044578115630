import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation, formatPhoneNumber } from '@oolio-group/localization';
import ButtonText from '../../../../../../components/Shared/TreatButton/TreatButton';
import { currentReservations } from '../../../../../../state/cache';
import TagsView from '../TagsView/TagsView';
import styles from './ReservationInfoModal.styles';

interface ReservationInfoModalProps {
  reservationId?: string;
  onContinue?: () => void;
  onClose?: () => void;
}

const ReservationInfoModal: React.FC<ReservationInfoModalProps> = ({
  reservationId,
  onContinue = () => undefined,
  onClose,
}: ReservationInfoModalProps) => {
  const { translate } = useTranslation();

  const reservation =
    reservationId &&
    currentReservations().find(x => x.reference_code === reservationId);

  if (!reservation) {
    onContinue();
    return null;
  }

  const allergens = reservation?.reservation_tags.filter(
    x => x.group === 'Dietary restrictions',
  );

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.nameText}>{reservation?.full_name}</Text>

        <Text style={styles.sectionTitle}>
          {translate('reservations.allergens')}
        </Text>
        {!!allergens.length ? (
          <TagsView tags={allergens} />
        ) : (
          <Text style={styles.emptyInfo}>
            {translate('reservations.noAllergens')}
          </Text>
        )}

        <Text style={styles.sectionTitle}>
          {translate('reservations.reservationNote')}
        </Text>
        {!!reservation?.notes ? (
          <Text style={styles.sectionInfo}>{reservation?.notes}</Text>
        ) : (
          <Text style={styles.emptyInfo}>
            {translate('reservations.emptyNote')}
          </Text>
        )}
        <Text style={styles.sectionTitle}>
          {translate('reservations.contactDetails')}
        </Text>
        <Text style={styles.sectionInfo}>
          {formatPhoneNumber(reservation?.phone_number ?? '', {
            intl: false,
          })}
        </Text>
        <Text style={styles.sectionInfo}>{reservation?.email}</Text>
      </View>
      <ButtonText
        type="neutral"
        label={translate('button.continue')}
        containerStyle={styles.btnContainer}
        onPress={onContinue}
      />
      {onClose && (
        <ButtonText
          type="cancel"
          label={translate('button.dismiss')}
          containerStyle={styles.btnContainer}
          onPress={onClose}
        />
      )}
    </View>
  );
};

export default ReservationInfoModal;
