import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, View } from 'react-native';
import { CreateTerminalInput } from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useTerminals } from '../../../../hooks/app/useTerminals';
import { useNotification } from '../../../../hooks/Notification';
import ModalTitle from '../ModalTitle/ModalTitle';
import styles from './ConnectTerminalModal.styles';
import InputText from '../../../Shared/Inputs/InputText';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import LoadingIndicator from '../../../Shared/Loaders/LoadingIndicator';

const ConnectPaymentTerminalModal: React.FC = () => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { loading, error, terminal, createTerminal } = useTerminals();

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (terminal) {
      showNotification({
        success: true,
        message: translate('paymentTerminal.createdSuccessfully'),
      });
      closeModal();
    }
  }, [closeModal, showNotification, terminal, translate]);

  const [form, setForm] = useState<CreateTerminalInput>({
    name: '',
    uuid: '',
  });

  const onChange = useCallback((prop: string, value) => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onSubmitForm = useCallback(() => {
    if (typeof form.name === 'string' && form.name.trim() === '') {
      showNotification({
        error: true,
        message: translate('form.paymentTerminal.nameIsRequired'),
      });
      return;
    }

    if (typeof form.uuid === 'string' && form.uuid.trim() === '') {
      showNotification({
        error: true,
        message: translate('form.paymentTerminal.terminalIdIsRequired'),
      });
      return;
    }

    createTerminal({
      name: form.name,
      uuid: form.uuid,
    });
  }, [createTerminal, form.name, form.uuid, showNotification, translate]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.container}>
        <ModalTitle title={translate('paymentTerminal.connectTerminal')} />
        <View style={styles.content}>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <InputText
                testID="paymentTerminalName"
                autoFocus={true}
                value={form.name}
                title={translate('form.name')}
                placeholder={translate('form.namePlaceholder')}
                onChangeText={onChange.bind(null, 'name')}
                maxLength={50}
              />
              <InputText
                testID="paymentTerminalTerminalId"
                title={translate('form.paymentTerminal.terminalId')}
                value={form.uuid}
                placeholder="Enter id...."
                onChangeText={onChange.bind(null, 'uuid')}
                maxLength={50}
              />
              <TreatButton
                testID="btn-connect"
                type="positive"
                label={translate('button.connect')}
                onPress={onSubmitForm}
              />
            </>
          )}
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ConnectPaymentTerminalModal;
