import React, { PropsWithChildren } from 'react';
import { View, Text } from 'react-native';
import {
  useNotification,
  useNotificationsList,
} from '../../../hooks/Notification';
import styles from './Transition.styles';
import Gradient from '../../../components/Gradient/Gradient';
import ButtonIcon from '../../../components/Shared/TreatButton/ButtonIcon';
import { NotificationsList } from '../../../components/Shared/Notifications/NotificationsList';

interface LayoutProps {
  title: string;
  onPressBack?: () => void;
}

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  title,
  onPressBack,
  children,
}) => {
  const notifications = useNotificationsList();
  const { closeNotification } = useNotification();

  return (
    <Gradient style={styles.screen}>
      <NotificationsList
        notifications={notifications}
        onDismiss={closeNotification}
      />
      <View style={styles.content}>
        <View style={styles.header}>
          <ButtonIcon
            testID="btn-back"
            size={44}
            type="cancel"
            icon="arrow-left"
            onPress={onPressBack}
          />
          <Text style={styles.title}>{title}</Text>
        </View>
        <View>{children}</View>
      </View>
    </Gradient>
  );
};

export default Layout;
