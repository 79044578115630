import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { EnrollmentSource } from '@oolio-group/loyalty-sdk';
import { CreateCustomerRequest } from '@oolio-group/domain';
import {
  usePhoneNumber,
  translate,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { useSession } from '../../../../../hooks/app/useSession';
import { useNotification } from '../../../../../hooks/Notification';
import {
  isValidEmail,
  isValidName,
  trimString,
} from '../../../../../utils/validator';
import { DEFAULT_COUNTRY_CODE } from '../../../../../constants';
import theme from '../../../../../common/default-theme';
import TreatModal from '../../../../../components/Shared/Modals/Modal/Modal';
import InputText from '../../../../../components/Shared/Inputs/InputText';
import InputEmail from '../../../../../components/Shared/Inputs/InputEmail';
import InputPhone from '../../../../../components/Shared/Inputs/InputPhone';
import InputToggle from '../../../../../components/Shared/Inputs/InputToggle';
import { useOolioLoyalty } from '../../../../../hooks/useOolioLoyalty';

interface CreateCustomerModalProps {
  loading?: boolean;
  onSubmit: (input: CreateCustomerRequest) => Promise<void>;
  onDismiss: () => void;
}

interface ICustomerForm {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
  loyaltyMember: boolean;
}

const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  loading,
  onSubmit,
  onDismiss,
}) => {
  const [session] = useSession();
  const { showNotification } = useNotification();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const [errorMessage, setErrorMessage] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const { country = DEFAULT_COUNTRY_CODE } = session?.currentOrganization || {};

  const { isLoyaltyEnabled: isOolioLoyaltyEnabled } = useOolioLoyalty();

  const [form, setForm] = useState<ICustomerForm>({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: country,
    phone: '',
    loyaltyMember: false,
  });

  useEffect(() => {
    if (errorMessage !== '') {
      showNotification({
        error: true,
        message: errorMessage,
      });
      setErrorMessage('');
    }
  }, [errorMessage, showNotification]);

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );

  const validateForm = useCallback(() => {
    const isValid =
      trimString(form.firstName) !== '' &&
      (form.email !== '' || form.phone.length > 0) &&
      (!form.email || isValidEmail(form.email)) &&
      (!form.phone || isPhoneValid);
    setIsFormValid(isValid);
  }, [form, isPhoneValid]);

  const getNameValidationError = useCallback(
    (value: string) => {
      if (value.length > 0) {
        if (!isOolioLoyaltyEnabled) {
          if (!isValidName(value)) {
            return translate('cdsInputInformation.invalidName');
          }
        }
      }

      return undefined;
    },
    [isOolioLoyaltyEnabled],
  );

  useEffect(() => {
    validateForm();
  }, [form, validateForm]);

  const onChangeFormInput = useCallback(
    (prop: string, value: string | boolean) => {
      setForm(form => ({
        ...form,
        [prop]: value,
      }));
    },
    [],
  );

  const onCreate = useCallback(() => {
    const { firstName, lastName, email, phone, loyaltyMember } = form;
    if (!isOolioLoyaltyEnabled) {
      if (trimString(firstName) === '') {
        setErrorMessage(
          translate('backOfficeCustomerModal.errorFirstNameMissing'),
        );
        return;
      } else if (email === '' && phone.length === 0) {
        setErrorMessage(translate('customer.emptyEmailandPhoneMessage'));
        return;
      } else if (email && !isValidEmail(email)) {
        setErrorMessage(translate('customer.invalidEmailMessage'));
        return;
      } else if (phone && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      }
    } else {
      if (
        !loyaltyMember &&
        trimString(firstName) === '' &&
        trimString(lastName) === '' &&
        email === '' &&
        !phone.length
      ) {
        setErrorMessage(
          translate('backOfficeCustomerModal.errorMinOneFieldMissing'),
        );
        return;
      } else if (loyaltyMember && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      } else if (email && !isValidEmail(email)) {
        setErrorMessage(translate('customer.invalidEmailMessage'));
        return;
      } else if (phone && !isPhoneValid) {
        setErrorMessage(translate('customer.invalidPhoneMessage'));
        return;
      }
    }
    const preferredAddress = {
      isoCountryCode: form.countryCode,
    };

    const customerAccountDetails = {
      currentBalance: 0,
      accountPayment: false,
    };
    const customer = {
      firstName,
      lastName,
      email,
      phone: getFullFormattedPhoneNumber(form.countryCode, form.phone),
      phoneNumber: phone.replace(/^0+/, ''),
      preferredAddress,
      customerAccountDetails,
      loyaltyMember,
      ...(loyaltyMember && {
        loyaltyEnrolmentSource: EnrollmentSource.BACK_OFFICE,
      }),
    };
    onSubmit(customer);
  }, [
    form,
    getFullFormattedPhoneNumber,
    isOolioLoyaltyEnabled,
    isPhoneValid,
    onSubmit,
  ]);

  return (
    <TreatModal
      size="medium"
      type="positive"
      loading={loading}
      title={translate('backOfficeCustomerModal.createTitle')}
      onConfirm={{
        label: translate('button.createNew'),
        disabled: !isOolioLoyaltyEnabled && !isFormValid,
        action: onCreate,
      }}
      onDismiss={{ action: onDismiss }}
    >
      <View style={theme.forms.row}>
        <InputText
          testID="input-first-name"
          title={translate('form.firstName')}
          value={form.firstName}
          placeholder={'Jenny'}
          onChangeText={onChangeFormInput.bind(null, 'firstName')}
          errorMessage={getNameValidationError(form.firstName)}
          containerStyle={theme.forms.inputHalf}
        />
        <InputText
          testID="input-last-name"
          title={translate('form.lastName')}
          value={form.lastName}
          placeholder={'Murtaugh'}
          onChangeText={onChangeFormInput.bind(null, 'lastName')}
          errorMessage={getNameValidationError(form.lastName)}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      <View style={theme.forms.row}>
        <InputPhone
          testID="input-phone"
          title={translate('form.phoneNumber')}
          value={form.phone}
          onChangeText={onChangeFormInput.bind(null, 'phone')}
          errorMessage={
            form.loyaltyMember && !form.phone
              ? translate('customer.invalidPhoneMessage')
              : undefined
          }
          onPressCountry={onChangeFormInput.bind(null, 'countryCode')}
          containerStyle={theme.forms.inputHalf}
          defaultCountry={form?.countryCode}
        />
        <InputEmail
          testID="input-email"
          title={translate('common.emailAddress')}
          value={form.email}
          placeholder={'jenny@email.com'}
          onChangeText={onChangeFormInput.bind(null, 'email')}
          containerStyle={theme.forms.inputHalf}
        />
      </View>
      {isOolioLoyaltyEnabled && (
        <View style={theme.forms.row}>
          <InputToggle
            testID="toggle-loyalty-status"
            isToggled={form.loyaltyMember}
            onToggle={onChangeFormInput.bind(
              null,
              'loyaltyMember',
              !form.loyaltyMember,
            )}
            type="switch"
            title={translate('form.enrolToLoyalty')}
            subtitle={translate('form.enrolToLoyaltySubtitle')}
            containerStyle={theme.forms.inputFluid}
          />
        </View>
      )}
    </TreatModal>
  );
};

export default CreateCustomerModal;
