import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const SettingsOptionsStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      marginBottom: 4,
      paddingHorizontal: 14,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
    },
    content: {
      minHeight: 50,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    disclosure: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    value: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    icon: {
      marginLeft: 12,
      alignItems: 'center',
      justifyContent: 'center',
    },
    action: {
      height: 50,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
    },
    actionLabel: {
      fontSize: 14,
      fontFamily: theme.fonts.medium,
    },
    errorView: {
      paddingVertical: 12,
      borderTopWidth: 1,
      borderTopColor: colors.borderColor,
    },
    errorText: {
      fontSize: 12,
      lineHeight: 16,
      color: colors.red,
      fontFamily: theme.fonts.medium,
    },
  });
};

export default SettingsOptionsStyles;
