import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import { useTheme } from '../../../../../hooks/useTheme';

const OnlineOrderSidePanelStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 340,
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 6,
      rowGap: 4,
      backgroundColor: colors.background,
      ...theme.shadow.light,
    },
    placeholder: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    content: {
      padding: 10,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    segments: {
      marginBottom: 20,
    },
    row: {
      paddingBottom: 16,
      paddingHorizontal: 12,
      marginBottom: 16,
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    rowTitle: {
      fontSize: 14,
      lineHeight: 20,
      marginBottom: 6,
      color: theme.colors.grey6,
    },
    rowValue: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      color: colors.text,
    },
    actions: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
    },
    btnAction: {
      flex: 1,
    },
  });
};

export default OnlineOrderSidePanelStyles;
