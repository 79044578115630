import React from 'react';
import { Text, View } from 'react-native';
import { Order, Table, TableStatus } from '@oolio-group/domain';
import { translate } from '@oolio-group/localization';
import { useTheme } from '../../../../../../hooks/useTheme';
import FloorViewStyles from '../../FloorView.styles';

interface FloorViewActionsProps {
  tables: Table[];
  inProgressOrders: Order[];
  tableToOrdersMap?: Record<string, Order[]>;
}

const FloorViewLegend: React.FC<FloorViewActionsProps> = ({
  tables,
  inProgressOrders,
  tableToOrdersMap,
}) => {
  const numberOfSeatedTables = inProgressOrders.filter(
    order => order.table.status === TableStatus.SEATED,
  ).length;
  const numberOfOccupiedTables = inProgressOrders.filter(
    order => order.table.status === TableStatus.OCCUPIED,
  ).length;
  const numberOfDoneSoonTables = inProgressOrders.filter(
    order => order.table.status === TableStatus.DONE_SOON,
  ).length;
  const numberOfAvailableTables = tables.filter(
    table => table.status === TableStatus.AVAILABLE || !table.status,
  ).length;
  const multipleOrderTables = tables.filter(
    table =>
      table.id && tableToOrdersMap && tableToOrdersMap[table.id]?.length > 1,
  ).length;

  const { colors } = useTheme();
  const styles = FloorViewStyles();

  return (
    <View style={styles.legend}>
      <View style={styles.status}>
        <View
          style={[
            styles.marker,
            {
              backgroundColor: colors.blueLightest,
              borderColor: colors.blue,
            },
          ]}
        />
        <Text testID="count-occupied" style={styles.statusText}>
          {translate('tableFloorView.seatedTablesAmount', {
            number: numberOfSeatedTables,
          })}
        </Text>
      </View>
      <View style={styles.status}>
        <View style={[styles.marker, { backgroundColor: colors.blue }]} />
        <Text testID="count-occupied" style={styles.statusText}>
          {translate('tableFloorView.occupiedTablesAmount', {
            number: numberOfOccupiedTables,
          })}
        </Text>
      </View>
      <View style={styles.status}>
        <View style={[styles.marker, { backgroundColor: colors.green }]} />
        <Text testID="count-doneSoon" style={styles.statusText}>
          {translate('tableFloorView.doneSoonTablesAmount', {
            number: numberOfDoneSoonTables,
          })}
        </Text>
      </View>
      <View style={styles.status}>
        <View style={[styles.marker, { backgroundColor: colors.deepPurple }]} />
        <Text testID="count-multiple" style={styles.statusText}>
          {translate('tableFloorView.multipleTablesAmount', {
            number: multipleOrderTables,
          })}
        </Text>
      </View>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={[styles.status, { marginRight: 0 }]}>
        <View style={[styles.marker, { backgroundColor: colors.grey3 }]} />
        <Text testID="count-available" style={styles.statusText}>
          {translate('tableFloorView.availableTablesAmount', {
            number: numberOfAvailableTables,
          })}
        </Text>
      </View>
    </View>
  );
};

export default FloorViewLegend;
