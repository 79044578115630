import React from 'react';
import { View, Modal, StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import LoadingIndicator from './LoadingIndicator';

export interface LoadingOverlayProps {
  testID?: string;
  isLoading?: boolean;
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isLoading,
  testID,
}: LoadingOverlayProps) => {
  if (isLoading) {
    return (
      <Modal
        testID={testID}
        visible={true}
        transparent={true}
        animationType="fade"
        presentationStyle="overFullScreen"
      >
        <View style={styles.background}>
          <View style={styles.container}>
            <LoadingIndicator large />
          </View>
        </View>
      </Modal>
    );
  }
  return null;
};

export default LoadingOverlay;

const styles = StyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.modalBg,
  },
  container: {
    width: 120,
    height: 120,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
    ...theme.shadow.strong,
  },
});
