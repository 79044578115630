import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Reservation } from '@oolio-group/domain';
import { useTranslation, formatPhoneNumber } from '@oolio-group/localization';
import ButtonText from '../../../../../../components/Shared/TreatButton/TreatButton';
import Icon from '../../../../../../components/Icon/Icon';
import { format, parse } from 'date-fns';
import theme from '../../../../../../common/default-theme';
import styles from './UpcomingReservationModal.styles';

interface UpcomingReservationModalProps {
  reservation?: Reservation;
  onSeatReservation: () => void;
  onClose?: () => void;
}

const UpcomingReservationModal: React.FC<UpcomingReservationModalProps> = ({
  reservation,
  onClose = () => undefined,
  onSeatReservation,
}: UpcomingReservationModalProps) => {
  const { translate } = useTranslation();

  const parsedDate =
    reservation &&
    parse(reservation.real_datetime_of_slot, 'yyyy-MM-dd HH:mm:ss', new Date());

  const slotTime = parsedDate && format(parsedDate, 'EEE, MMM dd - hh:mm a');
  const startTime = parsedDate && format(parsedDate, 'hh:mm a');

  return (
    <View style={styles.container}>
      <Icon
        name={'exclamation-triangle'}
        color={theme.colors.states.focus}
        size={40}
        style={styles.icon}
      />
      <Text style={styles.titleText}>
        {translate('reservations.upcomingReservation')}
      </Text>
      <Text style={styles.subTitle}>
        {translate('reservations.seatPrompt', {
          time: startTime,
        })}
      </Text>
      <TouchableOpacity
        style={styles.reservationContainer}
        onPress={onSeatReservation}
      >
        <View>
          <Text style={styles.timeText}>{slotTime}</Text>
          <Text style={styles.nameText}>{reservation?.full_name}</Text>
          <Text style={styles.phoneText}>
            {formatPhoneNumber(reservation?.phone_number ?? '', {
              intl: false,
            })}
          </Text>
          <Text style={styles.statusText}>{reservation?.status_display}</Text>
        </View>
        <Icon name="angle-right" size={24} color={theme.colors.grey6} />
      </TouchableOpacity>
      <ButtonText
        type="cancel"
        label={translate('button.dismiss')}
        onPress={onClose}
      />
    </View>
  );
};

export default UpcomingReservationModal;
