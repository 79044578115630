import { StyleSheet } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';

const SendReceiptModalStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      width: 340,
      alignSelf: 'center',
      rowGap: 8,
    },
    content: {
      rowGap: 30,
      padding: 30,
      borderRadius: theme.radius.s,
      backgroundColor: colors.white,
      ...theme.shadow.strong,
    },
    form: {
      rowGap: 20,
    },
  });
};

export default SendReceiptModalStyles;
