import React from 'react';
import { View, TouchableOpacity, ViewStyle } from 'react-native';
import Icon from '../../../Icon/Icon';
import styles from './PaginationTile.styles';
import { useTheme } from '../../../../hooks/useTheme';

interface PaginationTileProps {
  totalPages: number;
  currentPage: number;
  onPressNext: () => void;
  onPressBack: () => void;
  containerStyle?: ViewStyle | ViewStyle[];
}

interface ButtonProps {
  icon: string;
  testID: string;
  isDisabled: boolean;
  onPress: () => void;
}

const PaginationButton: React.FC<ButtonProps> = ({
  icon,
  testID,
  isDisabled,
  onPress,
}) => {
  const { colors, activeTheme } = useTheme();
  const isThemeLight = activeTheme === 'light';

  return (
    <TouchableOpacity
      testID={testID}
      onPress={onPress}
      disabled={isDisabled}
      style={[
        styles.btn,
        {
          backgroundColor: isDisabled
            ? colors.transparent
            : isThemeLight
            ? colors.white
            : colors.elevation1,
        },
      ]}
    >
      <Icon
        size={24}
        name={icon}
        color={isDisabled ? colors.disclosure : colors.text}
      />
    </TouchableOpacity>
  );
};

const PaginationTile: React.FC<PaginationTileProps> = ({
  totalPages,
  currentPage,
  onPressNext,
  onPressBack,
  containerStyle,
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <PaginationButton
        testID="btn-paginateBack"
        onPress={onPressBack}
        isDisabled={currentPage <= 1}
        icon="angle-left"
      />
      <PaginationButton
        testID="btn-paginateNext"
        onPress={onPressNext}
        isDisabled={currentPage >= totalPages}
        icon="angle-right"
      />
    </View>
  );
};

export default PaginationTile;
