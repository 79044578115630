import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const SetGridSizeModalStyles = () => {
  const { colors } = useTheme();

  const textStyle: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  };

  return StyleSheet.create({
    container: {
      width: 340,
      alignSelf: 'center',
    },
    content: {
      padding: 30,
      marginTop: 8,
      rowGap: 16,
      borderRadius: theme.radius.m,
      backgroundColor: colors.elevation0,
      ...theme.shadow.strong,
    },
    group: {
      rowGap: 14,
    },
    text: {
      ...textStyle,
      color: colors.textLight,
      paddingLeft: 14,
    },
    grid: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      rowGap: 4,
      columnGap: 4,
    },
    btnGrid: {
      width: 67,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation1,
    },
    btnGridSelected: {
      backgroundColor: colors.blue,
    },
    btnGridText: {
      ...textStyle,
      color: colors.text,
    },
    btnGridTextSelected: {
      color: colors.white,
    },
  });
};

export default SetGridSizeModalStyles;
