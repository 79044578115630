import {
  IntegrationApps,
  IntegrationPartner,
  Order,
  OrderStatus,
} from '@oolio-group/domain';
import { useCallback, useEffect, useRef } from 'react';
import { useOrders } from './useOrders';
import { useOnlineOrderEvents } from './useOnlineOrderEvents';

export const useAutoCompleteOrders = (
  appName: IntegrationApps,
  integrationPartnerMaps: Record<string, IntegrationPartner>,
) => {
  const { preferences } =
    integrationPartnerMaps[appName] || ({} as IntegrationPartner);
  const callbackFnRef = useRef<() => void>();
  const { returnOrdersFromCache } = useOrders();
  const { completeOrder } = useOnlineOrderEvents({ integrationPartnerMaps });

  const autoCompleteOrders = preferences?.onlineOrdering?.autoCompleteOrders;
  const periodInMinutes = preferences?.onlineOrdering?.autoCompletePeriod;

  const isOrderValid = useCallback(
    (order: Order) => {
      return (
        order.source === appName &&
        order.status === OrderStatus.IN_PROGRESS &&
        order.amountDue !== undefined &&
        order.amountDue <= 0
      );
    },
    [appName],
  );

  const processAutoCompleteOrders = useCallback(async () => {
    const orders = returnOrdersFromCache(OrderStatus.IN_PROGRESS, true);
    const paidOrders = orders?.filter(isOrderValid);
    if (paidOrders?.length) {
      for (let i = 0; i < paidOrders.length; i++) {
        const order = paidOrders[i];
        await completeOrder(order);
      }
    }
  }, [completeOrder, isOrderValid, returnOrdersFromCache]);

  useEffect(() => {
    callbackFnRef.current = processAutoCompleteOrders;
  }, [processAutoCompleteOrders]);

  useEffect(() => {
    if (autoCompleteOrders && periodInMinutes) {
      const id = setInterval(
        () => callbackFnRef.current && callbackFnRef.current(),
        periodInMinutes * 60 * 1000,
      );
      return () => {
        clearInterval(id);
      };
    }
  }, [autoCompleteOrders, periodInMinutes]);
};
