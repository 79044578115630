import React, { useCallback, useState } from 'react';
import { View, Text } from 'react-native';
import {
  AdjustmentUnit,
  CUSTOM_ADJUSTMENT_ID,
  Adjustment,
  AdjustmentType,
  OrderItem,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { translate, useCurrency } from '@oolio-group/localization';
import {
  getAdjustmentValue,
  computeLineTotal,
} from '@oolio-group/order-helper';
import { addToValue } from '../../../utils/keypadHelper';
import { useNotification } from '../../../hooks/Notification';
import { MAX_KEYPAD_INPUT } from '../../../types/Common';
import SetPriceModalStyles from './SetPriceModal.styles';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import ModalTitle from '../../POS/Modals/ModalTitle/ModalTitle';
import CartProduct from '../../POS/Cart/CartProduct/CartProduct';
import NumberPad from '../../POS/Modals/Keypad/NumberPad/NumberPad';

interface Props {
  defaultPrice: number;
  item: OrderItem;
  keypadInput?: string;
  maxDiscounts?: number;
  maxSellingPrice?: number;
  minSellingPrice?: number;
  priceOverrideMode?: 'default' | 'discount';
  title?: string;
  onSubmit: (price: number, adjustments: Adjustment[]) => void;
}

const MAX_PERCENTAGE = 100;

export function adjustDiscounts(
  adjustments: Adjustment[],
  maxDiscounts = Infinity,
) {
  return adjustments.slice(-maxDiscounts);
}

export const SetPriceModal: React.FC<Props> = ({
  defaultPrice,
  item,
  keypadInput,
  maxDiscounts,
  maxSellingPrice,
  minSellingPrice,
  priceOverrideMode = 'default',
  title,
  onSubmit,
}) => {
  const { closeModal } = useModal();
  const { showNotification } = useNotification();
  const {
    formatCurrency,
    currencySymbol,
    decimalSeparator,
    unFormatCurrency,
    formatMoneyValue,
  } = useCurrency();

  const styles = SetPriceModalStyles();

  const [input, setInput] = useState<string>(keypadInput || '');
  const [unitPrice, adjustUnitPrice] = useState(item.unitPrice);
  const [adjustments, addAdjustment] = useState<Adjustment[]>(
    item.adjustments || [],
  );

  const modifiersText =
    item.modifiers?.length > 1
      ? `${item.modifiers[0].name} + ${item.modifiers.length - 1} ${translate(
          'modifiers.modifiers',
        )}`
      : item.modifiers?.length === 1
      ? `${item.modifiers[0].name}`
      : '';

  const originalTotal = computeLineTotal({
    ...item,
    unitPrice: defaultPrice,
    discounts: [],
  });

  const totalBeforeDiscount = computeLineTotal({
    ...item,
    unitPrice,
    discounts: [],
  });

  const totalAfterDiscount =
    totalBeforeDiscount + getAdjustmentValue(totalBeforeDiscount, adjustments);

  const onPressKey = useCallback(
    (key: string): void => {
      setInput(value =>
        addToValue({ value, key, decimalSeparator, length: MAX_KEYPAD_INPUT }),
      );
    },
    [decimalSeparator],
  );

  const onPressPercentOff = useCallback(() => {
    setInput(input => {
      if (unFormatCurrency(input) > MAX_PERCENTAGE) {
        showNotification({
          message: translate('order.maxDiscountWarning', { value: '100%' }),
          error: true,
        });
        return '';
      }

      addAdjustment(adjustment =>
        adjustDiscounts(
          [
            ...adjustment,
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: -unFormatCurrency(input),
              adjustmentUnit: AdjustmentUnit.PERCENTAGE,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.DISCOUNT,
            },
          ],
          maxDiscounts,
        ),
      );
      return '';
    });
  }, [maxDiscounts, showNotification, unFormatCurrency]);

  const onPressDollarOff = useCallback(() => {
    setInput(input => {
      if (unFormatCurrency(input) > originalTotal) {
        showNotification({
          message: translate('order.maxDiscountWarning', {
            value: `${formatCurrency(originalTotal)}`,
          }),
          error: true,
        });
        return '';
      }

      addAdjustment(adjustment =>
        adjustDiscounts(
          [
            ...adjustment,
            {
              id: CUSTOM_ADJUSTMENT_ID,
              amount: -unFormatCurrency(input),
              adjustmentUnit: AdjustmentUnit.FLAT,
              allowOnPaymentType: false,
              adjustmentType: AdjustmentType.DISCOUNT,
            },
          ],
          maxDiscounts,
        ),
      );
      return '';
    });
  }, [
    maxDiscounts,
    originalTotal,
    showNotification,
    formatCurrency,
    unFormatCurrency,
  ]);

  const onPressAdjust = useCallback(() => {
    setInput(input => {
      const price = unFormatCurrency(input);

      if (!price) {
        return input;
      }

      if (minSellingPrice && price < minSellingPrice) {
        showNotification({
          message: translate('order.minPriceWarning', {
            price: formatCurrency(minSellingPrice),
          }),
          error: true,
        });
      } else if (maxSellingPrice && price > maxSellingPrice) {
        showNotification({
          message: translate('order.maxPriceWarning', {
            price: formatCurrency(maxSellingPrice),
          }),
          error: true,
        });
      } else {
        if (priceOverrideMode === 'default') {
          adjustUnitPrice(price);
        } else {
          addAdjustment(
            adjustDiscounts(
              [
                {
                  id: CUSTOM_ADJUSTMENT_ID,
                  amount: price - unitPrice,
                  adjustmentUnit: AdjustmentUnit.FLAT,
                  allowOnPaymentType: false,
                  adjustmentType:
                    price - unitPrice > 0
                      ? AdjustmentType.SURCHARGE
                      : AdjustmentType.DISCOUNT,
                },
              ],
              maxDiscounts,
            ),
          );
        }
      }

      return '';
    });
  }, [
    minSellingPrice,
    maxSellingPrice,
    showNotification,
    formatCurrency,
    priceOverrideMode,
    unitPrice,
    maxDiscounts,
    unFormatCurrency,
  ]);

  const onPressRevert = useCallback(() => {
    setInput('');
    addAdjustment([]);
    adjustUnitPrice(defaultPrice);
  }, [defaultPrice]);

  const onPressSubmit = useCallback(() => {
    closeModal();
    onSubmit(unitPrice, adjustments);
  }, [onSubmit, adjustments, unitPrice, closeModal]);

  const priceVariation = totalAfterDiscount / originalTotal;
  const variationPercent = Math.abs((1 - priceVariation) * 100);
  const variationFormatted = `${
    totalAfterDiscount < originalTotal ? '-' : '+'
  }${variationPercent.toFixed(2)}%`;

  return (
    <View style={styles.container}>
      <ModalTitle
        title={title || translate('order.setItemPrice')}
        onDismiss={closeModal}
      />
      <View style={styles.content}>
        {item && item.product && (
          <View style={styles.item}>
            <CartProduct minimised testID="product" orderItem={item} />
            {modifiersText !== '' && (
              <Text numberOfLines={1}>{modifiersText}</Text>
            )}
          </View>
        )}
        <View style={styles.display}>
          <Text style={styles.title}>
            {formatMoneyValue(unFormatCurrency(input) || totalAfterDiscount)}
          </Text>
          <Text style={styles.subtitle}>{variationFormatted}</Text>
        </View>
        <View style={styles.keypad}>
          <View style={styles.numberPad}>
            <NumberPad
              keypadButtons={[
                { value: '1' },
                { value: '2' },
                { value: '3' },
                { value: '4' },
                { value: '5' },
                { value: '6' },
                { value: '7' },
                { value: '8' },
                { value: '9' },
                { value: '0' },
                { value: decimalSeparator },
              ]}
              zeroButtonWidth={184.66}
              onPressKey={onPressKey}
            />
          </View>
          <View style={styles.actions}>
            <TreatButton
              testID="percent-off-button"
              height={70}
              // type="neutralLight"
              label={translate('button.percentOff')}
              onPress={onPressPercentOff}
            />
            <TreatButton
              testID="dollar-off-button"
              height={70}
              // type="neutralLight"
              label={translate('button.dollarOff', {
                currency: currencySymbol,
              })}
              onPress={onPressDollarOff}
            />
            <TreatButton
              testID="adjust-button"
              height={70}
              uppercase
              // type="neutralLight"
              label={translate('button.adjust')}
              onPress={onPressAdjust}
            />
            <TreatButton
              testID="percent-off-button"
              height={70}
              uppercase
              // type="neutralLight"
              label={translate('button.revert')}
              onPress={onPressRevert}
            />
          </View>
        </View>
        <TreatButton
          testID="submit-button"
          type="positive"
          height={50}
          onPress={onPressSubmit}
          label={translate('button.applyChanges')}
        />
      </View>
    </View>
  );
};
