import React, { useCallback } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import ModalDialog from '../Modal';
import styles from './NoBarcodeMatch.styles';
import TreatButton from '../../Shared/TreatButton/TreatButton';
import Icon from '../../Icon/Icon';
import theme from '../../../common/default-theme';

export enum SearchType {
  CUSTOMER = 'CUSTOMER',
  PRODUCT = 'PRODUCT',
}
interface NoBarcodeMatchModalProps {
  title: string;
  message: string;
  onSearch: () => void;
  searchTarget: SearchType;
}

const NoBarcodeMatchModal: React.FC<NoBarcodeMatchModalProps> = ({
  title,
  message,
  onSearch,
  searchTarget,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();

  const onPressSearch = useCallback(() => {
    closeModal();
    onSearch();
  }, [closeModal, onSearch]);

  const onPressDismiss = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const getSearchButtonLabel = (searchTarget: SearchType): string => {
    switch (searchTarget) {
      case SearchType.PRODUCT:
        return translate('productSettings.searchProduct');
      case SearchType.CUSTOMER:
        return translate('barcodeModal.searchCustomer');
    }
  };

  return (
    <ModalDialog
      onDismiss={onPressDismiss}
      showCloseButton={false}
      contentStyle={styles.container}
    >
      <Icon
        name="exclamation-triangle"
        size={36}
        color={theme.colors.states.focus}
        style={styles.icon}
      />
      <Text style={styles.header}>{title}</Text>
      <Text style={styles.message}>{message}</Text>
      <TreatButton
        testID="btn-searchProduct"
        label={getSearchButtonLabel(searchTarget)}
        type="neutral"
        onPress={onPressSearch}
        containerStyle={styles.btnSearchProduct}
      />
      <TreatButton
        testID="btn-dismiss"
        label={translate('button.dismiss')}
        type="cancel"
        onPress={onPressDismiss}
      />
    </ModalDialog>
  );
};

export default NoBarcodeMatchModal;
