import React from 'react';
import { Text, View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { ButtonTextProps } from './Button.types';
import theme from '../../../common/default-theme';
import ButtonStyles from './Button.styles';
import Icon from '../../Icon/Icon';

const TreatButton: React.FC<ButtonTextProps> = ({
  testID,
  type = 'cancel',
  label,
  icon,
  height = 44,
  disabled = false,
  isLoading = false,
  uppercase = false,
  themed = false,
  onPress,
  containerStyle,
}: ButtonTextProps) => {
  const styles = ButtonStyles({
    type,
    themed,
    disabled,
    uppercase,
  });

  return (
    <View style={containerStyle}>
      <TouchableOpacity
        testID={testID}
        style={[{ height: height }, styles.container, styles.btnText]}
        onPress={onPress}
        disabled={disabled}
      >
        {isLoading ? (
          <ActivityIndicator
            color={
              disabled
                ? theme.colors.dark
                : type?.includes('Light')
                ? theme.colors.dark
                : theme.colors.light
            }
          />
        ) : (
          <>
            <Text style={[styles.text, styles.fgColor]}>{label}</Text>
            {icon ? (
              <Icon size={20} name={icon} style={styles.fgColor} />
            ) : null}
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default TreatButton;
