/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Platform, View, Text, ViewStyle } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import {
  TIMEZONES_MAP,
  COUNTRY_CODE_CURRENCY_MAP,
  listCountries,
  Timezone,
  CountryCurrencyMap,
  Country,
  useTranslation,
} from '@oolio-group/localization';
import Select from './Select';
import PickerStyles from './Picker.styles';
import SelectStyles from './Select.styles';

export interface PickerOption {
  label: string;
  value: string;
  enabled?: boolean;
}

export interface TreatPickerProps {
  title?: string;
  testID: string;
  label?: string;
  editable?: boolean;
  selectedValue?: string;
  options: PickerOption[] | 'countries' | 'timezones' | 'currencies';
  containerStyle?: ViewStyle | ViewStyle[];
  onValueChange?: (itemValue: any, itemPosition: number) => void;
  secondary?: boolean;
  extraWidth?: number;
  themed?: boolean;
}

const TreatPicker: React.FC<TreatPickerProps> = ({
  testID,
  title,
  label,
  editable,
  containerStyle,
  options,
  selectedValue,
  secondary,
  extraWidth,
  themed,
  onValueChange,
}) => {
  const { translate } = useTranslation();

  const selectStyles = SelectStyles({ themed });
  const styles = PickerStyles({ themed, secondary });

  let pickerOptions: any[] = [];

  switch (options) {
    case 'countries':
      const COUNTRIES = listCountries().sort((country1, country2) =>
        country1.name.localeCompare(country2.name),
      );

      const COUNTRY_OPTIONS = [{ label: 'Select...', value: '' }].concat(
        COUNTRIES.map((_country: Country) => ({
          label: _country.name,
          value: _country.code,
        })),
      );

      pickerOptions = COUNTRY_OPTIONS;
      break;
    case 'timezones':
      const TIMEZONES: Array<Timezone> = Object.values(TIMEZONES_MAP).sort(
        (t1, t2) => t1.name.localeCompare(t2.name),
      );

      const TIMEZONE_OPTIONS = [{ label: 'Select...', value: '' }].concat(
        TIMEZONES.map((timezone: Timezone) => ({
          label: `${timezone.name} (UTC ${timezone.utcOffsetStr})`,
          value: timezone.name,
        })),
      );

      pickerOptions = TIMEZONE_OPTIONS;
      break;
    case 'currencies':
      const CURRENCY_OPTIONS = [{ label: 'Select...', value: '' }].concat(
        COUNTRY_CODE_CURRENCY_MAP.map((_currency: CountryCurrencyMap) => ({
          label: _currency.name,
          value: _currency.abbr,
        })),
      );

      pickerOptions = CURRENCY_OPTIONS;
      break;
    default:
      pickerOptions = options;
      break;
  }

  return (
    <View testID={testID} style={containerStyle}>
      {!!title && <Text style={selectStyles.inputTitleText}>{title}</Text>}
      {Platform.OS === 'web' ? (
        <View style={secondary ? styles.secondaryContainer : styles.container}>
          {!!label && (
            <View style={selectStyles.labelContainer}>
              <Text style={selectStyles.labelText}>{label}</Text>
            </View>
          )}
          <Picker
            mode="dropdown"
            enabled={editable}
            accessibilityRole="menu"
            selectedValue={selectedValue}
            onValueChange={onValueChange}
            style={styles.pickerStyle}
          >
            {pickerOptions.map(option => (
              <Picker.Item
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </Picker>
        </View>
      ) : (
        <Select
          testID={testID}
          themed={themed}
          options={pickerOptions}
          placeholder={translate('backOfficeFeatures.select')}
          onValueChange={onValueChange}
          selectedValue={selectedValue}
          containerStyle={
            extraWidth || secondary ? { width: extraWidth || 70 } : undefined
          }
        />
      )}
    </View>
  );
};

export default TreatPicker;
