import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const TransferPreviewStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    backdrop: {
      flex: 1,
      position: 'absolute',
      right: 0,
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    container: {
      flex: 1,
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: 320,
      padding: 6,
      backgroundColor: colors.background,
      rowGap: 4,
    },
    content: {
      flex: 1,
      padding: 10,
      width: '100%',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    itemsContainer: {
      flex: 1,
      width: '100%',
    },
    scrollContainer: {
      flex: 1,
      width: '100%',
    },
    footer: {
      height: 44,
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
      paddingHorizontal: 16,
      paddingVertical: 12,
    },
    footerText: {
      textAlign: 'center',
      fontFamily: theme.fonts.medium,
      fontSize: 16,
      lineHeight: 22,
      color: colors.text,
    },
    btnContainer: {
      width: '100%',
    },
  });
};

export default TransferPreviewStyles;
