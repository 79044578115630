import React, { useCallback } from 'react';
import { View, Text } from 'react-native';
import { PaymentAction } from '@oolio-group/domain';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useCart } from '../../../../hooks/orders/useCart';
import {
  OrderPaymentDetails,
  PaymentActionProp,
} from '../../../../screens/POS/Payments/PaymentScreen';
import PaymentCompleteStyles from './PaymentComplete.styles';
import Icon from '../../../Icon/Icon';
import OnHoldOrderOptions from '../OnHoldPaymentActions';
import PaymentHeader from '../PaymentHeader/PaymentHeader';
import TreatButton from '../../../Shared/TreatButton/TreatButton';
import ButtonActions from '../../../Shared/TreatButton/ButtonActions';
import { useTheme } from '../../../../hooks/useTheme';

const BTN_HEIGHT = 50;

export interface PaymentCompleteProps {
  changeDueAmount: number;
  earnedPointInfo?: string;
  loyaltyAction: React.ReactElement | null;
  orderPaymentDetails: OrderPaymentDetails;
  receivedAmount: number;
  showPrintReceiptButton: boolean;
  dispatch: React.Dispatch<PaymentActionProp>;
  onCompleteSale: () => void;
  onPressPrintReceipt: () => void;
  onPressSendReceipt: () => void;
  onPrintSplit: () => void;
  onTakeNextPayment: () => void;
}

const PaymentComplete: React.FC<PaymentCompleteProps> = ({
  changeDueAmount,
  earnedPointInfo,
  loyaltyAction,
  orderPaymentDetails,
  receivedAmount,
  showPrintReceiptButton,
  dispatch,
  onCompleteSale,
  onPressPrintReceipt,
  onPressSendReceipt,
  onPrintSplit,
  onTakeNextPayment,
}) => {
  const {
    isSplitPayment,
    remainingDueAmount,
    isPaymentCompleted,
    totalDueAmount,
    isCustomerBalancePayment,
    isSplitBySeat,
    processingPaymentSeat,
    nthPayment,
  } = orderPaymentDetails;

  const { colors } = useTheme();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { order, updateCart, openOrderCart } = useCart();

  const styles = PaymentCompleteStyles();

  const handleNextStepAction = useCallback(() => {
    if (isPaymentCompleted) {
      onCompleteSale();
    } else {
      onTakeNextPayment();
    }
  }, [isPaymentCompleted, onCompleteSale, onTakeNextPayment]);

  const onCloseSubPaymentScreen = useCallback(() => {
    dispatch({ type: PaymentAction.CANCEL_CURRENT_STEP, payload: {} });
  }, [dispatch]);

  const getTitleText = () => {
    return isSplitBySeat
      ? translate('payment.splitPaymentBySeat', {
          seat: processingPaymentSeat,
        })
      : nthPayment < 1 && isPaymentCompleted
      ? translate('cdsPaymentScreen.paymentComplete')
      : translate('payment.splitPaymentStep', {
          step: nthPayment,
        });
  };

  const sendReceiptButton = (
    <TreatButton
      testID="btn-sendReceipt"
      themed
      height={BTN_HEIGHT}
      type="neutralLight"
      label={translate('common.sendReceipt')}
      onPress={onPressSendReceipt}
      containerStyle={styles.btn}
    />
  );

  return (
    <View style={styles.container}>
      <PaymentHeader
        title={getTitleText()}
        onClose={!isPaymentCompleted ? onCloseSubPaymentScreen : undefined}
      />
      <View style={styles.content}>
        <View style={styles.response}>
          <View style={styles.icon}>
            <Icon name="check" size={30} color={colors.green} />
          </View>
          <Text testID="change-due" style={styles.title}>
            {changeDueAmount
              ? translate('payment.changeDueAmount', {
                  changeDue: formatCurrency(changeDueAmount || 0),
                })
              : translate('payment.noChangeDue')}
          </Text>
          <Text testID="amount-paid" style={styles.subtitle}>
            {translate('payment.amountPaid', {
              amount: formatCurrency(receivedAmount || 0),
            })}
          </Text>
          {isPaymentCompleted && earnedPointInfo ? (
            <Text testID="points-earned" style={styles.subtitle}>
              {earnedPointInfo}
            </Text>
          ) : null}
          {!isPaymentCompleted ? (
            <Text testID="remaining-amt" style={styles.textRemaining}>
              {translate('payment.remainingAmountOutOfTotal', {
                willRemainingAmount: formatCurrency(remainingDueAmount),
                totalDueAmount: formatCurrency(totalDueAmount),
              })}
            </Text>
          ) : null}
        </View>
        <View style={styles.actions}>
          <OnHoldOrderOptions
            updateCart={updateCart}
            openOrderCart={openOrderCart}
            order={order}
          />
          <View style={styles.actionsRow}>
            <TreatButton
              testID="btn-nextStep"
              height={60}
              type="positive"
              label={
                isPaymentCompleted
                  ? translate('payment.completeSale')
                  : translate('payment.takeNextPayment')
              }
              onPress={handleNextStepAction}
              containerStyle={styles.btn}
            />
          </View>
          {showPrintReceiptButton ? (
            !isCustomerBalancePayment ? (
              !isSplitPayment ? (
                <View style={styles.actionsRow}>
                  <TreatButton
                    testID="btn-printReceipt"
                    height={BTN_HEIGHT}
                    themed
                    type="neutralLight"
                    label={translate('payment.printReceipt')}
                    onPress={onPressPrintReceipt}
                    containerStyle={styles.btn}
                  />
                  {sendReceiptButton}
                  {loyaltyAction}
                </View>
              ) : isPaymentCompleted ? (
                <View style={styles.actionsRow}>
                  <ButtonActions
                    testID="btn-printOptions"
                    height={BTN_HEIGHT}
                    themed
                    type="neutralLight"
                    label="Print Options"
                    actions={[
                      {
                        label: translate('payment.printSplit'),
                        action: onPrintSplit,
                      },
                      {
                        label: translate('common.printReceipt'),
                        action: onPressPrintReceipt,
                      },
                    ]}
                    containerStyle={styles.btn}
                  />
                  {sendReceiptButton}
                  {loyaltyAction}
                </View>
              ) : (
                <View style={styles.actionsRow}>
                  <TreatButton
                    testID="btn-printSplit"
                    height={BTN_HEIGHT}
                    themed
                    type="neutralLight"
                    label={translate('payment.printSplit')}
                    onPress={onPrintSplit}
                    containerStyle={styles.btn}
                  />
                  {loyaltyAction}
                </View>
              )
            ) : null
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default PaymentComplete;
