import { StyleSheet, TextStyle } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';
import { CART_FONT_SIZE } from '../Cart.styles';

const COMBO_MARGIN = 30;

const CartProductStyles = ({ themed = false }) => {
  const { colors } = useTheme();

  const getCartTextStyle = (): TextStyle => ({
    fontSize: CART_FONT_SIZE,
    lineHeight: 20,
    paddingVertical: 4,
    color: themed ? colors.text : colors.black1,
    fontFamily: theme.fonts.medium,
    fontVariant: ['tabular-nums'],
  });

  return StyleSheet.create({
    container: {
      minHeight: 28,
      marginBottom: 4,
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    adjustmentMargin: {
      marginLeft: 32,
    },
    variation: {
      marginLeft: 32,
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
    },
    quantity: {
      minWidth: 28,
      height: '100%',
      marginRight: 4,
      borderRadius: theme.radius.s,
    },
    quantityText: {
      textAlign: 'center',
      ...getCartTextStyle(),
    },
    comboProduct: {
      marginLeft: COMBO_MARGIN,
    },
    savingsText: {
      marginLeft: COMBO_MARGIN + 8,
      color: colors.accent,
      fontFamily: theme.fonts.medium,
    },
    name: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
    },
    nameText: {
      flex: 1,
      ...getCartTextStyle(),
    },
    modifierText: {
      color: themed ? colors.textLight : colors.grey6,
    },
    priceText: {
      minWidth: 60,
      marginLeft: 10,
      textAlign: 'right',
      ...getCartTextStyle(),
    },
    voidReason: {
      marginLeft: 32,
      paddingHorizontal: 10,
      borderRadius: theme.radius.s,
      backgroundColor: themed ? colors.redLightest : theme.colors.redLightest,
    },
    voidView: {
      backgroundColor: themed ? colors.redLightest : theme.colors.redLightest,
    },
    voidText: {
      color: colors.red,
      textDecorationLine: 'line-through',
    },
    transferredView: {
      opacity: 0.6,
    },
    transferredText: {
      textDecorationLine: 'line-through',
    },
    paidView: {
      opacity: 0.6,
      backgroundColor: themed
        ? colors.greenLightest
        : theme.colors.greenLightest,
    },
    paidText: {
      color: colors.green,
    },
    selectedView: {
      backgroundColor: colors.blue,
    },
    selectedText: {
      color: colors.white,
    },
    partialSelectedView: {
      backgroundColor: themed ? colors.blueLight : theme.colors.blueLight,
    },
    partialSelectedText: {
      color: themed ? colors.text : colors.black1,
    },
    identifier: {
      marginRight: 10,
      top: 6,
      height: 14,
      width: 14,
    },
    seatText: {
      fontSize: CART_FONT_SIZE,
      lineHeight: 20,
      paddingLeft: 6,
      paddingVertical: 4,
      color: colors.grey6,
      fontFamily: theme.fonts.medium,
    },
    firedText: {
      lineHeight: 20,
      paddingRight: 8,
      paddingVertical: 4,
      color: colors.green,
    },
  });
};

export default CartProductStyles;
