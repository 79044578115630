import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, Platform } from 'react-native';
import pick from 'lodash/pick';
import {
  getAvailableLanguages,
  Locale,
  useLocalization,
  useTranslation,
  getSupportNumber,
} from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import SetGridSizeModal from '../../../../components/POS/Modals/GridSize/SetGridSizeModal';
import { DeviceProfile, UpdateDeviceInput } from '@oolio-group/domain';
import { useTheme } from '../../../../hooks/useTheme';
import { useDeviceId } from '../../../../hooks/app/useDeviceId';
import { useDeviceInfo } from '../../../../hooks/app/useDeviceInfo';
import { useDeviceProfiles } from '../../../../hooks/app/useDeviceProfiles';
import { useDevices } from '../../../../hooks/app/useDevices';
import { useRestart } from '../../../../hooks/app/useRestart';
import { useSession } from '../../../../hooks/app/useSession';
import { useSettingsSync } from '../../../../hooks/app/useSettingsSync';
import * as settings from '../../../../state/preferences';
import SettingsScreenStyles from '../SettingsScreen.styles';
import { Theme } from '../../../../common/theme.types';
import ConfirmationModal from '../../../../components/Modals/ConfirmationDialog';
import {
  SettingsIcon,
  SettingsToggle,
  SettingsAction,
  SettingsSelect,
} from '../../../../components/POS/SettingsOptions/SettingsOptions';
import LoadingOverlay from '../../../../components/Shared/Loaders/LoadingOverlay';

const availableLocales = getAvailableLanguages();

const GeneralSettings: React.FC = () => {
  const { showModal } = useModal();
  const { deviceId } = useDeviceId();
  const { translate } = useTranslation();
  const { versionNum } = useDeviceInfo();
  const { restartDevice } = useRestart();
  const [session, setSession] = useSession();
  const { locale: currentLocale } = useLocalization();
  const { colors, activeTheme, toggleTheme, grids } = useTheme();
  const { loading: loadingSync, syncApp } = useSettingsSync();

  const styles = SettingsScreenStyles();

  const [showLocales, setShowLocales] = useState<boolean>(false);

  const currentDevice = session?.currentStore?.devices?.find(
    device => device.uuid === deviceId,
  );

  const { updateDevice } = useDevices({
    deviceId: currentDevice?.id,
    storeId: session?.currentStore?.id,
  });

  const { updateDeviceProfiles } = useDeviceProfiles({
    storeId: session?.currentStore?.id,
    venueId: session?.currentVenue?.id,
  });

  const sync = (): void => {
    syncApp();
  };

  const filteredLanguages = useMemo(() => {
    return availableLocales.filter(
      x => x.languageTag !== 'en-US' && x.languageTag !== 'es-US',
    );
  }, []);

  const changeLanguage = useCallback(
    (locale: Locale): void => {
      settings.setLocale(locale);
      restartDevice();
    },
    [restartDevice],
  );

  const onChangeLocale = useCallback(
    (locale: Locale): void => {
      showModal(
        <ConfirmationModal
          title={translate('settings.languagePopUpPromptHeader')}
          message={translate('settings.languagePopUpPromptBody')}
          onConfirm={(): void => {
            changeLanguage(locale);
          }}
        />,
      );
    },
    [showModal, translate, changeLanguage],
  );

  const onToggleQuickPaymentSetting = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        enableQuickPaymentModeSetting:
          !session?.settings?.enableQuickPaymentModeSetting,
      },
    });
  }, [session, setSession]);

  const onToggleScrollButtons = useCallback((): void => {
    setSession({
      ...session,
      settings: {
        ...session.settings,
        showCartScrollSetting: !session?.settings?.showCartScrollSetting,
      },
    });
  }, [session, setSession]);

  const onToggleDeviceProfileSetting = useCallback(
    (key: keyof DeviceProfile, value: boolean): void => {
      if (!session.deviceProfile?.id) return;
      updateDeviceProfiles([
        {
          id: session.deviceProfile?.id,
          [key]: value,
        },
      ]);
      setSession({
        ...session,
        deviceProfile: {
          ...session.deviceProfile,
          [key]: value,
        },
      });
    },
    [session, setSession, updateDeviceProfiles],
  );

  const updateDeviceProfileOnSession = useCallback(
    (deviceProfileId: string): void => {
      const deviceProfile = session?.currentStore?.deviceProfiles?.find(
        deviceProfile => deviceProfile.id == deviceProfileId,
      );
      if (deviceProfile) {
        const updatedSession = {
          ...session,
          deviceProfile: {
            ...deviceProfile,
            defaultOrderType:
              deviceProfile?.defaultOrderType || deviceProfile?.orderTypes?.[0],
          },
        };
        setSession(updatedSession);
      }
    },
    [session, setSession],
  );

  const onchangeDeviceProfile = useCallback(
    (deviceProfileId: string): void => {
      let input = {
        ...currentDevice,
        deviceProfile: deviceProfileId,
      } as unknown as UpdateDeviceInput;
      if (!input.cashDrawer) input.cashDrawer = 'default';
      if (!input.paymentTerminal) input.paymentTerminal = 'default';
      input = pick(input, [
        'id',
        'name',
        'salesPrefix',
        'returnPrefix',
        'deviceProfile',
        'paymentTerminal',
        'cashDrawer',
      ]) as unknown as UpdateDeviceInput;

      updateDevice(input as unknown as UpdateDeviceInput);
      updateDeviceProfileOnSession(deviceProfileId);
    },
    [currentDevice, updateDevice, updateDeviceProfileOnSession],
  );

  const filteredProfiles = useMemo(() => {
    return (session?.currentStore?.deviceProfiles || []).sort((a, b) => {
      // Move default profiles to the top
      if (a.isDefault && !b.isDefault) {
        return -1;
      }
      if (!a.isDefault && b.isDefault) {
        return 1;
      }
      // If both have the same isDefault status, sort alphabetically
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
  }, [session?.currentStore?.deviceProfiles]);

  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('backOfficeDeviceProfiles.title')}
        </Text>
        {filteredProfiles.map((profile, index) => {
          return (
            <SettingsSelect
              key={index}
              testID={`profile-${index}`}
              title={profile.name}
              isSelected={session?.deviceProfile?.id == profile.id}
              onPress={(): void => onchangeDeviceProfile(profile.id)}
            />
          );
        })}
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('settings.behaviour')}
        </Text>
        <SettingsToggle
          testID="toggle-quickPayment"
          title={translate('settings.enableQuickPaymentMode')}
          isToggled={session?.settings?.enableQuickPaymentModeSetting || false}
          onToggle={onToggleQuickPaymentSetting}
        />
        <SettingsToggle
          testID="toggle-barcodeScanning"
          title={translate('settings.enableBarcodeScanning')}
          isToggled={session?.deviceProfile?.enableBarcodeScan || false}
          onToggle={onToggleDeviceProfileSetting.bind(
            null,
            'enableBarcodeScan',
            !session?.deviceProfile?.enableBarcodeScan,
          )}
        />
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('storesSettings.preferences')}
        </Text>
        <SettingsToggle
          testID="toggle-sendButton"
          title={translate('settings.hideSendButton')}
          isToggled={session?.deviceProfile?.hideSendButton || false}
          onToggle={onToggleDeviceProfileSetting.bind(
            null,
            'hideSendButton',
            !session?.deviceProfile?.hideSendButton,
          )}
        />
        <SettingsToggle
          testID="toggle-hideCartTotal"
          title={translate('settings.hideCartTotal')}
          isToggled={session?.deviceProfile?.hideCartTotal || false}
          onToggle={onToggleDeviceProfileSetting.bind(
            null,
            'hideCartTotal',
            !session?.deviceProfile?.hideCartTotal,
          )}
        />
        <SettingsToggle
          testID="toggle-cartActions"
          title={translate('settings.advancedCartActions')}
          isToggled={session?.deviceProfile?.showCartKeypad || false}
          onToggle={onToggleDeviceProfileSetting.bind(
            null,
            'showCartKeypad',
            !session?.deviceProfile?.showCartKeypad,
          )}
        />
        <SettingsToggle
          testID="toggle-scrollButtons"
          title={translate('settings.scrollButtons')}
          isToggled={session?.settings?.showCartScrollSetting || false}
          onToggle={onToggleScrollButtons}
        />
        <SettingsToggle
          testID="toggle-collapseSentItems"
          title={translate('settings.collapseSentItems')}
          isToggled={session?.deviceProfile?.enableCollapseSentItems || false}
          onToggle={onToggleDeviceProfileSetting.bind(
            null,
            'enableCollapseSentItems',
            !session?.deviceProfile?.enableCollapseSentItems,
          )}
        />
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>
          {translate('settings.appearance')}
        </Text>
        <SettingsToggle
          testID="toggle-darkMode"
          title={translate('settings.darkMode')}
          isToggled={activeTheme === Theme.DARK}
          onToggle={() => toggleTheme()}
        />
        <SettingsIcon
          testID="grid-product"
          title={translate('settings.productsGridSize')}
          icon="angle-right"
          iconColor={colors.disclosure}
          value={`${grids.products.columns} ✗ ${grids.products.rows}`}
          onPress={() => showModal(<SetGridSizeModal grid="product" />)}
        />
        <SettingsIcon
          testID="grid-options"
          title={translate('settings.optionsGridSize')}
          icon="angle-right"
          iconColor={colors.disclosure}
          value={`${grids.options.columns} ✗ ${grids.options.rows}`}
          onPress={() => showModal(<SetGridSizeModal grid="options" />)}
        />
      </View>
      <View style={styles.section}>
        <Text style={styles.sectionTitle}>{translate('payment.other')}</Text>
        <SettingsIcon
          title={translate('settings.language')}
          testID="locales-parent"
          value={currentLocale.label}
          icon={showLocales ? 'angle-up' : 'angle-down'}
          onPress={() => setShowLocales(!showLocales)}
        />
        {showLocales && (
          <View style={styles.locales}>
            {filteredLanguages.map((locale: Locale, i: number) => {
              return (
                <SettingsSelect
                  key={i}
                  title={locale.label}
                  testID={`locale-${i}`}
                  onPress={onChangeLocale.bind(null, locale)}
                  isSelected={locale.languageTag === currentLocale.languageTag}
                />
              );
            })}
          </View>
        )}
        <SettingsIcon
          testID="support"
          title={translate('settings.contactSupport')}
          value={getSupportNumber(session.currentOrganization?.country)}
        />
        {Platform.OS !== 'web' && (
          <SettingsIcon
            testID="appVersion"
            title={translate('settings.appVersion')}
            value={versionNum}
          />
        )}
        <SettingsAction
          type="neutral"
          testID="btn-sync"
          loading={loadingSync}
          label={translate('settings.syncApp')}
          onPress={sync}
          // eslint-disable-next-line react-native/no-inline-styles
          containerStyle={{ marginTop: 16 }}
        />
        <LoadingOverlay isLoading={loadingSync} testID="Spinner" />
      </View>
    </View>
  );
};

export default GeneralSettings;
