import { Platform, StyleSheet, TextStyle } from 'react-native';
import { useTheme } from '../../../../hooks/useTheme';
import { WaitlistStatusEnum } from '@oolio-group/domain';
import theme from '../../../../common/default-theme';

const BookingTableStyles = () => {
  const { colors } = useTheme();

  const textStyle: TextStyle = {
    fontSize: 14,
    lineHeight: 20,
    color: colors.text,
    fontVariant: ['tabular-nums'],
    fontFamily: theme.fonts.medium,
  };

  return StyleSheet.create({
    container: {
      rowGap: 4,
    },
    header: {
      height: 38,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 12,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    body: {
      rowGap: 4,
    },
    row: {
      height: 50,
      flexDirection: 'row',
      alignItems: 'center',
      paddingHorizontal: 12,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    cellPosition: {
      width: 36,
      marginRight: 10,
    },
    cellQueued: {
      width: 60,
      marginRight: 10,
      textAlign: 'center',
    },
    cellGuests: {
      width: 60,
      marginRight: 20,
      textAlign: 'center',
    },
    cellName: {
      width: 150,
      ...textStyle,
      marginRight: 20,
    },
    cellPhone: {
      width: 130,
      ...textStyle,
      marginRight: 20,
    },
    cellCreated: {
      flex: 1,
      ...textStyle,
      marginRight: 20,
    },
    cellStatus: {
      marginRight: 10,
      width: Platform.select({
        ios: 110,
      }),
    },
    disclosure: {
      width: 20,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 10,
    },
  });
};

export default BookingTableStyles;

export const getStatusStickerType = (status: WaitlistStatusEnum) => {
  switch (status) {
    case WaitlistStatusEnum.COMPLETED:
      return 'positive';
    case WaitlistStatusEnum.ARRIVED:
      return 'positiveLight';
    case WaitlistStatusEnum.READY:
      return 'neutral';
    case WaitlistStatusEnum.NO_SHOW:
      return 'focus';
    case WaitlistStatusEnum.CANCELLED:
      return 'negative';
    default:
      return 'cancel';
  }
};

export const getColorSchema = (minutes: number) => {
  if (minutes < 15) {
    return 'positiveLight';
  } else if (minutes >= 15 && minutes <= 30) {
    return 'focusLight';
  } else {
    return 'negativeLight';
  }
};
