import { StyleSheet, TextStyle } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';

const CartTotalItemsStyles = () => {
  const { colors } = useTheme();

  const textStyle: TextStyle = {
    fontSize: 13,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
  };

  return StyleSheet.create({
    container: {
      marginTop: 'auto',
      paddingTop: 12,
      borderTopWidth: 1,
      borderTopColor: colors.borderColor,
      backgroundColor: colors.transparent,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 10,
      marginBottom: 4,
    },
    label: {
      ...textStyle,
      color: colors.textLight,
    },
    value: {
      ...textStyle,
      color: colors.text,
      textAlign: 'right',
      fontVariant: ['tabular-nums'],
    },
  });
};

export default CartTotalItemsStyles;
