import React, { useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { format } from 'date-fns';
import { Waitlist, WaitlistStatusEnum } from '@oolio-group/domain';
import { formatPhoneNumber } from '@oolio-group/localization';
import { useTheme } from '../../../../hooks/useTheme';
import BookingTableStyles, {
  getStatusStickerType,
  getColorSchema,
} from './BookingsTable.styles';
import Icon from '../../../../components/Icon/Icon';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import {
  getDurationInMinutes,
  getDurationInHHMM,
} from '../../../../utils/TimeUtils';
import { STATUS_OPTIONS } from '../SidePanel/Filters';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';

interface Props {
  booking: Waitlist;
  currentTime: number;
  index: number;
  onEdit: (entry: Waitlist) => void;
  onPress: () => void;
}

const BookingRow: React.FC<Props> = ({
  booking,
  currentTime,
  index,
  onEdit,
  onPress,
}) => {
  const { colors } = useTheme();
  const styles = BookingTableStyles();

  const {
    status,
    customer,
    priority,
    seatedAt,
    noShowAt,
    cancelledAt,
    createdAt,
  } = booking;

  const customerName = `${customer.firstName} ${customer.lastName}`;

  const durationInMinutes = useMemo(
    () =>
      getDurationInMinutes(
        createdAt,
        noShowAt || cancelledAt || seatedAt || currentTime,
      ),
    [cancelledAt, createdAt, currentTime, noShowAt, seatedAt],
  );

  const stickerType = useMemo(() => {
    return noShowAt || cancelledAt || seatedAt
      ? 'cancel'
      : getColorSchema(durationInMinutes);
  }, [noShowAt, cancelledAt, seatedAt, durationInMinutes]);

  const statusLabel = useMemo(
    () => STATUS_OPTIONS.find(x => x.value === status)?.label ?? '',
    [status],
  );

  const formattedPhone = useMemo(
    () => formatPhoneNumber(customer.phone as string, { intl: true }),
    [customer.phone],
  );

  const isEditDisabled = ![
    WaitlistStatusEnum.READY,
    WaitlistStatusEnum.WAITING,
  ].includes(status);

  return (
    <TouchableOpacity onPress={onPress} style={styles.row}>
      <Sticker
        testID="sticker-position"
        themed
        type={stickerType}
        label={String(index).padStart(2, '0')}
        containerStyle={styles.cellPosition}
      />
      <Sticker
        testID="sticker-timer"
        themed
        type={stickerType}
        label={getDurationInHHMM(
          createdAt,
          noShowAt || cancelledAt || seatedAt || currentTime,
        )}
        containerStyle={styles.cellQueued}
      />
      <Sticker
        testID="sticker-guest-count"
        themed
        type="cancel"
        label={String(booking.guestCount)}
        containerStyle={styles.cellGuests}
      />
      <Text numberOfLines={1} style={styles.cellName}>{`${
        priority ? '★ ' : ''
      }${customerName}`}</Text>
      <Text numberOfLines={1} style={styles.cellPhone}>
        {formattedPhone}
      </Text>
      <Text numberOfLines={1} style={styles.cellCreated}>
        {format(new Date(booking.createdAt), 'MMM dd, hh:mm a')}
      </Text>
      <Sticker
        testID="sticker-status"
        themed
        rounded
        type={getStatusStickerType(status)}
        label={statusLabel}
        containerStyle={styles.cellStatus}
      />
      <ButtonIcon
        testID="btn-editCustomer"
        themed
        icon="pen"
        type="neutralLight"
        onPress={() => onEdit(booking)}
        disabled={isEditDisabled}
      />
      <View style={styles.disclosure}>
        <Icon name="angle-right" size={20} color={colors.disclosure} />
      </View>
    </TouchableOpacity>
  );
};

export default BookingRow;
