import { InMemoryCache } from '@apollo/client';
import {
  Order,
  OrderItem,
  OrderItemStatus,
  OrderStatus,
} from '@oolio-group/domain';
import { CachePersistor } from 'apollo3-cache-persist';
import { Subject } from 'rxjs';

interface Cache {
  ROOT_MUTATION: Record<string, any>;
  ROOT_QUERY: Record<string, any>;
  [key: string]: any;
}

export const persistCacheSub = new Subject<void>();

const COMPLETE_ORDERS_KEY = /orders\(\{\"status\":\"COMPLETE\"\}\)/;
const REFUND_ORDERS_KEY = /orders\(\{\"status\":\"REFUND\"\}\)/;
const ORDER_BY_ID_KEY = /order\(\{\"id\":\"([a-z]|[0-9]|-)+\"\}\)/;

export const shouldKeepOrder = (order: Order) =>
  [OrderStatus.IN_PROGRESS, OrderStatus.ON_HOLD].includes(order.status);

const shouldKeepOrderItem = (item: OrderItem) =>
  ![OrderItemStatus.COMPLETED].includes(item.status);

// TODO: add later with proper testing
const persistenceMapper = async (cacheData: any) => {
  const cacheObject = JSON.parse(cacheData) as Cache;
  const newCacheObject: Cache = {
    ROOT_MUTATION: {},
    ROOT_QUERY: {},
  };

  Object.keys(cacheObject).reduce((acc, cacheKey) => {
    if (cacheKey === 'ROOT_MUTATION') return acc;
    if (cacheKey === 'ROOT_QUERY') {
      const rootQuery = cacheObject['ROOT_QUERY'];
      Object.keys(rootQuery).forEach(queryKey => {
        // dont persist complete order and refund order query: since it accumulate a lot of data overtime
        if (
          COMPLETE_ORDERS_KEY.test(queryKey) ||
          REFUND_ORDERS_KEY.test(queryKey)
        )
          return;
        // persist only order pass shouldKeepOrder check
        if (ORDER_BY_ID_KEY.test(queryKey)) {
          const order = rootQuery[queryKey]['__ref'];
          if (shouldKeepOrder(order)) {
            acc.ROOT_QUERY[queryKey] = rootQuery[queryKey];
          }
          return;
        }
        // persist the rest of other keys
        acc.ROOT_QUERY[queryKey] = rootQuery[queryKey];
        return;
      });
      return acc;
    }
    // persist only order pass shouldKeepOrder check
    if (cacheKey.startsWith('Order:')) {
      if (!shouldKeepOrder(cacheObject[cacheKey])) {
        return acc;
      }
    }
    // persist only order item pass shouldKeepOrderItem check
    if (cacheKey.startsWith('OrderItem:')) {
      if (!shouldKeepOrderItem(cacheObject[cacheKey])) {
        return acc;
      }
    }
    // persist the rest of other keys
    acc[cacheKey] = cacheObject[cacheKey];
    return acc;
  }, newCacheObject);

  return JSON.stringify(newCacheObject);
};

// TODO: add later with proper testing
const trigger = (persist: Function) => {
  const persistSub = persistCacheSub.subscribe(() => {
    persist();
  });

  return () => {
    persistSub.unsubscribe();
  };
};

export const persistCache = (options: {
  cache: InMemoryCache;
  storage: any;
  [key: string]: any;
}) => {
  const persistor = new CachePersistor({
    ...options,
    trigger,
    persistenceMapper,
  });
  return persistor.restore();
};
