import React, { useCallback, useMemo } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { SetPrepTimeModal } from './SetPrepTimeModal';
import { SetBusyModal } from './SetBusyModal';
import ButtonActions from '../../../../../components/Shared/TreatButton/ButtonActions';
import { SetPauseOrdersModal } from './SetPauseOrdersModal';
import { SetDelayModal } from './SetDelayModal';

import {
  DelayOrdersChannelInput,
  PartnerChannel,
  PauseOrdersChannelInput,
} from '@oolio-group/domain';
import { Action } from '../../../../../components/Shared/TreatButton/Button.types';

interface OrderSettingsOptionsProps {
  setPreparationTime?: (time: number) => void;
  setBusyTime?: (time: number) => void;
  onSetPauseOrders: (updatedChannels: PauseOrdersChannelInput[]) => void;
  onSetDelayOrders: (updatedChannels: DelayOrdersChannelInput[]) => void;
  oomConnectedChannels: PartnerChannel[];
}

export const OrderSettingsOptions: React.FC<OrderSettingsOptionsProps> = ({
  setBusyTime,
  setPreparationTime,
  oomConnectedChannels,
  onSetPauseOrders,
  onSetDelayOrders,
}) => {
  const { translate } = useTranslation();
  const { showModal } = useModal();

  const setPrepTimeModal = useCallback(() => {
    showModal(<SetPrepTimeModal setPreparationTime={setPreparationTime} />);
  }, [showModal, setPreparationTime]);

  const setBusyModal = useCallback(() => {
    showModal(<SetBusyModal setBusyTime={setBusyTime} />);
  }, [showModal, setBusyTime]);

  const setPauseOrdersModal = useCallback(() => {
    showModal(
      <SetPauseOrdersModal
        oomConnectedChannels={oomConnectedChannels}
        onSetPauseOrders={onSetPauseOrders}
      />,
    );
  }, [showModal, oomConnectedChannels, onSetPauseOrders]);

  const setDelayModal = useCallback(() => {
    showModal(
      <SetDelayModal
        oomConnectedChannels={oomConnectedChannels}
        onSetDelayOrders={onSetDelayOrders}
      />,
    );
  }, [showModal, oomConnectedChannels, onSetDelayOrders]);

  const options = useMemo<Action[]>(() => {
    const defaultOptions = [
      {
        label: translate('onlineOrders.setPrepTime'),
        action: setPrepTimeModal,
        textStyle: 'default',
      },
      {
        label: translate('onlineOrders.setAsBusy'),
        action: setBusyModal,
        textStyle: 'default',
      },
    ] as Action[];
    if (oomConnectedChannels.length) {
      return defaultOptions.concat(
        {
          label: translate('onlineOrders.setDelay'),
          action: setDelayModal,
          textStyle: 'default',
        },
        {
          label: translate('onlineOrders.pauseOrders'),
          action: setPauseOrdersModal,
          textStyle: 'negative',
        },
      ) as Action[];
    }
    return defaultOptions;
  }, [
    oomConnectedChannels.length,
    setBusyModal,
    setDelayModal,
    setPauseOrdersModal,
    setPrepTimeModal,
    translate,
  ]);

  return (
    <ButtonActions
      actions={options}
      icon="clock"
      type="neutral"
      label="Configure"
      testID="btn-timeOptions"
    />
  );
};
