import React, { useCallback, useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, View } from 'react-native';
import {
  usePhoneNumber,
  useTranslation,
  isValidPhoneNumber,
} from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { Order } from '@oolio-group/domain';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { useSyncOrderEvents } from '../../../../hooks/app/useSyncOrderEvents';
import { userUtility } from '../../../../state/userUtility';
import { isValidEmail } from '../../../../utils/validator';
import { generateNotificationEvent } from '../../../../utils/sendReceiptHelper';
import { DEFAULT_COUNTRY_CODE } from '../../../../constants';
import SendReceiptModalStyles from './SendReceiptModal.styles';
import ModalTitle from '../ModalTitle/ModalTitle';
import InputPhone from '../../../Shared/Inputs/InputPhone';
import InputEmail from '../../../Shared/Inputs/InputEmail';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

export type SendReceiptForm = {
  email: string;
  phone: string;
  countryCode: string;
};

interface Props {
  order: Order;
}

const SendReceiptModal: React.FC<Props> = ({ order }) => {
  const [session] = useSession();
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();
  const { getFullFormattedPhoneNumber } = usePhoneNumber();

  const styles = SendReceiptModalStyles();

  const onSendSuccess = useCallback(() => {
    showNotification({
      success: true,
      message: translate('common.sendSaleReceiptToCustomerSuccess'),
    });
  }, [showNotification, translate]);

  const onSendFailed = useCallback(() => {
    showNotification({
      error: true,
      message: translate('common.sendSaleReceiptToCustomerFailed'),
    });
  }, [showNotification, translate]);

  const { syncOrderEvents } = useSyncOrderEvents(onSendSuccess, onSendFailed);

  const initialEmail = order?.customer?.email || '';
  const initialPhone = order?.customer?.phoneNumber || '';
  const initialCountryCode =
    order?.customer?.preferredAddress?.isoCountryCode || DEFAULT_COUNTRY_CODE;

  const [form, setForm] = useState<SendReceiptForm>({
    email: initialEmail,
    phone: initialPhone,
    countryCode: initialCountryCode,
  });

  useEffect(() => {
    setForm({
      email: order?.customer?.email || '',
      phone: order?.customer?.phoneNumber || '',
      countryCode:
        order?.customer?.preferredAddress?.isoCountryCode ||
        DEFAULT_COUNTRY_CODE,
    });
  }, [order]);

  const isPhoneValid = isValidPhoneNumber(
    getFullFormattedPhoneNumber(form.countryCode, form.phone),
  );
  const isEmailValid = isValidEmail(form.email);
  const canSend = isEmailValid || isPhoneValid;

  const onChangeText = (prop: keyof SendReceiptForm, text: string) => {
    setForm(prev => ({ ...prev, [prop]: text }));
  };

  const handleSendReceipt = useCallback(() => {
    if (!form.email && !form.phone) {
      showNotification({
        error: true,
        message: translate('sendReceipt.emptyEmailAndPhoneMsg'),
      });
      return;
    }
    if (form.email && !isEmailValid) {
      showNotification({
        error: true,
        message: translate('sendReceipt.invalidEmailMsg'),
      });
      return;
    }
    if (session && order?.id) {
      const event = generateNotificationEvent(
        session,
        order.id,
        userUtility.userActivity.posUser?.id || '',
        form.email,
        { countryCode: form.countryCode, number: form.phone },
      );
      if (event) {
        syncOrderEvents([event]);
      }
    }
    closeModal();
  }, [
    form,
    isEmailValid,
    order,
    session,
    showNotification,
    syncOrderEvents,
    translate,
    closeModal,
  ]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={styles.container}>
        <ModalTitle title="Send Receipt" onDismiss={closeModal} />
        <View style={styles.content}>
          <View style={styles.form}>
            <InputPhone
              testID="input-phone"
              title={translate('form.phoneNumber')}
              value={form.phone}
              defaultCountry={form.countryCode}
              onPressCountry={onChangeText.bind(null, 'countryCode')}
              onChangeText={text => onChangeText('phone', text)}
            />
            <InputEmail
              testID="input-email"
              title={translate('form.emailAddress')}
              placeholder={translate('common.enterPlaceholder')}
              value={form.email || ''}
              onChangeText={text => onChangeText('email', text)}
            />
          </View>
          <TreatButton
            testID="btn-confirm"
            type="neutral"
            label="Send Receipt"
            disabled={!canSend}
            onPress={handleSendReceipt}
          />
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

export default SendReceiptModal;
