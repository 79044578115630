import React from 'react';
import { View, StyleSheet, Text, ViewStyle } from 'react-native';
import { ReservationTag } from '@oolio-group/domain';
import theme from '../../../../../../common/default-theme';

interface TagsProps {
  tags: ReservationTag[];
  containerStyle?: ViewStyle;
}

const ReservationInfoModal: React.FC<TagsProps> = ({
  tags,
  containerStyle,
}: TagsProps) => {
  return (
    <View style={[containerStyle, styles.container]}>
      {tags.map((item, index) => (
        <View key={index} style={[styles.tag, { backgroundColor: item.color }]}>
          <Text style={styles.tagText}>{item.tag_display}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    gap: 4,
  },
  tag: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 20,
  },
  tagText: {
    color: theme.colors.light,
    fontSize: 12,
    fontFamily: theme.fonts.regular,
  },
});

export default ReservationInfoModal;
