import React, { useCallback, useMemo } from 'react';
import { ScrollView, Text, View } from 'react-native';
import { roundOffByValue } from '@oolio-group/client-utils';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { useNotification } from '../../../../hooks/Notification';
import { getFastCashOptions } from '../../../../utils/fastCashOptions';
import { CARD_PAYMENT_TYPE } from '../../../../types/Common';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import { OtherPaymentType } from '../PaymentOptions';
import PaymentButtonStyles from './PaymentButtons.styles';
import KeypadModal from '../../Modals/Keypad/KeypadModal';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

export interface PaymentButtonsProps {
  processingAmount: number;
  enableRoundOff?: boolean;
  roundOffValue?: number;
  otherPaymentTypes: OtherPaymentType[];
  onPressPayOut: (paymentTypeName: string, amount: number) => void;
  onPressOtherPayOut: (paymentTypeName: string) => void;
}

const PaymentButtons: React.FC<PaymentButtonsProps> = ({
  processingAmount,
  enableRoundOff,
  roundOffValue,
  otherPaymentTypes,
  onPressPayOut,
  onPressOtherPayOut,
}) => {
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();

  const styles = PaymentButtonStyles();

  // Show Card Option First
  const sortedPaymentTypes: OtherPaymentType[] = useMemo(
    () =>
      (otherPaymentTypes || []).sort(paymentType =>
        paymentType?.name == CARD_PAYMENT_TYPE ? -1 : 0,
      ),
    [otherPaymentTypes],
  );

  const otherPaymentOptionRows = useMemo(() => {
    const numberOfRow = Math.ceil(sortedPaymentTypes.length / 5);
    const data = [] as OtherPaymentType[];
    for (let i = 0; i < numberOfRow * 5; i++) {
      data.push(sortedPaymentTypes[i]);
    }
    return data;
  }, [sortedPaymentTypes]);

  const fastCashOptions = useMemo(
    () => getFastCashOptions(processingAmount, enableRoundOff, roundOffValue),
    [processingAmount, enableRoundOff, roundOffValue],
  );

  const onSetCustomInputAmount = useCallback(
    (amount: number) => {
      const validProcessingAmount = enableRoundOff
        ? roundOffByValue(processingAmount, roundOffValue || 0.05)
        : processingAmount;
      if (amount < validProcessingAmount) {
        showNotification({
          message: translate('payment.amountLessThanDueAmount'),
          error: true,
        });
        return;
      }
      closeModal();
      onPressPayOut('Cash', amount);
    },
    [
      closeModal,
      enableRoundOff,
      onPressPayOut,
      processingAmount,
      roundOffValue,
      showNotification,
      translate,
    ],
  );

  const onPressShowKeypad = useCallback(() => {
    showModal(
      <KeypadModal
        mode="currency"
        title={translate('payment.setAmountToPay')}
        onConfirm={onSetCustomInputAmount}
        onDismiss={closeModal}
      />,
      { onBackdropPress: closeModal },
    );
  }, [showModal, translate, onSetCustomInputAmount, closeModal]);

  const onPressCashOption = useCallback(
    (index: number) => {
      analyticsService.capture('payment_option', {
        type: 'cash',
        amount:
          index === 0
            ? 'Exact'
            : index === 5
            ? 'Other'
            : formatCurrency(fastCashOptions[index] || 0),
      });

      if (index < 4) {
        onPressPayOut('Cash', fastCashOptions[index]);
      } else {
        onPressShowKeypad();
      }
    },
    [fastCashOptions, formatCurrency, onPressPayOut, onPressShowKeypad],
  );

  const onPressOtherOption = useCallback(
    (type: OtherPaymentType) => {
      analyticsService.capture('payment_option', {
        type: type.name,
      });
      onPressOtherPayOut(type.name);
    },
    [onPressOtherPayOut],
  );

  return (
    <View style={styles.container}>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <View style={{ marginBottom: 30 }}>
        <Text style={styles.groupTitle}>
          {translate('payment.cashOptions')}
        </Text>
        <View style={styles.fixedOption}>
          {Array(5)
            .fill(null)
            .map((_, index) => {
              return (
                <TreatButton
                  key={index}
                  testID={
                    index < 4 ? `cash-${fastCashOptions[index]}` : 'cash-other'
                  }
                  label={
                    index < 4
                      ? formatCurrency(fastCashOptions[index] || 0)
                      : translate('payment.other')
                  }
                  onPress={() => onPressCashOption(index)}
                  type="positive"
                  height={60}
                  containerStyle={styles.btnOption}
                />
              );
            })}
        </View>
      </View>
      <View>
        <Text style={styles.groupTitle}>
          {translate('payment.otherOptions')}
        </Text>
        <ScrollView horizontal style={styles.options}>
          {otherPaymentOptionRows.map((otherPaymentType, index) => {
            if (!otherPaymentType) return <View key={index} />;

            return (
              <TreatButton
                key={index}
                height={60}
                type="neutral"
                testID={`other-payment-type-${otherPaymentType.name}`}
                label={otherPaymentType.name}
                onPress={() => onPressOtherOption(otherPaymentType)}
                disabled={otherPaymentType?.disabled}
                // eslint-disable-next-line react-native/no-inline-styles
                containerStyle={{ marginRight: 10 }}
              />
            );
          })}
        </ScrollView>
      </View>
    </View>
  );
};
export default PaymentButtons;
