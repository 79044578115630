import { StyleSheet } from 'react-native';
import theme from '../../../../common/default-theme';
import { useTheme } from '../../../../hooks/useTheme';
import { Theme } from '../../../../common/theme.types';

const FunctionsStyles = () => {
  const { activeTheme } = useTheme();
  const isDarkTheme = activeTheme === Theme.DARK;

  return StyleSheet.create({
    container: {
      flexWrap: 'wrap',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      columnGap: 4,
      rowGap: 4,
    },
    tileSize: {
      height: 70,
      flex: 1,
      flexGrow: 1,
      flexBasis: '18%',
    },
    tile: {
      padding: 10,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      borderWidth: isDarkTheme ? 1 : 0,
      borderColor: theme.colors.black1,
      backgroundColor: theme.colors.black,
    },
    back: {
      backgroundColor: theme.colors.white,
    },
    tileText: {
      fontSize: 13,
      lineHeight: 20,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontFamily: theme.fonts.semibold,
      color: theme.colors.white,
    },
    group: {
      position: 'absolute',
      width: 8,
      height: 8,
      borderRadius: 8,
      top: 6,
      right: 6,
      backgroundColor: theme.colors.white,
    },
    colour: {
      position: 'absolute',
      left: 4,
      right: 4,
      bottom: 4,
      height: 4,
      borderRadius: 4,
      backgroundColor: theme.colors.accent,
    },
  });
};

const positive = ['CARD PAY', 'CASH PAY', 'EXACT CASH'];
const focus = [
  'SWITCH MENU',
  'SWITCH COURSE',
  'HOLD PRINT',
  'Switch Price List',
  'TRANSFER',
];
const negative = [
  'VOID',
  'END SHIFT',
  'SYSTEM LOGOUT',
  'REMOVE ALL DISCOUNTS',
  'REMOVE ALL SURCHARGE',
];

export const getBarColor = (name: string): string => {
  if (positive.includes(name)) {
    return theme.colors.states.positive;
  } else if (focus.includes(name)) {
    return theme.colors.states.focus;
  } else if (negative.includes(name)) {
    return theme.colors.states.negative;
  } else {
    return theme.colors.states.neutral;
  }
};

export default FunctionsStyles;
