import { Dimensions, StyleSheet, TextStyle, ViewStyle } from 'react-native';
import theme from '../../../../../../../common/default-theme';
import { TableStatus } from '@oolio-group/domain';

export const styles = StyleSheet.create({
  container: {
    minWidth: 60,
    minHeight: 60,
    borderWidth: 2,
    borderRadius: 30,
    borderColor: theme.colors.grey3,
    backgroundColor: theme.colors.grey2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableText: {
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
    color: theme.colors.black1,
    fontFamily: theme.fonts.semibold,
  },
  dots: {
    width: 24,
    marginTop: 6,
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 6,
    backgroundColor: theme.colors.deepPurple,
  },
  popupBg: {
    width: '100%',
    opacity: 0.6,
    position: 'absolute',
    backgroundColor: theme.colors.black,
    height: Dimensions.get('window').height,
  },
  popupContainer: {
    width: 386,
    padding: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    borderRadius: theme.radius.m,
    backgroundColor: theme.colors.white,
  },
  // eslint-disable-next-line react-native/no-color-literals
  timerContainer: {
    height: 22,
    width: 68,
    backgroundColor: theme.colors.states.focus,
    borderRadius: 11,
    position: 'absolute',
    bottom: -10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.2)',
  },
  timerText: {
    fontSize: 10,
    color: theme.colors.light,
    fontFamily: theme.fonts.medium,
    paddingStart: 2,
  },
});

const getContainerStyle = (status?: TableStatus) => {
  const containerStyles: ViewStyle[] = [styles.container];

  switch (status) {
    case TableStatus.SEATED:
      containerStyles.push({
        backgroundColor: theme.colors.blueLightest,
        borderColor: theme.colors.blue,
      });
      break;
    case TableStatus.OCCUPIED:
      containerStyles.push({
        backgroundColor: theme.colors.blue,
        borderColor: theme.colors.blueDark,
      });
      break;
    case TableStatus.DONE_SOON:
      containerStyles.push({
        backgroundColor: theme.colors.green,
        borderColor: theme.colors.greenDark,
      });
      break;
    case TableStatus.MULTI_USE:
      containerStyles.push({
        backgroundColor: theme.colors.deepPurple,
        borderColor: theme.colors.deepPurpleDark,
      });
      break;
    default:
      break;
  }

  return containerStyles;
};

const getLabelStyle = (status?: TableStatus) => {
  const textStyles: TextStyle[] = [styles.tableText];

  switch (status) {
    case TableStatus.SEATED:
      textStyles.push({ color: theme.colors.blue });
      break;
    case TableStatus.OCCUPIED:
    case TableStatus.MULTI_USE:
    case TableStatus.DONE_SOON:
      textStyles.push({ color: theme.colors.white });
      break;
    default:
      break;
  }

  return textStyles;
};

export default {
  ...styles,
  getContainerStyle,
  getLabelStyle,
};
