import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

const PaymentScreenStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      overflow: 'hidden',
    },
    order: {
      width: 320,
      padding: 6,
      backgroundColor: colors.background,
      ...theme.shadow.light,
    },
    orderTitle: {
      height: 44,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 4,
      paddingHorizontal: 8,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    orderTitleText: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    cartItems: {
      padding: 10,
      marginTop: 4,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    gap: {
      marginTop: 4,
    },
    totals: {
      marginTop: 4,
    },
    totalRemaining: {
      padding: 16,
      marginTop: 4,
      justifyContent: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    content: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentContainer: {
      flex: 1,
      padding: 6,
    },
    btnEnrollContainer: {
      borderRadius: 4,
    },
    btnEnroll: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: 12,
    },
    btnText: {
      fontSize: 14,
      lineHeight: 20,
      color: theme.colors.white,
      fontFamily: theme.fonts.regular,
    },
    btn: {
      flex: 1,
    },
  });
};

export default PaymentScreenStyles;
