import { useModal } from '@oolio-group/rn-use-modal';
import { useEffect, useRef, useState, useCallback } from 'react';

export interface ChainItem<T> {
  action: (
    param: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect: (...arg: any[]) => void,
    onBack: () => void,
    onDismiss?: () => void,
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transformSubmitParamToState: (...arg: any[]) => T;
  dependsOn?: (state: T) => boolean; // Dependency condition
}

function useChainModal<T>(
  chain: ChainItem<T>[],
  onFinish: (param: T) => void,
  initialParam: T,
) {
  const currentState = useRef<T>(initialParam);
  const { closeModal } = useModal();
  const [currentIndex, setIndex] = useState<number>(-1);
  const history = useRef<number[]>([]);

  const cleanUp = () => {
    history.current = [];
  };

  useEffect(() => {
    if (currentIndex >= 0) {
      if (currentIndex < chain.length) {
        const currentStep = chain[currentIndex];

        const shouldExecute = currentStep.dependsOn
          ? currentStep.dependsOn(currentState.current)
          : true;

        if (shouldExecute) {
          if (history.current[history.current.length - 1] !== currentIndex) {
            history.current.push(currentIndex);
          }
          currentStep.action(
            currentState.current,
            (...arg) => {
              const newState = currentStep.transformSubmitParamToState(...arg);
              currentState.current = { ...currentState.current, ...newState };
              setIndex(i => i + 1);
            },
            () => {
              history.current.splice(-1);
              if (history.current.length === 0) {
                closeModal();
                cleanUp();
                setIndex(-1);
              } else {
                setIndex(history.current[history.current.length - 1]);
              }
            },
            () => {
              closeModal();
              cleanUp();
              setIndex(i => i + 1);
            },
          );
        } else {
          setIndex(i => i + 1);
        }
      } else {
        onFinish(currentState.current);
        cleanUp();
      }
    }
    // trigger the effect only when currentIndex change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return useCallback(() => {
    setIndex(0);
  }, []);
}

export default useChainModal;
