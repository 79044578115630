import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { ButtonStyle } from './Button.types';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';
import defaultColors from '../../../common/colours.json';

interface Props {
  type: ButtonStyle;
  themed?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
  transparent?: boolean;
}

const ButtonStyles = ({
  type,
  themed = false,
  disabled = false,
  uppercase = false,
  transparent = false,
}: Props) => {
  const { colors } = useTheme();

  const palette = themed ? colors : defaultColors;

  const getFgColor = useCallback(() => {
    if (disabled) {
      if (type === 'interface') {
        return colors.grey7;
      } else {
        return themed ? colors.disclosure : colors.grey4;
      }
    } else {
      switch (type) {
        case 'primary':
        case 'accent':
        case 'positive':
        case 'negative':
        case 'neutral':
        case 'focus':
        case 'interface':
          return palette.white;
        case 'primaryLight':
          return palette.primary;
        case 'positiveLight':
          return palette.green;
        case 'negativeLight':
          return palette.red;
        case 'neutralLight':
          return palette.blue;
        case 'focusLight':
          return palette.orange;
        case 'cancel':
          return themed ? colors.text : colors.black1;
        default:
          return colors.black1;
      }
    }
  }, [disabled, colors, type, themed, palette]);

  const getBgColor = useCallback(() => {
    if (transparent) {
      return colors.transparent;
    } else if (disabled) {
      if (type === 'interface') {
        return colors.tertiary1;
      }
      return themed ? colors.elevation1 : colors.grey1;
    } else {
      switch (type) {
        case 'primary':
          return themed ? colors.primary : palette.primary;
        case 'primaryLight':
          return palette.primaryLight;
        case 'accent':
          return colors.accent;
        case 'positive':
          return colors.green;
        case 'negative':
          return colors.red;
        case 'neutral':
          return colors.blue;
        case 'focus':
          return colors.orange;
        case 'positiveLight':
          return palette.greenLight;
        case 'negativeLight':
          return palette.redLight;
        case 'neutralLight':
          return palette.blueLight;
        case 'focusLight':
          return palette.amberLight;
        case 'interface':
          return theme.colors.tertiary2;
        default:
          return themed ? colors.elevation1 : colors.grey1;
      }
    }
  }, [transparent, colors, palette, disabled, type, themed]);

  return StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    container: {
      minWidth: 100,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: 8,
      borderRadius: theme.radius.s,
      backgroundColor: getBgColor(),
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.04)',
    },
    btnIcon: {
      minWidth: 0,
      paddingHorizontal: 0,
    },
    btnText: {
      paddingHorizontal: 14,
    },
    btnAction: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
      paddingHorizontal: 14,
    },
    text: {
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      textTransform: uppercase ? 'uppercase' : 'none',
      fontVariant: ['tabular-nums'],
    },
    fgColor: {
      color: getFgColor(),
    },
    option: {
      height: 38,
      marginHorizontal: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    icon: {
      width: 20,
      height: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default ButtonStyles;
