import React, { useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNotification } from '../../../../../../hooks/Notification';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  KitchenDisplay,
  LayoutStrategy,
  UpdateKitchenDisplayInput,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import pick from 'lodash/pick';
import { useKitchenDisplay } from '../../../../../../hooks/app/useKitchenDisplay';
import theme from '../../../../../../common/default-theme';
import ScreenLayout from '../../../../../../components/Office/ScreenLayout/ScreenLayout';
import Section from '../../../../../../components/Office/Section/Section';
import InputText from '../../../../../../components/Shared/Inputs/InputText';
import TreatPicker from '../../../../../../components/Shared/Select/Picker';
import { usePrinters } from '../../../../../../hooks/app/usePrinters';

export const KitchenDisplayDetails: React.FC = () => {
  const route = useRoute();
  const { showNotification } = useNotification();
  const { translate } = useTranslation();
  const [form, setForm] = useState<KitchenDisplay>({} as KitchenDisplay);
  const navigation = useNavigation();

  const params = route.params as {
    storeId: string;
    kitchenDisplayId: string;
  };

  const storeId = params.storeId;
  const kitchenDisplayId = params.kitchenDisplayId;

  const {
    kitchenDisplay,
    deleteKitchenDisplay,
    updateKitchenDisplay,
    resetKitchenDisplayCode,
    resetedKitchenDisplayCode,
    deletedKitchenDisplay,
    updatedKitchenDisplayId,
    loading: devicesLoading,
    resetStateData,
    error: devicesError,
  } = useKitchenDisplay({
    kitchenDisplayId,
    storeId,
  });

  const { printers, getPrinters } = usePrinters({
    storeId: kitchenDisplay?.store?.id,
  });

  const printerOptions: Array<{
    label: string;
    value: string;
  }> = [{ value: '', label: translate('kitchenDisplay.choosePrinter') }].concat(
    Object.keys(printers).map(_printerId => ({
      value: printers[_printerId].id,
      label: printers[_printerId].name,
    })),
  );

  const loading = devicesLoading;
  const error = devicesError;

  useEffect(() => {
    if (kitchenDisplayId && kitchenDisplay) {
      setForm(kitchenDisplay);
    }
  }, [kitchenDisplay, kitchenDisplayId]);

  useEffect(() => {
    getPrinters();
  }, [getPrinters]);

  useEffect((): void => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  // on change events
  const onChange = useCallback((prop: string, value: string): void => {
    setForm(form => ({
      ...form,
      [prop]: value,
    }));
  }, []);

  const onPrinterSelect = useCallback(
    (prop: string, value: string): void => {
      const printer = printers[value];
      setForm(form => ({
        ...form,
        printer,
      }));
    },
    [printers],
  );

  const onSave = useCallback((): void => {
    if (!form.name || form.layoutStrategy?.columnsOnScreen === 0) {
      showNotification({
        error: true,
        message: translate('kitchenDisplay.fieldsMissing'),
      });
      return;
    }

    const input = pick(form, [
      'id',
      'name',
      'layoutStrategy',
    ]) as UpdateKitchenDisplayInput;
    input.printer = form.printer?.id || null;
    // Handle layoutStrategy separately to remove __typename
    if (form.layoutStrategy) {
      input.layoutStrategy = {
        strategy: form.layoutStrategy.strategy,
        columnsOnScreen: form.layoutStrategy.columnsOnScreen,
      };
    }
    updateKitchenDisplay(input as UpdateKitchenDisplayInput);
  }, [form, showNotification, translate, updateKitchenDisplay]);

  useEffect(() => {
    if (updatedKitchenDisplayId) {
      showNotification({
        success: true,
        message: translate('kitchenDisplay.displayUpdatedSuccessfully'),
      });
    }
  }, [updatedKitchenDisplayId, showNotification, translate]);

  useEffect(() => {
    if (deletedKitchenDisplay) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deleteInfo', {
          name: form?.name,
        }),
      });
      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'KitchenDisplays',
      });
      resetStateData();
    }
  }, [
    deletedKitchenDisplay,
    navigation,
    showNotification,
    form,
    translate,
    resetStateData,
  ]);

  useEffect(() => {
    if (resetedKitchenDisplayCode) {
      showNotification({
        success: true,
        message: translate('backOfficeSettings.deviceCodeResetSuccessful', {
          name: form?.name,
        }),
      });
      navigation.navigate('StoreSettings', {
        storeId: form?.store?.id,
        screen: 'KitchenDisplays',
      });
      resetStateData();
    }
  }, [
    resetedKitchenDisplayCode,
    navigation,
    showNotification,
    form,
    translate,
    resetStateData,
  ]);

  const onChangeLayoutStrategy = (key, value) => {
    setForm(form => ({
      ...form,
      layoutStrategy: {
        ...form.layoutStrategy,
        [key]: value,
      },
    }));
  };

  return (
    <ScreenLayout
      loading={loading}
      onSave={onSave}
      onDelete={() => deleteKitchenDisplay(form.id)}
    >
      <Section title={translate('kitchenDisplay.displaySettings')}>
        <View style={theme.forms.row}>
          <InputText
            testID="input-name"
            title={translate('kitchenDisplay.displayName')}
            value={form.name}
            placeholder={translate('kitchenDisplay.displayName')}
            onChangeText={onChange.bind(null, 'name')}
            containerStyle={theme.forms.inputHalf}
            autoCapitalize="words"
          />
          <TreatPicker
            testID="select-type"
            editable={false}
            title={translate('kitchenDisplay.displayType')}
            options={[
              {
                value: 'Prep',
                label: 'Prep Station',
              },
            ]}
            onValueChange={onChange.bind(null, 'prep')}
            containerStyle={theme.forms.inputHalf}
          />
        </View>
        <View style={theme.forms.row}>
          <TreatPicker
            testID="select-type"
            title={translate('kitchenDisplay.docketsLayout')}
            options={[
              {
                value: LayoutStrategy.SINGLE_ROW,
                label: translate('kitchenDisplay.singleRowLayout'),
              },
              {
                value: LayoutStrategy.DOUBLE_ROW,
                label: translate('kitchenDisplay.doubleRowLayout'),
              },
              {
                value: LayoutStrategy.FREE_FLOW,
                label: translate('kitchenDisplay.freeFlowLayout'),
              },
            ]}
            onValueChange={itemValue =>
              onChangeLayoutStrategy('strategy', itemValue)
            }
            containerStyle={theme.forms.inputHalf}
            selectedValue={
              form.layoutStrategy?.strategy ?? LayoutStrategy.FREE_FLOW
            }
          />
          <InputText
            testID="input-name"
            title={translate('kitchenDisplay.columnsOnScreen')}
            value={(form.layoutStrategy?.columnsOnScreen ?? 8).toString()}
            placeholder={'8'}
            onChangeText={text => {
              onChangeLayoutStrategy('columnsOnScreen', parseFloat(text));
            }}
            containerStyle={theme.forms.inputHalf}
            numericOnly
          />
        </View>
      </Section>

      <Section title={translate('kitchenDisplay.printingPreferences')}>
        <TreatPicker
          testID="select-printer"
          editable={true}
          title={translate('kitchenDisplay.printer')}
          options={printerOptions}
          selectedValue={form.printer?.id || ''}
          onValueChange={onPrinterSelect.bind(null, 'printer')}
          containerStyle={theme.forms.inputHalf}
        />
      </Section>

      {form.uuid ? (
        <Section title={translate('backOfficeDevices.deviceInfo')}>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.details')}
            </Text>
            <Text>{form.details || 'Unknown'}</Text>
          </View>
          <View style={styles.infoSection}>
            <Text style={styles.infoTitle}>
              {translate('backOfficeDevices.uuid')}
            </Text>
            <Text>{form.uuid || 'Unknown'}</Text>
          </View>
          <TouchableOpacity
            testID="btn-reset"
            onPress={() => resetKitchenDisplayCode(kitchenDisplayId)}
          >
            <Text style={styles.btnUnpair}>
              {translate('backOfficeDevices.unPair')}
            </Text>
          </TouchableOpacity>
        </Section>
      ) : (
        <></>
      )}
    </ScreenLayout>
  );
};

const styles = StyleSheet.create({
  infoSection: {
    marginBottom: 20,
    paddingHorizontal: 12,
  },
  infoTitle: {
    fontSize: 14,
    lineHeight: 14,
    marginBottom: 6,
    color: theme.colors.grey6,
  },
  btnUnpair: {
    fontSize: 14,
    lineHeight: 20,
    fontFamily: theme.fonts.medium,
    color: theme.colors.states.negative,
    marginLeft: 12,
  },
});
