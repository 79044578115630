import { Breadcrumb } from '@sentry/react-native';

export const SENTRY_IGNORE_URLS = [
  /https:\/\/app.posthog.com.*/,
  /https:\/\/ok.oolio.dev.*/,
];

export const filterNoiseFetchCall = (breadcrumb: Breadcrumb) => {
  if (breadcrumb.category === 'fetch') {
    if (SENTRY_IGNORE_URLS.find(url => url.test(breadcrumb.data?.url))) {
      return null;
    }
  }
  return breadcrumb;
};
