import {
  OrderItemStatus,
  PrinterProfileType,
  PrinterSeries,
} from '@oolio-group/domain';
import { validatePrintConfigurationOrInput } from '../../../utils/printerTemplates/printingDataUtils';
import { Notification } from '../../../hooks/Notification';
import {
  BufferHandlerResult,
  DEFAULT_NET_PRINTER_PORT,
  PrintKitchenDocketWorkerInput,
  WorkerInput,
} from '../../utils';
import {
  groupItemsByPrinterProfile,
  getPrintableKitchenOrder,
} from '../../../utils/printerTemplates/kitchenReceiptLayout';

export const printKitchenDocketHandler = (
  message: WorkerInput,
): BufferHandlerResult[] => {
  const results: BufferHandlerResult[] = [];

  const {
    order,
    printerTemplateMapping,
    session,
    printItems = [],
    translatedNowCourse,
  } = message.data as PrintKitchenDocketWorkerInput;

  const itemsByPrinterProfiles = groupItemsByPrinterProfile(printItems);

  if (Object.keys(itemsByPrinterProfiles).length === 0) {
    // No items have a assigned printer profiles
    return [];
  }

  const printerProfileIds = Object.keys(itemsByPrinterProfiles);

  const data = validatePrintConfigurationOrInput(
    order,
    {
      ...printerTemplateMapping,
      [PrinterProfileType.KITCHEN]: printerProfileIds.reduce(
        (acc, printerProfileId) => {
          return {
            ...acc,
            [printerProfileId]:
              printerTemplateMapping[PrinterProfileType.KITCHEN][
                printerProfileId
              ],
          };
        },
        {},
      ),
    },
    PrinterProfileType.KITCHEN,
  );

  if (Object.keys(data).length > 0 && (data as Notification).message) {
    throw new Error((data as Notification).message);
  }

  const kitchenPrinterProfiles =
    printerTemplateMapping[PrinterProfileType.KITCHEN];

  // Iterate through all the printer profile currently available with cart original order items
  // Send the printable (partial) items to printers with respective templates
  printerProfileIds
    .filter(
      printerProfileId => itemsByPrinterProfiles[printerProfileId]?.length,
    )
    .map(async printerProfileId => {
      const currentProfile = kitchenPrinterProfiles[printerProfileId]?.profile;

      const canPrintThisOrderType =
        currentProfile?.orderTypes && currentProfile?.orderTypes.length > 0
          ? currentProfile?.orderTypes?.includes(order?.orderType?.code)
          : true;

      if (!kitchenPrinterProfiles[printerProfileId] || !canPrintThisOrderType) {
        return;
      }
      const { printer, template, profile } =
        kitchenPrinterProfiles[printerProfileId];

      const profileName = profile.name;
      const locale = profile.locale;

      const printablePartialOrders: (Buffer | undefined)[] = [];
      const printItems = itemsByPrinterProfiles[printerProfileId];

      const voidItems = printItems.filter(
        item => item.status === OrderItemStatus.VOID,
      );

      const newItems = printItems.filter(
        item =>
          item.status === OrderItemStatus.IN_PROGRESS ||
          item.status === OrderItemStatus.COMPLETED,
      );

      if (profile.singleItemPrinting) {
        // if single item printing
        if (newItems.length) {
          let currentItemNumber = 0;
          const totalItems = newItems
            .map(item => {
              return item.quantity || 1;
            })
            .reduce((sum, itemQuantity) => sum + itemQuantity, 0);
          newItems.forEach(newItem => {
            if (Number.isInteger(newItem.quantity)) {
              // if qty is integer
              // add each qty of the item to a docket
              for (let i = 0; i < (newItem.quantity || 1); i++) {
                currentItemNumber++;
                printablePartialOrders.push(
                  getPrintableKitchenOrder({
                    order,
                    printItems: [{ ...newItem, quantity: 1 }],
                    session,
                    template,
                    translatedNowCourse,
                    profileName,
                    locale,
                    printerSeries: printer.series || PrinterSeries.TM_M30II,
                    currentItemNumber: currentItemNumber,
                    totalItems: totalItems,
                  }),
                );
              }
            } else {
              // if qty is not integer
              // add each item to docket
              currentItemNumber++;
              printablePartialOrders.push(
                getPrintableKitchenOrder({
                  order,
                  printItems: [{ ...newItem }],
                  session,
                  template,
                  translatedNowCourse,
                  profileName,
                  locale,
                  printerSeries: printer.series || PrinterSeries.TM_M30II,
                  currentItemNumber: currentItemNumber,
                  totalItems: totalItems,
                }),
              );
            }
          });
        }
        if (voidItems.length) {
          voidItems.forEach((voidItem, index) => {
            // print each void item to a docket
            printablePartialOrders.push(
              getPrintableKitchenOrder({
                order,
                printItems: [voidItem],
                session,
                template,
                hasVoidOrderItem: true,
                translatedNowCourse,
                profileName,
                locale,
                printerSeries: printer.series || PrinterSeries.TM_M30II,
                currentItemNumber: index + 1,
                totalItems: voidItems.length,
              }),
            );
          });
        }
      } else {
        if (newItems.length) {
          printablePartialOrders.push(
            getPrintableKitchenOrder({
              order,
              printItems: newItems,
              session,
              template,
              translatedNowCourse,
              profileName,
              locale,
              printerSeries: printer.series || PrinterSeries.TM_M30II,
            }),
          );
        }
        if (voidItems.length) {
          printablePartialOrders.push(
            getPrintableKitchenOrder({
              order,
              printItems: voidItems,
              session,
              template,
              hasVoidOrderItem: true,
              translatedNowCourse,
              profileName,
              locale,
              printerSeries: printer.series || PrinterSeries.TM_M30II,
            }),
          );
        }
      }

      printablePartialOrders.forEach(printablePartialOrder => {
        printablePartialOrder &&
          results.push({
            printer: {
              id: printer.id,
              device_name: printer.name,
              host: printer.ipAddress,
              port: DEFAULT_NET_PRINTER_PORT,
              type: printer.printerType,
              bdAddress: printer.bdAddress,
              slug: printer.slug,
              series: printer.series,
              brand: printer.brand,
            },
            buffer: printablePartialOrder,
          });
      });
    });

  return results;
};
