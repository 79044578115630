import React from 'react';
import { View, ActivityIndicator, ViewStyle } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';

interface Props {
  large?: boolean;
  containerStyles?: ViewStyle | ViewStyle[];
}

const LoadingIndicator: React.FC<Props> = ({ large, containerStyles }) => {
  const { colors } = useTheme();

  return (
    <View style={containerStyles}>
      <ActivityIndicator
        color={colors.accent}
        size={large ? 'large' : 'small'}
      />
    </View>
  );
};

export default LoadingIndicator;
