import React, { useCallback, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation } from '@oolio-group/localization';
import { useNotification } from '../../../../hooks/Notification';
import { OrderHistoryItem } from '../../../POS/Orders/OpenOrders/OpenOrders.types';
import OrderHistoryTableStyles from './OrderHistoryTable.styles';
import theme from '../../../../common/default-theme';
import OrderHistoryRow from './OrderHistoryRow';
import Empty from '../../../../components/Shared/Empty/Empty';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import SendReceiptModal from '../../../../components/POS/Modals/SendReceipt/SendReceiptModal';

interface Props {
  currentPage: number;
  data: OrderHistoryItem[];
  isOffline?: boolean;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onPressReprintDocket: (orderId: string) => void;
  onPressRow: (order: OrderHistoryItem) => void;
}

const OrderHistoryTable: React.FC<Props> = ({
  currentPage,
  data,
  isOffline,
  itemsPerPage,
  onPageChange,
  onPressPrintReceipt,
  onPressReprintDocket,
  onPressRow,
}) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { showNotification } = useNotification();

  const styles = OrderHistoryTableStyles();

  const pageItems = useMemo(() => {
    return data?.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );
  }, [data, currentPage, itemsPerPage]);

  const onPressSendReceipt = useCallback(
    order => {
      if (order) {
        showModal(<SendReceiptModal order={order} />);
      }
    },
    [showModal],
  );

  const handleRowPress = useCallback(
    (order: OrderHistoryItem) => {
      if (isOffline) {
        showNotification({
          error: true,
          message: translate('offline.orderHistory'),
        });
      } else {
        onPressRow(order);
      }
    },
    [isOffline, onPressRow, showNotification, translate],
  );

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[theme.tables.headerText, styles.headerDate]}>
          {translate('orderHistory.completedAt')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerNo]}>
          {translate('backOfficeSalesFeed.OrderNo')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerName]}>
          {translate('openOrders.orderName')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerPayments]}>
          {translate('payment.payments')}
        </Text>
        <Text style={[theme.tables.headerText, styles.headerAmt]}>
          {translate('backOfficeSalesFeed.Total')}
        </Text>
      </View>
      <View style={styles.tableBody}>
        {data.length > 0 ? (
          pageItems.map((order, i) => (
            <OrderHistoryRow
              order={order}
              key={`${order.id}-${i}`}
              onPress={() => handleRowPress(order)}
              onPressPrintReceipt={onPressPrintReceipt}
              onPressReprintDocket={onPressReprintDocket}
              onPressSendReceipt={onPressSendReceipt}
            />
          ))
        ) : (
          <Empty themed label={translate('orderHistory.emptyList')} />
        )}
      </View>
      {data.length > itemsPerPage ? (
        <Pagination
          pageLength={itemsPerPage}
          page={currentPage}
          onPageChange={onPageChange}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default OrderHistoryTable;
