import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import NotificationStyles from './Notification.styles';
import Icon from '../../Icon/Icon';

interface Props {
  info?: boolean;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  message: string;
  onClose?: () => void;
}

const Notification: React.FC<Props> = ({ message, onClose, ...props }) => {
  const { colors } = useTheme();
  const styles = NotificationStyles(props);

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{message}</Text>
      <TouchableOpacity style={styles.btn} onPress={onClose}>
        {Boolean(onClose) && (
          <Icon size={20} name="times" color={colors.white} />
        )}
      </TouchableOpacity>
    </View>
  );
};

export default Notification;
