import { StyleSheet } from 'react-native';
import theme from '../../../../../common/default-theme';
import { useTheme } from '../../../../../hooks/useTheme';

const FloorSidePanelStyles = () => {
  const { colors } = useTheme();

  return StyleSheet.create({
    // Shared
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    container: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: 340,
      padding: 6,
      rowGap: 4,
      backgroundColor: colors.background,
      ...theme.shadow.strong,
    },
    content: {
      flex: 1,
      padding: 10,
      borderRadius: theme.radius.s,
      backgroundColor: colors.elevation0,
    },
    // Table Info
    actionsRow: {
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: 4,
    },
    btnAction: {
      flex: 1,
    },
    row: {
      padding: 12,
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    rowTitle: {
      fontSize: 13,
      lineHeight: 20,
      marginBottom: 6,
      color: colors.textLight,
      fontFamily: theme.fonts.regular,
    },
    rowValues: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontFamily: theme.fonts.regular,
    },
    rowValue: {
      fontSize: 14,
      lineHeight: 20,
      color: colors.text,
      fontFamily: theme.fonts.medium,
      fontVariant: ['tabular-nums'],
    },
    link: {
      color: colors.blue,
    },
    // Section Statistics
    tableRow: {
      paddingVertical: 10,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: colors.borderColor,
    },
    tableRowName: {
      paddingVertical: 6,
      flexDirection: 'row',
      alignItems: 'center',
    },
    dot: {
      width: 12,
      height: 12,
      marginLeft: 12,
      borderRadius: 12,
      backgroundColor: theme.colors.red,
    },
    tableRowText: {
      marginLeft: 12,
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
    tableRowValue: {
      flex: 1,
      paddingRight: 12,
      textAlign: 'right',
      color: colors.text,
      fontFamily: theme.fonts.medium,
    },
  });
};

export default FloorSidePanelStyles;
