/* eslint-disable @typescript-eslint/no-explicit-any */
import posthog from 'posthog-js';
import { IAnalyticsProvider } from './AnalyticsService';

class PosthogWebProvider implements IAnalyticsProvider {
  private client: any | undefined;

  init(): void {
    if (this.client === undefined) {
      this.client = posthog.init(process.env.REACT_APP_POSTHOG_API_KEY || '', {
        api_host: 'https://app.posthog.com',
        disable_session_recording: true,
      });
    }
  }

  identify(userId: string, properties: Record<string, any>): void {
    this.client?.identify(userId, properties);
  }

  group(
    groupType: string,
    groupId: string,
    properties: Record<string, any>,
  ): void {
    this.client?.group(groupType, groupId, properties);
  }

  capture(event: string, properties?: Record<string, any>): void {
    this.client?.capture(event, properties || {});
  }

  async isFeatureEnabled(feature: string): Promise<boolean> {
    return await new Promise(resolve => {
      if (this.client)
        if (this.client?.isFeatureEnabled(feature)) {
          // feature flags should be available at this point
          resolve(true);
        } else resolve(false);
      else resolve(false);
    });
  }

  async getAllFeatureFlags(): Promise<any> {
    return;
  }

  startSessionRecording(): void {
    this.client?.startSessionRecording();
  }

  stopSessionRecording(): void {
    this.client?.stopSessionRecording();
  }

  reset(): void {
    this.client?.reset();
  }
}

export default PosthogWebProvider;
