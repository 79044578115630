import { StyleSheet } from 'react-native';
import theme from '../../../../../../common/default-theme';

const styles = StyleSheet.create({
  container: {
    width: 340,
    backgroundColor: theme.colors.light,
    alignSelf: 'center',
    borderRadius: 10,
    padding: 30,
    ...theme.shadow.elevated1,
  },
  icon: { alignSelf: 'center', marginVertical: 10 },
  titleText: {
    fontFamily: theme.fonts.bold,
    fontSize: 20,
    color: theme.colors.dark,
    lineHeight: 28,
    textAlign: 'center',
    marginTop: 20,
  },
  subTitle: {
    fontFamily: theme.fonts.regular,
    fontSize: 14,
    color: theme.colors.dark,
    lineHeight: 20,
    textAlign: 'center',
    marginTop: 10,
  },
  reservationContainer: {
    flexDirection: 'row',
    width: '100%',
    backgroundColor: theme.colors.grey1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderRadius: 4,
    marginVertical: 30,
  },

  timeText: {
    fontFamily: theme.fonts.semibold,
    fontSize: 14,
    color: theme.colors.dark,
    lineHeight: 20,
    marginBottom: 6,
  },
  nameText: {
    fontFamily: theme.fonts.medium,
    fontSize: 14,
    color: theme.colors.dark,
    lineHeight: 20,
  },
  phoneText: {
    fontFamily: theme.fonts.semibold,
    fontSize: 12,
    color: theme.colors.grey6,
    lineHeight: 18,
  },
  statusText: {
    fontFamily: theme.fonts.semibold,
    fontSize: 12,
    color: theme.colors.states.focus,
    lineHeight: 18,
    marginTop: 6,
  },
});

export default styles;
