import { Platform } from 'react-native';

export async function convertBase64ToDithered(
  base64Image: string,
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (Platform.OS !== 'web') {
      resolve(base64Image);
      return;
    }
    if (!base64Image.startsWith('data:image')) {
      reject(new Error('Invalid Base64 Image Data'));
      return;
    }

    const img = new Image();
    img.src = base64Image;
    img.crossOrigin = 'Anonymous';

    img.onload = function () {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Could not get canvas context'));
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      convertToGrayscale(pixels);

      applyAtkinsonDithering(imageData);

      ctx.putImageData(imageData, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    };

    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
  });
}

function convertToGrayscale(data: Uint8ClampedArray): void {
  for (let i = 0; i < data.length; i += 4) {
    const r = data[i];
    const g = data[i + 1];
    const b = data[i + 2];

    const maxColor = Math.max(r, g, b);
    const minColor = Math.min(r, g, b);
    const colorDiff = maxColor - minColor;

    let gray = 0.4 * r + 0.4 * g + 0.2 * b;

    if (colorDiff > 50) {
      gray *= 0.3; // Darken strong colors
    }

    data[i] = data[i + 1] = data[i + 2] = gray;
  }
}

function applyAtkinsonDithering(imageData: ImageData): void {
  const { width, height, data } = imageData;

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const i = (y * width + x) * 4;
      const oldPixel = data[i];
      const newPixel = oldPixel < 128 ? 0 : 255;
      const error = oldPixel - newPixel;

      data[i] = data[i + 1] = data[i + 2] = newPixel;
      distributeError(data, width, height, x, y, error);
    }
  }
}

function distributeError(
  data: Uint8ClampedArray,
  width: number,
  height: number,
  x: number,
  y: number,
  error: number,
): void {
  function addError(offsetX: number, offsetY: number, factor: number): void {
    const j = ((y + offsetY) * width + (x + offsetX)) * 4;
    if (j >= 0 && j < data.length) {
      data[j] += error * factor;
      data[j + 1] += error * factor;
      data[j + 2] += error * factor;
    }
  }

  addError(1, 0, 1 / 8); // Right
  addError(2, 0, 1 / 8); // Right next
  addError(-1, 1, 1 / 8); // Bottom left
  addError(0, 1, 1 / 8); // Bottom
  addError(1, 1, 1 / 8); // Bottom right
  addError(0, 2, 1 / 8); // Below bottom
}
