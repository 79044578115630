import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

interface Props {
  type: string;
  themed?: boolean;
  rounded?: boolean;
}

const StickerStyles = ({ type, themed = false, rounded = false }: Props) => {
  const { colors } = useTheme();

  const getContainerColor = useCallback(() => {
    switch (type) {
      case 'primary':
        return theme.colors.primary;
      case 'primaryLight':
        return themed ? colors.primaryLight : theme.colors.primaryLight;
      case 'accent':
        return theme.colors.accent;
      case 'positive':
        return theme.colors.states.positive;
      case 'negative':
        return theme.colors.states.negative;
      case 'neutral':
        return theme.colors.states.neutral;
      case 'focus':
        return theme.colors.states.focus;
      case 'positiveLight':
        return themed ? colors.greenLightest : theme.colors.states.positiveXL;
      case 'negativeLight':
        return themed ? colors.redLightest : theme.colors.states.negativeXL;
      case 'neutralLight':
        return themed ? colors.blueLightest : theme.colors.states.neutralXL;
      case 'focusLight':
        return themed ? colors.orangeLightest : theme.colors.states.focusXL;
      case 'teal':
        return themed ? colors.tealLightest : theme.colors.tealLightest;
      default:
        return themed ? colors.elevation2 : theme.colors.grey1;
    }
  }, [colors, themed, type]);

  const getTextColor = useCallback(() => {
    switch (type) {
      case 'primary':
      case 'accent':
      case 'positive':
      case 'negative':
      case 'neutral':
      case 'focus':
        return colors.white;
      case 'primaryLight':
        return colors.primary;
      case 'positiveLight':
        return colors.green;
      case 'negativeLight':
        return colors.red;
      case 'neutralLight':
        return colors.blue;
      case 'focusLight':
        return colors.orange;
      case 'teal':
        return colors.teal;
      default:
        return themed ? colors.text : colors.black1;
    }
  }, [colors, themed, type]);

  return StyleSheet.create({
    container: {
      height: 28,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: rounded ? 14 : theme.radius.s,
      backgroundColor: getContainerColor(),
    },
    text: {
      fontSize: 12,
      minWidth: 60,
      width: '100%',
      textAlign: 'center',
      paddingHorizontal: 10,
      textTransform: 'uppercase',
      fontVariant: ['tabular-nums'],
      fontFamily: theme.fonts.semibold,
      color: getTextColor(),
    },
    indicator: {
      width: 8,
      height: 8,
      borderRadius: theme.radius.m,
      backgroundColor: colors.white,
      marginRight: 6,
    },
  });
};

export default StickerStyles;
