import React, { useCallback } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ViewStyle,
} from 'react-native';
import { groupBy } from 'lodash';
import { isToday, isYesterday, isTomorrow, format } from 'date-fns';
import { Waitlist, WaitlistStatusEnum } from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { useTheme } from '../../../../hooks/useTheme';
import theme from '../../../../common/default-theme';
import BookingFilterStyles from './Filters.styles';
import SelectDate from '../../../../components/Shared/Select/SelectDate';

export interface Filters {
  date: Date;
  status: WaitlistStatusEnum[];
  guestCount: number;
}

interface FiltersProps {
  show: boolean;
  onDismiss: () => void;
  onUpdate: (id: string, value: Date | number | WaitlistStatusEnum) => void;
  filters: Filters;
  waitlists: Waitlist[];
}
interface ItemProps {
  label: string;
  count: string | number;
  isSelected?: boolean;
  onPress?: () => void;
  disabled?: boolean;
  containerStyles?: ViewStyle;
  date?: Date;
}

const DATE_OPTIONS = ['Today', 'Yesterday', 'Tomorrow', 'Specific Date'];

const COUNT_OPTIONS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6+' },
];

export const STATUS_OPTIONS = [
  { value: 'All' as WaitlistStatusEnum, label: 'All Statuses' },
  { value: WaitlistStatusEnum.WAITING, label: 'Waiting' },
  { value: WaitlistStatusEnum.READY, label: 'Ready' },
  { value: WaitlistStatusEnum.ARRIVED, label: 'Arrived' },
  { value: WaitlistStatusEnum.COMPLETED, label: 'Completed' },
  { value: WaitlistStatusEnum.NO_SHOW, label: 'No Show' },
  { value: WaitlistStatusEnum.CANCELLED, label: 'Cancelled' },
];

export const getRelativeDayLabel = (timestamp: number | Date): string => {
  const date = new Date(timestamp);

  if (isToday(date)) return 'Today';
  if (isYesterday(date)) return 'Yesterday';
  if (isTomorrow(date)) return 'Tomorrow';

  return 'Specific Date';
};

const FilterItem: React.FC<ItemProps> = ({
  label,
  count,
  isSelected,
  onPress,
  disabled,
  containerStyles,
  date,
}) => {
  const { colors } = useTheme();
  const styles = BookingFilterStyles();

  return (
    <TouchableOpacity
      style={[
        styles.filterRow,
        containerStyles,
        isSelected && { backgroundColor: colors.blueLight },
      ]}
      onPress={onPress && onPress}
      disabled={disabled}
    >
      <View style={styles.filterRowContent}>
        <Text style={styles.filterText}>{label}</Text>
        {count ? (
          <View
            style={[
              styles.countContainer,
              isSelected && { backgroundColor: colors.blueLight },
            ]}
          >
            <Text style={styles.countText}>{count}</Text>
          </View>
        ) : null}
      </View>

      {date && (
        <View style={styles.specificDate}>
          <Text style={styles.specificDateText}>
            {format(new Date(date), 'dd MMMM yyyy ')}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

const Filters: React.FC<FiltersProps> = ({
  show,
  filters,
  onDismiss,
  onUpdate,
  waitlists,
}) => {
  const { colors } = useTheme();
  const { translate } = useTranslation();

  const styles = BookingFilterStyles();
  const safeHeight = theme.useSafeHeight();

  const onUpdateStatus = useCallback(
    (status: WaitlistStatusEnum) => {
      onUpdate('status', status);
    },
    [onUpdate],
  );

  const onSelectDate = (date?: Date) => {
    if (date) {
      const selection = new Date(date);
      onUpdate('date', selection);
    }
  };

  const onUpdateDate = useCallback(
    (selection: String) => {
      switch (selection) {
        case 'Today':
          const today = new Date();
          onUpdate('date', today);
          break;
        case 'Yesterday':
          const yesterday: Date = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          onUpdate('date', yesterday);
          break;
        case 'Tomorrow':
          const tomorrow: Date = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          onUpdate('date', tomorrow);
          break;
        case 'Specific Date':
          break;

        default:
          onUpdate('date', new Date());
      }
    },
    [onUpdate],
  );

  const onUpdateGuestCount = useCallback(
    (value: number) => {
      onUpdate('guestCount', value);
    },
    [onUpdate],
  );

  const waitlistsStatusMap = groupBy(waitlists, 'status');

  return (
    <>
      {show && (
        <TouchableOpacity
          style={[styles.backdrop, { height: safeHeight }]}
          onPress={onDismiss}
          activeOpacity={1.0}
        >
          <TouchableOpacity
            style={[styles.container, { height: safeHeight }]}
            activeOpacity={1.0}
          >
            <ScrollView
              style={styles.content}
              showsVerticalScrollIndicator={false}
            >
              <View style={styles.section}>
                <View style={styles.filterTitleContainer}>
                  <Text style={styles.sectionLabel}>
                    {translate('bookings.date')}
                  </Text>
                </View>
                {DATE_OPTIONS.map(day => {
                  if (day === 'Specific Date') {
                    return (
                      <View key={day}>
                        <SelectDate
                          key={day}
                          testID="select-date"
                          valueDate={new Date()}
                          onChangeDate={date => {
                            if (date !== undefined) {
                              onSelectDate(date);
                            }
                          }}
                          customView={
                            <FilterItem
                              label={day}
                              count={
                                getRelativeDayLabel(filters.date) === day
                                  ? waitlists.length
                                  : 0
                              }
                              disabled
                              onPress={() => onUpdateDate(day)}
                              isSelected={
                                getRelativeDayLabel(filters.date) === day
                              }
                              date={
                                getRelativeDayLabel(filters.date) === day
                                  ? filters.date
                                  : undefined
                              }
                            />
                          }
                        />
                      </View>
                    );
                  }
                  return (
                    <FilterItem
                      key={day}
                      label={day}
                      count={
                        getRelativeDayLabel(filters.date) === day
                          ? waitlists.length
                          : 0
                      }
                      onPress={() => onUpdateDate(day)}
                      isSelected={getRelativeDayLabel(filters.date) === day}
                    />
                  );
                })}
              </View>
              <View style={styles.section}>
                <View style={styles.filterTitleContainer}>
                  <Text style={styles.sectionLabel}>
                    {translate('bookings.guestCounts')}
                  </Text>
                </View>
                <FilterItem
                  label={translate('bookings.allCounts')}
                  count={0}
                  isSelected={filters.guestCount === -1}
                  onPress={() => onUpdateGuestCount(-1)}
                />
                <View style={styles.grid}>
                  {COUNT_OPTIONS.map(option => {
                    const isSelected = filters.guestCount === option.value;
                    return (
                      <TouchableOpacity
                        key={option.value}
                        style={[
                          styles.gridItem,
                          isSelected && {
                            backgroundColor: colors.blueLight,
                          },
                        ]}
                        onPress={() => onUpdateGuestCount(option.value)}
                      >
                        <Text style={styles.filterText}>{option.label}</Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
              <View style={styles.section}>
                <View style={styles.filterTitleContainer}>
                  <Text style={styles.sectionLabel}>
                    {translate('bookings.status')}
                  </Text>
                </View>
                {STATUS_OPTIONS.map(option => {
                  return (
                    <FilterItem
                      key={option.value}
                      label={option.label}
                      count={
                        option.value === ('All' as WaitlistStatusEnum)
                          ? waitlists.length
                          : waitlistsStatusMap[option.value]?.length
                      }
                      onPress={() => onUpdateStatus(option.value)}
                      isSelected={filters.status.includes(option.value)}
                    />
                  );
                })}
              </View>
            </ScrollView>
          </TouchableOpacity>
        </TouchableOpacity>
      )}
    </>
  );
};

export default Filters;
