import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useTheme } from '../../../hooks/useTheme';
import theme from '../../../common/default-theme';
import SelectListStyles from './SelectList.styles';
import Icon from '../../Icon/Icon';

export interface SelectOptionProps {
  title?: string;
  values: {
    label: string;
    value: string;
  }[];
  onPress?: (selectedValue: string) => void;
  testID?: string;
  selectedValue: string;
}

const SelectList: React.FC<SelectOptionProps> = ({
  title,
  values,
  onPress,
  selectedValue,
}) => {
  const { colors } = useTheme();
  const styles = SelectListStyles();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {values.map((item, index) => {
        const isSelected = item.value === selectedValue;
        return (
          <TouchableOpacity
            key={index + `${item.label}`}
            onPress={() => onPress && onPress(item.value)}
            style={[
              styles.optionContainer,
              isSelected && { backgroundColor: colors.elevation1 },
            ]}
          >
            <Text
              testID={'open-orders-filter-text'}
              style={[
                styles.optionLabel,
                isSelected && { color: theme.colors.blue },
              ]}
            >
              {item.label}
            </Text>
            {isSelected ? (
              <Icon size={20} name="check" color={theme.colors.green} />
            ) : null}
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default SelectList;
