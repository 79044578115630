import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { View, Text } from 'react-native';
import { useRoute, useIsFocused } from '@react-navigation/native';
import { cloneDeep, keyBy } from 'lodash';
import {
  AlternateName,
  CreateModifierInput,
  Modifier as ModifierDefault,
  UpdateModifierInput,
  Money,
  ProductPricingInput,
  DEFAULT_PRICING_GROUP,
  PricingGroup,
} from '@oolio-group/domain';
import {
  getBestPriceOfModifier,
  getTaxFromModifier,
} from '@oolio-group/catalog-helper';
import { useModal } from '@oolio-group/rn-use-modal';
import { useTranslation, useCurrency } from '@oolio-group/localization';
import { encodeAlternateNameValuesToBase64 } from '@oolio-group/client-utils';
import { useTaxes } from '../../../../../hooks/app/useTaxes';
import { useNotification } from '../../../../../hooks/Notification';
import { usePricingGroups } from '../../../../../hooks/app/usePricingGroups';
import { useModifiers } from '../../../../../hooks/app/modifiers/useModifiers';
import { useModifierGroups } from '../../../../../hooks/app/modifierGroups/useModifierGroups';
import { useProductPricings } from '../../../../../hooks/app/productPricings/useProductPricings';
import { useModifierPricings } from '../../../../../hooks/app/modifierPricings/useModifierPricings';
import { Operation } from '../../../../../types/Operation';
import theme, { DEFAULT_PAGE_SIZE } from '../../../../../common/default-theme';
import styles from '../Options.styles';
import { ModifiersRow } from './ModifiersRow';
import TranslationModal from '../TranslationModal';
import { CreateModifierModal } from './CreateModifier';
import { ModifierHeaderFilters } from './ModifierHeaderFilters';
import Section from '../../../../../components/Office/Section/Section';
import Pagination from '../../../../../components/Office/Pagination/Pagination';
import ConfirmationModal from '../../../../../components/Modals/ConfirmationDialog';
import ScreenLayout from '../../../../../components/Office/ScreenLayout/ScreenLayout';

interface CreateModifierInputAlias extends CreateModifierInput {
  tax?: string;
  price?: Money;
}

interface Modifier extends ModifierDefault {
  isSelected?: boolean;
  tax?: string;
  priceAmount?: string;
}

export const Modifiers: React.FC = () => {
  const route = useRoute();
  const isFocused = useIsFocused();
  const { translate } = useTranslation();
  const { showModal, closeModal } = useModal();
  const { showNotification } = useNotification();
  const { unAppendCurrency, currency } = useCurrency();
  const { taxesOptions, loading: taxesLoading, error: taxesError } = useTaxes();

  const {
    getAllModifiers,
    loading: modLoading,
    error: modError,
    operation: modOperation,
    modifiers,
    createModifiers,
    createdIds,
    updateModifiers,
    deleteModifiers,
    deletedIds,
  } = useModifiers();

  const {
    getAllModifierGroups,
    loading: MGLoading,
    error: MGError,
    modifierGroups,
  } = useModifierGroups();

  const {
    error: errorPP,
    loading: loadingPP,
    operation: PPOperation,
    addProductPricing,
    update: updateProductPricings,
  } = useProductPricings();

  const {
    error: MPError,
    loading: MPLoading,
    operation: MPOperation,
  } = useModifierPricings();

  const {
    defaultPricingGroup,
    error: PGErr,
    loading: PGLoading,
    getDefaultPricingGroup,
  } = usePricingGroups();

  const params = route.params as {
    openCreateModal: boolean;
  };

  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [modifiersData, setModifiersData] = useState<
    Record<string, Modifier & { isChanged?: boolean }>
  >({});
  const [createModifiersData, setCreateModifiersData] = useState(
    {} as CreateModifierInputAlias,
  );

  useEffect(() => {
    if (isFocused) {
      getAllModifiers();
      getAllModifierGroups();
      getDefaultPricingGroup();
    }
  }, [
    getAllModifiers,
    getAllModifierGroups,
    getDefaultPricingGroup,
    isFocused,
  ]);

  const error = modError || MGError || taxesError || PGErr;

  const loading = modLoading || MGLoading || taxesLoading || PGLoading;

  const isModifierCreated =
    !modError && !modLoading && modOperation === Operation.CREATE;

  const isProductPricingCreated =
    !errorPP && !loadingPP && PPOperation === Operation.CREATE;

  const isModifierPriceCreated =
    !MPError && !MPLoading && MPOperation === Operation.CREATE;

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!loading && modifiers) {
      setModifiersData(modifiers);
    }
  }, [loading, error, modOperation, modifiers]);

  useEffect(() => {
    if (!loading && modOperation === Operation.DELETE && deletedIds.length) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierDeletedSuccessfully'),
      });
      closeModal();
      setModifiersData(prev => {
        const tempPrev = { ...prev };
        deletedIds.forEach(x => delete tempPrev[x]);
        return tempPrev;
      });
    }
  }, [
    loading,
    modOperation,
    showNotification,
    translate,
    closeModal,
    deletedIds,
  ]);

  useEffect(() => {
    if (isModifierCreated && defaultPricingGroup && createdIds.length) {
      addProductPricing(createdIds[0], [
        {
          pricingGroupId: defaultPricingGroup.id,
          productPricing: {
            sellingPrice: createModifiersData?.price,
            sellingTax: createModifiersData?.tax,
          } as ProductPricingInput,
        },
      ]);
    }
  }, [
    isModifierCreated,
    defaultPricingGroup,
    createdIds,
    addProductPricing,
    createModifiersData,
  ]);

  useEffect(() => {
    if (
      createModifiersData?.name &&
      (isProductPricingCreated || isModifierPriceCreated) &&
      isModifierCreated &&
      createdIds.length
    ) {
      closeModal();
      showNotification({
        success: true,
        message: translate('modifiers.modifierCreatedSuccessfully'),
      });
      setCreateModifiersData(prev => prev && { ...prev, name: '' });
      getAllModifiers();
    }
  }, [
    createModifiersData,
    isProductPricingCreated,
    isModifierPriceCreated,
    isModifierCreated,
    createdIds,
    showNotification,
    translate,
    closeModal,
    getAllModifiers,
  ]);

  const optionGroups = useMemo(() => {
    return Object.values(modifierGroups)
      .filter(group => group.products && group.products.length === 0)
      .map(group => ({
        value: group.id,
        label: group.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [modifierGroups]);

  const modifiersDataArray = useMemo(() => {
    return Object.values(modifiersData).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [modifiersData]);

  const onChange = useCallback(
    (id, prop, value) => {
      if (prop === 'priceAmount') {
        value = unAppendCurrency(value);
      }
      setModifiersData(prev => {
        return {
          ...prev,
          [id]: {
            ...prev[id],
            [prop]: value,
            isChanged: true,
          },
        };
      });
    },
    [unAppendCurrency],
  );

  const pageItems = useMemo(() => {
    return modifiersDataArray.slice(
      (currentPage - 1) * DEFAULT_PAGE_SIZE,
      currentPage * DEFAULT_PAGE_SIZE,
    );
  }, [modifiersDataArray, currentPage]);

  const changedModifiers = useMemo(() => {
    return modifiersDataArray.filter(x => x && x.isChanged);
  }, [modifiersDataArray]);

  useEffect(() => {
    if (
      changedModifiers.length &&
      !modError &&
      !modLoading &&
      modOperation === Operation.UPDATE
    ) {
      showNotification({
        success: true,
        message: translate('modifiers.modifierUdpatedSuccessfully'),
      });
      getAllModifiers();
    }
  }, [
    changedModifiers,
    showNotification,
    translate,
    modError,
    modOperation,
    modLoading,
    getAllModifiers,
  ]);

  const getPricingInput = useCallback(
    (pricingGroups: PricingGroup[], modifier: Modifier) => {
      const pricingGroup =
        pricingGroups.find(g => g.name === DEFAULT_PRICING_GROUP) ||
        pricingGroups[0];
      const pricingId = pricingGroup?.prices?.[0]?.id;
      const price = modifier?.priceAmount || getBestPriceOfModifier(modifier);
      const tax = getTaxFromModifier(modifier);
      return {
        id: pricingId || '',
        sellingPrice: {
          amount: +(price || '0'),
          currency: currency,
        } as Money,
        sellingTax: modifier?.tax || tax?.[0]?.id,
        pricingGroupId: defaultPricingGroup?.id as string,
        product: modifier.id,
      } as unknown as ProductPricingInput;
    },
    [currency, defaultPricingGroup?.id],
  );

  const updateProductPricingsData = useCallback(() => {
    // update product pricings
    const productModifiers = cloneDeep(changedModifiers);
    const updateProductPricingsInput = productModifiers.map(x =>
      getPricingInput(x.pricingGroups, x),
    );
    updateProductPricingsInput.length &&
      updateProductPricings(updateProductPricingsInput);
  }, [getPricingInput, changedModifiers, updateProductPricings]);

  const updateModifiersData = useCallback(() => {
    // update modifiers
    changedModifiers.length &&
      updateModifiers(
        changedModifiers.map(
          x =>
            ({
              id: x.id,
              name: x.name.trim(),
              modifierGroups: x.modifierGroups.map(x => x?.id || x),
              pricing: getPricingInput(x.pricingGroups, x),
            } as UpdateModifierInput),
        ),
      );
  }, [changedModifiers, updateModifiers, getPricingInput]);

  const onPressSave = useCallback((): void => {
    if (!changedModifiers.length) {
      showNotification({
        error: true,
        message: translate('modifiers.noRecordsChanged'),
      });
    } else {
      updateProductPricingsData();
      updateModifiersData();
    }
  }, [
    updateProductPricingsData,
    updateModifiersData,
    translate,
    showNotification,
    changedModifiers,
  ]);

  const onCreateModifier = useCallback(
    (input: CreateModifierInputAlias): void => {
      createModifiers([
        {
          name: input.name,
          modifierGroups: input.modifierGroups,
          pricing: {
            sellingPrice: input.price,
            sellingTax: input.tax,
            pricingGroupId: defaultPricingGroup?.id,
          } as ProductPricingInput,
        },
      ]);
      setCreateModifiersData(input);
    },
    [createModifiers, defaultPricingGroup?.id],
  );

  const showCreateModifierModal = useCallback((): void => {
    if (defaultPricingGroup?.id) {
      showModal(
        <CreateModifierModal
          modifierGroups={optionGroups}
          onCreate={onCreateModifier}
          taxesOptions={taxesOptions}
        />,
      );
    } else {
      showNotification({
        error: true,
        message: translate('productSettings.defaultPricingGroupMissing'),
      });
    }
  }, [
    optionGroups,
    showModal,
    taxesOptions,
    onCreateModifier,
    defaultPricingGroup,
    showNotification,
    translate,
  ]);

  useEffect(() => {
    if (params?.openCreateModal) {
      showCreateModifierModal();
    }
  }, [params, showCreateModifierModal]);

  const onDeleteRow = useCallback(
    (id: string, name: string) => {
      showModal(
        <ConfirmationModal
          title={translate('modifiers.deletingModifier')}
          confirmLabel={translate('dialog.deleteTitle')}
          message={translate('modifiers.areYouSureWantToDeleteModifier', {
            name,
          })}
          onConfirm={() => deleteModifiers([id])}
        />,
      );
    },
    [deleteModifiers, showModal, translate],
  );

  const filterUpdate = useCallback(
    (modifierData: (Modifier & { isChanged?: boolean })[]) => {
      setModifiersData(keyBy(modifierData, 'id'));
    },
    [],
  );

  const onConfirmTranslations = useCallback(
    (id: string, alternateNames: AlternateName[]) => {
      const mod = modifiersDataArray.filter(x => x && x.id == id);
      if (mod?.length) {
        updateModifiers(
          mod.map(
            x =>
              ({
                id: x.id,
                name: x.name.trim(),
                modifierGroups: x.modifierGroups.map(x => x?.id || x),
                alternateNames:
                  encodeAlternateNameValuesToBase64(alternateNames),
              } as UpdateModifierInput),
          ),
        );
        closeModal();
      }
    },
    [modifiersDataArray, closeModal, updateModifiers],
  );

  const openTranslationModal = useCallback(
    (id: string) => {
      const altNames = modifiers[id]?.alternateNames || [];
      showModal(
        <TranslationModal
          alternateNames={altNames}
          id={id}
          onConfirm={onConfirmTranslations}
        />,
      );
    },
    [showModal, modifiers, onConfirmTranslations],
  );

  return (
    <ScreenLayout
      loading={loading}
      title="Modifiers | Oolio"
      onSave={onPressSave}
      hideFooter={changedModifiers.length < 1}
    >
      <Section
        title={translate('modifiers.modifiers')}
        subtitle={translate('modifiers.modifiersSubtitle')}
        layoutWidth="large"
      >
        <ModifierHeaderFilters
          searchText={searchText}
          onSearchChange={setSearchText}
          modifierGroups={optionGroups}
          onPressCreate={showCreateModifierModal}
          modifierList={Object.values(modifiers)}
          onFilterChange={filterUpdate}
        />
        <View>
          <View style={theme.tables.header}>
            <Text style={[theme.tables.headerText, styles.headerModifier]}>
              {translate('modifiers.modifierName')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerGroups]}>
              {translate('modifiers.optionGroups')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerPrice]}>
              {translate('backOfficeProducts.productPrice')}
            </Text>
            <Text style={[theme.tables.headerText, styles.headerTax]}>
              {translate('modifiers.taxRate')}
            </Text>
          </View>
          {pageItems.length > 0 ? (
            <View>
              {pageItems.map((modifier, i: number) => (
                <ModifiersRow
                  key={i}
                  rowIndex={i}
                  modifier={modifier}
                  modifierGroups={optionGroups}
                  taxes={taxesOptions}
                  onChange={onChange}
                  onDeleteRow={onDeleteRow}
                  openTranslationModal={openTranslationModal}
                />
              ))}
            </View>
          ) : (
            <View style={theme.tables.emptyView}>
              <Text style={theme.tables.emptyText}>
                {Object.values(modifiers).length < 1
                  ? translate('modifiers.noneCreated')
                  : translate('common.noMatches', {
                      entity: searchText,
                    })}
              </Text>
            </View>
          )}
          <Pagination
            page={currentPage}
            onPageChange={setCurrentPage}
            dataLength={modifiersDataArray.length}
            pageLength={pageItems.length}
            entityName={translate('modifiers.modifiers')}
          />
        </View>
      </Section>
    </ScreenLayout>
  );
};
