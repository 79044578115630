import React, { useCallback, useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import fetch from 'cross-fetch';
import { Resource } from '@oolio-group/domain';
import { useSession } from '../../../hooks/app/useSession';
import { useIntercom } from '../../../hooks/Intercom/useIntercom';
import useOfficeUserAuthorization from '../../../hooks/app/users/useOfficeUserAuthorization';
import { IMap } from '../Reports/types';
import { analyticsService } from '../../../analytics/AnalyticsService';
import { tokenUtility } from '../../../state/tokenUtility';
import { URL_INTERCOM } from '../../../constants';
import Shortcuts from './Sections/Shortcuts';
import OtherSection from './Sections/OtherSection';
import SalesSnapshot from './Sections/SalesSnapshot';
import ScreenLayout from '../../../components/Office/ScreenLayout/ScreenLayout';
import { useReportSettings } from '../../../hooks/app/useReportSettings';
import {
  endOfDay,
  endOfMonth,
  format,
  parse,
  setHours,
  setMinutes,
  startOfDay,
  startOfMonth,
} from 'date-fns';

const CUBE_DATE_FORMAT = 'yyyy-MM-dd HH:mm';

const determineDateRange = (
  duration: 'Today' | 'This month',
  startTimeOfDay = '00:00',
) => {
  const time = parse(startTimeOfDay, 'HH:mm', new Date());
  const today = new Date();

  if (duration === 'Today') {
    // If midnight (00:00)
    if (time.getHours() === 0 && time.getMinutes() === 0) {
      const start = startOfDay(today);
      const end = endOfDay(today);

      return [format(start, CUBE_DATE_FORMAT), format(end, CUBE_DATE_FORMAT)];
    }

    // For any other time, get 24 hour period
    const start = setHours(
      setMinutes(today, time.getMinutes()),
      time.getHours(),
    );
    const end = endOfDay(today);

    return [format(start, CUBE_DATE_FORMAT), format(end, CUBE_DATE_FORMAT)];
  }
  // If midnight (00:00)
  if (time.getHours() === 0 && time.getMinutes() === 0) {
    const start = startOfMonth(today);
    const end = endOfMonth(today);

    return [format(start, CUBE_DATE_FORMAT), format(end, CUBE_DATE_FORMAT)];
  }

  // For any other time, get rolling month period
  const start = setHours(
    setMinutes(startOfMonth(today), time.getMinutes()),
    time.getHours(),
  );

  const end = endOfMonth(today);

  return [format(start, CUBE_DATE_FORMAT), format(end, CUBE_DATE_FORMAT)];
};

export const OverviewScreen: React.FC = () => {
  const [session] = useSession();
  const Intercom = useIntercom();
  const { canI } = useOfficeUserAuthorization();

  const [salesDailyData, setSalesDailyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [salesMonthlyData, setSalesMonthlyData] = useState<
    IMap<string | undefined> | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { dateTime, getDateTime } = useReportSettings();

  const REACT_APP_ANALYTICS_API_URL =
    process.env['REACT_APP_ANALYTICS_API_URL'] ||
    'http://localhost:4020/api/analytics/v1';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function getSalesData(source: any, duration: string[] | string) {
      setIsLoading(true);
      const query = {
        measures: ['Orders.count', 'Orders.totalRevenue'],
        timeDimensions: [
          {
            dimension: 'Orders.updatedAt',
            dateRange: duration,
          },
        ],
        order: {
          'Orders.updatedAt': 'asc',
        },
      };

      const queryUrl = `${REACT_APP_ANALYTICS_API_URL}/load?query=${JSON.stringify(
        query,
      )}`;

      const request = await fetch(queryUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          organization: session?.currentOrganization?.id || '',
          Authorization: `Bearer ${tokenUtility.token || ''}`,
        },
      });

      const response = await request.json();
      setIsLoading(false);
      source(response?.data?.[0]);
    }

    // Initialize dateTime if not already set
    if (!dateTime?.startTime) {
      getDateTime();
      return;
    }

    if (!salesDailyData) {
      const dateRange = dateTime?.startTime
        ? determineDateRange('Today', dateTime?.startTime)
        : 'Today';

      getSalesData(setSalesDailyData, dateRange);
    }

    if (!salesMonthlyData) {
      const dateRange = dateTime?.startTime
        ? determineDateRange('This month', dateTime?.startTime)
        : 'This month';
      getSalesData(setSalesMonthlyData, dateRange);
    }
  }, [
    salesDailyData,
    salesMonthlyData,
    session.currentOrganization?.id,
    REACT_APP_ANALYTICS_API_URL,
    getDateTime, // only needed for initialization
    dateTime?.startTime,
  ]);

  const onPressContact = useCallback(() => {
    analyticsService.capture('home_link', {
      button: 'Contact Us',
    });

    const IntercomUser = {
      email: session?.user?.email || '',
      userId: session?.user?.id || '',
      name: session?.user?.name || '',
      customAttributes: {
        app: session?.activeApp || '',
      },
      companies: [
        {
          ...Platform.select({
            web: {
              companyId: session?.currentOrganization?.id || '',
            },
            native: {
              id: session?.currentOrganization?.id || '',
            },
          }),
          name: session?.currentOrganization?.name || '',
          customAttributes: {
            venue: session?.currentVenue?.name || '',
            store: session?.currentStore?.name || '',
            abn: session?.currentOrganization?.businessIdentifier || '',
          },
        },
      ],
    };
    Intercom.start(IntercomUser, Intercom.show);
  }, [Intercom, session]);

  const onPressSupport = () => {
    analyticsService.capture('home_link', {
      button: 'Support Center',
    });

    if (Platform.OS === 'web') {
      window.open(URL_INTERCOM, '_blank');
    } else {
      Linking.openURL(URL_INTERCOM);
    }
  };

  return (
    <ScreenLayout hideFooter loading={isLoading} title="Office | Oolio">
      {canI([
        { doOperations: ['view'], onResource: Resource.MANAGE_PRODUCTS },
      ]) && <Shortcuts />}
      {canI([
        { doOperations: ['view'], onResource: Resource.REPORT_SALES },
      ]) && (
        <SalesSnapshot
          dailySales={salesDailyData}
          monthlySales={salesMonthlyData}
        />
      )}
      <OtherSection
        onPressContact={onPressContact}
        onPressSupport={onPressSupport}
      />
    </ScreenLayout>
  );
};
