import { useCallback, useState, useRef, useEffect } from 'react';
import {
  Customer,
  BookingWidget,
  Waitlist,
  WaitlistStatusEnum,
  OrderEvent,
  OrderTypeCode,
  OrderAction,
  Table,
  BookingSource,
} from '@oolio-group/domain';
import { useSession } from '../useSession';
import { useCart } from '../../orders/useCart';
import { createNewOrderStore } from '../../../store/OrderStore';
import { useOrderTypes } from '../orderTypes/useOrderTypes';
import { useCustomers } from '../../orders/useCustomers';
import { formatEmail } from '../../../utils/customer';
import { getAuthenticationState } from '../../../state/preferences';

export interface CreateBookingInput {
  customer: Partial<Customer>;
  notes: string;
  priority: boolean;
  guestCount: number;
  widgetId: string;
  lastUpdatedBy: string;
  lastUpdatedBySource: BookingSource;
  createdBy: string;
}

export interface EditBookingInput extends Omit<CreateBookingInput, 'widgetId'> {
  waitlistId: string;
}

export const useBookingsV2 = () => {
  const [session] = useSession();
  const [widgets, setWidgets] = useState<BookingWidget[]>([]);
  const [waitlists, setWaitlists] = useState<Waitlist[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const { getOrderTypes, orderTypes } = useOrderTypes();
  const { addNewCustomer, getCustomerByEmailOrPhone } = useCustomers();

  const orderStoreRef = useRef(createNewOrderStore());
  const { resetCart, setCartParams, updateCart } = useCart(
    orderStoreRef.current,
  );

  const dineInOrderType = Object.values(orderTypes || {}).find(
    orderType => orderType.code === OrderTypeCode.DINE_IN,
  );

  useEffect(() => {
    getOrderTypes();
  }, [getOrderTypes]);

  const fetchWidgets = useCallback(
    async ({
      orgId,
      locId,
    }: {
      orgId: string;
      locId: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Promise<any> => {
      if (!orgId || !locId) {
        console.error('Missing required parameters: orgId or locId');
        throw new Error('Organization ID and Location ID are required.');
      }

      try {
        const tokenState = await getAuthenticationState();
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/org/${orgId}/location/${locId}/widgets`;

        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenState?.token ?? ''}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch widgets. Status: ${response.status}`,
          );
        }

        return await response.json();
      } catch (error) {
        console.error('Error fetching widgets:', error);
        throw error;
      }
    },
    [],
  );

  const fetchWaitlistByWidget = useCallback(
    async ({
      fromDate,
      toDate,
      widgetId,
    }: {
      fromDate: string | number;
      toDate: string | number;
      widgetId: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }): Promise<any> => {
      if (!widgetId) return;

      try {
        const tokenState = await getAuthenticationState();
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/widgets/${widgetId}/waitlists?from=${fromDate}&to=${toDate}`;
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${tokenState?.token ?? ''}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch waitlists. Status: ${response.status}`,
          );
        }

        return await response.json();
      } catch (error) {
        console.error('Error fetching waitlists:', error);
        throw error;
      }
    },
    [],
  );

  const getWaitlistsByWidget = useCallback(
    async ({
      fromDate,
      toDate,
      widgetId,
      polling = false,
    }: {
      fromDate: string | number;
      toDate: string | number;
      widgetId: string;
      polling?: boolean;
    }) => {
      if (!widgetId) {
        setError('Widget ID is required.');
        return;
      }

      if (!polling) setLoading(true);
      setError('');

      try {
        const data = await fetchWaitlistByWidget({
          fromDate,
          toDate,
          widgetId,
        });
        setWaitlists(data?.data ?? []);
      } catch (error) {
        console.error('Error fetching waitlists:', error);
        setError('Failed to fetch waitlists');
      } finally {
        setLoading(false);
      }
    },
    [fetchWaitlistByWidget],
  );

  const getWidgets = useCallback(
    async (orgId = '', locId = '') => {
      try {
        setLoading(true);
        setError('');

        const data = await fetchWidgets({ orgId, locId });
        setWidgets(data?.data ?? []);
      } catch (error) {
        console.error('Error fetching widgets:', error);
        setError('Failed to fetch Widgets');
      } finally {
        setLoading(false);
      }
    },
    [fetchWidgets],
  );

  const editBooking = useCallback(
    async ({
      customer,
      notes,
      priority,
      guestCount,
      waitlistId,
      lastUpdatedBy,
      createdBy,
      lastUpdatedBySource,
    }: EditBookingInput): Promise<Waitlist | undefined> => {
      try {
        const tokenState = await getAuthenticationState();
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/waitlists/${waitlistId}`;

        const body = JSON.stringify({
          customer: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone,
            email: customer.email,
          },
          notes,
          priority,
          guestCount,
          lastUpdatedBy,
          createdBy,
          lastUpdatedBySource,
        });

        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenState?.token ?? ''}`,
          },
          body,
        });

        if (!response.ok) {
          console.error(`Failed to update booking: ${response.statusText}`);
          return undefined;
        }

        const data = await response.json();
        const updatedBooking = data.data;

        setWaitlists(prev =>
          prev.map(booking =>
            booking.id === updatedBooking.id ? updatedBooking : booking,
          ),
        );

        return updatedBooking;
      } catch (error) {
        console.error('Error updating booking:', error);
        return undefined;
      }
    },
    [],
  );

  const createBooking = useCallback(
    async ({
      customer,
      notes,
      priority,
      guestCount,
      widgetId,
      lastUpdatedBySource,
      lastUpdatedBy,
      createdBy,
    }: CreateBookingInput): Promise<Waitlist | undefined> => {
      try {
        const tokenState = await getAuthenticationState();
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/widgets/${widgetId}/waitlists`;

        const body = JSON.stringify({
          customer: {
            firstName: customer.firstName,
            lastName: customer.lastName,
            phone: customer.phone,
            email: customer.email,
          },
          notes,
          priority,
          guestCount,
          lastUpdatedBy,
          createdBy,
          lastUpdatedBySource,
        });

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenState?.token ?? ''}`,
          },
          body,
        });

        if (!response.ok) {
          console.error(`Failed to create booking: ${response.statusText}`);
          return undefined;
        }

        const data = await response.json();
        const newBooking = data.data;

        setWaitlists(prev => [...prev, newBooking]);

        return newBooking;
      } catch (error) {
        console.error('Error creating booking:', error);
        return undefined;
      }
    },
    [],
  );

  const updateBookingStatus = useCallback(
    async ({
      bookingId,
      status,
      lastUpdatedBy,
      lastUpdatedBySource,
    }: {
      bookingId: string;
      status: WaitlistStatusEnum;
      lastUpdatedBy: string;
      lastUpdatedBySource: BookingSource;
    }): Promise<Waitlist | undefined> => {
      setStatusLoading(true);

      try {
        const tokenState = await getAuthenticationState();
        const url = `${process.env.REACT_APP_RESERVATIONS_API_URL}/waitlists/${bookingId}/status`;
        const body = JSON.stringify({
          status,
          lastUpdatedBy,
          lastUpdatedBySource,
        });

        const response = await fetch(url, {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenState?.token ?? ''}`,
          },
          body,
        });

        if (!response.ok) {
          console.error(
            `Failed to update booking status: ${response.statusText}`,
          );
          return undefined;
        }

        const data = await response.json();
        const updatedWaitlist = data.data;

        setWaitlists(prev =>
          prev.map(existingItem =>
            existingItem.id === updatedWaitlist.id
              ? updatedWaitlist
              : existingItem,
          ),
        );

        return updatedWaitlist;
      } catch (error) {
        console.error('Error updating booking status:', error);
        return undefined;
      } finally {
        setStatusLoading(false);
      }
    },
    [],
  );

  const generateOrderEventsForBooking = useCallback(
    async (booking: Waitlist, table: Table): Promise<Array<OrderEvent>> => {
      try {
        setCartParams(undefined, dineInOrderType?.id);
        await resetCart();

        updateCart(OrderAction.ORDER_ASSIGN_TABLE, {
          tableId: table.id,
          tableName: table.name,
          sectionId: table.section?.id,
          sectionName: table.section?.name,
          guestCount: booking.guestCount,
        });

        const { email, phone, firstName, lastName } = booking.customer;
        let customer = await getCustomerByEmailOrPhone(
          formatEmail(email),
          phone,
        );

        if (!customer && (email || phone)) {
          try {
            customer = await addNewCustomer({
              email: formatEmail(email),
              phone,
              phoneNumber: phone?.replace(/^[^\s]+\s/, ''),
              firstName,
              lastName,
            });
          } catch (error) {
            console.error('Failed to add new customer:', error);
          }
        }

        if (customer) {
          updateCart(OrderAction.ORDER_ASSIGN_CUSTOMER, {
            customerId: customer.id,
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            phone: customer.phone,
            isLoyaltyApplied: Boolean(customer.loyaltyMember),
          });
        }

        return orderStoreRef.current.getSnapshot()?.pendingEvents ?? [];
      } catch (error) {
        console.error('Error generating order events for booking:', error);
        return [];
      }
    },
    [
      updateCart,
      getCustomerByEmailOrPhone,
      setCartParams,
      dineInOrderType?.id,
      resetCart,
      addNewCustomer,
    ],
  );

  const createOrderForBooking = useCallback(
    async (booking: Waitlist, table: Table): Promise<void> => {
      if (!session.currentVenue?.id) {
        setError('Venue not available');
        return;
      }
      try {
        await generateOrderEventsForBooking(booking, table);
        updateCart(OrderAction.ORDER_SAVE);
        return;
      } catch (error) {
        console.error('Error creating order for booking:', error);
        setError('Failed to assign order');
        return;
      }
    },
    [session.currentVenue?.id, generateOrderEventsForBooking, updateCart],
  );

  return {
    widgets,
    waitlists,
    loading,
    statusLoading,
    error,
    updateBookingStatus,
    createBooking,
    editBooking,
    createOrderForBooking,
    getWidgets,
    getWaitlistsByWidget,
  };
};
