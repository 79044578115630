import React, { useCallback } from 'react';
import { View, Text, Linking, Platform } from 'react-native';
import { IntegrationApps } from '@oolio-group/domain';
import theme from '../../../../../../common/default-theme';
import styles from './Settings.styles';
import Icon from '../../../../../../components/Icon/Icon';
import ButtonIcon from '../../../../../../components/Shared/TreatButton/ButtonIcon';

interface StoreRowProps {
  isEnabled: boolean;
  isConnected: boolean;
  name: string;
  integrationApp: IntegrationApps;
  orgId: string;
}

export const StoreRow: React.FC<StoreRowProps> = ({
  isEnabled,
  isConnected,
  name,
  orgId,
}: StoreRowProps) => {
  const openWidgetsPortal = useCallback(() => {
    const widgetsUrl = `${process.env.REACT_APP_OOLIO_RESERVATIONS_URL}${orgId}/widgets`;

    if (Platform.OS === 'web') {
      window.open(widgetsUrl, '_blank');
    } else {
      Linking.openURL(widgetsUrl);
    }
  }, [orgId]);

  return (
    <View style={theme.tables.row}>
      {isEnabled && isConnected ? (
        <Icon name="check-circle" color={theme.colors.states.positive} />
      ) : (
        <Icon name="exclamation-triangle" color={theme.colors.grey4} />
      )}

      <Text style={styles.cellName}>{name}</Text>
      {
        <ButtonIcon
          type="neutralLight"
          icon="cog"
          onPress={openWidgetsPortal}
          containerStyle={styles.cellAccessory}
        />
      }
    </View>
  );
};
