import { useCallback } from 'react';
import { StyleSheet } from 'react-native';
import theme from '../../../common/default-theme';
import { useTheme } from '../../../hooks/useTheme';

interface Props {
  themed: boolean;
  secondary: boolean;
  customerId?: string;
}

const AssignCustomerStyles = ({
  themed = false,
  secondary = false,
  customerId,
}: Props) => {
  const { colors } = useTheme();

  const getTextColor = useCallback(() => {
    if (customerId && secondary) {
      return theme.colors.white;
    } else if (customerId) {
      if (themed) {
        return colors.text;
      } else {
        return colors.black1;
      }
    } else {
      if (themed) {
        return colors.disclosure;
      } else {
        return colors.grey6;
      }
    }
  }, [colors, customerId, secondary, themed]);

  return StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: theme.radius.s,
      backgroundColor: secondary
        ? theme.colors.tertiary2
        : themed
        ? colors.elevation1
        : colors.white,
    },
    text: {
      flex: 1,
      fontSize: 14,
      lineHeight: 20,
      fontFamily: theme.fonts.medium,
      color: getTextColor(),
    },
    icon: {
      width: 44,
      height: 44,
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconColor: {
      color: secondary
        ? theme.colors.white
        : themed
        ? colors.text
        : colors.black1,
    },
  });
};

export default AssignCustomerStyles;
