import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  DateFilter,
  IntegrationApps,
  Order,
  OrderStatus,
  PauseOrdersChannelInput,
  DelayOrdersChannelInput,
  RejectionReason,
  UpdateOnlineOrderStoreSettingsInput,
  RESERVATION_STATUS,
} from '@oolio-group/domain';
import { useTranslation } from '@oolio-group/localization';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useSession } from '../../../../hooks/app/useSession';
import { useNotification } from '../../../../hooks/Notification';
import { usePrinting } from '../../../../hooks/PrintingProvider';
import { useOrders } from '../../../../hooks/app/orders/useOrders';
import { useOnlineOrderQuery } from '../../../../hooks/app/orders/useOnlineOrderQuery';
import { useSalesChannels } from '../../../../hooks/app/salesChannels/useSalesChannels';
import { useOnlineOrderEvents } from '../../../../hooks/app/orders/useOnlineOrderEvents';
import { useIntegrationPartners } from '../../../../hooks/app/useIntegrationPartners/useIntegrationPartners';
import {
  alertAndPrintTriggerVar,
  pendingOnlineOrdersCountVar,
} from '../../../../state/cache';
import { AppScreen } from '../../../../types/AppScreen';
import {
  filterOnlineOrders,
  isValidOnlineOrder,
} from '../../../../utils/OnlineOrdersHelper';
import { groupBy, keyBy, sortBy } from 'lodash';
import { capitalCase } from 'change-case';
import { analyticsService } from '../../../../analytics/AnalyticsService';
import OnlineOrdersStyles from './OnlineOrders.styles';
import theme from '../../../../common/default-theme';
import ScreenLayout from '../../../../components/POS/ScreenLayout/ScreenLayout';
import OrdersSegmentTabs from '../OrdersSegmentTabs';
import CartSection from './SidePanel/CartSection';
import DetailsSection from './SidePanel/DetailsSection';
import OnlineSidePanel from './SidePanel/OnlineOrderSidePanel';
import OnlineOrdersHeader from './Table/OnlineOrdersHeader';
import OnlineOrdersFilters from './Filters/OnlineOrdersFilters';
import OnlineOrdersTable, {
  OnlineOrdersDataProps,
} from './Table/OnlineOrdersTable';
import { useCart } from '../../../../hooks/orders/useCart';
import Message from '../../../../components/Office/Message/Message';
import { addMinutes, format, subMinutes } from 'date-fns';
import { useNetworkStatus } from '../../../../hooks/app/useNetworkStatus';
import { useReservations } from '../../../../hooks/app/reservations/useReservations';
import {
  DEFAULT_RESERVATION_TIME_WINDOW_MINUTES,
  isReservationWithinTimeWindow,
  RESERVATION_GRACE_PERIOD_MIN,
  useTablesData,
} from '../../../../hooks/app/tables/useTablesData';
import UpcomingReservationModal from '../FloorView/Sections/Modals/UpcomingReservationModal';
import { useModal } from '@oolio-group/rn-use-modal';
import { addOptimisticReservation } from '../../Reservations/optimisticReservationsUtils';

type OnlineOrdersParamList = RouteProp<
  {
    OnlineOrders: { previousScreen: string };
  },
  'OnlineOrders'
>;

const OnlineOrders: React.FC = () => {
  const route = useRoute<OnlineOrdersParamList>();
  const { previousScreen } = route.params ?? {};

  const [session] = useSession();
  const navigation = useNavigation();
  const { translate } = useTranslation();
  const safeHeight = theme.useSafeHeight();
  const { showNotification } = useNotification();
  const { reprintKitchenDocket } = usePrinting();
  const { openOrderCart, setCartParams } = useCart();
  const { salesChannels, getSalesChannels } = useSalesChannels();
  const { getReservationOrderEvents, getWalkInReservationOrderEvents } =
    useReservations();
  const { upcomingReservationsMap } = useTablesData({
    shouldTrackReservations: true,
  });

  const {
    updateOnlineOrderStoreSettings,
    error: integrationError,
    getIntegrationPartnerSettings,
    integrationPartners,
    integrationPartnerMapsByApp,
  } = useIntegrationPartners();

  const { acceptOrders, completeOrder, rejectOrder } = useOnlineOrderEvents({
    integrationPartnerMaps: integrationPartnerMapsByApp,
  });

  const { showModal, closeModal } = useModal();
  const { isConnected } = useNetworkStatus();
  const {
    error: ordersDataError,
    getOrderFromCache,
    getOnlineOrders,
    returnOrdersFromCache,
    loading,
  } = useOrders();

  const styles = OnlineOrdersStyles();

  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [filters, setFilters] = useState<{
    status: string;
    dateFilter: string;
    salesChannel: string;
    searchValue: string;
  }>({
    dateFilter: DateFilter.TODAY,
    salesChannel: '',
    status: OrderStatus.IN_PROGRESS,
    searchValue: '',
  });

  const isOnlineOrdersEnabled = session?.deviceProfile?.enableOnlineOrders;

  const pendingOrders = useOnlineOrderQuery(OrderStatus.CREATED);
  const completedOrders = useOnlineOrderQuery(OrderStatus.COMPLETED);
  const cancelledOrders = useOnlineOrderQuery(OrderStatus.CANCELLED);
  const inProgressOrders = useOnlineOrderQuery(OrderStatus.IN_PROGRESS);
  const partnerCancelledOrders = useOnlineOrderQuery(
    OrderStatus.PARTNER_CANCELLED,
  );

  const orders = useMemo(() => {
    const allOrders = [
      ...pendingOrders,
      ...inProgressOrders,
      ...completedOrders,
      ...cancelledOrders,
      ...partnerCancelledOrders,
    ];
    return keyBy(allOrders, 'id');
  }, [
    cancelledOrders,
    completedOrders,
    inProgressOrders,
    pendingOrders,
    partnerCancelledOrders,
  ]);

  const resetSelectedOrder = useCallback(() => {
    setSelectedOrderId('');
  }, []);

  const navigateToPayment = useCallback(
    async (orderId: string) => {
      if (orderId) {
        await setCartParams(orderId, undefined, undefined, true);
        navigation.navigate('Payment', {
          orderId,
        });
        openOrderCart(orderId);
      }
    },
    [navigation, openOrderCart, setCartParams],
  );

  const onPressBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const navigateToOrder = useCallback(
    async orderId => {
      if (orderId) {
        await setCartParams(orderId, undefined, undefined, true);
        navigation.navigate('TakeOrder', {
          id: orderId,
          isCompleted: false,
          isExisting: true,
        });
        openOrderCart(orderId);
      }
    },
    [navigation, openOrderCart, setCartParams],
  );

  const onOrderView = useCallback(
    (orderId: string) => {
      navigateToOrder(orderId);
      resetSelectedOrder();
    },
    [navigateToOrder, resetSelectedOrder],
  );

  const error = integrationError || ordersDataError;
  const currentStoreId = session?.currentStore?.id;

  const ordersArray = useMemo(() => Object.values(orders), [orders]);

  useEffect(() => {
    getSalesChannels();
  }, [getSalesChannels]);

  useEffect(() => {
    if (error) {
      showNotification({
        error: true,
        message: error,
      });
    }
  }, [error, showNotification]);

  useEffect(() => {
    if (!currentStoreId) return;
    getIntegrationPartnerSettings({ store: currentStoreId });
  }, [currentStoreId, getIntegrationPartnerSettings]);

  const selectedOrderDetails = useMemo(() => {
    return orders?.[selectedOrderId];
  }, [orders, selectedOrderId]);

  useEffect(() => {
    const pendingOrders = ordersArray.filter(
      eachOrder =>
        eachOrder.status === OrderStatus.CREATED &&
        isValidOnlineOrder(eachOrder),
    );
    pendingOnlineOrdersCountVar(pendingOrders.length);
    if (pendingOrders.length > 0) {
      alertAndPrintTriggerVar({
        alert: true,
        app: pendingOrders[0]?.integrationInfo?.app,
        print: false,
      });
    } else {
      alertAndPrintTriggerVar({
        alert: false,
        print: false,
      });
    }
  }, [ordersArray]);

  const filteredOrders: OnlineOrdersDataProps[] = useMemo(() => {
    return filterOnlineOrders(ordersArray, {
      searchValue: filters.searchValue,
      status: filters.status,
      day: filters.dateFilter,
      salesChannel: filters.salesChannel,
    });
  }, [ordersArray, filters]);

  const onSelectOrder = useCallback(orderId => {
    setSelectedOrderId(orderId);
  }, []);

  const onPressAccept = useCallback(
    async (orderIds: string[], acceptAll = false) => {
      const processingOrders = orderIds
        .map(id => orders?.[id])
        .filter(order => typeof order === 'object') as Order[];

      if (processingOrders.length === 0) return;

      const processMultipleOrders = async (orders: Order[]) => {
        const ordersWithEvents = await Promise.all(
          orders.map(async order => {
            if (order.table) {
              const events = await getWalkInReservationOrderEvents(order);
              return { order, events };
            }
            return { order, events: [] };
          }),
        );
        return acceptOrders(ordersWithEvents);
      };

      const processSingleOrder = async (order: Order) => {
        if (!order.table) return acceptOrders([{ order, events: [] }]);

        const currentTime = new Date();
        const startTime = subMinutes(currentTime, RESERVATION_GRACE_PERIOD_MIN);
        const endTime = addMinutes(
          currentTime,
          DEFAULT_RESERVATION_TIME_WINDOW_MINUTES,
        );

        const upcomingReservation = upcomingReservationsMap[order.table.name];

        const isWithinReservationWindow =
          upcomingReservation &&
          isReservationWithinTimeWindow({
            reservationTime: upcomingReservation.real_datetime_of_slot,
            startTime,
            endTime,
          });

        if (isWithinReservationWindow) {
          showModal(
            <UpcomingReservationModal
              reservation={upcomingReservation}
              onSeatReservation={async () => {
                closeModal();
                const reservationData = await getReservationOrderEvents(
                  upcomingReservation,
                  order,
                );
                const events = reservationData.pendingEvents || [];

                addOptimisticReservation(upcomingReservation.reference_code, {
                  status: RESERVATION_STATUS.ARRIVED,
                  status_display: RESERVATION_STATUS.SEATED,
                });
                return acceptOrders([{ order, events }]);
              }}
              onClose={async () => {
                closeModal();
                const events = await getWalkInReservationOrderEvents(order);
                return acceptOrders([{ order, events }]);
              }}
            />,
          );
        } else {
          const events = await getWalkInReservationOrderEvents(order);
          return acceptOrders([{ order, events }]);
        }
      };

      if (acceptAll || processingOrders.length > 1) {
        return processMultipleOrders(processingOrders);
      } else {
        return processSingleOrder(processingOrders[0]);
      }
    },
    [
      acceptOrders,
      orders,
      getReservationOrderEvents,
      upcomingReservationsMap,
      showModal,
      closeModal,
      getWalkInReservationOrderEvents,
    ],
  );

  const onAcceptAllOrders = useCallback(() => {
    analyticsService.capture('oo_event', {
      event: 'Accept All',
    });
    const orderIds = ordersArray
      .filter(eachOrder => eachOrder.status === OrderStatus.CREATED)
      .map(order => order.id);
    onPressAccept(orderIds, true);
  }, [onPressAccept, ordersArray]);

  const onPressReject = useCallback(
    (orderId: string, reason: RejectionReason) => {
      const order = getOrderFromCache(orderId) || orders?.[orderId];
      if (order) {
        rejectOrder(order, reason);
      }
    },
    [getOrderFromCache, orders, rejectOrder],
  );

  const onPressComplete = useCallback(
    async (orderId: string) => {
      const order = getOrderFromCache(orderId) || orders?.[orderId];
      if (order) {
        completeOrder(order);
      }
    },
    [completeOrder, getOrderFromCache, orders],
  );

  const onPressReprintDocket = useCallback(
    (orderId: string) => {
      const order = getOrderFromCache(orderId) || orders?.[orderId];
      if (order) {
        reprintKitchenDocket && reprintKitchenDocket(order, order.orderItems);
      }
    },
    [orders, reprintKitchenDocket, getOrderFromCache],
  );

  const sidePanelTabOptions = useMemo(
    () => [
      {
        title: translate('onlineOrders.details'),
        component: <DetailsSection order={selectedOrderDetails as Order} />,
        value: 'details',
      },
      {
        title: translate('onlineOrders.cart'),
        component: <CartSection order={selectedOrderDetails as Order} />,
        value: 'cart',
      },
    ],
    [translate, selectedOrderDetails],
  );

  const onChangeOfFilter = useCallback((key: string, value: string) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  }, []);

  const salesChannelOptions = useMemo(() => {
    return Object.values(salesChannels).map(x => ({
      value: x.id,
      label: capitalCase(x.name),
    }));
  }, [salesChannels]);

  const onSetOnlineOrderSettings = useCallback(
    (
      key: keyof UpdateOnlineOrderStoreSettingsInput,
      value: number | PauseOrdersChannelInput[] | DelayOrdersChannelInput[],
    ) => {
      // allow to set busy mode with 0, with active again the store
      currentStoreId &&
        updateOnlineOrderStoreSettings({
          store: currentStoreId,
          [key]: value,
        });
    },
    [currentStoreId, updateOnlineOrderStoreSettings],
  );

  const refreshOnlineOrders = useCallback(() => {
    analyticsService.capture('oo_event', {
      event: 'Refresh',
    });
    const orderStatus = [
      OrderStatus.CREATED,
      OrderStatus.IN_PROGRESS,
      OrderStatus.COMPLETED,
    ];
    getOnlineOrders(orderStatus);
  }, [getOnlineOrders]);

  useEffect(() => {
    // if customer logout or clear cached, completed order need to be refresh
    const completedOnlineOrders = returnOrdersFromCache(
      OrderStatus.COMPLETED,
      true,
    );
    if (!completedOnlineOrders?.length) {
      getOnlineOrders([OrderStatus.COMPLETED]);
    }
  }, [getOnlineOrders, returnOrdersFromCache]);

  const oomConnectedChannels = useMemo(() => {
    const oomIntegration = Object.values(integrationPartners).find(
      integration =>
        integration.appName == IntegrationApps.OOM && integration.isActive,
    );
    return oomIntegration?.preferences?.onlineOrdering?.connectedChannels || [];
  }, [integrationPartners]);

  const pausedOrdersMessage = useMemo(() => {
    const pausedOrdersChannels = oomConnectedChannels.filter(channel => {
      const { pauseOrdersConfig } = channel;
      return (
        pauseOrdersConfig?.pausedOrders &&
        pauseOrdersConfig?.pausedUntil &&
        new Date(pauseOrdersConfig.pausedUntil) > new Date()
      );
    });
    if (pausedOrdersChannels.length == 0) return;
    const summaryMessage = pausedOrdersChannels.map(channel => {
      const hour = format(
        new Date(channel?.pauseOrdersConfig?.pausedUntil as string),
        'hh:mm a',
      );
      return (
        channel.name + ' ' + translate('oomChannels.untilTime', { time: hour })
      );
    });
    return summaryMessage.join(', ');
  }, [oomConnectedChannels, translate]);

  const delayingOrdersMessage = useMemo(() => {
    const delayingOrdersChannels = oomConnectedChannels.filter(channel => {
      const { delayConfig } = channel;
      return (
        delayConfig?.durationInMinutes &&
        delayConfig?.delayUntil &&
        new Date(delayConfig.delayUntil) > new Date()
      );
    });
    if (delayingOrdersChannels.length == 0) return;
    const groupByDelayTimes = groupBy(
      delayingOrdersChannels,
      item => item.delayConfig?.durationInMinutes,
    );

    const sortedDelayDurations = sortBy(Object.keys(groupByDelayTimes));
    const summaryMessage = sortedDelayDurations.map((durationTime, index) => {
      const affectedChannels = groupByDelayTimes[durationTime].map(
        channel => channel.name,
      );

      if (index == 0) {
        return `${durationTime} mins has been set for ${affectedChannels.join(
          ' and ',
        )}`;
      }
      return `${durationTime} mins for ${affectedChannels.join(', ')}`;
    });
    return `A delay of ${summaryMessage.join(', and ')}`;
  }, [oomConnectedChannels]);

  if (!isOnlineOrdersEnabled) {
    // if disabled show empty screen
    return <></>;
  }

  return (
    <ScreenLayout
      title="Online Orders"
      loading={loading}
      tabs={
        <OrdersSegmentTabs
          previousScreen={previousScreen}
          activeScreen={AppScreen.ONLINE_ORDER}
        />
      }
      onBack={onPressBack}
    >
      {!isConnected ? (
        <Message type="negative" message={translate('offline.onlineOrders')} />
      ) : null}
      <View style={[styles.container, { height: safeHeight }]}>
        <OnlineOrdersFilters
          salesChannelOptions={salesChannelOptions}
          onChangeOfFilter={onChangeOfFilter}
          filters={filters}
        />
        <View style={styles.tableContainer}>
          {pausedOrdersMessage && (
            <Message
              containerStyle={styles.message}
              type="negative"
              message={translate('oomChannels.pauseOrdersMessage', {
                channels: pausedOrdersMessage,
              })}
              icon="exclamation-triangle"
            />
          )}
          {delayingOrdersMessage && (
            <Message
              containerStyle={styles.message}
              type="negative"
              message={delayingOrdersMessage}
              icon="exclamation-triangle"
            />
          )}
          <OnlineOrdersHeader
            onSearchTextChange={value => onChangeOfFilter('searchValue', value)}
            onAcceptAllOrders={onAcceptAllOrders}
            setBusyTime={val => onSetOnlineOrderSettings('snoozeTime', val)}
            setPreparationTime={val =>
              onSetOnlineOrderSettings('prepTime', val)
            }
            onClickRefresh={refreshOnlineOrders}
            isLoading={loading}
            oomConnectedChannels={oomConnectedChannels}
            onSetPauseOrders={onSetOnlineOrderSettings.bind(
              null,
              'pauseOrdersChannels',
            )}
            onSetDelayOrders={onSetOnlineOrderSettings.bind(
              null,
              'delayOrdersChannels',
            )}
          />
          <OnlineOrdersTable
            data={filteredOrders}
            onSelectOrder={onSelectOrder}
            acceptOrders={onPressAccept}
            completeOrder={onPressComplete}
            filterStatus={filters.status as OrderStatus}
            onOrderPay={navigateToPayment}
          />
        </View>
      </View>
      <OnlineSidePanel
        showPanel={!!selectedOrderId}
        tabOptions={sidePanelTabOptions}
        loading={!selectedOrderDetails}
        order={selectedOrderDetails as Order}
        onOrderView={onOrderView}
        onClose={resetSelectedOrder}
        onPressAccept={onPressAccept}
        onPressReject={onPressReject}
        onPressComplete={onPressComplete}
        onPressReprintDocket={onPressReprintDocket}
      />
    </ScreenLayout>
  );
};

export default OnlineOrders;
