/* eslint-disable @typescript-eslint/no-explicit-any */
import PostHog from 'posthog-react-native';
import { IAnalyticsProvider } from './AnalyticsService';

class PostHogProvider implements IAnalyticsProvider {
  private client: PostHog | undefined;

  init(): void {
    this.client = new PostHog(process.env.REACT_APP_POSTHOG_API_KEY || '', {
      host: 'https://app.posthog.com',
    });
  }

  identify(userId: string, properties: Record<string, any>): void {
    this.client?.identify(userId, properties);
  }

  group(
    groupType: string,
    groupId: string,
    properties: Record<string, any>,
  ): void {
    this.client?.group(groupType, groupId, properties);
  }

  capture(event: string, properties?: Record<string, any>): void {
    this.client?.capture(event, properties || {});
  }

  async isFeatureEnabled(feature: string): Promise<boolean> {
    if (!this.client) {
      this.init();
    }

    try {
      return this.client?.isFeatureEnabled(feature) ?? false;
    } catch (error) {
      return false;
    }
  }

  async getAllFeatureFlags(): Promise<any> {
    if (!this.client) {
      this.init();
    }

    try {
      return await this.client?.reloadFeatureFlagsAsync();
    } catch (error) {
      return {};
    }
  }

  startSessionRecording(): void {
    return;
  }

  stopSessionRecording(): void {
    return;
  }

  reset(): void {
    this.client?.reset();
  }
}

export default PostHogProvider;
