import React, { useCallback, useRef, useState } from 'react';
import {
  View,
  Text,
  Linking,
  Platform,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import Popover, { PopoverPlacement } from 'react-native-popover-view';
import styles from './AppSwitcher.styles';
import theme from '../../../common/default-theme';
import Icon from '../../Icon/Icon';
import AppSwitcherIcon from './AppSwitcher.icon';
import { analyticsService } from '../../../analytics/AnalyticsService';

type AppSwitcherLink = {
  name: string;
  color: string;
  link: string;
};

const AppSwitcher: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);

  const touchable = useRef(null);

  const domain = process.env.REACT_APP_OOLIO_DOMAIN;

  const apps = [
    {
      name: 'Portal',
      color: theme.colors.deepPurple,
      link: `https://${domain}`,
    },
    {
      name: 'Pay',
      color: theme.colors.deepPurple,
      link: `https://pay.${domain}`,
    },
    {
      name: 'Insights',
      color: theme.colors.accent,
      link: `https://insights.${domain}`,
    },
    {
      name: 'Orders',
      color: theme.colors.red,
      link: `https://orders.${domain}`,
    },
    {
      name: 'Online Store',
      color: theme.colors.purple,
      link: `https://ordering-settings.${domain}`,
    },
    {
      name: 'Loyalty',
      color: '#7C4DFF',
      link: `https://loyalty.${domain}`,
    },
    {
      name: 'Reservations',
      color: theme.colors.yellowDark,
      link: `https://reservations.${domain}`,
    },
    {
      name: 'Tags',
      color: theme.colors.cyan,
      link: `https://${domain}/tags`,
    },
    {
      name: 'Checkout',
      color: theme.colors.deepPurple,
      link: `https://checkout-settings.${domain}`,
    },
  ];

  const onTogglePopup = useCallback((): void => {
    setShowPopup(current => !current);
  }, []);

  const onRequestClosePopover = useCallback(() => {
    setShowPopup(false);
  }, []);

  const onPressLink = useCallback((app: AppSwitcherLink) => {
    analyticsService.capture('app_switch', {
      app: app.name,
    });

    if (Platform.OS === 'web') {
      window.open(app.link, '_blank');
    } else {
      Linking.openURL(app.link);
    }
  }, []);

  return (
    <>
      <TouchableOpacity
        testID="btn-switchApp"
        ref={touchable}
        onPress={onTogglePopup}
        style={styles.container}
      >
        <AppSwitcherIcon />
      </TouchableOpacity>
      <Popover
        from={touchable}
        isVisible={showPopup}
        verticalOffset={6}
        arrowSize={{ width: 0, height: 0 }}
        placement={PopoverPlacement.BOTTOM}
        onRequestClose={onRequestClosePopover}
        backgroundStyle={styles.bgStyle}
      >
        <ScrollView style={styles.popup}>
          {apps.map(app => (
            <TouchableOpacity
              key={app.name}
              style={styles.link}
              onPress={() => onPressLink(app)}
            >
              <View style={[styles.color, { backgroundColor: app.color }]} />
              {/* eslint-disable-next-line react-native/no-inline-styles */}
              <View style={{ flex: 1 }}>
                <Text style={styles.linkText}>{app.name}</Text>
              </View>
              <Icon size={20} name="angle-right" color={theme.colors.grey4} />
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Popover>
    </>
  );
};

export default AppSwitcher;
