import React, { useCallback, useState } from 'react';
import { useTranslation } from '@oolio-group/localization';
import { Text, View } from 'react-native';
import { useModal } from '@oolio-group/rn-use-modal';
import { ButtonStyle } from '../Shared/TreatButton/Button.types';
import theme from '../../common/default-theme';
import styles from './ConfirmationDialog.styles';
import TreatButton from '../Shared/TreatButton/TreatButton';
import InputToggle from '../Shared/Inputs/InputToggle';

export interface ConfirmationModalProps {
  type?: 'positive' | 'neutral' | 'focus' | 'negative';
  title: string;
  message: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  secondaryAction?: {
    id: string;
    label: string;
    disabled?: boolean;
    onPress: () => void;
  };
  hideCancel?: boolean;
  preConfirm?: {
    label?: string;
  };
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  type,
  title,
  message,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  secondaryAction,
  hideCancel = false,
  preConfirm,
}) => {
  const { closeModal } = useModal();
  const { translate } = useTranslation();
  const [hasPreConfirmed, setHasPreConfirmed] = useState<boolean>(false);
  const onPressConfirm = useCallback(() => {
    onConfirm && onConfirm();
  }, [onConfirm]);

  const onPressCancel = useCallback(() => {
    onCancel && onCancel();
    closeModal();
  }, [closeModal, onCancel]);

  const getTextColor = (type?: string) => {
    switch (type) {
      case 'positive':
        return theme.colors.states.positive;
      case 'neutral':
        return theme.colors.states.neutral;
      case 'focus':
        return theme.colors.states.focus;
      default:
        return theme.colors.states.negative;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={[styles.title, { color: getTextColor(type) }]}>
          {title}
        </Text>
        <Text style={styles.message}>{message}</Text>
      </View>
      {preConfirm && (
        <InputToggle
          testID="toggle-acknowledgment"
          title={preConfirm?.label ?? 'I understand'}
          isToggled={hasPreConfirmed}
          onToggle={(): void => setHasPreConfirmed(!hasPreConfirmed)}
          containerStyle={styles.confirmationCheck}
        />
      )}
      <View style={styles.actions}>
        {!hideCancel && (
          <TreatButton
            testID="confirm-no"
            type="cancel"
            onPress={onPressCancel}
            label={cancelLabel || translate('button.dismiss')}
          />
        )}
        {secondaryAction && !secondaryAction.disabled && (
          <TreatButton
            type={`${type}Light` as ButtonStyle}
            testID={secondaryAction.id}
            label={secondaryAction.label}
            onPress={secondaryAction.onPress}
          />
        )}
        <TreatButton
          testID="confirm-yes"
          type={type || 'negative'}
          onPress={onPressConfirm}
          label={confirmLabel || translate('settings.confirm')}
          disabled={preConfirm && !hasPreConfirmed}
        />
      </View>
    </View>
  );
};

export default ConfirmationModal;
