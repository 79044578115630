import React, { useCallback, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Product } from '@oolio-group/domain';
import { translate, useCurrency } from '@oolio-group/localization';
import { useModal } from '@oolio-group/rn-use-modal';
import { addToValue } from '../../../../utils/keypadHelper';
import { MAX_KEYPAD_INPUT } from '../../../../types/Common';
import VariableQuantityModalStyles from './VariableQuantityModal.styles';
import Icon from '../../../Icon/Icon';
import ModalTitle from '../ModalTitle/ModalTitle';
import NumberPad from '../Keypad/NumberPad/NumberPad';
import TreatButton from '../../../Shared/TreatButton/TreatButton';

interface Props {
  item?: Product;
  quantity?: number;
  unitPrice?: number;
  onSubmit: (qty: number) => void;
}

export const VariableQuantityModal: React.FC<Props> = ({
  item,
  onSubmit,
  unitPrice = 0,
  quantity,
}) => {
  const { closeModal } = useModal();
  const { formatCurrency } = useCurrency();

  const [input, setInput] = useState('');

  const styles = VariableQuantityModalStyles();

  const numberKeys = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '0' },
    { value: '.' },
  ];

  const measuredBy = item?.measuredBy;
  const defaultSize = measuredBy?.defaultSize || 1;

  const onPressKey = useCallback((key: string): void => {
    setInput((preInput: string) => {
      const newValue = addToValue({
        value: preInput,
        key,
        decimalSeparator: '.',
        length: MAX_KEYPAD_INPUT,
        decimalPlaces: 3,
      });

      return newValue;
    });
  }, []);

  const handlePressDeleteInput = useCallback((): void => {
    setInput(prev => {
      return prev.slice(0, -1);
    });
  }, []);

  const updatedInput = !!input
    ? Number(input)
    : !!quantity
    ? quantity * defaultSize
    : Number(defaultSize);

  const handleApplyChange = useCallback(() => {
    onSubmit(updatedInput);

    closeModal();
  }, [closeModal, onSubmit, updatedInput]);

  const updatedQty = !!input
    ? Number(input) / defaultSize
    : !!quantity
    ? quantity
    : 1;

  const quantityPlaceHoler = '%';
  const productSize = parseFloat((updatedQty * defaultSize).toFixed(3));
  const sizeInfoText = `${productSize}${
    measuredBy?.units
  } NET @ ${formatCurrency(unitPrice || 0)}/${defaultSize}${measuredBy?.units}`;

  return (
    <View style={styles.container}>
      <ModalTitle
        title={translate('order.setQuantity')}
        onDismiss={closeModal}
      />
      <View style={styles.content}>
        {item && (
          <View style={styles.item}>
            <Text
              style={styles.itemText}
            >{`${quantityPlaceHoler} ${item?.name}`}</Text>
            <Text style={styles.itemText}>
              {formatCurrency(unitPrice * (updatedInput / defaultSize))}
            </Text>
          </View>
        )}
        <View style={styles.display}>
          <Text
            style={styles.title}
          >{`${updatedInput} ${item?.measuredBy.units}`}</Text>
          <Text style={styles.subtitle}>{sizeInfoText}</Text>
          <TouchableOpacity
            style={styles.btnClear}
            onPress={handlePressDeleteInput}
          >
            <Icon size={20} name="times-circle" />
          </TouchableOpacity>
        </View>
        <View>
          <NumberPad
            width={340}
            keypadButtons={numberKeys}
            zeroButtonWidth={222.66}
            onPressKey={onPressKey}
          />
        </View>
        <TreatButton
          testID="submit-button"
          height={50}
          type="positive"
          label={translate('button.applyChanges')}
          onPress={handleApplyChange}
        />
      </View>
    </View>
  );
};
