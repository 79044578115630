import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity, FlatList } from 'react-native';
import { DEFAULT_TABLE_ABBREVIATION } from '@oolio-group/domain';
import { useTheme } from '../../../../hooks/useTheme';
import CartSeatsStyles from './CartSeats.styles';
import Icon from '../../../Icon/Icon';

export interface CartSeatsProps {
  numberOfSeat: number;
  seats?: { id: string; title: string }[];
  selectedSeatNumber?: string;
  onSelectSeatNumber: (seatNumber?: string) => void;
}

const CartSeats: React.FC<CartSeatsProps> = ({
  numberOfSeat,
  selectedSeatNumber = DEFAULT_TABLE_ABBREVIATION,
  onSelectSeatNumber,
  seats: seatsProp,
}) => {
  const { colors } = useTheme();
  const styles = CartSeatsStyles();

  const seats = useMemo(() => {
    if (seatsProp) return seatsProp;
    const seatNumberArray = Array.from({ length: numberOfSeat }).map(
      (_item, index) => {
        return { id: String(index + 1), title: `S${String(index + 1)}` };
      },
    );

    return [
      { id: DEFAULT_TABLE_ABBREVIATION, title: DEFAULT_TABLE_ABBREVIATION },
      ...seatNumberArray,
      { id: 'add', title: '+' },
    ];
  }, [numberOfSeat, seatsProp]);

  const onPressSeat = useCallback(
    (id: string) => {
      const updateId = id === DEFAULT_TABLE_ABBREVIATION ? undefined : id;
      onSelectSeatNumber(updateId);
    },
    [onSelectSeatNumber],
  );

  const onPressAddSeat = useCallback(() => {
    onSelectSeatNumber && onSelectSeatNumber('+');
  }, [onSelectSeatNumber]);

  const renderItem = useCallback(
    ({ item, index }) => {
      const isSelected = selectedSeatNumber == item.id;
      if (item.id === 'add') {
        return (
          <TouchableOpacity
            testID="btn-addSeat"
            style={[styles.btn, styles.btnAdd]}
            onPress={onPressAddSeat}
          >
            <Icon name="plus" size={20} color={colors.green} />
          </TouchableOpacity>
        );
      }

      return (
        <TouchableOpacity
          testID="btn-seat"
          onPress={() => onPressSeat(item.id)}
          style={[
            isSelected ? styles.btnSelected : styles.btn,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginLeft: index % 5 === 0 ? 0 : 4,
            },
          ]}
        >
          <Text style={isSelected ? styles.btnTextSelected : styles.btnText}>
            {item.title}
          </Text>
        </TouchableOpacity>
      );
    },
    [styles, colors, selectedSeatNumber, onPressAddSeat, onPressSeat],
  );

  if (!seats.length) return null;

  return (
    <View style={styles.container}>
      <FlatList
        data={seats}
        numColumns={5}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        extraData={selectedSeatNumber}
      />
    </View>
  );
};

export default CartSeats;
