import React from 'react';
import RNModal from 'react-native-modal';
import { View } from 'react-native';
import { ActiveModalStateProp } from './RequiredOptionsModal';
import { isWeb } from '../../../../common/theme';
import AddNoteModal from '../AddNote/AddNoteModal';
import { useTranslation } from '@oolio-group/localization';
import ConfirmationModal from '../../../Modals/ConfirmationDialog';

interface Props {
  activeModalState: ActiveModalStateProp;
  onCloseModal: () => void;
  onSubmitModal: (updatedNotes?: string) => void;
}

export const NotesAndAvailabilityModal = ({
  activeModalState,
  onCloseModal,
  onSubmitModal,
}: Props) => {
  const { translate } = useTranslation();

  return (
    <RNModal
      isVisible={activeModalState.isVisible}
      onDismiss={onCloseModal}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ top: isWeb ? 100 : 0, alignSelf: 'center' }}
    >
      {activeModalState.isVisible && activeModalState.type === 'note' && (
        <AddNoteModal
          value={activeModalState.value}
          onClose={onCloseModal}
          onSubmit={onSubmitModal}
        />
      )}
      {activeModalState.isVisible &&
        activeModalState.type === 'availability' && (
          // eslint-disable-next-line react-native/no-inline-styles
          <View style={{ paddingBottom: isWeb ? 300 : 0 }}>
            <ConfirmationModal
              title={translate(
                'takeOrderScreen.itemAvailability.warningModalTitle',
              )}
              message={translate(
                'takeOrderScreen.itemAvailability.warningModalDescription',
                {
                  itemName: activeModalState.value,
                },
              )}
              onCancel={onCloseModal}
              onConfirm={onSubmitModal}
              confirmLabel={translate(
                'takeOrderScreen.itemAvailability.warningModalAddButton',
              )}
            />
          </View>
        )}
    </RNModal>
  );
};
