import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  View,
  Text,
  ViewStyle,
  ScrollView,
  TouchableOpacity,
  findNodeHandle,
} from 'react-native';
import {
  Course,
  DEFAULT_ENTITY_ID,
  OrderItem,
  OrderItemStatus,
  OrderType,
  OrderTypeCode,
  RewardAdjustment,
  AdjustmentType,
  DEFAULT_TABLE_ABBREVIATION,
  OrderPaymentStatus,
  OTHER_COURSE,
} from '@oolio-group/domain';
import {
  getAdjustmentValue,
  getItemRewardValue,
} from '@oolio-group/order-helper';
import { useTranslation } from '@oolio-group/localization';
import { useSession } from '../../../../hooks/app/useSession';
import { CartRewardSelectionState, CartSelectionState } from '../Cart';
import { useFocusEffect, useRoute } from '@react-navigation/native';
import { keyBy, sortBy, differenceWith, groupBy } from 'lodash';
import CartStyles from '../Cart.styles';
import CartNote from '../CartNote/CartNote';
import CartName from '../CartName/CartName';
import CartSeats from '../CartSeats/CartSeats';
import CartCourse from '../CartCourse/CartCourse';
import CartReward from '../CartReward/CartReward';
import CartProduct from '../CartProduct/CartProduct';
import CartTotalItems, { CartOrderTotalItems } from './CartTotalItems';
import Icon from '../../../Icon/Icon';
import { useTheme } from '../../../../hooks/useTheme';
import Collapsible from 'react-native-collapsible';
import useBehaviorSubjectState from '../../../../hooks/app/useSubjectState';
import { transferItemObservable } from '../../../../hooks/app/orders/ordersObservableUtils';
import {
  analyticsService,
  POSTHOG_EVENT_ACTIONS,
} from '../../../../analytics/AnalyticsService';
import CartTraining from '../CartTraining/CartTraining';
import CartNew from '../CartNew/CartNew';
import CartSentItems from './CartSentItems';

type CartItem = OrderItem | RewardAdjustment;

export interface CartItemProps {
  orderGuestCount?: number;
  orderNote?: string;
  orderIdentifier?: string;
  deliveryNote?: string;
  items?: OrderItem[];
  rewardItems?: RewardAdjustment[];
  courses?: Course[];
  containerStyle?: ViewStyle;
  selectedItem?: CartSelectionState;
  isRefundOrder?: boolean;
  selectedItemIds?: string[];
  isCoursesEnabled?: boolean;
  readonlyCourse?: boolean;
  orderType?: OrderType;
  subTotal?: number;
  selectedReward?: CartRewardSelectionState;
  seats?: { id: string; title: string }[];
  selectedSeatNumber?: string;
  extraItems?: OrderItem[];
  orderTotals?: CartOrderTotalItems;
  isTraining?: boolean;
  onToggleAutoFire?: (courseId: string) => void;
  onSelectItem?: (state: CartSelectionState) => void;
  onDeselectItem?: () => void;
  onIncrementSeatNumber?: () => void;
  setSelectedSeatNumber?: (seatNumber?: string) => void;
  onSwitchCourseItem?: (
    orderItemId: string,
    courseId: string,
    courseName?: string,
  ) => void;
  onSelectReward?: (state: CartRewardSelectionState) => void;
  enableCollapseSentItems?: boolean;
  themed?: boolean;
}

const SCROLL_STEP = 200;

const groupTransfers = (orderItems: OrderItem[]) => {
  return Object.values(
    groupBy(
      orderItems,
      item =>
        `${item?.transferInfo?.toTableId}#${item?.transferInfo?.transferredBy?.id}`,
    ),
  ) as OrderItem[][];
};

const CartItems: React.FC<CartItemProps> = ({
  items: itemsProp = [],
  rewardItems,
  orderGuestCount = 0,
  orderNote,
  orderIdentifier,
  containerStyle,
  selectedItem,
  courses = [],
  selectedItemIds,
  selectedSeatNumber: selectedSeatNumberProp,
  isCoursesEnabled,
  readonlyCourse,
  orderType,
  deliveryNote,
  subTotal,
  selectedReward,
  seats,
  extraItems: shareItems = [],
  orderTotals,
  isTraining,
  onSelectItem,
  onDeselectItem,
  onToggleAutoFire,
  onIncrementSeatNumber,
  setSelectedSeatNumber: onSeatNumberSelect,
  onSwitchCourseItem,
  onSelectReward,
  themed = false,
  enableCollapseSentItems,
}) => {
  const previousOrderItems = useRef<OrderItem[]>([]);
  const previousRewardItems = useRef<RewardAdjustment[]>([]);

  const scrollViewRef = useRef<ScrollView>(null);
  const { setValue: setTransferItem } = useBehaviorSubjectState<string | null>(
    transferItemObservable,
  );
  const route = useRoute();
  const [session] = useSession();
  const { translate } = useTranslation();

  const { colors } = useTheme();
  const styles = CartStyles({ themed });

  const scrollY = useRef(0);

  const [collapseCourses, setCollapseCourses] = useState<
    Record<string, boolean>
  >({});
  const itemRefs = useRef<Map<string, TouchableOpacity>>(new Map());
  const [isInitialSuccess, setIsInitialSuccess] = useState(false);
  const [expandedSentItemsSection, setExpandedSentItemsSection] =
    useState(false);

  const [expandedSections, setExpandedSections] = useState<Array<number>>([]);
  const [selectedSeatNumber, setSelectedSeatNumber] = useState<
    string | undefined
  >(undefined);

  const allTablesMap = keyBy(
    session.deviceProfile?.sections?.flatMap(section => section?.tables) ?? [],
    'id',
  );

  const isSeatManagementEnabled =
    session?.deviceProfile?.enableSeatManagement &&
    orderType?.code === OrderTypeCode.DINE_IN;

  const hideZeroPrices = session?.deviceProfile?.enableHideZeroPrices;
  const isComplimentaryMode = session?.deviceProfile?.enableComplimentaryMode;

  const showSeats = isSeatManagementEnabled && orderGuestCount > 0;

  const itemsBySeats = useMemo(() => {
    const selectedSeatItems = selectedSeatNumber
      ? itemsProp.filter(
          orderItem => String(orderItem.seatNumber) === selectedSeatNumber,
        )
      : itemsProp;
    return selectedSeatItems.concat(shareItems);
  }, [itemsProp, selectedSeatNumber, shareItems]);

  const orderItems = useMemo(
    () =>
      itemsBySeats.filter(
        item =>
          item.status !== OrderItemStatus.CANCELLED &&
          item.status !== OrderItemStatus.TRANSFERRED,
      ),
    [itemsBySeats],
  );

  const transferredItems = itemsBySeats.filter(
    item => item.status === OrderItemStatus.TRANSFERRED,
  );

  const transfers = groupTransfers(transferredItems);

  const showScrollButtons =
    orderItems.length >= 1 &&
    (session?.settings?.showCartScrollSetting || false);

  const { id: orderId } = (route.params || {}) as {
    id: string;
  };

  const onScrollTop = () => {
    scrollViewRef.current?.scrollTo({
      y: Math.max(0, scrollY.current - SCROLL_STEP),
      animated: true,
    });
  };

  const onScrollBottom = () => {
    scrollViewRef.current?.scrollTo({
      y: scrollY.current + SCROLL_STEP,
      animated: true,
    });
  };

  const handleCollapseCourse = useCallback(
    (id: string, status: boolean) => {
      if (status === collapseCourses[id]) return;
      setCollapseCourses(preState => ({
        ...preState,
        [id]: status,
      }));
    },
    [collapseCourses],
  );

  const handleCoursePressHeader = useCallback(
    (courseId: string) => {
      const allowSwitchOrderItemStatus = [
        OrderItemStatus.CREATED,
        OrderItemStatus.ON_HOLD,
      ];
      const selectOrderItem = orderItems.find(
        item => item.id === selectedItem?.item,
      ) as OrderItem;

      onDeselectItem?.();
      if (
        !selectOrderItem ||
        !allowSwitchOrderItemStatus.includes(selectOrderItem.status)
      )
        return;

      const defaultCourseId = selectOrderItem.course?.id || DEFAULT_ENTITY_ID;
      if (courseId === defaultCourseId) return;

      onSwitchCourseItem && onSwitchCourseItem(selectOrderItem?.id, courseId);
    },
    [onDeselectItem, onSwitchCourseItem, orderItems, selectedItem?.item],
  );

  const onScrollToItem = useCallback(
    (itemId: string) => {
      const itemRef = itemRefs.current.get(itemId);
      if (!itemRef || !scrollViewRef.current) {
        return;
      }

      try {
        itemRef.measureLayout(
          findNodeHandle(scrollViewRef.current) as number,
          async (_, y) => {
            scrollViewRef.current?.scrollTo({
              y: Math.max(0, y + scrollY.current - 100),
              animated: true,
            });

            // Apply highlight
            itemRef.setNativeProps({
              style: {
                borderColor: colors.deepPurple,
                borderRadius: 4,
                borderWidth: 1,
              },
            });

            // Remove highlight after delay
            setTimeout(() => {
              try {
                const currentRef = itemRefs.current.get(itemId);
                if (currentRef) {
                  try {
                    currentRef.setNativeProps({
                      style: {
                        borderColor: 'transparent',
                        borderWidth: 0,
                      },
                    });
                  } catch (e) {
                    console.warn('Error removing highlight:', e);
                  }
                }
              } catch (e) {
                console.warn('Error removing highlight:', e);
              }
            }, 1500);
          },
        );
      } catch (error) {
        console.warn('Error scrolling to item:', error);
      }
    },
    [colors],
  );

  const recentUpsertOrderItems = useMemo(() => {
    return differenceWith(
      orderItems,
      previousOrderItems.current,
      (preItem, nextItem) => {
        return (
          preItem.id === nextItem.id &&
          preItem?.product?.id === nextItem.product.id &&
          preItem.modifiers?.length === nextItem.modifiers?.length &&
          preItem.quantity === nextItem.quantity
        );
      },
    );
  }, [orderItems]);

  const recentUpsertRewardItems = useMemo(() => {
    return differenceWith(
      rewardItems || [],
      previousRewardItems.current,
      (preItem, nextItem) => {
        return (
          preItem.id === nextItem.id && preItem?.quantity === nextItem.quantity
        );
      },
    );
  }, [rewardItems]);

  useEffect(() => {
    if (isInitialSuccess || orderItems.length == 0) return;
    previousOrderItems.current = orderItems;
    previousRewardItems.current = rewardItems || [];
    setIsInitialSuccess(true);
  }, [isInitialSuccess, orderItems, rewardItems]);

  useEffect(() => {
    if (!orderId) return;
    setCollapseCourses({});
  }, [orderId]);

  useEffect(() => {
    setSelectedSeatNumber(selectedSeatNumberProp);
  }, [selectedSeatNumberProp]);

  useEffect(() => {
    if (!isInitialSuccess) return;
    if (recentUpsertOrderItems.length) {
      const focusItem = recentUpsertOrderItems[0];
      onScrollToItem(focusItem.id);
      if (isCoursesEnabled) {
        const courseIndex = Math.max(
          courses.findIndex(course => focusItem?.course?.id === course.id),
          0,
        );
        courses[courseIndex]?.id &&
          handleCollapseCourse(courses[courseIndex]?.id, false);
      }
      previousOrderItems.current = orderItems;
    }

    if (recentUpsertRewardItems.length) {
      const focusItem = recentUpsertRewardItems[0];
      onScrollToItem(focusItem.id);
      previousRewardItems.current = rewardItems || [];
    }
  }, [
    isCoursesEnabled,
    courses,
    handleCollapseCourse,
    onScrollToItem,
    isInitialSuccess,
    orderItems,
    recentUpsertOrderItems,
    recentUpsertRewardItems,
    rewardItems,
  ]);

  useFocusEffect(
    useCallback(() => {
      onScrollTop();
      setExpandedSentItemsSection(false);
      setIsInitialSuccess(false);
    }, []),
  );

  const onLongPressOrderItem = useCallback(
    (id: string) => {
      analyticsService.capture(POSTHOG_EVENT_ACTIONS.CART_ORDER_ITEM, {
        action: 'long_press',
      });
      setTransferItem(id);
    },
    [setTransferItem],
  );

  const registerItemRef = useCallback(
    (id: string, ref: TouchableOpacity | null) => {
      if (ref) {
        itemRefs.current.set(id, ref);
      } else {
        itemRefs.current.delete(id);
      }
    },
    [],
  );

  const renderItem = useCallback(
    (item: OrderItem | RewardAdjustment, index: number) => {
      if (isRewardItem(item)) {
        // renders for RewardItem
        return (
          <CartReward
            id={item.id}
            key={item.id}
            name={item.name}
            quantity={item.quantity}
            amount={getAdjustmentValue(subTotal || 0, [item])}
            testID={`cart-item-${index}`}
            onSelectReward={onSelectReward}
            isSelected={selectedReward?.reward === item.id}
            onRegisterRef={registerItemRef}
          />
        );
      }

      // renders for OrderItem
      let isSelected = selectedItem?.item === item.id;

      if (!isSelected) {
        const isItemSelected = selectedItemIds?.find(
          itemId => itemId === item.id,
        );
        isSelected = isItemSelected ? true : false;
      }

      const selectedSubItem =
        selectedItem?.selectedModifierKey ||
        selectedItem?.selectedVariantKey ||
        selectedItem?.selectedItemKeyForAdjustment ||
        selectedItem?.combo?.selectedComboKey;

      const SeatNumberLabel = showSeats
        ? `@${String(item?.seatNumber || DEFAULT_TABLE_ABBREVIATION)}`
        : undefined;

      const reward = item.adjustments?.find(
        adj => adj.adjustmentType === AdjustmentType.REWARD,
      ) as RewardAdjustment;

      const isPaid = item.paymentStatus === OrderPaymentStatus.COMPLETE;

      const onSelect = (state: CartSelectionState) => {
        if (isPaid) return;
        onSelectItem && onSelectItem(state);
      };

      return (
        <View key={item.id}>
          <CartProduct
            testID={`cart-item-${index}`}
            themed={themed}
            orderItem={item}
            isSelected={isSelected}
            isPaid={isPaid}
            seat={SeatNumberLabel}
            onSelect={onSelect}
            selectedSubItem={selectedSubItem}
            onLongPress={onLongPressOrderItem}
            hideZeroPrices={hideZeroPrices}
            onRegisterRef={registerItemRef}
          />
          {reward ? (
            <CartReward
              id={reward.id}
              key={reward.id}
              name={reward.name}
              quantity={reward.itemQuantity || reward.quantity}
              amount={getItemRewardValue(item, [reward])}
              testID={`cart-item-${index}`}
              onSelectReward={onSelectReward}
              isSelected={
                selectedReward?.reward === reward.id &&
                selectedReward?.productId === item.product.id
              }
              productId={item.product.id}
            />
          ) : null}
        </View>
      );
    },
    [
      themed,
      subTotal,
      onSelectReward,
      selectedReward?.reward,
      selectedReward?.productId,
      selectedItem?.item,
      selectedItem?.selectedModifierKey,
      selectedItem?.selectedVariantKey,
      selectedItem?.selectedItemKeyForAdjustment,
      selectedItem?.combo?.selectedComboKey,
      showSeats,
      onLongPressOrderItem,
      hideZeroPrices,
      selectedItemIds,
      onSelectItem,
      registerItemRef,
    ],
  );

  const renderCourseData = useCallback(
    (
      course: Course,
      targetItems: OrderItem[],
      controlledCollapse?: boolean,
    ) => {
      const itemsOfCourse = targetItems.filter(item => {
        if (course.id === OTHER_COURSE && item.comboItems?.length) return true;
        if (course.id === DEFAULT_ENTITY_ID && !item.comboItems?.length)
          return !item.course?.id;
        return item.course?.id === course.id && !item.comboItems?.length;
      });

      if (!itemsOfCourse.length) return null;

      const firedItemsCount = itemsOfCourse.reduce((total, item) => {
        if (item.itemFired && item.status !== OrderItemStatus.VOID)
          total += item.quantity;
        return total;
      }, 0);

      const totalItemsCount = itemsOfCourse.reduce((total, item) => {
        if (item.status !== OrderItemStatus.VOID) total += item.quantity;
        return total;
      }, 0);

      const name =
        !readonlyCourse && firedItemsCount > 0
          ? `${course.name} (${firedItemsCount}/${totalItemsCount})`
          : course.name;

      const newItems = itemsOfCourse?.filter(item => !item.saved);
      const savedUnfiredItems = itemsOfCourse?.filter(
        item =>
          item.saved &&
          !item.itemFired &&
          item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      );
      const firedItems = itemsOfCourse?.filter(
        item =>
          item.saved &&
          item.itemFired &&
          item.paymentStatus !== OrderPaymentStatus.COMPLETE,
      );
      const paidItems = itemsOfCourse?.filter(
        item => item.paymentStatus === OrderPaymentStatus.COMPLETE,
      );

      const items = [...firedItems, ...savedUnfiredItems, ...paidItems];

      const showNewSeparator = Boolean(
        savedUnfiredItems.length && newItems.length,
      );
      return (
        <CartCourse
          name={name}
          id={course.id}
          themed={themed}
          autoFire={course.autoFire}
          onToggleAutoFire={onToggleAutoFire}
          readonlyCourse={readonlyCourse}
          disabled={firedItemsCount === totalItemsCount}
          isCollapsed={collapseCourses[course.id]}
          onCollapseCourse={handleCollapseCourse}
          onPressCourseHeader={handleCoursePressHeader}
          controlledCollapse={controlledCollapse}
        >
          {items.map(renderItem)}
          {showNewSeparator && <CartNew />}
          {newItems.map(renderItem)}
        </CartCourse>
      );
    },
    [
      themed,
      readonlyCourse,
      onToggleAutoFire,
      collapseCourses,
      handleCollapseCourse,
      handleCoursePressHeader,
      renderItem,
    ],
  );

  const onPressSeatNumber = (seatNumber?: string) => {
    if (seatNumber == '+') {
      onIncrementSeatNumber && onIncrementSeatNumber();
      return;
    }
    onSeatNumberSelect && onSeatNumberSelect(seatNumber);
    setSelectedSeatNumber(seatNumber);
  };

  const isRewardItem = (item: CartItem): item is RewardAdjustment => {
    return (
      'adjustmentType' in item && item.adjustmentType === AdjustmentType.REWARD
    );
  };

  const cartItems: Array<CartItem> = useMemo(() => {
    if (isCoursesEnabled) {
      return orderItems;
    }
    if (rewardItems && rewardItems.length > 0) {
      return [...orderItems, ...rewardItems].sort(
        (itemA, itemB) => (itemA.createdAt || 0) - (itemB.createdAt || 0),
      );
    }
    return sortBy(orderItems, item => item.saved !== true);
  }, [isCoursesEnabled, orderItems, rewardItems]);

  const onPressSection = useCallback((section: number) => {
    setExpandedSections(prev =>
      prev.includes(section)
        ? prev.filter(x => x !== section)
        : [...prev, section],
    );
  }, []);

  const renderCourses = useMemo(() => {
    const firedItems = orderItems.filter(item => item.itemFired);
    const unFiredItems = !enableCollapseSentItems
      ? orderItems
      : orderItems.filter(item => !(item as OrderItem).itemFired);

    return (
      <View>
        <CartSentItems
          themed={themed}
          sentItems={firedItems}
          isExpanded={expandedSentItemsSection}
          onExpand={(value: boolean) => setExpandedSentItemsSection(value)}
          show={enableCollapseSentItems}
          subTotal={subTotal || 0}
        >
          {courses.map(course => renderCourseData(course, firedItems, false))}
        </CartSentItems>
        {unFiredItems.length > 0
          ? courses.map(course => renderCourseData(course, unFiredItems, true))
          : null}
      </View>
    );
  }, [
    orderItems,
    enableCollapseSentItems,
    expandedSentItemsSection,
    subTotal,
    courses,
    themed,
    renderCourseData,
  ]);

  const renderNormalItems = useMemo(() => {
    const firedItems = [] as CartItem[];
    const savedUnfiredItems = [] as CartItem[];
    const newItems = [] as CartItem[];

    cartItems.forEach(item => {
      if (!item.saved) {
        newItems.push(item);
        return;
      }
      if (isRewardItem(item)) {
        savedUnfiredItems.push(item);
        return;
      }
      if (item.itemFired) {
        firedItems.push(item);
      } else {
        savedUnfiredItems.push(item);
      }
    });

    const showNewSeparator = Boolean(
      (firedItems.length || savedUnfiredItems.length) && newItems.length,
    );

    const items = enableCollapseSentItems
      ? savedUnfiredItems
      : [...firedItems, ...savedUnfiredItems];

    return (
      <View>
        <CartSentItems
          themed={themed}
          isExpanded={expandedSentItemsSection}
          onExpand={(value: boolean) => setExpandedSentItemsSection(value)}
          show={enableCollapseSentItems}
          sentItems={firedItems}
          subTotal={subTotal || 0}
        >
          {firedItems.map(renderItem)}
        </CartSentItems>
        {items.map(renderItem)}
        {showNewSeparator && <CartNew />}
        {newItems.map(renderItem)}
      </View>
    );
  }, [
    cartItems,
    enableCollapseSentItems,
    expandedSentItemsSection,
    subTotal,
    themed,
    renderItem,
  ]);

  return (
    <View
      testID="cart-items-container"
      style={[styles.itemsContainer, containerStyle]}
    >
      {isTraining ? <CartTraining /> : null}
      {orderIdentifier ? (
        <CartName testID="order-name" name={orderIdentifier} />
      ) : null}
      {showSeats ? (
        <CartSeats
          numberOfSeat={orderGuestCount || 0}
          onSelectSeatNumber={onPressSeatNumber}
          selectedSeatNumber={selectedSeatNumber}
          seats={seats}
        />
      ) : null}
      {orderNote ? (
        <CartNote themed={themed} testID="note-order" note={orderNote} />
      ) : null}
      {deliveryNote ? (
        <CartNote
          themed={themed}
          testID="note-delivery"
          note={translate('order.deliveryNote', { value: deliveryNote })}
        />
      ) : null}
      {orderItems.length >= 1 || transferredItems.length >= 1 ? (
        <ScrollView
          ref={scrollViewRef}
          testID="scrollView-items"
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          onScroll={event => {
            scrollY.current = event.nativeEvent.contentOffset.y;
          }}
          contentContainerStyle={styles.grow}
        >
          <View style={styles.grow}>
            <View
              testID={isCoursesEnabled ? 'order-courseItems' : 'order-items'}
              style={styles.items}
            >
              {isCoursesEnabled ? renderCourses : renderNormalItems}

              {transfers.length > 0 ? (
                <>
                  {transfers.map((transfer, index) => {
                    const transferredBy = `by ${
                      transfer?.[0]?.transferInfo?.transferredBy?.name ?? ''
                    }`;
                    const table =
                      allTablesMap[
                        transfer?.[0]?.transferInfo?.toTableId ?? ''
                      ] ?? {};
                    const transferredToTable = table ? `(${table.name})` : '';
                    const isCollapsed = !expandedSections.includes(index);

                    return (
                      <>
                        <TouchableOpacity
                          onPress={() => onPressSection(index)}
                          testID="expand-collapse-btn"
                          style={styles.transferredItemsHeader}
                        >
                          <Text style={styles.transferredItemsText}>
                            {translate('order.transferred')}{' '}
                            {transferredToTable}
                          </Text>
                          <View style={styles.collapseBtn}>
                            <Icon
                              size={20}
                              color={colors.pink}
                              name={isCollapsed ? 'angle-down' : 'angle-up'}
                            />
                          </View>
                        </TouchableOpacity>
                        <Collapsible collapsed={isCollapsed}>
                          {!!transferredBy && (
                            <CartNote
                              themed={themed}
                              testID="note-order"
                              note={transferredBy}
                            />
                          )}
                          {transfer.map(renderItem)}
                        </Collapsible>
                      </>
                    );
                  })}
                </>
              ) : null}
            </View>
            {orderTotals && !isComplimentaryMode ? (
              <CartTotalItems {...orderTotals} />
            ) : undefined}
          </View>
        </ScrollView>
      ) : (
        <View style={styles.emptyCart}>
          <Text style={styles.emptyCartText}>
            {translate('cart.emptyCart', {
              context: selectedSeatNumber
                ? translate('payment.seat').toLowerCase()
                : translate('onlineOrders.cart').toLowerCase(),
            })}
          </Text>
        </View>
      )}
      {showScrollButtons ? (
        <View style={styles.scrollButtons}>
          <TouchableOpacity style={styles.scrollButton} onPress={onScrollTop}>
            <Icon name="angle-up" color={colors.black1} />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.scrollButton}
            onPress={onScrollBottom}
          >
            <Icon name="angle-down" color={colors.black1} />
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(CartItems);
