import React, { useCallback, useEffect } from 'react';
import { Session } from '../state/Session';
import { useSession } from '../hooks/app/useSession';
import { analyticsService } from './AnalyticsService';

interface AnalyticsInitializerProps {
  children: React.ReactNode;
}

const AnalyticsInitializer: React.FC<AnalyticsInitializerProps> = ({
  children,
}: AnalyticsInitializerProps) => {
  const [session] = useSession();

  analyticsService.init();

  const setOrganizationToAnalytics = useCallback(
    (session: Partial<Session>) => {
      if (!session?.user?.email || !session?.currentOrganization?.id) {
        return;
      }

      // Identify Users
      analyticsService.identify(session?.user?.email as string, {
        email: session?.user?.email || '',
        name: session?.user?.name || '',
        org: session?.currentOrganization?.name || '',
      });

      // Identify Organisation
      const features = session?.currentOrganization?.features;
      const xformFeatures = features?.map(feature => {
        return {
          name: feature.name,
          enabled: feature.enabled,
        };
      });

      analyticsService.group(
        'organisation',
        session?.currentOrganization?.id as string,
        {
          id: session?.currentOrganization?.id,
          name: session?.currentOrganization?.name,
          features: xformFeatures,
        },
      );
    },
    [],
  );

  useEffect(() => {
    if (session) {
      setOrganizationToAnalytics(session);
    }
  }, [session, setOrganizationToAnalytics]);

  return <>{children}</>;
};

export default AnalyticsInitializer;
