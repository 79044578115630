import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  currentReservations,
  optimisticReservationsMap,
} from '../../../state/cache';
import { useSession } from '../../../hooks/app/useSession';
import { useApolloClient } from '@apollo/client/react/hooks';
import { GET_INTEGRATION_PARTNERS } from '../../../hooks/app/useIntegrationPartners/graphql';
import { IntegrationApps } from '@oolio-group/domain';
import { useReservations } from '../../../hooks/app/reservations/useReservations';
import { format, addDays, set, isBefore } from 'date-fns';
import { clearStaleOptimisticReservations } from './optimisticReservationsUtils';
import {
  RESERVATION_GRACE_PERIOD_MIN,
  UPCOMING_RESERVATION_WINDOW_HOURS,
} from '../../../hooks/app/tables/useTablesData';

const RESERVATIONS_POLLING_INTERVAL = 10000; // 10 seconds;
const HOURS_IN_A_DAY = 24;

const getReservationDateRange = () => {
  const now = new Date();
  const currentDate = format(now, 'yyyy-MM-dd');

  const previousDayReservationCutOff = set(now, {
    hours: 0,
    minutes: RESERVATION_GRACE_PERIOD_MIN,
    seconds: 0,
    milliseconds: 0,
  });

  const nextDayReservationCutoff = set(now, {
    hours: HOURS_IN_A_DAY - UPCOMING_RESERVATION_WINDOW_HOURS,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (isBefore(now, previousDayReservationCutOff)) {
    // Early morning period: Include previous day's reservations
    const previousDate = format(addDays(now, -1), 'yyyy-MM-dd');
    return { fromDate: previousDate, toDate: currentDate };
  } else if (isBefore(now, nextDayReservationCutoff)) {
    // Main operational period: Only today’s reservations
    return { fromDate: currentDate, toDate: currentDate };
  } else {
    // Late night period: Include upcoming reservations for tomorrow
    const nextDate = format(addDays(now, 1), 'yyyy-MM-dd');
    return { fromDate: currentDate, toDate: nextDate };
  }
};

export const ReservationHermes: React.FC = () => {
  const client = useApolloClient();
  const { fetchReservationsData } = useReservations();
  const [session] = useSession();
  const [posLocationId, setPosLocationId] = useState<string>();
  const intervalRef = useRef<number | null>(null);

  const fetchPosLocationId = useCallback(async () => {
    if (!session?.currentStore?.id) return;

    try {
      const { data } = await client.query({
        query: GET_INTEGRATION_PARTNERS,
        variables: {
          filter: {
            appName: IntegrationApps.OOLIO_RESERVATION,
            store: session.currentStore.id,
          },
        },
        fetchPolicy: 'network-only',
      });

      const integrationPartner = data?.integrationPartners?.[0];
      const posLocationId =
        integrationPartner?.preferences?.oolioReservation?.posLocationId;

      if (!posLocationId) {
        console.log('posLocationId not found');
        return;
      }
      setPosLocationId(posLocationId);
    } catch (err) {
      console.log('error fetching posLocationId:', err);
    }
  }, [client, session?.currentStore?.id]);

  useEffect(() => {
    fetchPosLocationId();
  }, [fetchPosLocationId]);

  const pollReservations = useCallback(async () => {
    if (!session?.currentOrganization?.id || !posLocationId) return;
    const { fromDate, toDate } = getReservationDateRange();

    try {
      const data = await fetchReservationsData({
        posLocationId,
        organizationId: session.currentOrganization.id,
        fromDate,
        toDate,
      });
      currentReservations(data || []);
    } catch (err) {
      console.log('error fetching reservations:', err);
    }
  }, [posLocationId, session?.currentOrganization?.id, fetchReservationsData]);

  useEffect(() => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        pollReservations();
        if (Object.keys(optimisticReservationsMap()).length > 0) {
          clearStaleOptimisticReservations();
        }
      }, RESERVATIONS_POLLING_INTERVAL);

      pollReservations(); // init run
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [pollReservations]);

  return null;
};
