import React, { useCallback, useMemo } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
  Customer,
  OrderAction,
  OrderStatus,
  OrderType,
  Table,
} from '@oolio-group/domain';
import { useModal } from '@oolio-group/rn-use-modal';
import { useCurrency, useTranslation } from '@oolio-group/localization';
import format from 'date-fns/format';
import { useNotification } from '../../../../hooks/Notification';
import CustomersStyles from '../Customers.styles';
import theme from '../../../../common/default-theme';
import Sticker from '../../../../components/Shared/Sticker/Sticker';
import Pagination from '../../../../components/POS/Pagination/Pagination';
import ButtonIcon from '../../../../components/Shared/TreatButton/ButtonIcon';
import PrintReceiptButton from '../../../../components/POS/Buttons/PrintReceipt/PrintReceiptButton';
import { PaymentTypeDisplay } from '../../Orders/OpenOrders/OpenOrders.types';
import { getTableNameFromOrder } from '@oolio-group/order-helper';
import Empty from '../../../../components/Shared/Empty/Empty';
import SendReceiptModal from '../../../../components/POS/Modals/SendReceipt/SendReceiptModal';

export interface OrderTypeDisplayProps extends OrderType {
  colorId: number;
}

export interface OnAccountItem {
  orderNumber: string;
  id: string;
  status: OrderStatus;
  createdAt: number;
  completedAt: number;
  customer: Customer;
  orderName?: string;
  customerName: string;
  orderType: OrderTypeDisplayProps;
  payTypes: Array<PaymentTypeDisplay>;
  totalValue: number;
  table: Table;
  tables: Table[];
}

export interface OnAccountOrderViewProps {
  data: OnAccountItem[];
  isOffline?: boolean;
  itemsPerPage: number;
  selectedPage: number;
  onChangeSelectedPage: (page: number) => void;
  onPressPrintReceipt: (orderId: string, nthPayment?: number) => void;
  onPressRePrintDocket: (orderId: string) => void;
  onPressRow: (id: string) => void;
  onSelectOrder?: (id: string) => void;
}

const OnAccountOrdersTable: React.FC<OnAccountOrderViewProps> = ({
  data,
  isOffline,
  itemsPerPage,
  selectedPage,
  onChangeSelectedPage,
  onPressPrintReceipt,
  onPressRePrintDocket,
  onPressRow,
}: OnAccountOrderViewProps) => {
  const { showModal } = useModal();
  const { translate } = useTranslation();
  const { formatCurrency } = useCurrency();
  const { showNotification } = useNotification();

  const pageItems = useMemo(() => {
    return data?.slice(
      (selectedPage - 1) * itemsPerPage,
      selectedPage * itemsPerPage,
    );
  }, [data, selectedPage, itemsPerPage]);

  const handleRowPress = useCallback(
    (orderId: string) => {
      if (isOffline) {
        showNotification({
          error: true,
          message: translate('offline.noInternet'),
        });
      } else {
        onPressRow(orderId);
      }
    },
    [isOffline, onPressRow, showNotification, translate],
  );

  const showPrintModal = useCallback(
    order => {
      if (order) {
        showModal(<SendReceiptModal order={order} />);
      }
    },
    [showModal],
  );

  const styles = CustomersStyles();

  return (
    <View style={styles.table}>
      <View style={styles.tableHeader}>
        <Text style={[styles.cellType, theme.tables.headerText]}>
          {translate('openOrders.type')}
        </Text>
        <Text style={[styles.cellNo, theme.tables.headerText]}>
          {translate('orderHistory.order')}
        </Text>
        <Text style={[styles.cellDate, theme.tables.headerText]}>
          {translate('onAccount.completedOn')}
        </Text>
        <Text style={[styles.cellFlex, theme.tables.headerText]}>
          {translate('onAccount.paymentMethod')}
        </Text>
        <Text style={[styles.cellAmount, theme.tables.headerText]}>
          {translate('onAccount.total')}
        </Text>
        <Text style={[styles.cellAmount, theme.tables.headerText]}>
          {translate('onAccount.balance')}
        </Text>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={{ width: 100 }} />
      </View>
      <View style={styles.tableBody}>
        {pageItems.length > 0 ? (
          pageItems.map((order, i) => {
            const isDineInOrder = order.orderType.code === 'D';

            const onAccountPaymentObj = order.payTypes.find(
              pay => pay.name === OrderAction.ON_ACCOUNT_PAYMENT_TYPE,
            );

            const outstandingBalance = onAccountPaymentObj?.amount || 0;

            return (
              <TouchableOpacity
                key={i}
                style={styles.tableRow}
                onPress={() => handleRowPress(order.id)}
              >
                <Sticker
                  testID="order-type"
                  themed
                  type={isDineInOrder ? 'neutralLight' : 'teal'}
                  label={
                    (isDineInOrder && getTableNameFromOrder(order)) ||
                    order.orderType?.code
                  }
                  containerStyle={styles.cellType}
                />
                <Sticker
                  testID="order-no"
                  themed
                  type={isDineInOrder ? 'neutralLight' : 'teal'}
                  label={order.orderNumber.slice(0, -9)}
                  containerStyle={styles.cellNo}
                />
                <Text style={styles.cellDate}>
                  {format(order.completedAt, 'dd/MM/yy hh:mm a')}
                </Text>
                <Text style={styles.cellFlex}>
                  {order.payTypes
                    ?.map(
                      payType =>
                        `${payType.name} (${formatCurrency(payType.amount)})`,
                    )
                    .join(', ')}
                </Text>
                <Text style={styles.cellAmount}>
                  {formatCurrency(order.totalValue)}
                </Text>
                <Text style={styles.cellAmount}>
                  {formatCurrency(outstandingBalance)}
                </Text>
                <PrintReceiptButton
                  themed
                  options={order.payTypes}
                  onPrintReceipt={paymentIndex =>
                    onPressPrintReceipt(order.id, paymentIndex)
                  }
                  reprintDocket={() => {
                    onPressRePrintDocket(order.id);
                  }}
                  orderStatus={OrderStatus.COMPLETED}
                />
                <ButtonIcon
                  icon="envelopeAlt"
                  themed
                  type="neutralLight"
                  onPress={() => showPrintModal(order)}
                  containerStyle={styles.btnReceipt}
                />
              </TouchableOpacity>
            );
          })
        ) : (
          <Empty themed label={translate('customerView.emptyOrders')} />
        )}
      </View>
      {data.length > 10 ? (
        <Pagination
          pageLength={10}
          page={selectedPage}
          onPageChange={onChangeSelectedPage}
          dataLength={data.length}
        />
      ) : null}
    </View>
  );
};

export default OnAccountOrdersTable;
