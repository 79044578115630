import jwt_decode, { JwtPayload } from 'jwt-decode';
import { parseToFloat } from './parseToFloat';
import { getPhoneNumberValidationSchemaByPhoneCode } from './validatorTemplate';

const maxIntegerDigitAllowedForPrice = 9;
const maxFractionDigitAllowedForPrice = 2;

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email) && email?.length < 50;
};

export const isStrongPassword = (password: string): boolean => {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g;
  return re.test(password);
};

export const isValidName = (name: string): boolean => {
  const re = /^(?=.*[a-zA-Z]){5,}([^0-9]*)$/g;
  return re.test(name);
};

/*
Reuses the alpha check from isValidName as that was used previously
Still requires 5 minimum characters
Now allows for numbers + spaces
*/
export const isValidBusinessName = (name: string): boolean => {
  const re = /^(?=.*[a-zA-Z])[A-Za-z\d_ ]{5,}$/g;
  return re.test(name);
};

export const isValidPrice = (price: string): boolean => {
  if (!isNotEmpty(price)) return false;
  if (price.includes('.')) {
    const [number, fraction] = price.split('.');
    return (
      number.length <= maxIntegerDigitAllowedForPrice &&
      isDigit(number) &&
      fraction.length <= maxFractionDigitAllowedForPrice &&
      isDigit(fraction)
    );
  } else {
    return price.length <= 9 && isDigit(price);
  }
};

export const isDigit = (name: string): boolean => {
  const re = /^\d+$/;
  return re.test(name);
};

export const isValidPinNumber = (number: string): boolean => {
  const re = /^[0-9]{4,6}$/;
  return re.test(number);
};

export const isValidPhoneNumber = (number: string): boolean => {
  const re = /^[0-9]{10}$/;
  return re.test(number);
};

export const isValidNumber = (number: string): boolean => {
  const phoneSplit = (number || '').split(' ');

  if (phoneSplit.length !== 2) {
    return false;
  }

  const phoneCode = phoneSplit[0] || '';
  const schemaPhoneNumber =
    getPhoneNumberValidationSchemaByPhoneCode(phoneCode);
  const regPhone = new RegExp(schemaPhoneNumber, 'g');

  return regPhone.test(number);
};

export const isValidABN = (number: string): boolean => {
  const re = /^[0-9]{11}$/;
  return re.test(number);
};

export const isValidBusinessIdentifier = (
  number: string,
  country: string | undefined,
): boolean => {
  switch (country) {
    case 'GB':
      const gb = /^(?:GB)?(?:\d{3} ?\d{4} ?\d{2}(?:\d{3})?|[A-Z]{2}\d{3})$/;
      return gb.test(number);
    case 'AU':
      const au = /^[0-9]{11}$/;
      return au.test(number);
    case 'NZ':
      const nz = /^[0-9]{13}$/;
      return nz.test(number);
    default:
      return true;
  }
};

export const isValidDomain = (text: string): boolean =>
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    text,
  );

export const isValidPostCode = (
  number: string,
  country: string | undefined,
): boolean => {
  switch (country) {
    case 'GB':
      const gb = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
      return gb.test(number);
    case 'AU':
      const au = /^[0-9]{4}$/;
      return au.test(number);
    case 'US':
      const us = /\d{5}([ \-]\d{4})?/;
      return us.test(number);
    default:
      return isNotEmpty(number);
  }
};

export const isNotEmpty = (value = ''): boolean =>
  value?.replace(/\s/g, '').length > 0;

/**
 *
 * @description validation for allow the value to be 9 digits with two decimal max. Expect no thousand separator present
 * @param {string} value
 * @param {string} [decimalSeparator=.]
 * @returns {boolean}
 */
export const is9DigitWith3Decimal = (value: string, decimalSeparator = '.') => {
  const split = value.split('');
  let isAllowed = true;
  split.some((item: string) => {
    if (isNaN(Number(item))) {
      if (item === decimalSeparator) {
        isAllowed = true;
      } else {
        isAllowed = false;
      }
    }
  });
  if (isAllowed) {
    const check = split.some((item: string) => item === decimalSeparator);
    if (!Number.isInteger(Number(value))) {
      if (value.toString().split(decimalSeparator)[1].length >= 4) {
        return false;
      }
    } else if (Number.isInteger(Number(value))) {
      if (check) {
        return true;
      }
      if (value.length > 9) {
        return false;
      }
      return true;
    } else if (value.length > 12) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const isGreaterThanLimit = (value: string, limit: number): boolean => {
  if (!isDigit(value) && !parseToFloat(value)) {
    return false;
  }
  const parsedValue = parseToFloat(value);
  if (limit < parsedValue) {
    return true;
  } else {
    return false;
  }
};

export const isLessThanLimit = (value: string, limit: number): boolean => {
  if (!isDigit(value) && !parseToFloat(value)) {
    return false;
  }
  const parsedValue = parseToFloat(value);
  if (parsedValue <= limit) {
    return true;
  } else {
    return false;
  }
};

export const isFloat = (value: string): boolean => {
  if (!isNotEmpty(value)) return false;
  if (value.includes('.')) {
    const [num, frac] = value.split('.');
    return isDigit(num) && frac ? isDigit(frac) : true;
  } else return isDigit(value);
};

export const getCounterKeyPadMaxValue = (value: number) => {
  return Number(Array(value + 1).join('9'));
};

export const trimString = (text: string): string => text.replace(/\s/g, '');

export const OFFLINE_ERROR_MESSAGES = [
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource.', // Firefox
  'Network Request Failed', // Android.
  'The internet connection appears to be offline.', // Safari
  'Load failed', // Safari
  'Network request failed', // iOS
];

export function isErrorForDeviceOffline(errorMessage: string): boolean {
  return OFFLINE_ERROR_MESSAGES.indexOf(errorMessage) !== -1;
}

export function isValidToken(token: string) {
  const decoded = jwt_decode<JwtPayload>(token);
  const exp = decoded?.exp ?? 0;
  const currentTime = Date.now() / 1000;
  return exp > currentTime;
}

export function isWithinCharLimit(
  value: string,
  min?: number,
  max?: number,
): boolean {
  if (min !== undefined && value.length < min) return false;
  if (max !== undefined && value.length > max) return false;
  return true;
}
